import FormattedTime from "#Root/formatters/FormattedTime"

import { MARKERS_HEIGHT } from "../../constants"

const NotificationMarker = ({ marker, spacingLeft }) => {
  return (
    <div
      className="h-full absolute top-[50%] -translate-x-2/4 -translate-y-2/4 flex items-center justify-center group"
      data-marker-type="notification"
      style={{ left: `${spacingLeft}px` }}
    >
      <i className="fa fa-comment h-3 w-3"></i>
      <div
        className="absolute bottom-0 c-box w-56 p-4 hidden group-hover:block"
        style={{ marginBottom: `${MARKERS_HEIGHT / 2 + 15}px` }}
      >
        <table className="c-table">
          <tbody>
            <tr>
              <td>
                Time: <FormattedTime value={marker.createdAt} format="short" />
              </td>
            </tr>
            <tr>
              <td>{marker.message ? marker.message : "No message"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

NotificationMarker.propTypes = {
  marker: PropTypes.object.isRequired,
  spacingLeft: PropTypes.number.isRequired,
}

export default NotificationMarker
