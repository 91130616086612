import PropTypes from "prop-types"
import React from "react"

export const FooterItem = ({ children, className, ...props }) => {
  return (
    <div
      className={`c-virtual-table__footer-row-cell ${className}`}
      {...props}
    >
      <div className="py-4 align-middle leading-normal">{children}</div>
    </div>
  )
}

const Footer = ({ children }) => {
  return <div className="c-virtual-table__footer-row border-l border-r border-b">{children}</div>
}

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  __TYPE: PropTypes.string,
}

Footer.defaultProps = {
  __TYPE: "Footer",
}

FooterItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Footer
