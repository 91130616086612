import gql from "graphql-tag"

import TimelineEvent from "./_timeline_event_fragment"

export default gql `
  fragment PerformanceSample on PerformanceSample {
    id
    appId
    time
    revision
    action
    namespace
    originallyRequested
    hasNPlusOne
    timelineTruncatedEvents
    originalId
    overview {
      key
      value
    }
    params
    sessionData
    attributes {
      key
      value
    }
    customData
    environment {
      key
      value
    }
    duration
    queueDuration
    timeline {
      ...TimelineEvent
    }
    version
  }
  ${TimelineEvent}
`
