import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export default gql`
  mutation createVisualTimeseriesMutation(
    $appId: String!,
    $dashboardId: String!,
    $visual: VisualTimeseriesInput!
  ) {
    createVisualTimeseries(
      appId: $appId,
      dashboardId: $dashboardId,
      visual: $visual
    ) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`
