// Main content auto-focuser
//
// Selects the "main" element on page load and when nothing else (input
// elements and such) is focussed.
// When the scrollable navigation area is selected, it will not focus the main
// area, but keep on the navigation element instead.
//
// This fixes the arrow key navigation on page load without having to click the
// main content element in order for it to get focussed.
//
// Works in combination with `tabindex="-1"` on the elements that should be
// focusable by JavaScript.
$(() => {
  const navElement = document.getElementById("scrollable-nav")
  if (navElement) {

    document.addEventListener("keydown", _ => {
      const currentlyFocussedElement = document.activeElement
      const defaultFocus = currentlyFocussedElement === document.body

      if (defaultFocus) {
        const mainElement = findMainElement()
        mainElement.focus()
      }
    })
  }
})

function findMainElement() {
  const innerElement = document.getElementById("main-inner")
  if (innerElement) {
    // Incident sample detail show page, page with 2 sidebars
    return innerElement
  } else {
    // All other in-app pages
    return document.getElementById("main")
  }
}
