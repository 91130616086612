import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export const mockData = {
  "app": {
    "id": "55df0815776f720dee49d101",
    "dashboard": {
      "__typename": "Dashboard",
      "id": "5ea815f014ad6675639fba6d",
      "title": "Allocation count",
      "description": null,
      "graphs": [],
      "visuals": [
        {
          "__typename": "VisualTimeseries",
          "id": "5ec637ea14ad6605d0f5a2d9",
          "title": "Allocation count for all actions in \"web\" namespace",
          "description": null,
          "lineLabel": "%action%",
          "display": "LINE",
          "format": null,
          "formatInput": null,
          "drawNullAsZero": true,
          "metrics": [
            {
              "name": "transaction_allocation_count",
              "fields": [
                {
                  "field": "COUNTER",
                  "__typename": "VisualTimeseriesMetricField"
                }
              ],
              "tags": [
                {
                  "key": "namespace",
                  "value": "web",
                  "__typename": "KeyStringValue"
                },
                {
                  "key": "action",
                  "value": "*",
                  "__typename": "KeyStringValue"
                }
              ],
              "__typename": "VisualTimeseriesMetric"
            }
          ]
        },
        {
          "__typename": "VisualTimeseries",
          "id": "5ec637ea14ad6605d0f5a2da",
          "title": "Allocation count for all actions in \"background\" namespace",
          "description": null,
          "lineLabel": "%action%",
          "display": "LINE",
          "format": null,
          "formatInput": null,
          "drawNullAsZero": true,
          "metrics": [
            {
              "name": "transaction_allocation_count",
              "fields": [
                {
                  "field": "COUNTER",
                  "__typename": "VisualTimeseriesMetricField"
                }
              ],
              "tags": [
                {
                  "key": "namespace",
                  "value": "background",
                  "__typename": "KeyStringValue"
                },
                {
                  "key": "action",
                  "value": "*",
                  "__typename": "KeyStringValue"
                }
              ],
              "__typename": "VisualTimeseriesMetric"
            }
          ]
        }
      ]
    },
    "__typename": "App"
  }
}

export default gql `query ExportDashboardQuery(
  $appId: String!,
  $id: String!
) {
  app(id: $appId) {
    id
    dashboard(id: $id) {
      ...Dashboard
    }
  }
}
${DashboardFragment}
`
