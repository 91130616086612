import gql from "graphql-tag"

import TriggerFragment from "./_trigger_fragment"

export default gql`
  mutation createTriggerMutation(
    $previousTriggerId: String,
    $appId: String!,
    $name: String,
    $metricName: String!,
    $tags: [KeyStringValueInput!],
    $kind: String!,
    $field: MetricFieldEnum!,
    $comparisonOperator: ThresholdAlertSettingComparisonOperationEnum!,
    $conditionValue: Float!,
    $warmupDuration: Int!,
    $cooldownDuration: Int!,
    $notifierIds: [String!],
    $noMatchIsZero: Boolean,
    $description: String
    $dashboardId: String,
    $format: String,
    $formatInput: String
  ) {
    createTrigger(
      previousTriggerId: $previousTriggerId,
      appId: $appId,
      name: $name,
      metricName: $metricName,
      tags: $tags,
      kind: $kind,
      field: $field,
      notifierIds: $notifierIds,
      noMatchIsZero: $noMatchIsZero,
      description: $description,
      condition: {
        comparisonOperator: $comparisonOperator,
        value: $conditionValue
      },
      warmupDuration: $warmupDuration,
      cooldownDuration: $cooldownDuration
      dashboardId: $dashboardId,
      format: $format,
      formatInput: $formatInput
    ) {
      ...Trigger
    }
  }
  ${TriggerFragment}
`
