import gql from "graphql-tag"

export default gql`
  fragment ShortcutStory on ShortcutStory {
    id
    title
    description
    url
  }
`
