export default class CheckIns {
  client

  constructor(client) {
    this.client = client
  }

  debugCron(params, options = {}) {
    return this.client.post("/api/v2/check_ins/test_cron", params, options)
  }
}
