import Mousetrap from "mousetrap"
import PropTypes from "prop-types"
import React from "react"
import onClickOutside from "react-onclickoutside"

export class PopOver extends React.Component {
  static displayName = "PopOver"
  static propTypes = {
    innerRef: PropTypes.object,
    children: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    position: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    withEscapeListener: PropTypes.bool
  }
  static defaultProps = {
    position: "top-full",
    width: "w-64",
    height: "max-h-96",
    className: "",
    style: {},
    withEscapeListener: true
  }

  componentDidMount() {
    const { onClose, withEscapeListener } = this.props
    if (!withEscapeListener) { return }

    Mousetrap.bind(["escape"], _ => {
      return onClose()
    })
  }

  componentWillUnmount() {
    if (!this.props.withEscapeListener) { return }

    Mousetrap.unbind("escape")
  }

  render() {
    const { innerRef, position, width, height, className, style } = this.props
    const classes = [
      "c-popover z-20 overflow-y-auto overflow-x-hidden",
      position,
      width,
      height,
      className
    ]

    return (
      <div
        tabIndex="0" // makes the element focusable.
        ref={innerRef}
        className={classes.join(" ")}
        style={style}
      >
        {this.props.children}
      </div>
    )
  }

  handleClickOutside(event) {
    event.preventDefault()
    event.stopPropagation()
    this.props.onClose()
  }
}

export default onClickOutside(PopOver)
