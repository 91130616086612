import { useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React from "react"

import AccountsQuery from "../../../graphql/accounts_query"

const AppSelector = ({ organizationSlug, appId, onSelect }) => {
  const { data, loading } = useQuery(AccountsQuery)
  const handleSelect = (organizationSlugAndAppId) => {
    if (!organizationSlugAndAppId) { return }

    const [organizationSlug, appId] = organizationSlugAndAppId.split("_")
    onSelect({ organizationSlug, appId })
  }

  let appSelector
  if (loading) {
    appSelector = (
      <select
        id="app_select"
        key="loading_placeholder"
        defaultValue=""
        className="c-select__input"
      >
        <option value="" hidden>Loading...</option>
      </select>
    )
  } else {
    const orgAppsOptions = []
    const organizations = data.viewer.organizations
    organizations.forEach(org => {
      const appOptions = []
      org.apps.forEach(app => {
        appOptions.push(
          <option
            key={`app_option_${app.id}`}
            value={`${org.slug}_${app.id}`}
          >
            {app.name} ({app.environment})
          </option>
        )
      })

      orgAppsOptions.push(
        <optgroup
          key={`org_${org.slug}`}
          label={org.name}
        >
          {appOptions}
        </optgroup>
      )
    })
    appSelector = (
      <select
        id="app_select"
        key="app_selector"
        defaultValue={`${organizationSlug}_${appId}`}
        className="c-select__input"
        onChange={event => handleSelect(event.target.value)}
      >
        {orgAppsOptions}
      </select>
    )
  }

  return (
    <>
      <label htmlFor="app_select">App</label>
      <div className="c-form__element">
        <div className="c-select">
          {appSelector}
        </div>
      </div>
    </>
  )
}

AppSelector.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default AppSelector
