import URL_LIST from "../../base/url_list"

class UrlHelper {
  static get timeDefaults() {
    return ["timeframe", "from", "to"]
  }
  // Encode special characters to match the server side interpretation of
  // unsafe characters in URLs.
  //
  // Example:
  //   UrlHelper.encodeAction("foo#bar/baz?.")
  //   // => "foo%23bar%2Fbaz%3F."
  static encodeAction(value) {
    return encodeURIComponent(value)
  }

  // Encode special characters to match the server side interpretation of
  // unsafe characters in URLs.
  //
  // Example:
  //   UrlHelper.decodeAction("foo%23bar%2Fbaz%3F.")
  //   // => "foo#bar/baz?."
  static decodeAction(value) {
    const regex = /(-[a-z]+-)/g
    const decodedAction = value.replace(regex, (match) => {
      switch (match) {
        case "-hash-":
          return "#"
        case "-dot-":
          return "."
        case "-questionmark-":
          return "?"
        case "-slash-":
          return "/"
        default:
          return match
      }
    })
    return decodeURIComponent(decodedAction)
  }

  // Defines helper functions for paths.
  //
  // Example:
  //
  //     URL_LIST = {
  //       ':organizationSlug/sites/:appId': {
  //         'controller': 'FooController||organizationSlug|appId',
  //         'as': 'Site' // name of of helper prefix
  //       }
  //     }
  //
  // The `as` option defines the helper prefix.
  // Example: `'as': 'Site'` // results in `UrlHelper.SitePath()`.
  //
  // The URL parameters can be given as an object to the helper function. Known
  // parameters currently present in the page's URL are added to the URL, but can
  // be overwritten by given arguments.
  //
  // Usage example:
  //
  //     UrlHelper.SitePath()
  //     // => /appsignal/sites/123456abcdef
  //     UrlHelper.SitePath({ 'organizationSlug': 'foo' })
  //     // => /foo/sites/123456abcdef
  static init() {
    for (const url of Object.keys(URL_LIST)) {
      const options = URL_LIST[url]
      if (options.as) {
        ((urlDefinition, options) => {
          UrlHelper[`${options.as}Path`] = (params, defaultParams) => {
            let url = urlDefinition
            const queryParams = new URLSearchParams()

            // Set params given to function manually first
            if (params) {
              for (const param of Object.keys(params)) {
                const value = params[param]
                const placeholder = `:${param}`
                if (url.indexOf(placeholder) > -1) {
                  url = url.replace(placeholder, value)
                } else if (value != null) {
                  queryParams.append(param, value)
                }
              }
            }
            // Set defaults given as second argument
            if (defaultParams === UrlHelper.ALL_PARAMS) {
              if (window._routerParams && window._routerParams.rawParams && window._routerParams.rawParams) {
                for (const param in window._routerParams.rawParams) {
                  if (queryParams.has(param)) { continue }
                  const value = window._routerParams.rawParams[param]

                  queryParams.append(param, value)
                }
              }
            } else if (defaultParams) {
              for (const param of defaultParams) {
                if (!queryParams.has(param) && window._routerParams && window._routerParams.rawParams && window._routerParams.rawParams[param]) {
                  const value = window._routerParams.rawParams[param]
                  queryParams.append(param, value)
                }
              }
            }

            // Set known params to those placeholders in the URL that still exist
            const knownParams = window._routerParams
            if (knownParams) {
              for (const param of Object.keys(knownParams)) {
                const value = knownParams[param]
                url = url.replace(`:${param}`, value)
              }
            }
            // Replace optional slash string from url (/)
            url = url.replace("(/)", "")

            // Set leftover given params as query params
            let queryString = queryParams.toString()
            if (queryString.length > 0) {
              queryString = `?${queryString}`
            }
            return `/${url}${queryString}`
          }
        })(url, options)

        UrlHelper[`${options.as}Url`] = (params, defaultParams) => {
          return `${window.location.protocol}//${window.location.hostname}${UrlHelper[`${options.as}Path`](params, defaultParams)}`
        }
      }
    }
  }
}

UrlHelper.ALL_PARAMS = "ALL_PARAMS"

export default UrlHelper
