const LoadingState = ({ height }) => {
  return (
    <div className="flex items-center justify-center flex-col" style={{ height }}>
      <img src="/assets/puff.svg" /> Loading data
    </div>
  )
}

LoadingState.propTypes = {
  height: PropTypes.number,
}

export default LoadingState
