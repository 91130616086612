import PropTypes from "prop-types"
import React from "react"

import { useRouter } from "#Root/hooks"

import Link from "./link"

const LinkToParams = ({ params, ...rest }) => {
  const { currentPathWithParams } = useRouter()
  return <Link href={currentPathWithParams(params)} {...rest} />
}

LinkToParams.propTypes = {
  params: PropTypes.object.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

export default LinkToParams
