import gql from "graphql-tag"

export default gql `
  fragment StatusPage on StatusPage {
    id
    title
    hasSsl
    forceSsl
    hostname
    description
    threshold
    uptimeMonitorIds
  }
`
