import TagsFormCombinations from "#Components/shared/tags_form/Combinations"
import TagsFormCombinationWarning from "#Components/shared/tags_form/CombinationWarning"
import TagsFormInputPairs from "#Components/shared/tags_form/InputPairs"

const Tags = ({ triggerData, metricMetadata, onChange }) => {
  const childProps = {
    tags: triggerData.tags || [],
    metricMetadata,
    onChange,
  }
  return (
    <>
      <div className="c-form">
        <label className="c-form__label">Tags</label>
        <div className="c-form__element">
          <TagsFormInputPairs {...childProps} />
          <TagsFormCombinationWarning {...childProps} />
          <TagsFormCombinations {...childProps} />
        </div>
      </div>
      <hr />
    </>
  )
}

Tags.propTypes = {
  triggerData: PropTypes.object.isRequired,
  metricMetadata: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default Tags
