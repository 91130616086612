import EnvironmentLabel from "#Components/shared/environment_label"
import Dropdown from "#Root/ui/Dropdown"

const appButtonText = (data, loading, appId) => {
  if (loading && !data?.organization) return "Loading..."
  if (appId == null || appId == "") return "All apps"

  const app = (data?.organization?.apps || []).find((a) => a.id === appId)
  if (!app) return "All apps"

  return (
    <>
      {app.name}
      {" "}
      <EnvironmentLabel env={app.environment} />
    </>
  )
}

const AppFilter = ({ setParams, data, loading, params }) => {
  return (
    <Dropdown select="single" closeOnSelect={false}>
      <Dropdown.Button>{appButtonText(data, loading, params.searchAppId)}</Dropdown.Button>
      <Dropdown.Items align="left" width="w-96">
        <Dropdown.Item
          onSelect={() => setParams({ searchAppId: null })}
          selected={params.searchAppId == null || params.searchAppId == ""}
        >
          All apps
        </Dropdown.Item>
        {(data?.organization?.apps || []).sort((a, b) => a.name.localeCompare(b.name)).map((app) => (
          <Dropdown.Item
            key={app.id}
            onSelect={() => setParams({ searchAppId: app.id, namespace: app.namespaces.includes(params.namespace) ? params.namespace : null })}
            selected={params.searchAppId == app.id}
          >
            {app.name}
            {" "}
            <EnvironmentLabel env={app.environment} />
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}

AppFilter.propTypes = {
  setParams: PropTypes.func.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool,
  params: PropTypes.object,
}

export default AppFilter
