import PropTypes from "prop-types"
import React, { useContext } from "react"

import { FormatterContext } from "../../contexts/FormatterContext"

const ImpactPercent = ({ val, total }) => {
  const formatter = useContext(FormatterContext)
  let impactPercentage = 0

  if (val !== 0) {
    impactPercentage =  Math.round((val / total) * 10000) / 100
    if (impactPercentage < 0.001) { impactPercentage = "< 0.001" }
  }

  if (formatter.number === "DC") {
    impactPercentage = `${impactPercentage}`.replace(".", ",")
  }

  return <span>{impactPercentage} <span className="postfix">%</span></span>
}

ImpactPercent.propTypes = {
  val: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default ImpactPercent
