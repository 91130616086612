import PropTypes from "prop-types"
import React from "react"
import { graphql } from "react-apollo"

import CreateGithubIssueMutation from "../../../graphql/create_github_issue_mutation"
import { IntegrationDescription, IntegrationTitle } from "../../../utils/incident"
import ValidationErrors from "../../shared/validation_errors"

export class GitHubForm extends React.Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    incident: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  state = {
    description: IntegrationDescription(this.props.incident, this.props.incident.sample),
    title: IntegrationTitle(this.props.incident),
    saveButtonText: "Create GitHub issue",
  }

  handleTitleChange = (event) => {
    this.setState({ title: event.target.value })
  }

  handleDescriptionChange = (event) => {
    this.setState({ description: event.target.value })
  }

  handleSubmit = (event) => {
    const {
      mutate,
      appId,
      incident: { number },
    } = this.props
    event.preventDefault()
    event.stopPropagation()
    this.setState({
      saveButtonText: "Saving...",
    })

    mutate({
      variables: {
        appId: appId,
        incidentNumber: number,
        title: this.state.title,
        description: this.state.description,
      },
    })
      .then((_) => {
        this.props.onSubmit()
      })
      .catch((error) => {
        this.setState({ error, saveButtonText: "Retry" })
      })
  }

  render() {
    return (
      <div className="p-4">
        {this.state.error && <ValidationErrors error={this.state.error} />}
        <form noValidate="novalidate" onSubmit={this.handleSubmit} acceptCharset="UTF-8">
          <fieldset>
            <div className="mb-4">
              <label htmlFor="title" className="c-form__label">
                Title of issue
              </label>
              <input
                type="text"
                value={this.state.title}
                name="title"
                onChange={this.handleTitleChange}
                id="title"
                className="c-form__input"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="description" className="c-form__label">
                Description
              </label>
              <textarea
                name="description"
                value={this.state.description}
                onChange={this.handleDescriptionChange}
                id="description"
                rows="10"
                className="c-form__input"
              />
            </div>
            <input
              type="submit"
              value={this.state.saveButtonText}
              name="commit"
              className="c-button c-button--sm mb-1"
            />
          </fieldset>
        </form>
      </div>
    )
  }
}

export default graphql(CreateGithubIssueMutation)(GitHubForm)
