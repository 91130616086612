import PropTypes from "prop-types"
import React from "react"

import { measurementFields } from "../../../../utils/metric_keys"
import MetricFormFieldCheckboxes from "./field/Checkboxes"
import MetricFormFieldDropdown from "./field/Dropdown"

const MetricFormField = ({onChange, metric, metricMetadata}) => {
  const { fields } = metric
  const measurementFieldKeys = Object.keys(measurementFields)
  const containsMeasurementFields = fields ? (fields.length === 0 || fields.some(field => measurementFieldKeys.includes(field.field))) : false

  return (
    <>
      {!metricMetadata && <MetricFormFieldDropdown onChange={onChange} metric={metric} containsMeasurementFields={containsMeasurementFields} />}
      {containsMeasurementFields && <MetricFormFieldCheckboxes onChange={onChange} metric={metric} /> }
    </>
  )
}

MetricFormField.propTypes = {
  onChange: PropTypes.func.isRequired,
  metric: PropTypes.object.isRequired,
  metricMetadata: PropTypes.object
}

export default MetricFormField
