const TriggerFormNamespace = ({ namespace, namespaceParam, namespaces, onChange }) => {
  const namespaceOptions = [{ label: "All namespaces", value: "*" }]
  for (const namespace of namespaces) {
    namespaceOptions.push({ label: namespace, value: namespace })
  }
  const knownNamespaceFromParam = isKnownNamespace(namespaceParam, namespaces)

  const options = namespaceOptions.map(({ label, value }) => (
    <option key={value} value={value}>
      {label}
    </option>
  ))
  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="namespace">
        Namespace
      </label>
      <div className="c-form__element">
        <div className="c-select">
          <select
            onChange={(e) => onChange(e.target.value)}
            id="namespace"
            name="namespace"
            value={namespace}
            className="c-select__input required"
          >
            {options}
          </select>
        </div>
        {!knownNamespaceFromParam && (
          <p className="c-form__error">
            Namespace <code>{namespaceParam}</code> does not exist for this app. Please select
            another namespace.
          </p>
        )}
      </div>
    </div>
  )
}

function isKnownNamespace(paramValue, namespaces) {
  if ((paramValue || "").trim() === "") {
    return true // No param value is present, so don't check it
  }

  return namespaces.includes(paramValue)
}

export function findNamespace(namespace, namespaces) {
  if (namespaces.includes(namespace)) {
    return namespace
  } else {
    return "*" // Default "All namespaces" option
  }
}

TriggerFormNamespace.propTypes = {
  namespace: PropTypes.string,
  namespaceParam: PropTypes.string,
  namespaces: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TriggerFormNamespace
