import PropTypes from "prop-types"
import React, { useEffect } from "react"

import { usePageTitle } from "#Root/hooks/usePageTitle"
import { useRouter } from "#Root/hooks/useRouter"

import Overlay from "../../components/shared/overlay"
import Ident from "../../utils/Ident"
import Form from "./Form"

const states = {
  new: {
    title: "Create new uptime monitor",
    metric: "UptimeMonitorNew",
  },
  edit: {
    title: "Edit uptime monitor",
    metric: "UptimeMonitorEdit",
  },
}

const UptimeMonitorOverlay = ({ appId, uptimeMonitorId }) => {
  const { navigateWithParams } = useRouter()
  const stateKey = uptimeMonitorId ? "edit" : "new"
  const state = states[stateKey]

  usePageTitle(state.title)
  useEffect(() => {
    Ident.trackAction(state.metric)
  })

  const handleClose = () => {
    closeOverlay()
  }

  const handleCloseWithoutConfirmation = () => {
    closeOverlay()
  }

  const closeOverlay = () => {
    navigateWithParams({ overlay: null, uptimeMonitorId: null })
  }

  return (
    <Overlay classNames="medium" onClose={handleClose} title={state.title}>
      <Form
        appId={appId}
        uptimeMonitorId={uptimeMonitorId}
        closeOverlay={handleCloseWithoutConfirmation}
      />
    </Overlay>
  )
}

UptimeMonitorOverlay.propTypes = {
  appId: PropTypes.string.isRequired,
  uptimeMonitorId: PropTypes.string,
}

export default UptimeMonitorOverlay
