import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Form, { useForm, useWatch } from "#Components/forms/Form"
import createMutation from "#Graphql/createStatusPageUpdateMutation"
import deleteMutation from "#Graphql/deleteStatusPageUpdateMutation"
import statusPagesQuery from "#Graphql/statusPagesQuery"
import statusPageUpdateFormQuery from "#Graphql/statusPageUpdateFormQuery"
import updateMutation from "#Graphql/updateStatusPageUpdateMutation"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import Ident from "../../utils/Ident"
import ErrorBox from "../shared/error_box"
import ValidationErrors from "../shared/validation_errors"

const states = {
  new: {
    submitLabel: "Create new status page update",
  },
  edit: {
    submitLabel: "Update",
  },
}

const StateFieldHint = ({ control }) => {
  const state = useWatch({
    control: control,
    name: "state",
    defaultValue: null,
  })

  switch (state) {
    case "RESOLVED":
      return (
        <div className="c-message c-message--blue w-full px-3 py-2 mt-2 space-y-1">
          <p>
            Posting an update with the state <span className="font-medium">resolved</span> will
            report page as <span className="font-medium">up</span>.
          </p>
        </div>
      )
    case "INVESTIGATING":
    case "IDENTIFIED":
    case "RECOVERING":
      return (
        <div className="c-message c-message--red w-full px-3 py-2 mt-2 space-y-1">
          <p>
            Posting an update with the state <span className="font-medium">investigating</span> will
            report page as <span className="font-medium">down</span>.
          </p>
        </div>
      )
    default:
      return (
        <div className="c-message c-message--blue w-full px-3 py-2 mt-2 space-y-1">
          <p>This will determine the global status of the status page:</p>
          <ul className="list-inside list-disc pl-2">
            <li>
              Selecting <span className="font-medium">investigating</span>,{" "}
              <span className="font-medium">identified</span>, or{" "}
              <span className="font-medium">recovering</span> will report page as{" "}
              <span className="font-medium">down</span>.
            </li>
            <li>
              Selecting <span className="font-medium">resolved</span> will report page as{" "}
              <span className="font-medium">up</span>.
            </li>
          </ul>
        </div>
      )
  }
}

StateFieldHint.propTypes = {
  control: PropTypes.object.isRequired,
}

const StatusPageForm = ({ organizationSlug, statusPageId, statusPageUpdateId, closeOverlay }) => {
  const [mutationError, setMutationError] = useState(null)
  // Remove this when using the proper form inputs
  const onSuccess = (identMetric) => {
    setMutationError(null)
    closeOverlay()
    Ident.trackAction(identMetric)
  }

  const onError = (message, error) => {
    // eslint-disable-next-line no-console
    console.error(message, error)
    setMutationError(error)
  }

  // Mutations to create/update/delete the monitor
  const [createStatusPageUpdate] = useMutation(createMutation, {
    onCompleted() {
      onSuccess("StatusPageUpdateOverlayCreate")
    },
    onError(error) {
      onError("Error creating uptime monitor", error)
    },
    refetchQueries: [{ query: statusPagesQuery, variables: { organizationSlug } }],
  })

  const [updateStatusPageUpdate] = useMutation(updateMutation, {
    onCompleted() {
      onSuccess("StatusPageUpdateOverlayUpdate")
    },
    onError(error) {
      onError("Error updating uptime monitor", error)
    },
    refetchQueries: [{ query: statusPagesQuery, variables: { organizationSlug } }],
  })

  const [deleteStatusPageUpdate] = useMutation(deleteMutation, {
    onCompleted() {
      onSuccess("StatusPageUpdateOverlayDelete")
    },
    onError(error) {
      onError("Error removing uptime monitor", error)
    },
    refetchQueries: [{ query: statusPagesQuery, variables: { organizationSlug } }],
  })

  // Form handlers
  const stateKey = statusPageUpdateId ? "edit" : "new"
  const state = states[stateKey]
  const formControl = useForm({ mode: "onChange" })
  const handleSubmit = (data) => {
    if (stateKey === "edit") {
      updateStatusPageUpdate({
        variables: {
          organizationSlug,
          statusPageId,
          id: statusPageUpdateId,
          statusPageUpdate: data,
        },
      })
    } else {
      createStatusPageUpdate({
        variables: {
          organizationSlug,
          statusPageId,
          statusPageUpdate: data,
        },
      })
    }
  }
  const handleDelete = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm("Are you sure you want to delete this status page update?")) {
      deleteStatusPageUpdate({
        variables: { id: statusPageUpdateId, statusPageId, organizationSlug },
      })
    }
  }

  // Get the status page to update and the Uptime Monitors for this org
  const { data, loading, error } = useQuery(statusPageUpdateFormQuery, {
    variables: { organizationSlug, statusPageId, id: statusPageUpdateId },
  })

  // Render states or form
  if (loading) {
    return <LoadingAnimation message="Loading data" />
  }
  if (error) {
    return <ErrorBox error={error} />
  }

  return (
    <div className="c-box mb-0 bg-gray-100 text-gray-800">
      {mutationError && <ValidationErrors error={mutationError} />}
      <div className="py-4 px-5">
        <Form formControl={formControl} onSubmit={(data, event) => handleSubmit(data, event)}>
          <Form.Field>
            <Form.Label name="state">Status</Form.Label>
            <Form.Hint name="state">Required</Form.Hint>
            <Form.Select
              name="state"
              defaultValue={
                data.organization.statusPageUpdate ? data.organization.statusPageUpdate.state : null
              }
              options={[
                { key: null, value: "Select status" },
                { key: "INVESTIGATING", value: "Investigating" },
                { key: "IDENTIFIED", value: "Identified" },
                { key: "RECOVERING", value: "Recovering" },
                { key: "RESOLVED", value: "Resolved" },
              ]}
            />
            <StateFieldHint control={formControl.control} />
          </Form.Field>
          <hr className="-mx-5 my-6 ignore-old-css" />
          <Form.Field>
            <Form.Label name="title">Title</Form.Label>
            <Form.Hint name="state">Required</Form.Hint>
            <Form.TextField
              name="title"
              defaultValue={
                data.organization.statusPageUpdate ? data.organization.statusPageUpdate.title : ""
              }
              placeholder="Title"
              validation={{ required: true }}
            />
          </Form.Field>
          <Form.Field>
            <Form.Label name="description">Description</Form.Label>
            <Form.TextArea
              defaultValue={
                data.organization.statusPageUpdate
                  ? data.organization.statusPageUpdate.description
                  : ""
              }
              name="description"
              placeholder="Description"
              autosize={true}
              minRows={4}
            />
          </Form.Field>
          <hr className="-mx-5 ignore-old-css" />
          <div className="flex justify-end pt-4">
            {stateKey == "edit" && (
              <a
                onClick={handleDelete}
                className="c-button c-button--sm cursor-pointer c-button--red mr-auto"
              >
                Remove update
              </a>
            )}
            <Form.SubmitButton>{state.submitLabel}</Form.SubmitButton>
          </div>
        </Form>
      </div>
    </div>
  )
}

StatusPageForm.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  statusPageId: PropTypes.string.isRequired,
  statusPageUpdateId: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
}

export default StatusPageForm
