import gql from "graphql-tag"

export default gql`
  fragment ExceptionSample on ExceptionSample {
    id
    appId
    time
    revision
    action
    namespace
    queueDuration
    originallyRequested
    originalId
    overview {
      key
      value
    }
    params
    sessionData
    attributes {
      key
      value
    }
    customData
    environment {
      key
      value
    }
    exception {
      name
      message
      backtrace {
        original
        line
        column
        path
        method
        url
        type
        code {
          line
          source
        }
        error {
          class
          message
        }
      }
    }
    firstMarker {
      id
      createdAt
      shortRevision
      revision
      gitCompareUrl
      user
    }
    breadcrumbs {
      time
      category
      action
      message
      metadata {
        key
        value
      }
    }
  }
`
