import LoadingSpinner from "#Components/shared/loading_spinner"
import TextfieldWithAutoComplete from "#Components/shared/TextfieldWithAutoComplete"
import TooltipWithIcon from "#Components/shared/TooltipWithIcon"
import { useRestClientContext } from "#Root/contexts/RestClientContext"
import { useRouter } from "#Root/hooks"
import { isNameConfigured } from "#Root/utils/metric_keys"
import { useQuery } from "#Root/utils/react-query-client"

const MetricFormName = ({ onChange, metric: { name } }) => {
  const { getParams } = useRouter()
  const { appId } = getParams()
  const restApiClient = useRestClientContext()
  const { data, isLoading } = useQuery({
    queryKey: ["metricNames", appId],
    queryFn: () => restApiClient.metrics.metricNames(appId),
  })

  const configuredMetric = name !== null
  const metricNames = data ?? []
  const emptyMetricConfiguration = configuredMetric && !isNameConfigured(name)
  const unknownMetric =
    !isLoading && configuredMetric && !emptyMetricConfiguration && !metricNames.includes(name)
  const autoCompleteMessage = isLoading ? <LoadingSpinner message="Loading metrics..." /> : null

  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="metric_field">
        Metric
        <TooltipWithIcon
          content={
            <>
              <p className="my-2">
                The name of the metric to be shown in this graph. You can either use the full metric
                name to select one metric, or use a wildcard (*) to select multiple metrics at once.
              </p>
              <p className="my-2">Examples:</p>
              <ul className="list-disc list-inside">
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">
                      db_users_document_count
                    </span>
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">db_*_size</span>
                  </span>
                </li>
              </ul>
              <hr className="ignore-old-css -mx-3 border-gray-700 my-2" />
              <p className="my-2">
                <a
                  className="text-underline text-blue-200"
                  href="https://docs.appsignal.com/metrics/custom.html#metric-naming"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read the documentation
                </a>
              </p>
            </>
          }
        />
      </label>
      <div className="c-form__element">
        <TextfieldWithAutoComplete
          id="metric_field"
          name="metric_field"
          autoComplete="off"
          message={autoCompleteMessage}
          value={name}
          placeholder="Metric"
          options={metricNames.sort()}
          popOverHeader="Metrics in last hour"
          hasWarning={unknownMetric}
          hasError={emptyMetricConfiguration}
          onSelect={(name) => onChange({ name })}
        />
      </div>
      {unknownMetric && (
        <p className="mt-2 mb-0 c-p--sm text-orange-500">
          Selected metric was not found in the last hour on page load. It might exist outside this
          timeframe and still render a graph.
        </p>
      )}
      {emptyMetricConfiguration && (
        <p className="mt-2 mb-0 c-form__error">No metric configured. Please select a metric.</p>
      )}
    </div>
  )
}

MetricFormName.propTypes = {
  onChange: PropTypes.func.isRequired,
  metric: PropTypes.object.isRequired,
}

export default MetricFormName
