export const URL_LIST = {
  ":organizationSlug/search(/)": {
    controller: "SearchIndex||organizationSlug",
  },
  ":organizationSlug/status_pages(/)": {
    controller: "StatusPagesPage||organizationSlug",
    as: "statusPages",
  },
  ":organizationSlug/status_pages/:id(/)": {
    controller: "StatusPagesPage||organizationSlug|id",
    as: "statusPage",
  },
  ":organizationSlug/sites/:appId/heroku/new(/)": {
    controller: "NewSiteHeroku||organizationSlug|appId",
    as: "newSiteHeroku",
  },
  ":organizationSlug/sites/:appId/heroku/new/:step(/)": {
    controller: "NewSiteHeroku||organizationSlug|appId|step",
  },
  ":organizationSlug/sites/new(/)": {
    controller: "SiteNew||organizationSlug",
    as: "newSite",
  },
  ":organizationSlug/sites/new/js(/)": {
    controller: "NewSiteJs||organizationSlug",
    as: "newSiteJs",
  },
  ":organizationSlug/sites/new/js/:step(/)": {
    controller: "NewSiteJs||organizationSlug|step",
    as: "newSiteJsStep",
  },
  ":organizationSlug/sites/new/:language/:languageStep/:framework(/)": {
    controller: "SiteNew||organizationSlug|language|languageStep|framework",
  },
  ":organizationSlug/sites/new/:language/:languageStep/:framework/:frameworkStep(/)": {
    controller: "SiteNew||organizationSlug|language|languageStep|framework|frameworkStep",
  },
  ":organizationSlug/sites/new/:language(/)": {
    controller: "SiteNew||organizationSlug|language",
  },
  ":organizationSlug/sites/new/:language/:step(/)": {
    controller: "SiteNew||organizationSlug|language|step",
  },
  ":organizationSlug/sites/new/:step(/)": {
    controller: "SiteNew||organizationSlug|step",
  },
  ":organizationSlug/sites/:appId/metrics/:id/blank_slate(/)": {
    controller: "MagicDashboardBlankSlate||organizationSlug|appId|id",
    as: "magicDashboardBlankSlate",
  },
  ":organizationSlug/sites/:appId/getting_started(/)": {
    controller: "GettingStartedPage||organizationSlug|appId",
    as: "gettingStartedPage",
  },
  ":organizationSlug/sites/:appId/dashboard(/)": {
    controller: "DashboardShow||organizationSlug|appId",
    as: "dashboardShow",
  },
  ":organizationSlug/sites/:appId/dashboard/add(/)": {
    controller: "AddDashboardPage||organizationSlug|appId",
    as: "addDashboardPage",
  },
  ":organizationSlug/sites/:appId/performance/incidents/:number(/)": {
    controller: "PerformanceIncidentPage||organizationSlug|appId|number",
    as: "performanceIncidentPage",
  },
  ":organizationSlug/sites/:appId/performance/incidents/:number/samples(/)": {
    controller: "PerformanceIncidentSamplesPage||organizationSlug|appId|number",
    as: "performanceIncidentSamplesPage",
  },
  ":organizationSlug/sites/:appId/performance/incidents/:number/graphs(/)": {
    controller: "PerformanceIncidentGraphsPage||organizationSlug|appId|number",
    as: "performanceIncidentGraphsPage",
  },
  ":organizationSlug/sites/:appId/performance/incidents/:number/logbook(/)": {
    controller: "PerformanceIncidentLogbookPage||organizationSlug|appId|number",
    as: "performanceIncidentLogbookPage",
  },
  ":organizationSlug/sites/:appId/performance/incidents/:number/samples/:sampleId(/)": {
    controller: "PerformanceIncidentSamplePage||organizationSlug|appId|number|sampleId",
    as: "performanceIncidentSamplePage",
  },
  ":organizationSlug/sites/:appId/performance/incidents/:number/samples/timestamp/:timestamp(/)": {
    controller: "PerformanceIncidentSamplePage||organizationSlug|appId|number|timestamp",
    as: "PerformanceIncidentSampleTimestampPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number(/)": {
    controller: "ExceptionIncidentPage||organizationSlug|appId|number",
    as: "exceptionIncidentPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/samples(/)": {
    controller: "ExceptionIncidentSamplesPage||organizationSlug|appId|number",
    as: "exceptionIncidentSamplesPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/traces(/)": {
    controller: "ExceptionIncidentTracesPage||organizationSlug|appId|number",
    as: "exceptionIncidentTracesPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/logbook(/)": {
    controller: "ExceptionIncidentLogbookPage||organizationSlug|appId|number",
    as: "exceptionIncidentLogbookPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/attributes(/)": {
    controller: "ExceptionIncidentAttributesPage||organizationSlug|appId|number",
    as: "exceptionIncidentAttributesPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/graphs(/)": {
    controller: "ExceptionIncidentGraphsPage||organizationSlug|appId|number",
    as: "exceptionIncidentGraphsPage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/samples/:sampleId(/)": {
    controller: "ExceptionIncidentSamplePage||organizationSlug|appId|number|sampleId",
    as: "exceptionIncidentSamplePage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/traces/:traceId(/)": {
    controller: "ExceptionIncidentTracePage||organizationSlug|appId|number|traceId",
    as: "exceptionIncidentTracePage",
  },
  ":organizationSlug/sites/:appId/exceptions/incidents/:number/samples/timestamp/:timestamp(/)": {
    controller: "ExceptionIncidentSamplePage||organizationSlug|appId|number|timestamp",
    as: "exceptionIncidentSampleTimestampPage",
  },
  ":organizationSlug/sites/:appId/anomalies/incidents/:number/alerts(/)": {
    controller: "AnomalyIncidentAlertsPage||organizationSlug|appId|number",
    as: "anomalyIncidentAlertsPage",
  },
  ":organizationSlug/sites/:appId/anomalies/incidents/:number/alerts/:id(/)": {
    controller: "AnomalyIncidentAlertPage||organizationSlug|appId|number|id",
    as: "anomalyIncidentAlertPage",
  },
  ":organizationSlug/sites/:appId/anomalies/incidents/:number/logbook(/)": {
    controller: "AnomalyIncidentLogbookPage||organizationSlug|appId|number",
    as: "anomalyIncidentLogbookPage",
  },
  ":organizationSlug/sites/:appId/anomalies/incidents/:number/graphs(/)": {
    controller: "AnomalyIncidentGraphsPage||organizationSlug|appId|number",
    as: "anomalyIncidentGraphsPage",
  },
  ":organizationSlug/sites/:appId/anomalies/incidents/:number(/)": {
    controller: "AnomalyIncidentPage||organizationSlug|appId|number",
    as: "anomalyIncidentPage",
  },
  ":organizationSlug/sites/:appId/charts(/)": {
    controller: "ChartsPage||organizationSlug|appId",
    as: "chartsPage",
  },
  ":organizationSlug/sites/:appId/host_metrics(/)": {
    controller: "HostMetricsPage||organizationSlug|appId",
    as: "hostMetricsIndex",
  },
  ":organizationSlug/sites/:appId/host_metrics/inspect(/)": {
    controller: "HostMetricsInspectPage||organizationSlug|appId",
    as: "hostMetricsInspect",
  },
  ":organizationSlug/sites/:appId/host_usage(/)": {
    controller: "HostUsagePage||organizationSlug|appId",
    as: "hostUsageIndex",
  },
  ":organizationSlug/sites/:appId/host_usage/inspect(/)": {
    controller: "HostUsageInspectPage||organizationSlug|appId",
    as: "hostUsageInspect",
  },
  ":organizationSlug/sites/:appId/check_ins(/)": {
    controller: "CheckInsPage||organizationSlug|appId",
    as: "checkInsIndex",
  },
  ":organizationSlug/sites/:appId/check_ins/:triggerId(/)": {
    controller: "CheckInPage||organizationSlug|appId|triggerId",
    as: "checkInDetail",
  },
  ":organizationSlug/sites/:appId/check_ins/:triggerId/occurrences(/)": {
    controller: "CheckInOccurrencesPage||organizationSlug|appId|triggerId",
    as: "checkInOccurrences",
  },
  ":organizationSlug/sites/:appId/check_ins/:triggerId/occurrences/:id(/)": {
    controller: "CheckInOccurrencePage||organizationSlug|appId|triggerId|id",
    as: "checkInOccurrence",
  },
  ":organizationSlug/sites/:appId/check_ins/:triggerId/logbook(/)": {
    controller: "CheckInLogbookPage||organizationSlug|appId|triggerId",
    as: "checkInLogbook",
  },
  ":organizationSlug/sites/:appId/check_ins/:triggerId/installation(/)": {
    controller: "CheckInInstallationPage||organizationSlug|appId|triggerId",
    as: "checkInInstallation",
  },
  ":organizationSlug/sites/:appId/uptime_monitors(/)": {
    controller: "UptimeMonitorPage||organizationSlug|appId",
    as: "uptimeMonitorsPage",
  },
  ":organizationSlug/sites/:appId/uptime_monitors/:id(/)": {
    controller: "UptimeMonitorPage||organizationSlug|appId|id",
    as: "uptimeMonitorPage",
  },
  ":organizationSlug/sites/:appId/logs(/)": {
    controller: "LogsPage||organizationSlug|appId",
    as: "logsPage",
  },
  ":organizationSlug/sites/:appId/logs/triggers(/)": {
    controller: "LogsTriggersPage||organizationSlug|appId",
    as: "logsTriggersPage",
  },
  ":organizationSlug/sites/:appId/logs/incidents(/)": {
    controller: "LogsIncidentsPage||organizationSlug|appId",
    as: "logsIncidentsPage",
  },
  ":organizationSlug/sites/:appId/logs/incidents/:number(/)": {
    controller: "LogsIncidentPage||organizationSlug|appId|number",
    as: "logsIncidentPage",
  },
  ":organizationSlug/sites/:appId/logs/incidents/:number/logbook(/)": {
    controller: "LogsIncidentLogbookPage||organizationSlug|appId|number",
    as: "logsIncidentLogbookPage",
  },
  ":organizationSlug/sites/:appId/logs/incidents/:number/graphs(/)": {
    controller: "LogsIncidentGraphsPage||organizationSlug|appId|number",
    as: "logsIncidentGraphsPage",
  },
  ":organizationSlug/sites/:appId/logs/incidents/:number/lines(/)": {
    controller: "LogsIncidentLinesPage||organizationSlug|appId|number",
    as: "logsIncidentLinesPage",
  },
  ":organizationSlug/sites/:appId/logs/sources(/)": {
    controller: "SourcesPage||organizationSlug|appId",
    as: "sourcesPage",
  },
  ":organizationSlug/sites/:appId/logs/sources/:logSourceId(/)": {
    controller: "SourcePage||organizationSlug|appId|logSourceId",
    as: "sourcePage",
  },
  ":organizationSlug/sites/:appId/logs/:viewId(/)": {
    controller: "LogsViewPage||organizationSlug|appId|viewId",
    as: "logsViewPage",
  },
  ":organizationSlug/sites/:appId/actions(/)": {
    controller: "ActionsPage||organizationSlug|appId",
    as: "actionsPage",
  },
  ":organizationSlug/sites/:appId/notifiers(/)": {
    controller: "AppPage||organizationSlug|appId|notifierId",
    as: "notifiersPage",
  },
  ":organizationSlug/sites/:appId/notifiers/:notifierId/logs(/)": {
    controller: "AppPage||organizationSlug|appId",
    as: "notifierLogs",
  },
  ":organizationSlug/sites/:appId/performance(/)": {
    controller: "PerformanceIncidentsPage||organizationSlug|appId",
    as: "performanceIncidentsIndex",
  },
  ":organizationSlug/sites/:appId/performance/graphs(/)": {
    controller: "PerformanceIncidentsGraphs||organizationSlug|appId",
    as: "performanceIncidentsGraphs",
  },
  ":organizationSlug/sites/:appId/exceptions(/)": {
    controller: "ExceptionIncidentsPage||organizationSlug|appId",
    as: "exceptionIncidentsIndex",
  },
  ":organizationSlug/sites/:appId/exceptions/graphs(/)": {
    controller: "ExceptionIncidentsGraphs||organizationSlug|appId",
    as: "exceptionIncidentsGraphs",
  },
  ":organizationSlug/sites/:appId/anomalies(/)": {
    controller: "AnomalyIncidentsPage||organizationSlug|appId",
    as: "anomalyIncidentsIndex",
  },
  ":organizationSlug/sites/:appId/markers(/)": {
    controller: "MarkersIndex||organizationSlug|appId",
    as: "markersIndex",
  },
  ":organizationSlug/sites/:appId/triggers(/)": {
    controller: "TriggersIndex||organizationSlug|appId",
    as: "triggersIndex",
  },
  ":organizationSlug/sites/:appId/events/:group(/)": {
    controller: "EventGroupShow||organizationSlug|appId|group",
    as: "eventGroupShow",
  },
  ":organizationSlug/sites/:appId/events/:group/:id(/)": {
    controller: "EventShow||organizationSlug|appId|group|id",
    as: "eventShow",
  },
  ":organizationSlug/sites/:appId/improve/:group(/)": {
    controller: "EventPage||organizationSlug|appId|group",
    as: "slowEventsPage",
  },
  ":organizationSlug/sites/:appId/improve/:group/:id(/)": {
    controller: "EventPage||organizationSlug|appId|group|id",
    as: "slowEventPage",
  },
  ":organizationSlug/sites/:appId/events(/)": {
    controller: "EventsIndex||organizationSlug|appId",
    as: "eventsIndex",
  },
  ":organizationSlug/sites/:appId/metrics(/)": {
    controller: "MetricsIndex||organizationSlug|appId",
    as: "metricsIndex",
  },
  ":organizationSlug/sites/:appId/metrics/new(/)": {
    controller: "MetricsNew||organizationSlug|appId",
    as: "metricsNew",
  },
  ":organizationSlug/sites/:appId/metrics/:id(/)": {
    controller: "MetricsIndex||organizationSlug|appId|id",
    as: "metricsShow",
  },
  ":organizationSlug/sites/:appId/metrics/:id/edit": {
    controller: "MetricsEdit||organizationSlug|appId|id",
    as: "metricsEdit",
  },
  ":organizationSlug/sites/:appId/actions/:namespace/:action_name(/)": {
    controller: "ActionShow||organizationSlug|appId|namespace|action_name",
    as: "actionShow",
  },
  ":organizationSlug/sites/:appId/redirect_to_edit(/)": {
    controller: "ActionShow||organizationSlug|appId",
    as: "editSite",
  },
  "accounts(/)": {
    controller: "OrganizationsPage",
    as: "accounts",
  },
  "assignments(/)": {
    controller: "AssignmentsPage",
  },
  ":organizationSlug/admin(/)": {
    controller: "OrganizationPage||organizationSlug",
    as: "editOrganization",
  },
  ":organizationSlug(/)": {
    controller: "OrganizationPage||organizationSlug",
    as: "showOrganization",
  },
  "*invalidRoute": {
    controller: "native",
  },
}
window.URL_LIST = URL_LIST
export default URL_LIST
