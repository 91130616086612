import PropTypes from "prop-types"
import React, { useContext } from "react"

import { RouterContext } from "../../contexts/RouterContext"
import withParamNavigator from "../../wrappers/param_navigator"
import Overlay from "../shared/overlay"
import Form from "./ReorderDashboard/Form"

const ReorderOverlay = ({ currentPathWithParams, appId, id }) => {
  const router = useContext(RouterContext)

  const handleClose = () => {
    router.navigate(currentPathWithParams({ overlay: null }), { trigger: true })
  }

  return (
    <Overlay
      classNames="medium"
      onClose={handleClose}
      title="Reorder Graphs"
    >
      <Form appId={appId} dashboardId={id} handleClose={handleClose} />
    </Overlay>
  )
}

ReorderOverlay.propTypes = {
  currentPathWithParams: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
}

export default withParamNavigator(ReorderOverlay)
