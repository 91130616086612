import { useQuery } from "react-apollo"

import { ORANGE_TRIAL_DAYS, RED_TRIAL_DAYS } from "#Root/constants/trial"
import { pluralize } from "#Utils/string"

import ProgressRing from "../shared/ProgressRing"

export const trialNotificationQuery = gql`
  query trialNotificationQuery($slug: String!) {
    organization(slug: $slug) {
      id
      inTrial
      daysLeftInTrial
    }
  }
`

const TrialNotification = ({ organizationSlug }) => {
  const { data, loading, error } = useQuery(trialNotificationQuery, {
    variables: { slug: organizationSlug },
  })

  if (loading || error || !data?.organization?.inTrial) return null
  const daysLeftInTrial = data.organization.daysLeftInTrial > 0 ? data.organization.daysLeftInTrial : 0
  let colors = "bg-green-100 text-green-500"

  if (daysLeftInTrial < RED_TRIAL_DAYS) {
    colors = "bg-red-100 text-red-500"

  } else if (daysLeftInTrial <= ORANGE_TRIAL_DAYS) {
    colors = "bg-orange-100 text-orange-500"
  }

  return (
    <div className={`${colors} rounded p-3`}>
      <div className="flex items-center">
        <div className="flex-none">
          <ProgressRing daysLeftInTrial={daysLeftInTrial} />
        </div>
        <div className="flex-auto font-bold responsive-hidden">{daysLeftInTrial} {pluralize(daysLeftInTrial, "day")} left</div>
        <div className="flex-none text-xs mr-2 responsive-hidden">
          <a href={`/${organizationSlug}/admin/billing`} className="underline">
            View plans
          </a>
        </div>
      </div>
    </div>
  )
}

TrialNotification.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
}

export default TrialNotification
