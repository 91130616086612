/**
 * DEPRECATED: Use #Root/ui/PageBar instead
 */
import PageBar from "#Root/ui/PageBar"

const Pagebar = ({ icon, color, background = "", title, subtitle, actions }) => {
  return (
    <PageBar icon={icon} color={color} className={background}>
      <PageBar.Title title={title} subtitle={subtitle} />
      {actions && <PageBar.Actions>{actions}</PageBar.Actions>}
    </PageBar>
  )
}

Pagebar.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  background: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actions: PropTypes.node,
}

export default Pagebar
