import PropTypes from "prop-types"
import React from "react"

const NamespaceLabel = ({ namespace, className }) => {
  let classNames = "c-pill"
  if (className) {
    classNames += ` ${className}`
  }
  return (
    <span className={classNames} title={`Occurred in the "${namespace}" namespace`}>
      {namespace}
    </span>
  )
}

NamespaceLabel.propTypes = {
  namespace: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default NamespaceLabel
