import { graphql } from "react-apollo"

import Timeframes from "#Root/base/timeframes"

import MetricKeysQuery from "../graphql/metric_keys_query"
import MetricsListQuery from "../graphql/metrics_list_query"
import { findTagValue } from "./metric_keys"

export function hostnameFromTags(tags) {
  // Make sure given param is an array
  if (!Array.isArray(tags)) {
    return
  }

  const hostname = findTagValue(tags, "hostname")
  // When hostname === *, it's not interesting to show it's a wildcard,
  // matching all hosts.
  if (hostname && hostname !== "*") {
    return hostname
  }
}

export function labelWithHostnameFromTags(initialLabel, tags) {
  const hostname = hostnameFromTags(tags)
  return hostname ? `${initialLabel} on ${hostname}` : initialLabel
}

export function computeHostMetrics(rows) {
  const hosts = {}

  for (const row of rows) {
    const hostname = findTagValue(row.tags, "hostname")
    let name = row.name

    if (row.name == "memory") {
      const memoryKind = findTagValue(row.tags, "state")
      name = `memory_${memoryKind}`
    }
    const hostData = hosts[hostname] || { hostname: hostname, id: hostname }

    hostData[name] = row.fields[0].value

    // Calculate memory percentage we can sort on
    if (hostData["memory_total"] > 0 && hostData["memory_used"] > 0 && !hostData["memory_pct"]) {
      hostData["memory_pct"] = Math.round(
        (hostData["memory_used"] / hostData["memory_total"]) * 100,
      )
    } else {
      hostData["memory_pct"] = hostData["memory_pct"] || 0
    }
    hostData["load_average"] = hostData["load_average"] || 0
    hostData["disk_usage"] = hostData["disk_usage"] || 0
    hosts[hostname] = hostData
  }
  return Object.values(hosts)
}

export function hostOptions(data) {
  if (data && !data.loading && data.app && data.app.metrics.keys) {
    const hostnames = new Set()
    for (const key of data.app.metrics.keys) {
      const hostname = findTagValue(key.tags, "hostname")
      hostnames.add(hostname)
    }
    return Array.from(hostnames)
  }
  return []
}

export function namespaceHostOptions(data) {
  if (data && !data.loading && data.app && data.app.metrics.keys) {
    const hostnames = new Set()
    for (const key of data.app.metrics.keys) {
      const hostname = findTagValue(key.tags, "hostname")
      const namespace = findTagValue(key.tags, "namespace")
      hostnames.add([namespace, hostname].join(":"))
    }
    return Array.from(hostnames)
  }
  return []
}

export function twoDMap(firstDimensions, secondDimensions, func) {
  const result = []
  for (const firstDimension of firstDimensions) {
    for (const secondDimension of secondDimensions) {
      result.push(func(firstDimension, secondDimension))
    }
  }
  return result
}

export const withHostMetricsList = graphql(MetricsListQuery, {
  options: ({ params, pollInterval }) => {
    return {
      variables: {
        appId: params.appId,
        start: params.from,
        end: params.to,
        timeframe: Timeframes.backwardsCompatibleTimeframeKey(params.timeframe),
        query: [
          {
            name: "load_average",
            tags: [
              { key: "hostname", value: "*" },
              { key: "host_metric", value: "" },
            ],
            fields: [{ field: "GAUGE", aggregate: "LAST" }],
          },
          {
            name: "memory",
            tags: [
              { key: "state", value: "*" },
              { key: "hostname", value: "*" },
              { key: "host_metric", value: "" },
            ],
            fields: [{ field: "GAUGE", aggregate: "LAST" }],
          },
          {
            name: "disk_usage",
            tags: [
              { key: "mountpoint", value: "/" },
              { key: "hostname", value: "*" },
              { key: "host_metric", value: "" },
            ],
            fields: [{ field: "GAUGE", aggregate: "LAST" }],
          },
        ],
      },
      pollInterval: pollInterval || Timeframes.pollIntervalFromTimeframe(params.timeframe),
    }
  },
})

export const withHostMetricKeys = graphql(MetricKeysQuery, {
  options: ({ params }) => ({
    variables: {
      appId: params.appId,
      start: params.from,
      end: params.to,
      timeframe: Timeframes.backwardsCompatibleTimeframeKey(params.timeframe),
      name: "load_average",
      tags: [
        { key: "hostname", value: "*" },
        { key: "host_metric", value: "" },
      ],
    },
  }),
})
