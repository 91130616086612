import { useMutation, useQuery } from "@apollo/react-hooks"

import FormattedTime from "#Root/formatters/FormattedTime"
import { useRouter } from "#Root/hooks"
import { MarkersTimelineQuery } from "#Root/ui/ASChart/components/Markers"
import Button from "#Root/ui/Button"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"
import Overlay from "#Root/ui/Overlay"

const GET_MARKER_QUERY = gql`
  query GetMarkerQuery($appId: String!, $id: String!) {
    app(id: $appId) {
      id
      marker(id: $id) {
        ... on CustomMarker {
          id
          icon
          message
          createdAt
        }
      }
    }
  }
`

const CREATE_UPDATE_CUSTOM_MARKER_QUERY = gql`
  mutation createOrUpdateCustomMarker(
    $id: String
    $appId: String!
    $icon: String!
    $message: String
    $createdAt: DateTime!
  ) {
    createOrUpdateCustomMarker(
      id: $id
      appId: $appId
      icon: $icon
      message: $message
      createdAt: $createdAt
    ) {
      id
      __typename
      icon
      message
      createdAt
    }
  }
`

const DELETE_CUSTOM_MARKER = gql`
  mutation deleteCustomMarker($appId: String!, $id: String!) {
    deleteCustomMarker(appId: $appId, id: $id) {
      id
    }
  }
`

export const MarkerBoxOverlay = ({ params }) => {
  const { appId, markerId, date, startDate, endDate } = params
  const iconOptions = ["👍", "👎", "😀", "😡", "🔥", "🚀"]
  const messageField = React.useRef()
  const [createdAt, setCreatedAt] = React.useState(date || new Date())
  const { navigateWithParams } = useRouter()
  const [icon, setIcon] = React.useState(iconOptions[0])

  const { data, loading } = useQuery(GET_MARKER_QUERY, {
    variables: { appId, id: markerId },
    skip: !markerId,
  })

  const [createOrUpdateCustomMarker] = useMutation(CREATE_UPDATE_CUSTOM_MARKER_QUERY)
  const [deleteCustomMarker] = useMutation(DELETE_CUSTOM_MARKER)

  const handleClose = (e) => {
    if (e) e.preventDefault()
    navigateWithParams({
      overlay: null,
      date: null,
      startDate: null,
      endDate: null,
      markerId: null,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    createOrUpdateCustomMarker({
      variables: {
        id: markerId,
        appId,
        icon,
        message: messageField.current.value,
        createdAt,
      },
      refetchQueries: [
        {
          query: MarkersTimelineQuery,
          variables: { appId, start: startDate, end: endDate },
        },
      ],
    }).then(() => {
      handleClose()
    })
  }
  const handleRemove = (e) => {
    e.preventDefault()
    deleteCustomMarker({
      variables: {
        id: markerId,
        appId,
      },

      refetchQueries: [
        {
          query: MarkersTimelineQuery,
          variables: { appId, start: startDate, end: endDate },
        },
      ],
    }).then(() => {
      handleClose()
    })
  }

  const handleIconSelect = (i) => {
    setIcon(i)
    messageField.current.focus()
  }

  React.useEffect(() => {
    if (data?.app?.marker) {
      setIcon(data.app.marker.icon)
      setCreatedAt(data.app.marker.createdAt)
    }
  }, [data])

  const content = () => {
    if (loading) return <LoadingAnimation />

    return (
      <form onSubmit={(e) => handleSubmit(e)} acceptCharset="UTF-8">
        <div className="flex space-y-4 flex-col">
          <h2 className="font-bold">
            {markerId ? "Update" : "Add"} marker at{" "}
            <FormattedTime format="short" value={createdAt} />
          </h2>
          <hr />
          <div>
            <h2 className="font-bold pb-2">Choose marker</h2>
            <div className="flex space-x-3">
              {iconOptions.map((i) => (
                <div
                  key={i}
                  onClick={() => handleIconSelect(i)}
                  className={`border rounded p-2 cursor-pointer w-10 h-10 flex items-center justify-center ${
                    icon === i ? "bg-green-200 border-green-500" : ""
                  }`}
                >
                  {i}
                </div>
              ))}
            </div>
          </div>
          <div>
            <h2 className="font-bold pb-2">Your message</h2>
            <textarea
              name="message"
              placeholder="your custom marker message"
              defaultValue={data?.app?.marker?.message}
              ref={messageField}
            />
          </div>
        </div>
        <Overlay.Actions>
          <Button type="submit">Save marker</Button>
          {markerId && (
            <Button color="red" onClick={handleRemove}>
              Remove
            </Button>
          )}
          <button className="c-link ml-auto" onClick={handleClose}>
            Cancel
          </button>
        </Overlay.Actions>
      </form>
    )
  }

  return (
    <Overlay open={true} onClose={handleClose} size="sm">
      <Overlay.Title
        title={markerId ? "Update custom marker" : "Add custom marker"}
        handleClose={handleClose}
      ></Overlay.Title>
      <Overlay.Content>{content()}</Overlay.Content>
      <Overlay.Hint>
        Tip: Create markers with our{" "}
        <a href="https://docs.appsignal.com/api/markers.html">markers API</a>.
      </Overlay.Hint>
    </Overlay>
  )
}

MarkerBoxOverlay.propTypes = {
  params: PropTypes.shape({
    appId: PropTypes.string.isRequired,
    markerId: PropTypes.string,
    date: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
}

export default MarkerBoxOverlay
