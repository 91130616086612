import * as RadixHoverCard from "@radix-ui/react-hover-card"

const HoverCardContext = React.createContext()

export const HoverCard = ({
  defaultOpen,
  initiallyOpen,
  onOpenChange,
  openDelay = 200,
  closeDelay = 300,
  closeCallback,
  disabled = false,
  children,
}) => {
  const [open, setOpen] = React.useState(initiallyOpen)

  if (disabled) {
    return <HoverCardContext.Provider value={{ disabled }}>{children}</HoverCardContext.Provider>
  }

  return (
    <HoverCardContext.Provider value={{ open, setOpen, closeCallback, disabled }}>
      <RadixHoverCard.Root
        defaultOpen={defaultOpen}
        open={open}
        onOpenChange={onOpenChange}
        openDelay={openDelay}
        closeDelay={closeDelay}
      >
        {children}
      </RadixHoverCard.Root>
    </HoverCardContext.Provider>
  )
}

HoverCard.propTypes = {
  defaultOpen: PropTypes.bool,
  initiallyOpen: PropTypes.bool,
  onOpenChange: PropTypes.func,
  openDelay: PropTypes.number,
  closeDelay: PropTypes.number,
  closeCallback: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
}

export const Trigger = ({ inline = false, asChild, className, children }) => {
  const { disabled } = React.useContext(HoverCardContext)

  if (disabled) return children
  return (
    <RadixHoverCard.Trigger
      asChild={inline || asChild}
      className={`${inline ? "border-b border-gray-400 border-dotted" : ""} ${className || ""}`}
    >
      {children}
    </RadixHoverCard.Trigger>
  )
}

Trigger.propTypes = {
  children: PropTypes.node,
  inline: PropTypes.bool,
  asChild: PropTypes.bool,
  className: PropTypes.string,
}

export const Content = ({
  side = "bottom",
  align = "center",
  alignOffset = 0,
  sideOffset = 0,
  title,
  maxWidth = "max-w-xs",
  asTooltip = false,
  children,
}) => {
  const { disabled, setOpen, closeCallback } = React.useContext(HoverCardContext)

  if (disabled) return null

  const handleClose = () => {
    setOpen(false)
    if (typeof closeCallback === "function") closeCallback()
  }

  return (
    <RadixHoverCard.Portal>
      <RadixHoverCard.Content
        side={side}
        align={align}
        alignOffset={alignOffset}
        sideOffset={sideOffset}
        // hover-card v1 doesn't set the z-index of the content anymore, so we need to set it here
        // previously it was set to a 5 digit number, so we set it to 1000 to be sure it's on top of everything
        className="text-gray-900 z-[1000]"
      >
        <RadixHoverCard.Arrow fill="currentColor" />
        <div
          className={`bg-gray-900 text-gray-200 ignore-react-onclickoutside rounded-lg ${
            asTooltip ? "py-2 px-3" : "p-4"
          } text-white ${maxWidth}`}
        >
          {title && (
            <header className="flex gap-2 items-center">
              <button
                className="order-2 ignore-old-css"
                title="close tooltip"
                onClick={handleClose}
              >
                <span className="mr-2 fa fa-times" />
              </button>
              <h2 className="c-heading flex-1 mb-0">{title}</h2>
            </header>
          )}
          {children}
        </div>
      </RadixHoverCard.Content>
    </RadixHoverCard.Portal>
  )
}

Content.propTypes = {
  side: PropTypes.oneOf(["bottom", "top", "left", "right"]),
  align: PropTypes.oneOf(["start", "center", "end"]),
  alignOffset: PropTypes.number,
  sideOffset: PropTypes.number,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  asTooltip: PropTypes.bool,
  children: PropTypes.node,
}

HoverCard.Trigger = Trigger
HoverCard.Content = Content

export default HoverCard
