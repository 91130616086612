import gql from "graphql-tag"

export default gql`
  fragment AsanaTask on AsanaTask {
    id
    title
    description
    url
  }
`
