import { scaleUtc } from "d3-scale"
import { utcDay, utcHour, utcMinute } from "d3-time"

import { DATA_FIELDS, DISPLAY_FIELDS } from "../constants"

// Simple min/max comparison. Start with null, compare and decide if we need to replace them limits
const getSeriesLimits = (series) => {
  let yMin = null
  let yMax = null

  for (const { yMin: seriesYMin, yMax: seriesYMax } of series) {
    if (yMin == null || seriesYMin < yMin) {
      yMin = seriesYMin
    }
    if (seriesYMax > yMax) {
      yMax = seriesYMax
    }
  }

  return { yMin, yMax }
}

// In stacked series, we need to find the maximum value of each data point index.
// We can't just add the max values of each series, as they might not align.
export const getStackedSerieLimits = (series) => {
  const yMin = 0
  let yMax = null

  const dataSlots = series[0].data.length

  for (let index = 0; index < dataSlots; index++) {
    let sum = 0

    // Iterate vertically, trying to find the maximum value of series index
    for (const entry of series) {
      sum += entry.data[index]?.y || 0
    }
    if (yMax == null || sum > yMax) {
      yMax = sum
    }
  }

  return { yMin, yMax }
}

export const getRelativeLimits = () => ({ yMin: 0, yMax: 100 })

export const getExtents = ({
  timeseries,
  disabledLines = [],
  isStacked = false,
  isRelative = false,
}) => {
  const { xMin, xMax, series } = timeseries
  const applicableSeries = series.filter((serie) => !disabledLines.includes(serie.id))

  const { yMax, yMin } = isRelative
    ? getRelativeLimits()
    : isStacked
      ? getStackedSerieLimits(applicableSeries)
      : getSeriesLimits(applicableSeries)

  // Fallback to 0 and 1 respectively if we have no proper values
  return { yMin: yMin || 0, yMax: yMax || 1, xMin, xMax }
}

export const formatDataField = (field) => {
  return DATA_FIELDS[field]
}

export const formatDisplayField = (field) => {
  return DISPLAY_FIELDS[field]
}

export const parseLabel = (label, name, tags, field) => {
  if (!label) {
    return name
  }
  for (const [key, value] of Object.entries(tags)) {
    label = label.replace(new RegExp(`%${key}%`, "g"), value)
  }
  label = label.replace(new RegExp("%field%", "g"), formatDisplayField(field))
  label = label.replace(new RegExp("%name%", "g"), name)
  label = label.replace(new RegExp("%[\\w_]+%", "g"), "")
  return label.trim()
}

export const getTicks = (from, to, resolution) => {
  const scale = scaleUtc().domain([new Date(from), new Date(to)])

  switch (resolution) {
    case "MINUTELY":
      return scale.ticks(utcMinute.every(1))
    case "HOURLY":
      return scale.ticks(utcHour.every(1))
    case "DAILY":
      return scale.ticks(utcDay.every(1))
  }
}

export const getColor = (total, index, startColor) => {
  const h = startColor[0]
  const s = startColor[1]
  const l = startColor[2]
  const steps = 360 / total
  let newH = index * steps + h
  if (newH > 360) {
    newH = newH - 360
  }
  return `hsl(${newH},${s}%,${l}%)`
}

export const generateColorPaletteFromStops = (timeseries, startColor = [212, 81, 38]) => {
  const sortedSeries = (timeseries?.series || [])
    .map((ts) => ts.id)
    .sort((a, b) => a.localeCompare(b))
  const totalLines = sortedSeries.length

  if (totalLines == 0) {
    return {}
  }

  const colorMap = Object.assign(
    ...sortedSeries.map((serie, idx) => ({
      [serie]: getColor(totalLines, idx, startColor),
    })),
  )

  return ({ id }) => colorMap[id]
}

export const applyColorPalette = (series, colorPalette) => {
  const sortedSeries = (series || []).map((ts) => ts.id).sort((a, b) => a.localeCompare(b))
  const totalLines = sortedSeries.length

  if (totalLines == 0) {
    return {}
  }

  // We're working with a fixed palette, so we can just return the color for the index
  if (colorPalette.type === "steps") {
    const colors = colorPalette.values
    const colorMap = Object.assign(
      ...sortedSeries.map((serie, idx) => ({
        [serie]: colors[idx % colors.length],
      })),
    )

    return ({ id }) => colorMap[id]
  }
  // Otherwise the color palette is generated on the fly, on a spectrum from values 0-100
  else if (colorPalette.type === "continuous") {
    const colorMap = Object.assign(
      ...sortedSeries.map((serie, index) => ({
        [serie]: colorPalette.fn(index / (totalLines - 1)),
      })),
    )

    return ({ id }) => colorMap[id]
  }
  // Special case where we have different # of colors, depending on the series count
  else if (colorPalette.type === "divergent") {
    const divergentPalettes = colorPalette.values
    let colors

    if (totalLines < 3) {
      colors = divergentPalettes[3] // Use the smallest available palette
    } else if (totalLines >= divergentPalettes.length) {
      colors = divergentPalettes[divergentPalettes.length - 1] // Use the largest palette
    } else {
      colors = divergentPalettes[totalLines] // Use the palette matching the series length
    }

    const colorMap = Object.assign(
      ...sortedSeries.map((serie, idx) => ({
        [serie]: colors[idx % colors.length],
      })),
    )

    return ({ id }) => colorMap[id]
  }

  return {}
}

// We're comparing DOM node placements during mouse-move events.
// There are some edge cases where the user can right-click and drag the mouse
// to extensions, other tabs, etc. These targets won't have `nodeType`
export const isDOMNode = (node) => {
  return node && node.nodeType
}

export const significantDecimalCountFor = (value) => {
  // Start at 2, as those are the decimals we show that actually change between series values.
  // For example, with `0.000012`, count until we find a non-zero, will show the `12` as well.
  const defaultPrecision = 2

  // whole number, or bigger than 1
  if (value > 1 || Math.floor(value) === value) return defaultPrecision

  const [, decimalPart] = value.toString().split(".")

  // find consecutive zeroes in the start
  let consecutiveZeroes = 0
  for (const digit of decimalPart) {
    if (digit === "0") {
      consecutiveZeroes++
    } else {
      break
    }
  }
  // split zeroCount from the decimal part to find the actual numbers
  const digits = consecutiveZeroes ? decimalPart.slice(consecutiveZeroes) : decimalPart
  return digits.length > 1 ? consecutiveZeroes + 2 : consecutiveZeroes + 1
}

export const transformSeriesToRelative = (series) => {
  const yOffsetArray = new Array(series[0].data.length).fill(0)

  // Calculate the total for each timestamp
  const totals = series[0].data.map((_, index) =>
    series.reduce((sum, serie) => sum + serie.data[index].y, 0),
  )

  const seriesWithOffset = series.map((serie) => {
    const nextDataSet = serie.data.map((dataPoint, index) => {
      const offset = yOffsetArray[index]
      const ratio = totals[index] ? (dataPoint.y / totals[index]) * 100 : 0
      yOffsetArray[index] += ratio
      return { ...dataPoint, y0: offset, y1: ratio + offset, y: ratio }
    })
    return { ...serie, data: nextDataSet }
  })

  return seriesWithOffset
}

export const transformSeriesToStacked = (series) => {
  const yOffsetArray = new Array(series[0].data.length).fill(0)

  const seriesWithOffset = series.map((serie) => {
    const nextDataSet = serie.data.map((dataPoint, index) => {
      const offset = yOffsetArray[index]
      yOffsetArray[index] += dataPoint.y
      return { ...dataPoint, y0: offset, y1: dataPoint.y + offset }
    })
    return { ...serie, data: nextDataSet }
  })

  return seriesWithOffset
}

export const formatSerieLabels = ({ series, label }) => {
  return series.map((serie) => ({
    ...serie,
    label: parseLabel(label, serie.name, serie.tags, serie.field),
  }))
}
