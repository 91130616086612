import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export const mockData = {
  app: {
    __typename: "Site",
    id: "abc123",
    dashboard: {
      __typename: "Dashboard",
      id: "dashboard1",
      title: "Dashboard title",
      description: "Dashboard description",
      graphs: [],
      visuals: []
    }
  }
}

export default gql`
  query CustomMetricsDashboardDefinitionQuery(
    $appId: String!,
    $id: String!
  ) {
    app(id: $appId) {
      id
      dashboard(id: $id) {
        ...Dashboard
      }
    }
  }
  ${DashboardFragment}
`
