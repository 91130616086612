import { generateCommonPrefixRecord } from "./generateCommonPrefixRecord"
import { formatDataField, getTicks } from "./util"

/*
  Convert a GraphQL timeseries object to a format that can be used by the ASChart graph

  * @param {Object} graphqlTimeseries - The timeseries object from GraphQL
  * @param {Object} options - Options to control the conversion
  * @param {Boolean} options.drawNullAsZero - Draw null values as zero

  * @returns {Object} - The timeseries object for the graph
*/

export const graphQLToTimeseries = (graphqlTimeseries, options = {}) => {
  if (!graphqlTimeseries) {
    return null
  }

  const { drawNullAsZero = true } = options

  // Setup return values
  const resolution = graphqlTimeseries.resolution
  const xMin = new Date(graphqlTimeseries.start)
  const xMax = new Date(graphqlTimeseries.end)

  const series = []

  // TODO: Future optimisation, cache min/max
  // so if we disable a line, we don't have to recalculate the extents

  // Calculate a "clean" x-axis, taking care of missing points
  const ticks = getTicks(xMin, xMax, resolution)

  const commonPrefixRecord = generateCommonPrefixRecord(graphqlTimeseries)

  // Loop the keys to generate the series
  for (const key of graphqlTimeseries.keys) {
    for (const field of key.fields) {
      let yMin = 0
      let yMax = 0
      let firstPointValue = true // Use the yMin/yMax from the first point
      let lastValue = 0

      const formattedField = formatDataField(field)
      const id = `${key.digest};${formattedField}`
      const name = key.name
      let tags = {}
      if (key.tags && Array.isArray(key.tags) && key.tags.length > 0) {
        tags = Object.assign(
          ...key.tags.map(({ key, value }) => {
            return { [key]: value.replace(commonPrefixRecord[key], "") }
          }),
        )
      }

      const data = ticks.map((tick) => {
        let value = drawNullAsZero ? 0 : lastValue

        const point = graphqlTimeseries.points.find(
          (point) => point.timestamp == tick.getTime() / 1_000,
        )

        if (point) {
          const valuePoint = point.values.find((value) => value.key === id)
          if (valuePoint) {
            value = valuePoint.value
            lastValue = value

            if (firstPointValue) {
              yMin = value
              yMax = value
              firstPointValue = false
            }
          }
        }

        // Update the min/max values for the y-axis
        if (value < yMin) {
          yMin = value
        }
        if (value > yMax) {
          yMax = value
        }

        return {
          x: tick,
          y: value,
        }
      })

      series.push({ name, field, tags, id, data, yMin, yMax })
    }
  }

  return { resolution, xMin, xMax, series }
}
