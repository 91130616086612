import cn from "#Root/utils/cn"

import { BAR, HOVER_PANEL_Z_INDEX } from "../../constants"
import { useChartDataContext } from "../../contexts/DataContext"
import { useDraggingContext } from "../../contexts/DraggingContext"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"
import { useMouseCoordinates } from "../../utils/hooks"
import { Lock } from "./Lock"

const HighlightLine = ({ enableLock }) => {
  const { lockRef } = useChartHoverStateContext()
  const { invertDate } = useChartHoverStateContext()
  const { canvasHeight, canvasWidth, width, xScale, domain, renderer } = useChartGraphContext()
  const { mouseX, mouseY } = useMouseCoordinates()
  const { timeseries } = useChartDataContext()
  const { hasSelectedArea } = useDraggingContext()

  // No mouse coordinates, or no selected data
  if (!mouseX || !mouseY || !invertDate) {
    return null
  }

  if (hasSelectedArea) {
    return null
  }

  const canvasXOffset = width - canvasWidth
  xScale.domain(domain.x)

  // Calculate the number of data points in the current zoomed-in domain
  const [xDomainStart, xDomainEnd] = domain.x
  const visibleDataPoints = timeseries.series[0].data.filter(
    (d) => d.x >= xDomainStart && d.x <= xDomainEnd,
  ).length

  const dataPointStart = xScale(invertDate) + canvasXOffset
  const dataPointWidth = canvasWidth / visibleDataPoints

  if (visibleDataPoints === 0) {
    return null
  }

  let lineStyle = {}
  let lockLeft = undefined

  if (renderer === BAR) {
    lockLeft = dataPointStart + dataPointWidth / 2
    lineStyle = {
      width: dataPointWidth,
      left: dataPointStart,
      top: 0,
      height: canvasHeight,
      zIndex: 0,
    }
  } else {
    lockLeft = dataPointStart
    lineStyle = {
      width: 1,
      left: dataPointStart,
      top: 0,
      height: canvasHeight,
      zIndex: 0,
    }
  }

  return (
    <div ref={lockRef}>
      <div
        data-testid="chart-hover-highlight-line"
        style={lineStyle}
        className={cn("pointer-events-none absolute cursor-pointer", {
          "bg-gradient-to-b from-[rgba(107,123,150,0.1)] to-[rgba(107,123,150,0)]":
            renderer === BAR,
          "bg-gray-200/90": renderer !== BAR,
        })}
      />
      {enableLock ? (
        <div
          data-testid="chart-hover-lock"
          className="absolute -translate-y-1/2 -translate-x-1/2"
          style={{ zIndex: HOVER_PANEL_Z_INDEX - 1, left: lockLeft, top: canvasHeight }}
        >
          <Lock />
        </div>
      ) : null}
    </div>
  )
}

HighlightLine.propTypes = {
  enableLock: PropTypes.bool,
}
HighlightLine.displayName = "HighlightLine"

export default HighlightLine
