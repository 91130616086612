const Section = ({ children }) => {
  return <div className="space-y-2 px-5 py-3 border-b last-of-type:border-b-0">{children}</div>
}

Section.propTypes = {
  children: PropTypes.node,
}

const Title = ({ children }) => {
  return <div className="text-sm font-semibold leading-5 text-gray-800">{children}</div>
}

Title.propTypes = {
  children: PropTypes.node,
}

const Content = ({ children }) => {
  return <div className="text-sm leading-5 text-gray-800">{children}</div>
}

Content.propTypes = {
  children: PropTypes.node,
}

Section.Content = Content
Section.Title = Title

export default Section
