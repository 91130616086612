import gql from "graphql-tag"

import AssigneeFragment from "./_assignee_fragment"
import PerformanceIntegrationsFragment from "./_performance_integrations_fragment"
import PerformanceSampleFragment from "./_performance_sample_fragment"

export default gql`
  fragment PerformanceIncident on PerformanceIncident {
    id
    number
    lastOccurredAt
    actionNames
    state
    notificationFrequency
    notificationThreshold
    namespace
    description
    hasNPlusOne
    severity
    assignees {
      ...AssigneeFragment
    }
    sample(id: $sampleId, timestamp: $timestamp, timerange: $timerange) {
      ... PerformanceSample
    }
    ... PerformanceIntegrationsFragment
  }
  ${PerformanceIntegrationsFragment}
  ${PerformanceSampleFragment}
  ${AssigneeFragment}
`
