import SideNavigation from "#Root/components/shared/side_navigation"
import { useRouter } from "#Root/hooks"

const Tabs = () => {
  const { currentPathWithParams, getParams } = useRouter()

  const currentTab = getParams()?.td_tab || "performance"
  const tabs = [
    {
      label: "Errors",
      color: "orange",
      icon: "fa fa-bug",
      link: currentPathWithParams({ td_tab: "errors" }),
      active: currentTab === "errors",
    },
    {
      label: "Performance",
      color: "green",
      icon: "fa fa-rabbit-fast",
      link: currentPathWithParams({ td_tab: "performance" }),
      active: currentTab === "performance",
    },
    {
      label: "Alerts",
      color: "red",
      icon: "fa fa-siren-on",
      link: currentPathWithParams({ td_tab: "alerts" }),
      active: currentTab === "alerts",
    },
    {
      label: "Hosts",
      color: "teal",
      icon: "fa fa-server",
      link: currentPathWithParams({ td_tab: "hosts" }),
      active: currentTab === "hosts",
    },
    {
      label: "Logs",
      color: "pink",
      icon: "fas fa-line-columns",
      link: currentPathWithParams({ td_tab: "logs" }),
      active: currentTab === "logs",
    }
  ]

  return (
    <nav className="border-r bg-white w-[256px] shrink-0 px-3">
      <ul className="c-side-nav my-4 flex-grow pb-24 w-full">
        {tabs.map(({ label, icon, link, active, color }, index) => (
          <SideNavigation
            key={index}
            id={index}
            title={label}
            color={color}
            icon={icon}
            type="link"
            href={link}
            active={active}
          />
        ))}
      </ul>
    </nav>
  )
}

export default Tabs
