import gql from "graphql-tag"

export const mockData = {
  __typename: "VisualTimeseries",
  id: "visual1",
  title: "title",
  description: "",
  lineLabel: "",
  display: "",
  format: "",
  formatInput: "",
  drawNullAsZero: "",
  minYAxis: "",
  metrics: [],
}

export default gql`
  fragment Visual on Visual {
    ... on VisualTimeseries {
      __typename
      id
      title
      description
      lineLabel
      display
      format
      formatInput
      drawNullAsZero
      minYAxis
      metrics {
        name
        fields {
          field
        }
        tags {
          key
          value
        }
      }
    }
  }
`
