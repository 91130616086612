export const fields = ["GAUGE", "COUNTER", "COUNT", "MEAN", "P90", "P95"]

export const measurementFields = {
  COUNT: "Count",
  MEAN: "Mean",
  P90: "90th percentile",
  P95: "95th percentile",
}
export const durationFields = {
  MEAN: "Mean",
  P90: "90th Percentile",
  P95: "95th Percentile",
}

export function isNameConfigured(name) {
  return (name || "").trim() !== ""
}

export function findTagValue(tags, key) {
  const foundTag = tags.find((tag) => tag.key === key)
  if (foundTag) {
    return foundTag.value
  }
}

export function findFieldsForMetricType(type) {
  if (type === "MEASUREMENT") {
    return Object.keys(measurementFields).map((field) => ({ field }))
  } else if (type) {
    return [{ field: type }]
  } else {
    return []
  }
}

export function includeMeasurementFields(fields) {
  return fields.findIndex(({ field }) => measurementFields[field]) > -1
}
