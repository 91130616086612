import PropTypes from "prop-types"
import React, { useState } from "react"

import HoverCard from "#Root/ui/HoverCard"

import MetricList from "../shared/visual_form/MetricList"
import VisualOptions from "../shared/visual_form/Options"
import VisualSettings from "../shared/visual_form/Settings"
import VisualBuilderSkeletonSidebarMetricPanel from "./SidebarMetricPanel"

const VisualBuilderSkeletonSidebar = ({
  appId,
  visual,
  metricForm,
  optionsForm,
  onChange,
  onLineLabelFocus,
  onLineLabelBlur,
}) => {
  const [showMetricsPanel, setShowMetricsPanel] = useState(false)
  const [selectedMetricIndex, setSelectedMetricIndex] = useState(null)
  const handleSelect = (index) => {
    setSelectedMetricIndex(index)
    setShowMetricsPanel(true)
  }
  const closeMetricsPanel = () => {
    setSelectedMetricIndex(null)
    setShowMetricsPanel(false)
  }
  const handleMetricChange = (index, metric) => {
    const { metrics: immutableMetrics } = visual
    const metrics = immutableMetrics.slice(0) // Mutable array clone
    if (metric) {
      metrics[index] = metric
    } else {
      // Delete action, remove metric from array and closing the metrics panel
      metrics.splice(index, 1)
      if (index > -1) {
        closeMetricsPanel()
      }
    }
    onChange({ metrics })
  }

  return (
    <>
      {!showMetricsPanel && (
        <div>
          <h1 className="sticky top-0 bg-white z-10 c-heading py-4 px-6 border-b border-gray-200 mb-0">
            Graph settings
          </h1>
          <VisualSettings visual={visual} onChange={onChange} />

          <div className="p-6 border-b border-gray-200">
            <h2 className="c-heading mb-3">
              Metrics
              <HoverCard>
                <HoverCard.Trigger>
                  <i className="ml-2 text-gray-600 text-ms align-baseline cursor-pointer fa fa-info-circle"></i>
                </HoverCard.Trigger>
                <HoverCard.Content>
                  Add metrics to describe which lines should be drawn in the graph.{" "}
                  <a
                    className="text-underline text-blue-200"
                    href="https://docs.appsignal.com/metrics/custom.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read how to send custom metrics
                  </a>
                  .
                </HoverCard.Content>
              </HoverCard>
            </h2>

            <MetricList metrics={visual.metrics} onSelect={handleSelect} onChange={onChange} />
          </div>

          <VisualOptions
            visual={visual}
            optionsForm={optionsForm}
            onChange={onChange}
            onLineLabelFocus={onLineLabelFocus}
            onLineLabelBlur={onLineLabelBlur}
          />
        </div>
      )}

      {showMetricsPanel && (
        <VisualBuilderSkeletonSidebarMetricPanel
          appId={appId}
          metric={visual.metrics[selectedMetricIndex]}
          metricForm={metricForm}
          onChange={handleMetricChange.bind(this, selectedMetricIndex)}
          closeMetricsPanel={closeMetricsPanel}
        />
      )}
    </>
  )
}

VisualBuilderSkeletonSidebar.propTypes = {
  appId: PropTypes.string.isRequired,
  visual: PropTypes.object.isRequired,
  metricForm: PropTypes.func.isRequired,
  optionsForm: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onLineLabelFocus: PropTypes.func.isRequired,
  onLineLabelBlur: PropTypes.func.isRequired,
}

export default VisualBuilderSkeletonSidebar
