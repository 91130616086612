import React, { useState } from "react"

export const MenuContext = React.createContext({
  menu: "default",
  setMenu: () => {},
})

const MenuContextController = ({ children }) => {
  const [menu, setMenu] = useState("default")

  const defaultContext = {
    menu,
    setMenu,
  }

  return <MenuContext.Provider value={defaultContext}>{children}</MenuContext.Provider>
}

MenuContextController.propTypes = {
  children: PropTypes.node,
}

export default MenuContextController
