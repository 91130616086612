export const TRIGGER_TYPES = {
  ExceptionRate: {
    label: "Error rate for %namespace%",
    formatter: "percent",
  },
  ExceptionCount: {
    label: "Error count for %namespace%",
    formatter: "number",
  },
  HostCPUUsage: {
    label: "%hostname% - %state%",
    formatter: "percent",
  },
  HostDiskIO: {
    label: "%hostname% - %disk%",
    formatter: "size",
  },
  HostDiskUsage: {
    label: "%hostname% - %mountpoint%",
    formatter: "percent",
  },
  HostLoadAverage: {
    label: "%hostname%",
    formatter: "number",
  },
  HostMemoryUsage: {
    label: "%hostname% - %state%",
    formatter: {
      usage: "percent",
      used: "size",
      free: "size",
    },
  },
  HostNetworkTraffic: {
    label: "%hostname% - %interface%",
    formatter: "size",
  },
  HostSwapUsage: {
    label: "%hostname%",
    formatter: {
      usage: "percent",
      used: "size",
    },
  },
  Performance: {
    label: "Slow actions for %namespace%",
    formatter: "duration",
  },
  Throughput: {
    label: "Throughput for %namespace%",
    formatter: "throughput",
  },
  QueueTime: {
    label: "Queue time for %namespace%",
    formatter: "duration",
  },
}

export const NULL_AS_ZERO_COMPARISON_OPERATORS = ["EQUAL", "LESS_THAN", "LESS_THAN_OR_EQUAL"]
