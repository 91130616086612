import PropTypes from "prop-types"
import { useState } from "react"

import { Box } from "#Root/ui"
import Chart from "#Root/ui/ASChart"
import { MARKERS_HEIGHT } from "#Root/ui/ASChart/constants"
import { graphQLToTimeseries } from "#Root/ui/ASChart/utils/transformer"

import DateTimeFilter from "../filters/DateTimeFilter"

function warnings(trigger) {
  const { metricName, field } = trigger
  if (!metricName || !field) {
    return {
      icon: "fa-chart-area",
      message: (
        <>
          <span className="font-bold mb-2">New trigger</span>
          <span>A preview will appear here for configured triggers.</span>
        </>
      ),
    }
  }

  return {
    icon: "fa-chart-area",
    message: (
      <>
        <span className="font-bold mb-2">No data found</span>
        <span>No complete trigger was found to render the graph.</span>
        <span>Please check you graph configuration for errors and warnings.</span>
      </>
    ),
  }
}

const TriggerGraphPreview = ({ trigger, title, lineLabel, valueFormat, valueInput }) => {
  const [previewTimeframe, setPreviewTimeframe] = useState("R1H")
  const { metricName, field, tags } = trigger
  const queries = [{ name: metricName, fields: [{ field }], tags }]
  const noDataMessage = warnings(trigger)

  const handlePreviewTimeframeChange = ({ timeframe }) => {
    setPreviewTimeframe(timeframe)
  }

  return (
    <>
      <Chart.GraphQL queries={queries} timeframe={previewTimeframe} onSuccess={graphQLToTimeseries}>
        <Chart.Container label={lineLabel}>
          <Box>
            <Box.Header>
              <div className="flex space-x-2 items-center">
                <div className="truncate w-full text-gray-600">
                  <span className="text-gray-800 font-semibold">{title}</span>
                </div>
                <Chart.ExportButton
                  title={title}
                  valueFormat={valueFormat}
                  valueInput={valueInput}
                />
              </div>
            </Box.Header>
            <div className="pt-5 px-5">
              <Chart.Graph
                valueFormat={valueFormat}
                valueInput={valueInput}
                emptyRenderer={(height) => (
                  <div>
                    <div
                      className="loader centered no-markers no-data-message text-gray-600"
                      style={height}
                    >
                      <span className="rounded-full bg-blue-100 mb-4 w-12 h-12 flex justify-center items-center text-xl">
                        <i className={`fa fa-fw ${noDataMessage.icon}`} />
                      </span>
                      {noDataMessage.message}
                    </div>
                    {/* offset for markers */}
                    <div style={{ height: MARKERS_HEIGHT }} className="mt-2" />
                  </div>
                )}
              >
                <Chart.YAxis />
                <Chart.XAxis />
                <Chart.Hover>
                  <Chart.Hover.MarkerButton />
                  <Chart.Hover.TimeDetectiveButton />
                </Chart.Hover>
                <Chart.HighlightLine enableLock={true} />
                <Chart.HighlightDot />
                <Chart.Markers />
              </Chart.Graph>
            </div>
          </Box>
        </Chart.Container>
      </Chart.GraphQL>

      <div className="flex justify-end">
        <DateTimeFilter
          handleChange={handlePreviewTimeframeChange}
          hideCustomRange={true}
          initialValue={{ timeframe: previewTimeframe }}
        />
      </div>
    </>
  )
}

TriggerGraphPreview.propTypes = {
  appId: PropTypes.string.isRequired,
  trigger: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  lineLabel: PropTypes.string.isRequired,
  valueFormat: PropTypes.string,
  valueInput: PropTypes.string,
}

TriggerGraphPreview.defaultProps = {
  valueFormat: "number",
}

export default TriggerGraphPreview
