export const DEFAULT_FORMATTING_SETTINGS = {
  valueFormat: "number",
  valuePrecision: 2,
  valueInput: null,
}

export const ChartFormattingContext = React.createContext(DEFAULT_FORMATTING_SETTINGS)

export const ChartFormattingProvider = ({ children, ...props }) => {
  return (
    <ChartFormattingContext.Provider
      value={{
        ...DEFAULT_FORMATTING_SETTINGS,
        ...props,
      }}
    >
      {children}
    </ChartFormattingContext.Provider>
  )
}

ChartFormattingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  valueFormat: PropTypes.string,
  valuePrecision: PropTypes.number,
  valueInput: PropTypes.string,
}

export const useChartFormattingContext = () => React.useContext(ChartFormattingContext)
