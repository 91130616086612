import dayjs from "dayjs"
import minMax from "dayjs/plugin/minMax"
dayjs.extend(minMax)

export function setTimestampsFromParams(params) {
  const { timeframe, start, end, from, to } = params

  if (timeframe && timeframe !== "custom") {
    return {
      timeframe,
      from: null,
      to: null,
    }
  } else {
    // ? This is to support the legacy Datetime filter.
    // ? Once we have fully removed that one we can drop the start/end support
    if (start && end) {
      return {
        timeframe: "custom",
        from: start.toISOString(),
        to: end.toISOString(),
      }
    } else {
      return {
        timeframe: "custom",
        from,
        to,
      }
    }
  }
}

export function parseTimestampFromURL(timestamp) {
  if (dayjs.isDayjs(timestamp)) {
    return timestamp
  } else {
    return dayjs(decodeURIComponent(timestamp))
  }
}

export function chunkTimeline(start, end, durationInSeconds = 86400) {
  const chunks = []
  let startTimestamp = dayjs(start)
  const endTimestamp = dayjs(end)

  while (startTimestamp.isBefore(endTimestamp)) {
    let nextEnd = startTimestamp.add(durationInSeconds, "second")

    if (nextEnd.isAfter(endTimestamp)) {
      nextEnd = endTimestamp
    }

    chunks.push({ start: startTimestamp.toISOString(), end: nextEnd.toISOString() })
    startTimestamp = nextEnd
  }

  return chunks
}

function hasMultipleDayChunks(start, end) {
  const secondDayTimestamp = dayjs(start).add(1, "day").startOf("day")
  const endTimestamp = dayjs(end)

  return secondDayTimestamp.isBefore(endTimestamp)
}

export function chunkToDays({ start, end }) {
  // We have a single day, so we can just return the start and end
  if (!hasMultipleDayChunks(start, end)) {
    return [{ start: dayjs(start).startOf("minute").toISOString(), end }]
  }

  const chunks = []
  let startTimestamp = dayjs(start).startOf("hour")
  const endTimestamp = dayjs(end)

  while (startTimestamp.isBefore(endTimestamp)) {
    const nextEnd = startTimestamp.endOf("day")

    // If we go over the end, just add the last chunk and break
    if (nextEnd.isAfter(endTimestamp)) {
      chunks.push({
        start: startTimestamp.toISOString(),
        end: endTimestamp.toISOString(),
      })
      break
    }

    chunks.push({
      start: startTimestamp.toISOString(),
      end: nextEnd.toISOString(),
    })

    // Move to the next day, and start at the beginning
    startTimestamp = startTimestamp.add(1, "day").startOf("day")
  }

  return chunks
}

export function maxRange(firstRange, secondRange) {
  // Turn the ranges into an array, filter out nulls, and map to a dayjs object we can compare
  const days = [firstRange.start, firstRange.end, secondRange.start, secondRange.end]
    .filter((r) => r)
    .map((i) => dayjs(i))

  return { start: dayjs.min(days).toISOString(), end: dayjs.max(days).toISOString() }
}
