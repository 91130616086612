import { BAR, MIN_HOVER_PANEL_WIDTH } from "../../constants"
import { useChartDataContext } from "../../contexts/DataContext"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"
import { useMouseCoordinates } from "../../utils/hooks"

export const useHoverPlacement = () => {
  const prevWidth = React.useRef(MIN_HOVER_PANEL_WIDTH)

  const { mouseX, mouseY } = useMouseCoordinates()
  const { timeseries, renderer } = useChartDataContext()
  const { invertDate, hoverRef } = useChartHoverStateContext()
  const { canvasWidth, width, xScale, graphRef, domain } = useChartGraphContext()

  const [xDomainStart, xDomainEnd] = domain.x
  xScale.domain(domain.x)

  if (!mouseX || !mouseY) {
    return { isVisible: false }
  }

  const visibleDataPoints = timeseries.series[0].data.filter(
    (d) => d.x >= xDomainStart && d.x < xDomainEnd,
  ).length

  if (visibleDataPoints === 0) {
    return { isVisible: false }
  }

  const panelWidth = hoverRef?.current?.offsetWidth || prevWidth.current
  const graphCoordinates = graphRef.current.getBoundingClientRect()

  // Prevent the flicker, when the width resets to the minimum width
  if (panelWidth !== prevWidth.current) {
    prevWidth.current = panelWidth
  }

  // Determine if the panel is about to overflow.
  // Note that we divide by two, as we want to center the panel,
  // so only half of the panel width is considered
  const isAtRightEdge = mouseX + panelWidth / 2 >= width
  const isAtLeftEdge = mouseX - panelWidth / 2 <= 0

  if (isAtRightEdge) {
    return {
      isVisible: true,
      left: graphCoordinates.left + width - panelWidth,
      transform: "translateY(-100%)",
      top: graphCoordinates.top,
    }
  } else if (isAtLeftEdge) {
    return {
      isVisible: true,
      left: graphCoordinates.left,
      transform: "translateY(-100%)",
      top: graphCoordinates.top,
    }
  }

  const canvasXOffset = width - canvasWidth

  const dataPointStart = xScale(invertDate) + canvasXOffset
  const dataPointWidth = canvasWidth / visibleDataPoints

  let panelLeft

  if (renderer === BAR) {
    panelLeft = dataPointStart + dataPointWidth / 2
  } else {
    panelLeft = dataPointStart
  }

  return {
    isVisible: true,
    left: panelLeft + graphCoordinates.left,
    transform: "translateX(-50%) translateY(-100%)", // center the panel
    top: graphCoordinates.top,
  }
}
