import isEqual from "lodash.isequal"
import PropTypes from "prop-types"
import React from "react"

const TagsFormCombinationWarning = ({ tags: tagsArray, metricMetadata }) => {
  if (!metricMetadata) {
    return null
  } // Unknown metric, skip warning

  // Create array with tag uniquely configured tag keys
  const tagSet = new Set()
  const configuredTags = tagsArray || []
  configuredTags.forEach((tag) => {
    if (!tag) {
      return null
    }
    tagSet.add(tag.key)
  })
  const tags = [...tagSet].sort()
  if (tags.length === 0) {
    return null
  } // No tags configured, don't show warning

  const combinationFound = metricMetadata.tagCombinations.some((element) => isEqual(element, tags))
  if (combinationFound) {
    return null
  } // Only show error when not found

  return (
    <p className="mt-2 c-p--sm text-orange">
      Tag combination is unknown in last hour and may not render data in the graph.
    </p>
  )
}

TagsFormCombinationWarning.propTypes = {
  tags: PropTypes.array.isRequired,
  metricMetadata: PropTypes.object,
}

export default TagsFormCombinationWarning
