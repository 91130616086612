import { useState } from "react"

import TriggerFormComparisonOperators, {
  COMPARISON_OPERATORS,
} from "#Components/triggers/form/fields/ComparisonOperators"
import TriggerFormDashboard, {
  dashboardsPropType,
} from "#Components/triggers/form/fields/Dashboard"
import TriggerFormDescription from "#Components/triggers/form/fields/Description"
import MetricName from "#Components/triggers/form/fields/MetricName"
import NoMatchIsZero from "#Components/triggers/form/fields/NoMatchIsZero"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import Tags from "#Components/triggers/form/fields/Tags"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown"
import { DEFAULT_TIMEFRAME } from "#Root/constants/timeframe"

import useMetricKeyMetadata from "../../../hooks/useMetricKeyMetadata"
import {
  findFieldsForMetricType,
  includeMeasurementFields,
  isNameConfigured,
} from "../../../utils/metric_keys"
import { tagsToArray } from "../../../utils/tags"
import withNotifiers, { notifiersPropType } from "../../../wrappers/notifiers"
import withWarmupAndCooldown, {
  warmupAndCooldownPropType,
} from "../../../wrappers/warmup_and_cooldown"
import DataFormatSelector from "../../shared/visuals/DataFormatSelector"
import TriggerGraphPreview from "../GraphPreview"
import TriggerFormField from "./custom_metrics/Field"
import ShareLink from "./ShareLink"

export const formName = "Advanced"
export const paramTypes = {
  metricName: "field",
  name: "field",
  tags: "field",
  field: "field",
  comparisonOperator: "field",
  conditionValue: "field",
  noMatchIsZero: "field",
}

export const CustomMetrics = ({
  params,
  blankSlate,
  trigger,
  onClose,
  onChange,
  notifiers,
  warmupAndCooldown,
  dashboards,
  appId,
  error,
}) => {
  const saveButtonLabel = blankSlate ? "Save your first Trigger" : "Save Trigger"
  const [triggerData, setTriggerData] = useState(() => {
    if (trigger && trigger.id) {
      return {
        metricName: trigger.metricName,
        name: trigger.name || trigger.metricName,
        tags: trigger.tags || [],
        field: trigger.field,
        comparisonOperator: trigger.thresholdCondition.comparisonOperator,
        conditionValue: trigger.thresholdCondition.value.toString(),
        description: trigger.description,
        noMatchIsZero: trigger.noMatchIsZero,
        dashboardId: trigger.dashboardId,
        format: trigger.format || "number",
        formatInput: trigger.formatInput || null,
      }
    } else {
      return {
        metricName: params.metricName || "",
        name: params.name || params.metricName || "",
        tags: tagsToArray(params.tags) || [],
        field: (params.field || "GAUGE").toUpperCase(),
        comparisonOperator: params.comparisonOperator || COMPARISON_OPERATORS[0]["value"],
        conditionValue: params.conditionValue || "0.0",
        description: params.description || "",
        noMatchIsZero: params.noMatchIsZero === "true" ? true : false,
        dashboardId: params.dashboardId || "",
        format: params.format || "number",
        formatInput: params.formatInput || null,
      }
    }
  })

  const isTriggerNameConfigured = isNameConfigured(triggerData.metricName)
  const { loading: metadataLoading, metadata: metricMetadata } = useMetricKeyMetadata(
    {
      skip: !isTriggerNameConfigured,
      variables: { appId, timeframe: DEFAULT_TIMEFRAME, name: triggerData.metricName },
    },
    (key) => {
      // Update the `fields` for the metric once we know which metric the user
      // has selected.
      const fields = findFieldsForMetricType(key.type)
      const field = fields[0].field
      if (includeMeasurementFields(fields || [])) {
        if (!includeMeasurementFields([{ field: triggerData.field }])) {
          // Only apply a measurement field if the metric currently does not
          // use a measurement field. This will overwrite any GAUGE and
          // COUNTER, but not existing measurement fields. Otherwise the
          // trigger form will always reopen with the first measurement field
          // selected.
          setTriggerData({ ...triggerData, field })
        }
      } else {
        // Non Measurement metric fields, apply normally
        setTriggerData({ ...triggerData, field })
      }
    },
  )

  const handleFormSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    onChange({
      ...normalizeTrigger(triggerData),
      conditionValue: parseFloat(triggerData.conditionValue),
    })
  }

  const lineLabelFor = (tags) => {
    const label = ["%name%"]
    const mappedTags = new Set()
    ;(tags || []).forEach((tag) => tag && mappedTags.add(`%${tag.key}%`))
    if (mappedTags.size > 0) {
      label.push(` - ${[...mappedTags].sort().join("-")}`)
    }
    return label.join("")
  }
  const lineLabel = lineLabelFor(triggerData.tags)

  const handleDataFormatChange = ({ format, formatInput }) => {
    setTriggerData({
      ...triggerData,
      format,
      formatInput,
    })
  }

  const suffixWrapper = (text) => {
    return <span className="c-textfield__suffix">{text}</span>
  }

  const valueSuffix = (trigger) => {
    switch (trigger.format) {
      case "duration": {
        return suffixWrapper("ms")
      }
      case "size": {
        switch (trigger.formatInput) {
          case "bit": {
            return suffixWrapper("Bits/min")
          }
          case "byte": {
            return suffixWrapper("Bytes/min")
          }
          case "kilobit": {
            return suffixWrapper("Kilobits/min")
          }
          case "kilobyte": {
            return suffixWrapper("Kilobytes/min")
          }
          case "megabyte": {
            return suffixWrapper("Megabytes/min")
          }
          default: {
            return suffixWrapper("MB/min")
          }
        }
      }
      case "throughput": {
        return suffixWrapper(<abbr title="requests per minute">req/min</abbr>)
      }
      case "percent": {
        return suffixWrapper("%")
      }
      default: {
        return null
      }
    }
  }

  return (
    <>
      <div className="relative h-full overflow-y-auto">
        <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
          <h2 className="c-heading text-base mb-0 text-gray-800">Custom metrics Trigger</h2>
        </div>
        {error}
        <form onSubmit={handleFormSubmit} acceptCharset="UTF-8" className="px-5">
          <div className="py-5">
            <p>Set up alerts for custom metrics.</p>
            <p>
              See our docs for more information about{" "}
              <a href="https://docs.appsignal.com/metrics/custom.html">metrics</a>.
            </p>
          </div>
          <hr />
          <div className="c-form">
            <label className="c-form__label" htmlFor="trigger-name">
              Name
            </label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  className="c-textfield__input"
                  name="Name"
                  id="trigger-name"
                  value={triggerData.name}
                  onChange={(e) => setTriggerData({ ...triggerData, name: e.target.value })}
                />
              </div>
            </div>
            <p className="c-form__description">
              Use a descriptive name for your trigger. This name will be used in alert
              notifications. Leave empty to use the metric name.
            </p>
          </div>
          <hr />
          <MetricName
            triggerData={triggerData}
            onChange={setTriggerData}
            appId={appId}
            timeframe={DEFAULT_TIMEFRAME}
          />

          {!metadataLoading && triggerData.metricName && (
            <>
              <TriggerFormField
                trigger={triggerData}
                metricMetadata={metricMetadata}
                onChange={(trigger) => setTriggerData({ ...triggerData, ...trigger })}
              />
            </>
          )}

          {!metadataLoading && triggerData.metricName && (
            <Tags
              triggerData={triggerData}
              metricMetadata={metricMetadata}
              onChange={(trigger) => setTriggerData({ ...triggerData, ...trigger })}
            />
          )}

          <div className="c-form">
            <label className="c-form__label" htmlFor="comparisonOperator">
              Comparison operator
            </label>
            <div className="c-form__element">
              <TriggerFormComparisonOperators
                onChange={(e) =>
                  setTriggerData({ ...triggerData, comparisonOperator: e.comparisonOperator })
                }
                value={triggerData.comparisonOperator}
              />
            </div>
          </div>

          <div className="c-form">
            <label className="c-form__label" htmlFor="conditionValue">
              Value
            </label>
            <div className="c-form__element">
              <div className="c-textfield">
                <input
                  className="c-textfield__input"
                  id="conditionValue"
                  name="conditionValue"
                  step="any"
                  type="number"
                  placeholder="1"
                  value={triggerData.conditionValue}
                  onChange={(e) =>
                    setTriggerData({ ...triggerData, conditionValue: e.target.value })
                  }
                />
                {valueSuffix(triggerData)}
              </div>
            </div>
          </div>
          <hr />
          <div>
            <NoMatchIsZero
              value={triggerData.noMatchIsZero}
              onChange={(value) =>
                setTriggerData({
                  ...triggerData,
                  noMatchIsZero: value,
                })
              }
            />
            <hr />
          </div>

          <TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
          <hr />

          <TriggerFormDescription
            description={triggerData.description || ""}
            onChange={(newDescription) =>
              setTriggerData({ ...triggerData, description: newDescription })
            }
          />
          <hr />

          {dashboards.length > 0 && (
            <>
              <TriggerFormDashboard
                dashboardId={triggerData.dashboardId}
                dashboards={dashboards}
                onChange={(newDashboardId) =>
                  setTriggerData({ ...triggerData, dashboardId: newDashboardId })
                }
              />
              <hr />
            </>
          )}

          <TriggerFormNotifiers notifiers={notifiers} />
          <hr />

          <ShareLink trigger={triggerData} form={formName} warmupAndCooldown={warmupAndCooldown} />

          <div className="my-5">
            <button className="c-button c-button--sm mr-4">{saveButtonLabel}</button>
            {!blankSlate && (
              <button
                id="cancelLink"
                onClick={onClose}
                className="c-button c-button--sm c-button--white"
              >
                Cancel
              </button>
            )}
          </div>
        </form>
      </div>
      <div className="w-256 p-5 bg-gray-100">
        <TriggerGraphPreview
          title="Custom metrics graph preview"
          lineLabel={lineLabel}
          appId={appId}
          trigger={normalizeTrigger(triggerData)}
          valueFormat={triggerData.format}
          valueInput={triggerData.formatInput}
        />
        <div className="mt-5">
          <DataFormatSelector
            visual={{ format: triggerData.format, formatInput: triggerData.formatInput }}
            onChange={handleDataFormatChange}
          />
        </div>
      </div>
    </>
  )
}

CustomMetrics.propTypes = {
  params: PropTypes.object.isRequired,
  blankSlate: PropTypes.bool,
  trigger: PropTypes.object,
  appId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  notifiers: notifiersPropType.isRequired,
  warmupAndCooldown: warmupAndCooldownPropType.isRequired,
  dashboards: dashboardsPropType.isRequired,
  error: PropTypes.object,
}

function normalizeTrigger(rawTrigger) {
  const trigger = { ...rawTrigger }
  const newTags = []
  const tags = trigger.tags || []
  tags.forEach((tag) => {
    if (tag && tag.key) {
      // Remove any metric tags that are incomplete so that the graph and
      // code preview don't render with invalid config
      // Only use key and value properties, and not __typename
      const { key, value } = tag
      newTags.push({ key, value })
    }
  })
  trigger.tags = newTags
  return trigger
}

export default withWarmupAndCooldown(withNotifiers(CustomMetrics))
