import PropTypes from "prop-types"
import React from "react"

import { isNameConfigured } from "../../../../utils/metric_keys"
import { areFieldsConfigured } from "../Utils"

export const MetricList = ({ metrics, onSelect, onChange }) => {
  const handleSelect = (index) => { onSelect(index) }
  const handleAddMetric = () => {
    const emptyNewMetric = { name: null }
    onChange({ metrics: [...metrics, emptyNewMetric] })
    handleSelect(metrics.length)
  }
  const parseMetric = (metric) => {
    const nameConfigured = isNameConfigured(metric.name)
    const fieldsConfigured = areFieldsConfigured(metric.fields)
    return {
      name: nameConfigured ? metric.name : <em>Unconfigured metric</em>,
      nameConfigured,
      fields: fieldsConfigured ? renderFields(metric.fields) : <em>Unconfigured fields</em>,
      fieldsConfigured,
      hasError: !nameConfigured || !fieldsConfigured
    }
  }
  const metricClasses = (details) => {
    const classes = ["border my-3 p-3 rounded cursor-pointer block no-underline"]
    if (details.hasError) {
      classes.push("border-red-200 hover:bg-red-100")
    } else {
      classes.push("hover:border-blue-200 hover:bg-blue-100")
    }
    return classes.join(" ")
  }
  const renderFields = (fields) => {
    return fields.map(({ field }) => {
      return <span className="c-pill bg-gray-200 mr-2" key={field}>{field}</span>
    })
  }
  const renderTags = (metric) => {
    const mappedTags = []
    const tags = (metric.tags || [])
    tags.map((tag, index) => {
      if (!tag) { return }

      mappedTags.push(
        <div className="c-code c-code--white bg-transparent p-0" key={`MetricTag${index}`}>
          {tag.key}: {renderTagValue(tag.value)}
        </div>
      )
    })
    if (mappedTags.length > 0) {
      return <div>{mappedTags}</div>
    }
  }
  const renderTagValue = (value) => {
    if (value === "") {
      // Show empty string as value
      return "''"
    }
    return value
  }
  const renderError = (details) => {
    if (!details.hasError) { return }

    return (
      <div
        className="c-form__error mt-2"
      >
        <i className="fa fa-fw fa-exclamation-circle mr-1" />
        Configuration invalid
      </div>
    )
  }
  const metricsItems = metrics.map((metric, index) => {
    const metricDetails = parseMetric(metric)
    return (
      <a
        key={`metricListItem-${index}`}
        className={metricClasses(metricDetails)}
        onClick={handleSelect.bind(this, index)}
      >
        <h2 className="c-heading">{metricDetails.name}</h2>
        <div className="mb-1">{metricDetails.fields}</div>
        {renderTags(metric)}
        {renderError(metricDetails)}
      </a>
    )
  })

  return (
    <div>
      {metricsItems}
      <a
        className="c-button c-button--white c-button--xs block text-center cursor-pointer"
        onClick={handleAddMetric}
      >Add metric</a>
    </div>
  )
}

MetricList.propTypes = {
  metrics: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
}

export default MetricList
