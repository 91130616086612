import dayjs from "dayjs"
import advancedFormat from "dayjs/plugin/advancedFormat"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import PropTypes from "prop-types"
import React, { useContext } from "react"
dayjs.extend(advancedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
import { FormatterContext } from "../../contexts/FormatterContext"

const TIME_FORMATS = {
  DMY: {
    default: "Do MMM YYYY HH:mm Z",
    long: "Do MMM YYYY HH:mm",
    longWithSeconds: "Do MMM YYYY HH:mm:ss",
    weekday: "ddd Do MMM HH:mm",
    short: "Do MMM HH:mm",
    shortWithSeconds: "Do MMM HH:mm:ss",
    date: "DD-MM",
    dateLong: "DD-MM-YYYY",
    time: "HH:mm",
    timeWithSeconds: "HH:mm:ss",
    log: "DD-MM-YYYY HH:mm:ss.SSS",
  },
  MDY: {
    default: "MMM Do YYYY HH:mm Z",
    long: "MMM Do YYYY HH:mm",
    longWithSeconds: "MMM Do YYYY HH:mm:ss",
    weekday: "ddd MMM Do HH:mm",
    short: "MMM Do HH:mm",
    shortWithSeconds: "MMM Do HH:mm:ss",
    date: "MM-DD",
    dateLong: "MM-DD-YYYY",
    time: "HH:mm",
    timeWithSeconds: "HH:mm:ss",
    log: "MM-DD-YYYY HH:mm:ss.SSS",
  },
  YMD: {
    default: "YYYY MMM Do HH:mm Z",
    long: "YYYY MMM Do HH:mm",
    longWithSeconds: "YYYY MMM Do HH:mm:ss",
    weekday: "ddd MMM Do HH:mm",
    short: "MMM Do HH:mm",
    shortWithSeconds: "MMM Do HH:mm:ss",
    date: "MM-DD",
    dateLong: "YYYY-MM-DD",
    time: "HH:mm",
    timeWithSeconds: "HH:mm:ss",
    log: "YYYY-MM-DD HH:mm:ss.SSS",
  },
}

const FormattedTime = ({ format, className, value, noTitle, onClick }) => {
  const formatter = useContext(FormatterContext)
  const inTimezone = (time, timezone) => {
    const dayjsTime = dayjs(time)
    if (timezone) {
      return dayjsTime.tz(timezone)
    } else {
      return dayjsTime
    }
  }
  const { date, timeZone } = formatter
  const selectedFormat = TIME_FORMATS[date][format]
  const dayjsTime = inTimezone(value, timeZone)

  if (!selectedFormat) {
    throw `Format '${format}' does not exist`
  }

  const timestamp = dayjsTime.format()
  const formattedTime = dayjsTime.format(selectedFormat)

  return (
    <time
      dateTime={timestamp}
      title={!noTitle ? timestamp : undefined}
      onClick={onClick}
      className={`formatted-time ${className || ""}`}
    >
      {formattedTime}
    </time>
  )
}

FormattedTime.propTypes = {
  format: PropTypes.string,
  className: PropTypes.string,
  noTitle: PropTypes.bool,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.object.isRequired]),
}

FormattedTime.defaultProps = {
  format: "default",
}

export default FormattedTime
