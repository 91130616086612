import PropTypes from "prop-types"
import React from "react"
import Cookie from "universal-cookie"

export default function withPersistedParams(WrappedComponent, persistedKeys) {
  class PersistedParams extends React.Component {
    constructor(props) {
      super(props)
      this.state = { trigger: null }
    }

    keyWithPrefix(k) {
      if (this.props.params?.appId) {
        return `${this.props.params.appId}_${k}`
      }

      return k
    }

    // Called from wrapped component, sets given params in url or cookie,
    // depending on persistedKeys settings
    setParams(newParams, trigger = true) {
      const cookieParams = {}
      const urlParams = {}

      for (const k in newParams) {
        // Get all params that have to be set in a cookie
        const v = newParams[k]
        if (persistedKeys[k] && persistedKeys[k]["cookie"] === true) {
          const ingoreCookieFunction = persistedKeys[k]["ignoreCookieFunc"]

          if (
            (typeof ingoreCookieFunction === "function" && ingoreCookieFunction(v) === false) ||
            !ingoreCookieFunction
          ) {
            cookieParams[this.keyWithPrefix(k)] = v
          }
        }

        // Get all params that have to be set in the url
        if (persistedKeys[k] && persistedKeys[k]["url"] === true) {
          urlParams[k] = v
        }
      }

      // Store cookieparams in cookie
      this.setCookieParams(cookieParams)

      // If we have urlparams, store them in the url
      if (Object.keys(urlParams).length > 0) {
        return this.navigateToCurrentPathWithParams(urlParams, trigger)

        // If not, force a re-render of the child
      } else if (trigger) {
        return this.forceRender()
      }
    }

    // Update trigger state to force a re-render
    forceRender() {
      return this.setState({ trigger: new Date().getTime() })
    }

    render() {
      const cookies = new Cookie()
      // Get defaults, cookie or url params
      for (const k in persistedKeys) {
        // Set default
        const keyWithPrefix = this.keyWithPrefix(k)
        const v = persistedKeys[k]
        let p = undefined

        // If there is a default value given
        if (Object.prototype.hasOwnProperty.call(v, "default")) {
          p = v.default
        }

        // If we store state in cookie
        if (
          v.cookie === true &&
          cookies.get(keyWithPrefix) !== null &&
          cookies.get(keyWithPrefix) !== undefined
        ) {
          p = cookies.get(keyWithPrefix)
        }

        // If we get state from url
        if (v.url === true && Object.prototype.hasOwnProperty.call(this.props.params, k)) {
          p = this.props.params[k]
        }

        // Write value to props.params
        if (p !== undefined) {
          this.props.params[k] = p
        }
      }

      return (
        <WrappedComponent
          setParams={this.setParams.bind(this)}
          mergeParams={this.mergeWithParams.bind(this)}
          {...this.props}
        />
      )
    }

    // Writes given params to cookie with coookieOptions
    setCookieParams(params) {
      const cookies = new Cookie()
      return (() => {
        const result = []
        for (const k in params) {
          const v = params[k]
          result.push(cookies.set(k, v, { maxAge: 356 * 86400, path: "/" }))
        }
        return result
      })()
    }

    // Merges given params with current URL params
    // and triggers the backbone router
    navigateToCurrentPathWithParams(givenParams, trigger = true) {
      const path = this.currentPathWithParams(givenParams)
      return this.context.router.navigate(path, { trigger: trigger })
    }

    // Returns current path, with given params merged with current params
    currentPathWithParams(givenParams) {
      const currentPath = location.pathname
      const queryArray = [] // Holds encoded params to be joined into query
      const newParams = this.mergeNewQueryParams(givenParams)

      // Loop the params, URI encode the key/value and add it to the queryArray
      for (const key in newParams) {
        const val = newParams[key]
        queryArray.push(encodeURIComponent(key) + "=" + encodeURIComponent(val))
      }

      // Join the path with params
      return `${currentPath}?${queryArray.join("&")}`
    }

    // Merge new params with existing params,
    // remove any params that have `nil` as value
    // (prevents params from lingering in the URL)
    mergeNewQueryParams(givenParams) {
      let v
      const newParams = {} // Holds the new params
      const finalParams = {} // Holds the params we actually return

      // Clone the old params, if they have a value
      for (const k in this.props.params.rawParams) {
        v = this.props.params.rawParams[k]
        newParams[k] = v
      }

      // Add/override the new param
      for (const k in givenParams) {
        v = givenParams[k]
        newParams[k] = v
      }

      // Only add params to the final hash if they have a value
      for (const k in newParams) {
        v = newParams[k]
        if (v != null) {
          finalParams[k] = v
        }
      }

      return finalParams
    }

    // Convenience method, merge given params with current params
    mergeWithParams(givenParams) {
      let v
      const newParams = {}

      // Clone the old params
      for (const k in this.props.params) {
        v = this.props.params[k]
        newParams[k] = v
      }

      // Add/override the new param
      for (const k in givenParams) {
        v = givenParams[k]
        newParams[k] = v
      }

      return newParams
    }
  }
  PersistedParams.propTypes = {
    params: PropTypes.object.isRequired,
  }
  PersistedParams.contextTypes = {
    router: PropTypes.object,
  }
  PersistedParams.displayName = `PersistedParams(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`
  return PersistedParams
}
