import PropTypes from "prop-types"
import React from "react"

const LoadingSpinner = ({ size, text = "Loading", spinnerClassName = "" }) => {
  const imgClasses = ["c-spinner__img", spinnerClassName.split(" ")]
  const sizeMap = {
    default: "",
    sm: "c-spinner__img--sm",
    md: "c-spinner__img--md",
    lg: "c-spinner__img--lg",
  }

  if (size && sizeMap[size]) imgClasses.push(sizeMap[size])

  return (
    <span className="c-spinner">
      <span className={`text-gray-600 ${imgClasses.flat().join(" ")}`}></span>
      <span className="c-spinner__text text-gray-600">{text}</span>
    </span>
  )
}

LoadingSpinner.propTypes = {
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  text: PropTypes.string,
  spinnerClassName: PropTypes.string,
}

export default LoadingSpinner
