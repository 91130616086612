import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import BarChart from "#Components/shared/bar_chart"
import UrlHelper from "#Components/shared/url_helper"
import { Column,Table } from "#Components/Table"
import { SEVERITIES } from "#Root/constants/Logs"
import FormattedNumber from "#Root/formatters/FormattedNumber"
import { maxField } from "#Root/utils/table"
import { resolutionEnum } from "#Root/utils/TimeDetective"

const QUERY = gql`
  query TimeDetectiveOverlayLogsQuery(
    $appId: String!
    $start: PreciseDateTime
    $end: PreciseDateTime
    $resolution: ResolutionEnum
  ) {
    app(id: $appId) {
      id
      logs {
        sources {
          id
          name
          metrics(start: $start, end: $end, resolution: $resolution) {
            series {
              digest
              name
            }
            values {
              values {
                key
                value
              }
            }
          }
        }
      }
    }
  }
`

const TimeDetectiveLogsPage = ({ params }) => {
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
      resolution: resolutionEnum(params.td_start),
      limit: 1000,
    },
  })

  const mappedData = (data?.app?.logs?.sources || []).map(({ metrics, ...source }) => {
    const fields = {}
    for (const severity of SEVERITIES) {
      const field = severity.value.toLowerCase()
      const digest = metrics.series.find((s) => s.name == field)?.digest
      const count = digest
        ? (metrics.values[0]?.values || []).find((v) => v.key == `${digest};counter`)?.value || 0
        : 0
      fields[field] = count
    }
    return { ...fields, ...source }
  })
  const maxValues = {
    unknown: maxField(mappedData, "unknown"),
    trace: maxField(mappedData, "trace"),
    debug: maxField(mappedData, "debug"),
    info: maxField(mappedData, "info"),
    notice: maxField(mappedData, "notice"),
    warn: maxField(mappedData, "warn"),
    error: maxField(mappedData, "error"),
    critical: maxField(mappedData, "critical"),
    alert: maxField(mappedData, "alert"),
    fatal: maxField(mappedData, "fatal"),
  }

  /* eslint-disable react/prop-types */
  const NameCell = ({
    cellData,
    rowData,
    container: {
      props: { start },
    },
  }) => (
    <div className="c-virtual-table__row-cell-content">
      <a
        href={UrlHelper.logsPagePath({ timestamp: start, sourceIds: rowData.id })}
        className="c-link"
      >
        {cellData}
      </a>
    </div>
  )

  /* eslint-disable react/prop-types */
  const NumberCell = ({
    cellData,
    rowData,
    column: { dataKey },
    container: {
      props: { maxValues, start },
    },
  }) => (
    <div className="c-virtual-table__row-cell-content">
      <a
        href={UrlHelper.logsPagePath({
          timestamp: start,
          sourceIds: rowData.id,
          severities: dataKey.toUpperCase(),
        })}
        className="c-link"
      >
        <FormattedNumber value={cellData} />
      </a>
      <BarChart max={maxValues[dataKey]} val={cellData} />
    </div>
  )
  return (
    <Table
      data={mappedData}
      title="Log lines per source in this timeframe"
      initialSortKey="name"
      initialSortOrder="asc"
      loading={loading}
      error={error}
      maxValues={maxValues}
      start={params.td_start}
      ignoreContainerHeight
    >
      <Column dataKey="name" title="Name" flexGrow={1} minWidth={200} cellRenderer={NameCell} />
      <Column dataKey="unknown" title="Unknown" cellRenderer={NumberCell} />
      <Column dataKey="trace" title="Trace" cellRenderer={NumberCell} />
      <Column dataKey="debug" title="Debug" cellRenderer={NumberCell} />
      <Column dataKey="info" title="Info" cellRenderer={NumberCell} />
      <Column dataKey="notice" title="Notice" cellRenderer={NumberCell} />
      <Column dataKey="warn" title="Warn" cellRenderer={NumberCell} />
      <Column dataKey="error" title="Error" cellRenderer={NumberCell} />
      <Column dataKey="critical" title="Critical" cellRenderer={NumberCell} />
      <Column dataKey="alert" title="Alert" cellRenderer={NumberCell} />
      <Column dataKey="fatal" title="Fatal" cellRenderer={NumberCell} />
    </Table>
  )
}

TimeDetectiveLogsPage.propTypes = {
  params: PropTypes.object.isRequired,
}

export default TimeDetectiveLogsPage
