import React from "react"

import useFeatureUsed from "../hooks/useFeatureUsed"

export default function withUsedFeature(WrappedComponent) {
  const WithUsedFeature = (props) => {
    const { didUse, markUsed } = useFeatureUsed()

    return (
      <WrappedComponent
        hasUsedFeature={didUse}
        didUseFeature={markUsed}
        {...props} />
    )
  }

  WithUsedFeature.displayName = `UsedFeature(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`

  return WithUsedFeature
}
