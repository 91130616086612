import { bisector } from "d3-array"

import { useChartDataContext } from "../../contexts/DataContext"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

// When preview mode is enabled, the chart will display the middle point of the chart
export const PreviewMode = ({ enabled = false }) => {
  const { setHoverState } = useChartHoverStateContext()
  const { canvasWidth, xScale, canvasHeight } = useChartGraphContext()
  const { timeseries } = useChartDataContext()

  React.useEffect(() => {
    if (enabled) {
      const invert = xScale.invert(canvasWidth / 2)
      const bisectDate = bisector((d) => d.x).center
      const dataIndex = bisectDate(timeseries.series[0].data, invert, 1)
      const invertDate = timeseries.series[0].data[dataIndex - 1].x

      setHoverState({
        dataIndex: dataIndex - 1,
        invertDate,
        canvasWidth: canvasWidth,
        mouseX: canvasWidth / 2,
        mouseY: canvasHeight / 2,
      })
    } else {
      setHoverState(null)
    }
  }, [enabled, setHoverState, timeseries, xScale, canvasWidth, canvasHeight])

  return null
}

PreviewMode.propTypes = {
  enabled: PropTypes.bool,
}

export default PreviewMode
