import PropTypes from "prop-types"
import React from "react"

export default function BarChart({ val, max, color = "gray", padEmpty = true }) {
  const style = {}
  const colorMapping = {
    gray: {
      background: "bg-gray-200 bg-opacity-50",
      foreground: "bg-gray-400",
    },
    blue: {
      background: "bg-blue-200 bg-opacity-50",
      foreground: "bg-blue-300",
    },
    green: {
      background: "bg-green-200 bg-opacity-50",
      foreground: "bg-green-300",
    },
    orange: {
      background: "bg-orange-200 bg-opacity-50",
      foreground: "bg-orange-300",
    },
    purple: {
      background: "bg-purple-200 bg-opacity-50",
      foreground: "bg-purple-300",
    },
    teal: {
      background: "bg-teal-200 bg-opacity-50",
      foreground: "bg-teal-300",
    },
    yellow: {
      background: "bg-yellow-200 bg-opacity-50",
      foreground: "bg-yellow-300",
    },
  }

  const backgroundColor = colorMapping[color].background
  const foregroundColor = colorMapping[color].foreground

  if (max === 0) {
    style["width"] = "0%"
  } else {
    const pct = Math.round((val / max) * 100)
    if (pct < 6 && padEmpty) {
      style["width"] = "6px"
    } else {
      style["width"] = `${pct}%`
    }
  }

  return (
    <span className={`c-bar ${backgroundColor}`}>
      <span
        className={`c-bar__value min-w-0 ${foregroundColor}`}
        style={style}
        data-testid="bar"
      ></span>
    </span>
  )
}

BarChart.propTypes = {
  val: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  color: PropTypes.oneOf(["gray", "blue", "green", "orange", "purple", "teal", "yellow"]),
  hasBackground: PropTypes.bool,
  padEmpty: PropTypes.bool,
}
