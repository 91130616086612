import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"
import FormattedPercent from "#Root/formatters/FormattedPercent"

import { labelWithHostnameFromTags } from "../../../../utils/host_metrics"
import Link from "../../../shared/link"
import { METRICS } from "../../../triggers/form/host/swap_usage"
import TriggerFormButton from "../../../triggers/form_button"

const TriggerHostSwapUsage = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const tags = alertTags || trigger.tags
  const { comparisonOperator, value } = trigger.thresholdCondition
  const label = labelWithHostnameFromTags("Host swap usage", tags)
  let formattedValue
  if (trigger.metricName === "swap_usage") {
    formattedValue = <FormattedPercent value={value} />
  } else {
    formattedValue = <FormattedFileSize value={value} />
  }

  return (
    <>
      <span className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </span>
      <p className="pt-px text-gray-700">
        {METRICS[trigger.metricName]}
        <FormattedComparisonOperator value={comparisonOperator} />
        {formattedValue}
      </p>
    </>
  )
}
TriggerHostSwapUsage.propTypes = {
  trigger: PropTypes.object.isRequired,
  link: PropTypes.string,
  tags: PropTypes.array,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostSwapUsage
