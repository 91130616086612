import { useTableContext } from "#Root/contexts/TableContext"
import LoadingSpinner from "#Root/ui/LoadingSpinner"

const LoadingState = () => {
  const { table, loading, dataSize } = useTableContext()
  if (!loading) return null
  if (dataSize > 0) return null

  return (
    <tbody>
      <tr>
        <td colSpan={table.getFlatHeaders().length} className="c-table__td">
          <div className="flex items center justify-center h-48">
            <LoadingSpinner />
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export default LoadingState
