import Mousetrap from "mousetrap"
import PropTypes from "prop-types"
import React from "react"
import onClickOutside from "react-onclickoutside"
import { Portal } from "react-portal"

/*
 * This is an older component and should be avoided
 * You're probably searching for /ui/overlay
 */

export class Overlay extends React.Component {
  static propTypes = {
    title: PropTypes.node,
    footer: PropTypes.node,
    classNames: PropTypes.string,
    innerClassName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  }

  handleClose = () => {
    return this.props.onClose()
  }

  UNSAFE_componentWillMount() {
    const { handleClose } = this
    return Mousetrap.bind(["escape"], () => handleClose())
  }

  componentWillUnmount() {
    return Mousetrap.unbind("escape")
  }

  render() {
    let footer
    if (this.props.footer) {
      footer = <footer className="overlay-footer">{this.props.footer}</footer>
    }

    const classNames = `l-overlay ${this.props.classNames}`
    return (
      <Portal>
        <div className={classNames}>
          <OverlayInner onClose={this.handleClose} className={this.props.innerClassName}>
            <header className="overlay-header">
              <h1>{this.props.title}</h1>
              <button onClick={this.handleClose}>
                <i className="fa fa-times"></i>
              </button>
            </header>
            <div className="overlay-content rounded">{this.props.children}</div>
            {footer}
          </OverlayInner>
        </div>
      </Portal>
    )
  }
}

class OverlayInnerClass extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    className: "",
  }

  handleClickOutside = () => {
    return this.props.onClose()
  }

  render() {
    const classNames = `l-inner ${this.props.className}`

    return <div className={classNames}>{this.props.children}</div>
  }
}

export const OverlayInner = onClickOutside(OverlayInnerClass)

export default Overlay
