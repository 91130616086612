import gql from "graphql-tag"

import StatusPageFragment from "./_statusPageFragment"

export const statusPageFormQuery = gql`
  query statusPageFormQuery($organizationSlug: String!, $id: String) {
    organization(slug: $organizationSlug) {
      id
      name
      statusPage(id: $id) {
        ...StatusPage
      }
      apps {
        id
        name
        environment
        uptimeMonitors {
          id
          name
          url
        }
      }
    }
  }
  ${StatusPageFragment}
`

export default statusPageFormQuery
