import { useTableContext } from "#Root/contexts/TableContext"
import Dropdown from "#Root/ui/Dropdown/Dropdown"
import { capitalize } from "#Root/utils/string"

const ColumnSelect = () => {
  const { table } = useTableContext()
  const dropdownItems = table.getAllColumns().map((column) => (
    <Dropdown.Item
      key={column.id}
      onSelect={(e) => column.getToggleVisibilityHandler()({ target: { value: e } })}
      selected={column.getIsVisible()}
      locked={!column.getCanHide()}
      closeOnSelect={false}
      value={column.name}
    >
      {capitalize(column.id)}
    </Dropdown.Item>
  ))
  const selected = table.getAllColumns().filter((column) => column.getIsVisible()).length

  return (
    <Dropdown select="multiple" closeOnSelect={false}>
      <Dropdown.Button
        iconPosition="right"
        customIcon={<i className="fa fa-table text-gray-500" />}
        hideCaret
      >
        Columns {selected} / {dropdownItems.length}
      </Dropdown.Button>
      <Dropdown.Items align="right">
        <Dropdown.Item
          onSelect={(_e) =>
            table.getToggleAllColumnsVisibilityHandler()({ target: { value: null } })
          }
          selected={table.getIsAllColumnsVisible()}
        >
          Show all columns
        </Dropdown.Item>
        <hr className="bg-gray-200 mb-3 mt-0.5 ignore-old-css -ml-2" />
        {dropdownItems}
      </Dropdown.Items>
    </Dropdown>
  )
}

export default ColumnSelect
