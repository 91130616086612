import dayjs from "dayjs"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import FormattedTime from "#Root/formatters/FormattedTime"

import { X_AXIS_HEIGHT, Y_AXIS_WIDTH } from "../constants"
import { useChartGraphContext } from "../contexts/GraphContext"

const XAxis = ({ tickInterval, tickSize = 100 }) => {
  const { settings } = useChartSettingsContext()
  const { width, canvasWidth, canvasHeight, xScale, domain, hasYAxis } = useChartGraphContext()

  xScale.domain(domain.x)
  const ticks = xScale.ticks.apply(xScale, [tickInterval || Math.round(width / tickSize)])

  const scaleStart = dayjs(xScale.domain()[0])
  const scaleEnd = dayjs(xScale.domain()[1])

  const format = scaleEnd.diff(scaleStart, "hours") > 24 ? "date" : "time"

  return (
    <>
      {settings.verticalSupportLines &&
        ticks.map((tick, i) => (
          <div
            key={i}
            className="absolute h-px w-px z-0 pointer-events-none border-l border-gray-200/75 border-dashed x-axis-grid-line"
            style={{
              left: hasYAxis ? xScale(tick) + Y_AXIS_WIDTH : xScale(tick),
              height: canvasHeight,
            }}
          />
        ))}
      <div
        className="absolute text-ms text-gray-600 pt-3"
        style={{
          top: `${canvasHeight}px`,
          left: `${width - canvasWidth}px`,
          width: `${canvasWidth}px`,
          height: `${X_AXIS_HEIGHT}px`,
        }}
      >
        {ticks.map((tick, i) => (
          <div key={i}>
            <span
              style={{ left: `${xScale(tick)}px` }}
              className="absolute block whitespace-nowrap -translate-x-1/2 select-none"
            >
              <FormattedTime value={tick} format={format} />
            </span>
          </div>
        ))}
      </div>
    </>
  )
}

XAxis.propTypes = {
  tickInterval: PropTypes.func,
  tickSize: PropTypes.number,
}
XAxis.displayName = "XAxis"

export default XAxis
