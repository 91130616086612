import Checkbox from "#Root/ui/Checkbox"

import Section from "./Section"

const HorizontalLinePreview = () => (
  <svg width="25" height="13" viewBox="0 0 25 13" fill="none" className="stroke-gray-600">
    <line x1="0.5" y1="2" x2="24.5" y2="2" strokeLinecap="square" strokeDasharray="3 3" />
    <line x1="0.5" y1="6" x2="24.5" y2="6" strokeLinecap="square" strokeDasharray="3 3" />
    <line x1="0.5" y1="10" x2="24.5" y2="10" strokeLinecap="square" strokeDasharray="3 3" />
  </svg>
)

const VerticalLinePreview = () => (
  <svg width="26" height="13" viewBox="0 0 26 13" fill="none" className="stroke-gray-600">
    <line x1="0.878418" y1="0.5" x2="0.878418" y2="12.5" strokeDasharray="3 1" />
    <line x1="8.87842" y1="0.5" x2="8.87842" y2="12.5" strokeDasharray="3 1" />
    <line x1="16.8784" y1="0.5" x2="16.8784" y2="12.5" strokeDasharray="3 1" />
    <line x1="24.8784" y1="0.5" x2="24.8784" y2="12.5" strokeDasharray="3 1" />
  </svg>
)

const ChartSupportLines = ({
  horizontalSupportLines,
  verticalSupportLines,
  setHorizontalSupportLines,
  setVerticalSupportLines,
}) => {
  return (
    <Section>
      <Section.Title>Grid lines</Section.Title>
      <Section.Content>
        <ul className="flex items-center space-x-3">
          <li>
            <Checkbox
              id="horizontal"
              name="support-lines"
              className="px-1"
              label={<HorizontalLinePreview />}
              ariaLabel="Horizontal lines"
              selected={horizontalSupportLines}
              setSelected={() => setHorizontalSupportLines(!horizontalSupportLines)}
            />
          </li>
          <li>
            <Checkbox
              id="vertical"
              name="support-lines"
              className="px-1"
              label={<VerticalLinePreview />}
              ariaLabel="Vertical lines"
              selected={verticalSupportLines}
              setSelected={() => setVerticalSupportLines(!verticalSupportLines)}
            />
          </li>
        </ul>
      </Section.Content>
    </Section>
  )
}

ChartSupportLines.propTypes = {
  horizontalSupportLines: PropTypes.bool,
  verticalSupportLines: PropTypes.bool,
  setHorizontalSupportLines: PropTypes.func,
  setVerticalSupportLines: PropTypes.func,
}

export default ChartSupportLines
