import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

export const Lock = () => {
  const { setHoverState, lockState, toggleLock } = useChartHoverStateContext()

  const { locked, graphLockingUsed } = lockState

  let lockClassName = ""
  let label = ""

  if (locked) {
    lockClassName = "fa fa-fw fa-lock"
  } else if (graphLockingUsed) {
    lockClassName = "fa fa-fw fa-unlock"
  } else {
    label = "Click to lock" // Show tooltip for first time usage
    lockClassName = "fa fa-fw fa-unlock"
  }

  return (
    <button
      className="cursor-pointer text-[10px] text-white bg-[#21375A] px-[7px] py-[5px] uppercase rounded-[3px] whitespace-nowrap"
      onMouseLeave={() => setHoverState({})}
      onClick={toggleLock}
    >
      <i className={lockClassName} /> {label}
    </button>
  )
}
