import PropTypes from "prop-types"
import React from "react"

const EnvironmentLabel = ({ env, className }) => {
  let labelClassName

  switch (env) {
    case "test":
      labelClassName = "c-pill--orange"
      break
    case "testing":
      labelClassName = "c-pill--orange"
      break
    case "staging":
      labelClassName = "c-pill--purple"
      break
    case "integration":
      labelClassName = "c-pill--purple"
      break
    case "acceptance":
      labelClassName = "c-pill--purple"
      break
    case "dev":
      labelClassName = "c-pill--gray"
      break
    case "development":
      labelClassName = "c-pill--gray"
      break
    case "production":
      labelClassName = "c-pill--blue"
      break
    default:
      labelClassName = ""
  }

  return <span className={`c-pill c-pill--sans ${labelClassName} ${className || ""}`}>{env}</span>
}

EnvironmentLabel.propTypes = {
  env: PropTypes.string,
  className: PropTypes.string,
}

export default EnvironmentLabel
