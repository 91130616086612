import PropTypes from "prop-types"
import React from "react"

export class CheckBox extends React.Component {
  static propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }

  static defaultProps = { checked: false, disabled: false }

  handleClick = () => {
    if (this.props.disabled) {
      return
    }
    this.props.onChange(!this.props.checked)
  }

  render() {
    let checkboxClass = "c-checkbox mt-0 text-gray-500"
    let checkboxIconClass = "far fa-square fa-fw"

    if (this.props.checked === true) {
      checkboxClass += "c-checkbox mt-0 text-green-500"
      checkboxIconClass = "fas fa-check-square fa-fw"
    } else if (this.props.disabled === true) {
      checkboxClass = "c-checkbox mt-0 text-gray-300"
    }

    return (
      <div
        className={checkboxClass}
        onClick={this.handleClick}
        data-testid="checkbox"
        role="checkbox"
      >
        <div className="c-checkbox__input mx-auto">
          <i className={checkboxIconClass} />
        </div>
      </div>
    )
  }
}

export default CheckBox
