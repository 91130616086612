import PropTypes from "prop-types"
import React from "react"

const SearchField = React.forwardRef(
  ({ value, handleSubmit, handleChange }, ref) => {
    const onSubmit = (e) => {
      e.preventDefault()

      handleSubmit()
    }

    return (
      <div className="c-textfield flex-grow rounded-l-none relative z-10">
        <form
          onSubmit={(e) => onSubmit(e)}
          className="search flex w-full"
          aria-label="form"
        >
          <i className="far fa-search fa-fw c-textfield__icon-left"></i>
          <input
            type="search"
            className="c-textfield__input pr-3"
            placeholder="Search for incident name, action name or tag values"
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            ref={ref}
          />
        </form>
      </div>
    )
  }
)

SearchField.displayName = "SearchField"

SearchField.propTypes = {
  value: PropTypes.string,
  autoFocus: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
}

export default SearchField
