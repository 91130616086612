import { DEFAULT_TIMEFRAME } from "#Root/constants/timeframe"

import useMetricKeyMetadata from "../../../hooks/useMetricKeyMetadata"
import { findFieldsForMetricType, includeMeasurementFields } from "../../../utils/metric_keys"
import ErrorBox from "../../shared/error_box"
import LoadingSpinner from "../../shared/loading_spinner"

const VisualBuilderSkeletonSidebarMetricFormWithMetadata = ({
  appId,
  onChange,
  metric,
  metricForm: MetricForm,
}) => {
  const { loading, error, metadata } = useMetricKeyMetadata(
    { variables: { appId, timeframe: DEFAULT_TIMEFRAME, name: metric.name } },
    (key) => {
      // Update the `fields` for the metric once we know which metric the user
      // has selected.
      const fields = findFieldsForMetricType(key.type)
      if (includeMeasurementFields(fields || [])) {
        if (!includeMeasurementFields(metric.fields || [])) {
          // Only apply measurement fields if the metric currently does not
          // include measurement fields. This will overwrite any GAUGE and
          // COUNTER, but not existing measurement fields. Otherwise the
          // metricForm will always reopen with all measurement fields
          // checked.
          onChange({ fields })
        }
      } else {
        // Non Measurement metric fields, apply normally
        onChange({ fields })
      }
    },
  )

  if (error) {
    return <ErrorBox error={error} />
  }
  if (loading) {
    return <LoadingSpinner />
  }

  return <MetricForm onChange={onChange} metric={metric} metricMetadata={metadata} />
}

VisualBuilderSkeletonSidebarMetricFormWithMetadata.propTypes = {
  appId: PropTypes.string.isRequired,
  metric: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  metricForm: PropTypes.func.isRequired,
}
export default VisualBuilderSkeletonSidebarMetricFormWithMetadata
