import gql from "graphql-tag"

import VisualFragment from "./_visual_fragment"

export const mockData = {
  app: {
    __typename: "App",
    id: "appId",
    dashboard: {
      __typename: "Dashboard",
      id: "dashboardId",
      visual: {
        __typename: "VisualTimeseries",
        id: "visualId",
        title: "title",
        description: "description",
        lineLabel: "lineLabel",
        display: "LINE",
        format: "duration",
        formatInput: null,
        drawNullAsZero: true,
        metrics: [
          {
            __typename: "Metric",
            name: "transaction_duration",
            fields: [{ __typename: "Field", field: "MEAN" }],
            tags: [{ __typename: "Tag", key: "namespace", value: "web" }]
          }
        ]
      }
    }
  }
}

export const VisualQuery = gql`
  query visualQuery(
    $appId: String!,
    $dashboardId: String!,
    $visualId: String!
  ) {
    app(id: $appId) {
      id
      dashboard(id: $dashboardId) {
        id
        visual(id: $visualId) {
          ...Visual
        }
      }
    }
  }
  ${VisualFragment}
`

export default VisualQuery
