import UrlHelper from "#Root/components/shared/url_helper"
import { useRouter } from "#Root/hooks"

const AddNewIntegrationNotice = ({ organizationSlug, appId }) => (
  <p className="c-form__description">
    Missing an integration? You can enable more in the{" "}
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={UrlHelper.notifiersPagePath({
        organizationSlug,
        appId,
      })}
    >
      Notifiers page
    </a>
  </p>
)

const TriggerFormNotifiers = ({ notifiers: { available, selected, onChange }, hint }) => {
  const { getParams } = useRouter()
  const { organizationSlug, appId } = getParams()

  return (
    <div className="c-form">
      <label className="c-form__label ignore-old-css" htmlFor="field">
        Notify me through
      </label>
      <div className="c-form__element">
        {available.map((notifier) => {
          const checkboxId = `n-${notifier.id}`
          return (
            <div className="c-checkbox" key={notifier.id}>
              <input
                className="c-checkbox__input"
                id={checkboxId}
                type="checkbox"
                checked={selected.has(notifier.id)}
                onChange={() => onChange(notifier.id)}
              />
              <label
                className="c-checkbox__label ignore-old-css cursor-pointer"
                htmlFor={checkboxId}
              >
                <img
                  src={`/assets/integrations/${notifier.icon}`}
                  alt={notifier.name}
                  className="notifier_logo ml-1 mr-2 inline w-4"
                />
                {notifier.name}
              </label>
            </div>
          )
        })}
      </div>
      {hint && <p className="c-form__description">{hint}</p>}
      {organizationSlug && appId && (
        <AddNewIntegrationNotice organizationSlug={organizationSlug} appId={appId} />
      )}
    </div>
  )
}

TriggerFormNotifiers.propTypes = {
  hint: PropTypes.string,
  notifiers: PropTypes.shape({
    available: PropTypes.array.isRequired,
    selected: PropTypes.instanceOf(Set).isRequired,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
}

AddNewIntegrationNotice.propTypes = {
  organizationSlug: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
}

export default TriggerFormNotifiers
