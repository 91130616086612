const COLORS = {
  blue: "c-message--blue",
  red: "c-message--red",
  yellow: " c-message--yellow",
  green: "c-message--green",
  gray: "c-message--gray",
}

const Message = ({ title, children, color, className }) => (
  <div className={["c-message", COLORS[color], className].filter((r) => r).join(" ")}>
    {title && <h3 className="c-message__heading">{title}</h3>}
    {children}
  </div>
)

Message.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  color: PropTypes.string,
  className: PropTypes.string,
}

const Title = ({ children }) => <h3 className="c-message__heading">{children}</h3>

Title.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}

Message.Title = Title

export default Message
