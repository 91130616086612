import Tippy from "@tippyjs/react/headless"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useMutation } from "react-apollo"

import Tooltip from "#Components/shared/Tooltip"
import createSavedVisualMutation from "#Graphql/create_saved_visual_mutation"

import ClipGraphMenu from "./ClipGraphMenu"

function ClipGraphButton({ getGraphData }) {
  // Track component mounted state, as we use `await` in the component below
  // we don't want to change the state on an unmounted component
  const isScreenMounted = useRef(true)
  useEffect(() => {
    return () => (isScreenMounted.current = false)
  }, [])

  // We don't rely on the presence `data` to open the menu,
  // as we need to explicitly close it in the onClick handler for PopOver,
  // hence the `menuOpen` state.
  const [menuOpen, setMenuOpen] = useState(false)
  const [saveVisual, { data, loading, error }] = useMutation(createSavedVisualMutation)

  const handleClick = async () => {
    // Catch the error here, since it's populated in the deconstructed useMutation above
    await saveVisual({ variables: getGraphData() }).catch((_err) => {})

    // Prevent state changes on an unmounted component
    if (!isScreenMounted.current) {
      return
    }

    setMenuOpen(true)
  }

  return (
    <div className="relative flex h-full">
      <Tooltip content="Export this graph" maxWidth="none" placement="bottom">
        <Tippy
          offset={[0, -5]}
          visible={menuOpen}
          placement="bottom-end"
          interactive={true}
          onClickOutside={() => setMenuOpen(false)}
          render={(attrs) => (
            <div {...attrs}>
              {error && (
                <div className="p-5">
                  <h2 className="font-bold mb-4">Error: Could clip this visual</h2>
                  <pre className="c-code">{error.message}</pre>
                </div>
              )}
              {data && !error && (
                <div className="mt-4 right-0 font-normal c-popover overflow-y-auto overflow-x-hidden top-full w-96 max-h-96">
                  <ClipGraphMenu visual={data?.createSavedVisual} />
                </div>
              )}
            </div>
          )}
        >
          <a
            role="button"
            className="c-button c-button--xs c-button--white ml-1 -my-2 py-2 text-gray-700 shadow-none hover:text-gray-800 border-gray-200 hover:shadow-sm cursor-pointer"
            onClick={handleClick}
          >
            <i
              className={`c-button__icon ${
                loading ? "fas fa-fw fa-spinner fa-spin" : "far fa-file-export"
              }`}
            />
          </a>
        </Tippy>
      </Tooltip>
    </div>
  )
}

ClipGraphButton.propTypes = {
  getGraphData: PropTypes.func.isRequired,
}

export default ClipGraphButton
