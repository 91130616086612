import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"

import { Y_AXIS_WIDTH } from "../constants"
import { useChartDataContext } from "../contexts/DataContext"
import { useChartFormattingContext } from "../contexts/FormattingContext"
import { useChartGraphContext } from "../contexts/GraphContext"
import FormattedLabel from "./FormattedLabel"

const YAxis = ({ tickCount = 4 }) => {
  const { valuePrecision, valueInput, valueFormat } = useChartFormattingContext()
  const { yScale, canvasHeight, canvasWidth, domain } = useChartGraphContext()
  const {
    timeseries: { resolution },
  } = useChartDataContext()
  const { settings } = useChartSettingsContext()

  yScale.domain(domain.y)
  const ticks = yScale.ticks.apply(yScale, [tickCount])

  return (
    <>
      {settings.horizontalSupportLines &&
        ticks.map((tick, i) => (
          <div
            key={i}
            className="absolute h-px w-px z-0 pointer-events-none border-t border-gray-200/75 border-dashed y-axis-grid-line"
            style={{ top: yScale(tick), width: canvasWidth, left: Y_AXIS_WIDTH }}
          />
        ))}
      <div
        className="absolute top-0 left-0 text-ms text-gray-600"
        style={{
          width: `${Y_AXIS_WIDTH}px`,
          height: `${canvasHeight}px`,
        }}
      >
        {ticks.map((tick, i) => (
          <div
            key={i}
            className="absolute left transform -translate-y-1/2 select-none"
            style={{ top: `${yScale(tick)}px`, width: `${Y_AXIS_WIDTH}px` }}
          >
            <FormattedLabel
              value={tick}
              valueFormat={valueFormat}
              resolution={resolution}
              valuePrecision={valuePrecision}
              valueInput={valueInput}
            />
          </div>
        ))}
      </div>
    </>
  )
}

YAxis.propTypes = {
  tickCount: PropTypes.number,
}
YAxis.displayName = "YAxis"

export default YAxis
