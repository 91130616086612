import PropTypes from "prop-types"
import React from "react"

import TriggerFormDescription from "#Components/triggers/form/fields/Description"
import TriggerFormDurationField, {
  DEFAULT_DURATION_OPTION,
} from "#Components/triggers/form/fields/Duration"
import TriggerFormNamespace, { findNamespace } from "#Components/triggers/form/fields/Namespace"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown"

import withNotifiers, { notifiersPropType } from "../../../wrappers/notifiers"
import withWarmupAndCooldown, {
  warmupAndCooldownPropType,
} from "../../../wrappers/warmup_and_cooldown"
import TriggerGraphPreview from "../GraphPreview"
import ShareLink from "./ShareLink"

const formName = "QueueTime"
export class TriggerFormQueueTime extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    blankSlate: PropTypes.bool,
    trigger: PropTypes.object,
    appId: PropTypes.string.isRequired,
    namespaces: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    notifiers: notifiersPropType.isRequired,
    warmupAndCooldown: warmupAndCooldownPropType.isRequired,
    error: PropTypes.object,
  }
  static form = formName
  static params = {
    namespace: "tag",
    field: "field",
    conditionValue: "field",
  }

  constructor(props) {
    super(props)
    // If a trigger is given as a param, use the namespace/conditionValue
    if (props.trigger && props.trigger.id) {
      this.state = {
        namespace: props.trigger.tags[0].value,
        field: props.trigger.field,
        conditionValue: props.trigger.thresholdCondition.value,
        description: props.trigger.description,
      }

      return
    } else {
      const { params } = this.props
      this.state = {
        namespace: findNamespace(params.namespace, props.namespaces),
        field: params.field || DEFAULT_DURATION_OPTION,
        conditionValue: params.conditionValue || 200,
        description: params.description || "",
      }

      return
    }
  }

  triggerObject() {
    return {
      metricName: "transaction_queue_duration",
      tags: [{ key: "namespace", value: this.state.namespace }],
      field: this.state.field,
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.props.onChange({
      ...this.triggerObject(),
      comparisonOperator: "GREATER_THAN",
      conditionValue: parseFloat(this.state.conditionValue),
      description: this.state.description,
    })
  }

  render() {
    let cancelLink
    let saveButtonLabel = "Save Trigger"
    const { appId, error, params, namespaces, onClose, notifiers, warmupAndCooldown } = this.props

    if (this.props.blankSlate) {
      saveButtonLabel = "Save your first Trigger"
    } else {
      cancelLink = (
        <button id="cancelLink" onClick={onClose} className="c-button c-button--sm c-button--white">
          Cancel
        </button>
      )
    }

    return (
      <>
        <div className="relative h-full overflow-y-auto">
          <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
            <h2 className="c-heading text-base mb-0 text-gray-800">Queue time Trigger</h2>
          </div>
          {error}
          <form onSubmit={this.handleFormSubmit} acceptCharset="UTF-8" className="px-5">
            <p className="py-5">
              Receive alerts about the average or the 90th percentile of queue durations per minute.
            </p>
            <hr />

            <TriggerFormNamespace
              namespace={this.state.namespace}
              namespaceParam={params.namespace}
              namespaces={namespaces}
              onChange={(namespace) => this.setState({ namespace })}
            />
            <hr />

            <div className="flex">
              <div className="w-1/2 mr-2">
                <div className="c-form">
                  <label className="c-form__label" htmlFor="field">
                    Alert me when the
                  </label>
                  <div className="c-form__element">
                    <TriggerFormDurationField
                      value={this.state.field}
                      onChange={(e) => this.setState({ field: e.field })}
                    />
                  </div>
                </div>
              </div>

              <div className="w-1/2 ml-2">
                <div className="c-form">
                  <label className="c-form__label" htmlFor="conditionValue">
                    Is above
                  </label>
                  <div className="c-form__element">
                    <div className="c-textfield">
                      <input
                        className="c-textfield__input"
                        id="conditionValue"
                        name="conditionValue"
                        type="number"
                        value={this.state.conditionValue}
                        aria-label="Threshold value"
                        onChange={(e) => this.setState({ conditionValue: e.target.value })}
                      />
                      <span className="c-textfield__suffix">ms</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
            <hr />

            <TriggerFormDescription
              description={this.state.description}
              onChange={(newDescription) => this.setState({ description: newDescription })}
            />
            <hr />

            <TriggerFormNotifiers notifiers={notifiers} />
            <hr />

            <ShareLink trigger={this.state} form={formName} warmupAndCooldown={warmupAndCooldown} />

            <div className="my-5">
              <button className="c-button c-button--sm mr-4">{saveButtonLabel}</button>
              {cancelLink}
            </div>
          </form>
        </div>
        <div className="w-256 p-5 bg-gray-100">
          <TriggerGraphPreview
            title="Queue time graph preview"
            lineLabel="Queue time for %namespace%"
            valueFormat="duration"
            appId={appId}
            trigger={this.triggerObject()}
          />
        </div>
      </>
    )
  }
}

export default withWarmupAndCooldown(withNotifiers(TriggerFormQueueTime))
