import { colorPaletteMap } from "#Root/contexts/ChartSettingsContext"

import RadioGroup from "../RadioGroup/RadioGroup"
import Section from "./Section"

const { main, stepped1, stepped2, continuous1, divergent1 } = colorPaletteMap

const ChartColorPalette = ({ colorPalette, setColorPalette }) => {
  return (
    <>
      <Section>
        <Section.Title>Colors</Section.Title>
        <Section.Content>
          <RadioGroup
            name="color-palette"
            value={colorPalette}
            onChange={(value) => setColorPalette(value)}
            className="space-y-3"
          >
            <RadioGroup.Radio
              id="main-palette"
              value={main.name}
              label={<SteppedPalettePreview palette={main.values} />}
              labelClassName="w-full"
              className="px-1"
            />
            <RadioGroup.Radio
              id="stepped1"
              value={stepped1.name}
              label={<SteppedPalettePreview palette={stepped1.values} />}
              labelClassName="w-full"
              className="px-1"
            />

            <RadioGroup.Radio
              id="stepped2"
              value={stepped2.name}
              label={<SteppedPalettePreview palette={stepped2.values} />}
              labelClassName="w-full"
              className="px-1"
            />

            <RadioGroup.Radio
              id="divergent1"
              value={divergent1.name}
              label={<DivergentPalettePreview palette={divergent1.values} />}
              labelClassName="w-full"
              className="px-1"
            />

            <RadioGroup.Radio
              id="continuous1"
              value={continuous1.name}
              label={<LoopingPalettePreview paletteFn={continuous1.fn} />}
              labelClassName="w-full"
              className="px-1"
            />
          </RadioGroup>
        </Section.Content>
      </Section>
    </>
  )
}

ChartColorPalette.propTypes = {
  colorPalette: PropTypes.string,
  setColorPalette: PropTypes.func,
}

const SteppedPalettePreview = ({ palette }) => {
  return (
    <div className="flex items-center w-full">
      {palette.map((color, index) => (
        <div key={index} className="h-4 flex-grow" style={{ backgroundColor: color }}></div>
      ))}
    </div>
  )
}
SteppedPalettePreview.propTypes = {
  palette: PropTypes.array,
}

const LoopingPalettePreview = ({ paletteFn }) => {
  return (
    <div className="flex items-center w-full">
      {Array.from({ length: 180 }).map((_, index) => (
        <div
          key={index}
          className="w-px h-4"
          style={{ backgroundColor: paletteFn(index / 160) }}
        ></div>
      ))}
    </div>
  )
}

LoopingPalettePreview.propTypes = {
  paletteFn: PropTypes.func,
}
const DivergentPalettePreview = ({ palette }) => {
  const fullPalette = palette[palette.length - 1]
  return (
    <div className="flex items-center w-full">
      {fullPalette.map((color, index) => (
        <div key={index} className="h-4 flex-grow" style={{ backgroundColor: color }}></div>
      ))}
    </div>
  )
}

DivergentPalettePreview.propTypes = {
  palette: PropTypes.array,
}

export default ChartColorPalette
