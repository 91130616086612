import PropTypes from "prop-types"
import React from "react"

const VisualSettings = ({
  visual: { title, description },
  onChange
}) => {
  return (
    <div className="p-6 border-b border-gray-200">
      <div className="c-form">
        <label className="c-form__label" htmlFor="visual-title">Title</label>
        <div className="c-form__element">
          <div className="c-textfield">
            <input
              type="text"
              id="visual-title"
              name="visual-title"
              className="c-textfield__input"
              placeholder="Graph title"
              value={title || ""}
              onChange={e => onChange({ title: e.target.value })}
            />
          </div>
        </div>
      </div>
      <div className="c-form mb-0">
        <label className="c-form__label" htmlFor="visual-description">Description</label>
        <div className="c-form__element">
          <div className="c-textfield">
            <input
              type="text"
              id="visual-description"
              name="visual-description"
              className="c-textfield__input"
              placeholder="Optional graph description"
              value={description || ""}
              onChange={e => onChange({ description: e.target.value })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

VisualSettings.propTypes = {
  visual: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default VisualSettings
