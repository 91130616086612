export class Ident {
  static state = {
    previousPage: null
  }

  // Track a page view/action.
  //
  // It allows setting of the current page/action and a previous page/action.
  // If `previousPage` is `undefined`, it will be prefilled with the
  // `currentPage` value from last time it was called.
  //
  // Examples
  //   Ident.track({ currentPage: "newPageName", previousPage: "previousPageName" })
  //
  // Example without previous page
  //   Ident.track({ currentPage: "pageName1" }) // previousPage = null
  //   // Secondary call prefills `previousPage` option
  //   Ident.track({ currentPage: "pageName2" }) // previousPage = "pageName1"
  //   // Explicitly unset previousPage
  //   Ident.track({ currentPage: "pageName3", previousPage: null }) // previousPage = null
  static track(options) {
    const {
      previousPage,
      currentPage,
      language
    } = options
    if (!currentPage) { return }

    const params = {
      page: currentPage,
      category: "react",
      path: window.location.pathname
    }
    if (previousPage === undefined) {
      params.previous_page = Ident.state.previousPage || ""
    } else {
      params.previous_page = previousPage || ""
    }
    if (language != undefined) {
      params.language = language
    }
    const query = new URLSearchParams(params).toString()

    fetch(`/ident?${query}`, { credentials: "same-origin" })

    if (typeof appsignal !== "undefined") {
      appsignal.addBreadcrumb({
        category: "navigation",
        action: `Navigated from '${params.previous_page || "nothing"}' to '${currentPage}'`
      })
    }
    Ident.state.previousPage = currentPage
  }

  // Convenience function to simply track the state change from one action
  // another.
  // Example
  //
  //   Ident.trackAction("myActionName")
  static trackAction(action) {
    Ident.track({ currentPage: action })
  }

  // Track an action with an associated language
  //
  // Example
  //   Ident.trackActionWithLanguage("InstallNew", "ruby")
  static trackActionWithLanguage(action, language) {
    Ident.track({ currentPage: action, language: language })
  }
}

export default Ident
