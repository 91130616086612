import PropTypes from "prop-types"
import React from "react"

import BoxEmpty from "./boxes/empty"

const ErrorBox = ({ className, error, help, children }) => {
  let helpLink
  const message = (error && <p>{error.message}</p>) || children
  if (help) {
    helpLink = (
      <p className="mt-2">
        <a href={help.url} rel="noopener noreferrer">
          {help.label}
        </a>
      </p>
    )
  }

  return (
    <BoxEmpty header="Oops!" className={`c-box mb-8 p-5 ${className}`} image="error.svg">
      {message}
      {helpLink}
    </BoxEmpty>
  )
}

ErrorBox.propTypes = {
  error: PropTypes.object,
  className: PropTypes.string,
  help: PropTypes.object,
  children: PropTypes.node,
}

export default ErrorBox
