export function paramToArray(arrayParams) {
  if (!arrayParams || arrayParams.length == 0) {
    return []
  }
  return arrayParams.split(",").map((e) => decodeURIComponent(e).trim())
}

export function arrayToParam(array) {
  if (!array || array.length == 0) {
    return null
  }
  return array
    .map((e) => (e.includes(",") || e.includes(" ") ? encodeURIComponent(e) : e))
    .join(",")
}
