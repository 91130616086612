import Tippy from "@tippyjs/react"

const Tooltip = (props) => {
  return (
    <Tippy
      {...props}
      content={<div className="ignore-react-onclickoutside break-words">{props.content}</div>}
    >
      {props.children}
    </Tippy>
  )
}

Tooltip.defaultProps = {
  placement: "right",
  appendTo: document.body,
  interactive: true,
  popperOptions: {
    strategy: "fixed",
  },
}

Tooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.node,
}

export default Tooltip
