import { measurementFields } from "#Root/utils/metric_keys"

const TriggerFormFieldRadiobuttons = ({ value, onChange }) => {
  const radiobuttons = Object.entries(measurementFields).map((field) => {
    const [key, label] = field
    const id = `trigger_field_radio_${key}`
    return (
      <div key={id} className="c-radio p-0">
        <input
          id={id}
          name="trigger_field_radio"
          className="c-radio__input"
          type="radio"
          value={key}
          checked={value === key}
          onChange={({ target: { value } }) => onChange({ field: value })}
        />
        <label htmlFor={id} className="c-radio__label font-normal mb-0">
          {label}
        </label>
      </div>
    )
  })

  return (
    <div className="c-form" data-testid="TriggerFormFieldRadiobuttons">
      <label className="c-form__label mb-1">Measurement</label>
      <div className="c-form__element">{radiobuttons}</div>
    </div>
  )
}

TriggerFormFieldRadiobuttons.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TriggerFormFieldRadiobuttons
