import { useState } from "react"

const useTableSort = ({ initialSortColumn, initialSortDirection }) => {
  const [ currentSortColumn, setCurrentSortColumn ] = useState(initialSortColumn)
  const [ currentSortDirection, setCurrentSortDirection ] = useState(initialSortDirection)

  const nestedValueFor = (value, keys) => {
    if (keys.length > 0) {
      const key = keys.shift()
      return nestedValueFor(value[key], keys)
    } else {
      return value
    }
  }

  const valueFor = (value, key) => {
    const keys = key.split(".")
    return nestedValueFor(value, keys)
  }

  const sortColumn = (name, direction) => {
    setCurrentSortColumn(name)
    setCurrentSortDirection(direction)
  }

  const sortArray = (arr, defaultValue) => {
    const column = currentSortColumn
    const direction = currentSortDirection

    const newArray = arr.slice()

    newArray.sort((x, y) => {
      let a = valueFor(x, column)
      let b = valueFor(y, column)

      if (Number.isNaN(a)) { a = defaultValue }
      if (Number.isNaN(b)) { b = defaultValue }

      if (a === undefined) {
        throw `Sort field '${column}' not present`
      } else if ((typeof a === "string" || typeof b === "string") && "".localeCompare) {

        // Prevent `null` values from ruining the sort
        const localeA = a || ""
        const localeB = b || ""

        return localeB.localeCompare(localeA)
      } else {
        return b - a
      }
    })

    if (direction === "asc") { newArray.reverse() }

    return newArray
  }

  return { sortColumn, sortArray, currentSortColumn, currentSortDirection }
}

export default useTableSort
