import Canvas from "./components/Canvas"
import ChunkedRest from "./components/ChunkedRest"
import Container from "./components/Container"
import ExportButton from "./components/ExportButton"
import Graph from "./components/Graph"
import GraphQL from "./components/GraphQL"
import HighlightDot from "./components/HighlightDot"
import HighlightLine from "./components/HighlightLine"
import Hover from "./components/Hover"
import Markers from "./components/Markers"
import Rest from "./components/Rest"
import XAxis from "./components/XAxis"
import YAxis from "./components/YAxis"

export default {
  Graph,
  Container,
  XAxis,
  YAxis,
  Canvas,
  Hover,
  HighlightLine,
  HighlightDot,
  Markers,
  ExportButton,
  GraphQL,
  Rest,
  ChunkedRest,
}
