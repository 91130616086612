import PropTypes from "prop-types"
import React from "react"

export const warmupAndCooldownPropType = PropTypes.shape({
  onWarmupChange: PropTypes.func.isRequired,
  onCooldownChange: PropTypes.func.isRequired,
  warmupDuration: PropTypes.string.isRequired,
  cooldownDuration: PropTypes.string.isRequired,
})

/*
`withWarmupAndCooldown` is a High order Component (HoC) that adds a
`warmupAndCooldown` propType to the wrapped component with 4 props that are
required to keep track of an render the warmup and cooldown input types.
All other logic is abstracted away in this component.

This component hijacks the `onChange` prop of the WrappedComponent and injects
the `warmupDuration` and `cooldownDuration` fields.

To use this component follow these steps:

1. Import the withWarmupAndCooldown module, the PropType definition and the
TriggerFormWarmupAndCooldown component.

```
import withWarmupAndCooldown, { warmupAndCooldownPropType } from "../../../wrappers/warmup_and_cooldown"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown
```

2. Require the HoC's props:

```
propTypes: {
  warmupAndCooldown: warmupAndCooldownPropType.isRequired
}
```

3. Render the TriggerFormWarmupAndCooldown component:

```
<TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
```

And don't forget to wrap the component:

```
export default withWarmupAndCooldown(<component>)
```
*/
export default function withWarmupAndCooldown(WrappedComponent) {
  class WithWarmupAndCooldown extends React.Component {
    constructor(props) {
      super(props)

      const trigger = props.trigger
      if (trigger && trigger.id) {
        this.state = {
          warmupDuration: trigger.warmupDuration.toString(),
          cooldownDuration: trigger.cooldownDuration.toString(),
        }
      } else {
        const { params } = this.props
        this.state = {
          warmupDuration: params.warmupDuration || "0",
          cooldownDuration: params.cooldownDuration || "0",
        }
      }
    }

    handleWarmupChange(duration) {
      this.setState({ warmupDuration: duration })
    }

    handleCooldownChange(duration) {
      this.setState({ cooldownDuration: duration })
    }

    handleFormSubmit(params) {
      params["warmupDuration"] = parseInt(this.state.warmupDuration) || 0
      params["cooldownDuration"] = parseInt(this.state.cooldownDuration) || 0
      this.props.onChange(params)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          warmupAndCooldown={{
            onWarmupChange: this.handleWarmupChange.bind(this),
            onCooldownChange: this.handleCooldownChange.bind(this),
            warmupDuration: this.state.warmupDuration,
            cooldownDuration: this.state.cooldownDuration,
          }}
          onChange={this.handleFormSubmit.bind(this)}
        />
      )
    }
  }
  WithWarmupAndCooldown.displayName = `WithWarmupAndCooldown(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`
  WithWarmupAndCooldown.propTypes = {
    params: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    trigger: PropTypes.object,
  }
  return WithWarmupAndCooldown
}
