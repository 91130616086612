import gql from "graphql-tag"

import UptimeMonitorFragment from "./_uptimeMonitorFragment"

export default gql`
  mutation createUptimeMonitorMutation(
    $appId: String!,
    $uptimeMonitor: UptimeMonitorInput!
  ) {
    createUptimeMonitor(
      appId: $appId,
      uptimeMonitor: $uptimeMonitor
    ) {
      ...UptimeMonitor
    }
  }
  ${UptimeMonitorFragment}
`
