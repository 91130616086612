import { useMutation,useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

export const VIEWER_QUERY = gql`
  query getViewer{
    viewer {
      id
      usedFeatures
      hideGettingStarted
    }
  }
`

export const USED_FEATURE_MUTATION = gql`
  mutation usedFeature($feature: String!) {
    usedFeature(feature: $feature) {
      id
      usedFeatures
    }
  }
`

const useFeatureUsed = (defaultReturnValue = false) => {
  const [mutation] = useMutation(USED_FEATURE_MUTATION)
  const { data, loading } = useQuery(VIEWER_QUERY)

  const didUse = (feature) => {
    if (loading) { return defaultReturnValue }
    if (data && data.viewer) {
      return data.viewer.usedFeatures.includes(feature)
    }
    return defaultReturnValue
  }

  const markUsed = (feature) => {
    return mutation({ variables: { feature } })
  }

  return { didUse, markUsed, loading }
}

export default useFeatureUsed
