import gql from "graphql-tag"

import UptimeMonitorFragment from "./_uptimeMonitorFragment"

export default gql`
  mutation deleteUptimeMonitorMutation(
    $appId: String!,
    $id: String!,
  ) {
    deleteUptimeMonitor(
      appId: $appId,
      id: $id
    ) {
      ...UptimeMonitor
    }
  }
  ${UptimeMonitorFragment}
`
