import FormattedNumber from "#Root/formatters/FormattedNumber"

const FormattedPercent = ({ value, precision }) => {
  return (
    <span>
      <FormattedNumber value={value} precision={precision} />{" "}
      <span className="text-gray-600">%</span>
    </span>
  )
}

FormattedPercent.propTypes = {
  value: PropTypes.number.isRequired,
  precision: PropTypes.number,
}

export default FormattedPercent
