import { findFieldsForMetricType } from "#Root/utils/metric_keys"

export const TriggerFormFieldDropdown = ({ trigger, isMeasurementField, onChange }) => {
  const value = isMeasurementField ? "MEASUREMENT" : trigger.field

  const handleMetricFieldDropdownChange = ({ target: { value } }) => {
    const fields = findFieldsForMetricType(value)
    onChange({ field: fields[0].field })
  }

  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="trigger_field_type">
        Field
      </label>
      <div className="c-form__element">
        <div className="c-select">
          <select
            id="trigger_field_type"
            name="trigger_field_type"
            className="c-select__input"
            value={value}
            onChange={handleMetricFieldDropdownChange}
          >
            <option value="" disabled>
              Select metric type
            </option>
            <option value="COUNTER">Counter</option>
            <option value="GAUGE">Gauge</option>
            <option value="MEASUREMENT">Measurement</option>
          </select>
        </div>
      </div>
    </div>
  )
}

TriggerFormFieldDropdown.propTypes = {
  trigger: PropTypes.object.isRequired,
  isMeasurementField: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default TriggerFormFieldDropdown
