import PropTypes from "prop-types"
import React from "react"

export class BoxEmpty extends React.Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    className: PropTypes.string,
    image: PropTypes.string,
    body: PropTypes.node,
    children: PropTypes.node,
  }

  render() {
    let className, source
    const body = this.props.body || this.props.children

    if (this.props.className) {
      className = `flex items-center ${this.props.className}`
    } else {
      className = "flex items-center"
    }

    if (this.props.image) {
      source = `/assets/illustrations/${this.props.image}`
    } else {
      source = "/assets/illustrations/search.svg"
    }

    return (
      <div className={className}>
        <div>
          <img className="w-12" src={source} />
        </div>
        <div className="flex flex-col justify-center p-4">
          <h2 className="font-semibold text-base pb-px">{this.props.header}</h2>
          {body && <div className="pt-px">{body}</div>}
        </div>
      </div>
    )
  }
}

export default BoxEmpty
