import PropTypes from "prop-types"
import React from "react"

function compact(headers) {
  return headers.filter(({ key, value }) => `${key}${value}`.length > 0)
}

const Headers = ({ headers, onChange }) => {
  const headersWithEmpty = [...compact(headers), { key: "", value: "" }]

  const handleChange = (idx, value) => {
    const newHeaders = compact(headers)
    newHeaders[idx] = value

    onChange(newHeaders)
  }

  const rows = headersWithEmpty.map(({ key, value }, idx) => {
    return <div className="flex my-2" key={idx}>
      <div className="c-textfield mr-4 flex-1">
        <input type="text" value={key} name="key" onChange={(e) => handleChange(idx, { key: e.target.value, value: value })} className="c-textfield__input string required" placeholder="Header name" />
      </div>
      <div className="c-textfield flex-1">
        <input type="text" value={value} name="value" onChange={(e) => handleChange(idx, { key: key, value: e.target.value })} className="c-textfield__input string required" placeholder="Header value" />
      </div>
    </div>
  })

  return (
    <div className="c-form headers">
      <label className="c-form__label ignore-old-css string  control-label mb-0">
        Headers
      </label>
      <div className="c-form__element">
        {rows}
      </div>
      <p className="mt-0 c-form__description">These headers will be sent with the ping request. Add authorisation headers if the endpoint requires these. Leave empty if no headers are required.</p>
    </div>
  )
}

Headers.propTypes = {
  headers: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Headers
