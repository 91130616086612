import PropTypes from "prop-types"
import React from "react"

import { useOnClickOutside } from "../../hooks"

export const options = [
  {
    modifier: "action:",
    key: "action_name",
    example: "action:User::BlogpostController#show",
  },
  {
    modifier: "error:",
    key: "error_name",
    example: "error:StandardError",
  },
  {
    modifier: "message:",
    key: "message",
    hint: "(supports partial search)",
    example: 'message:"Could not connect"',
  },
  {
    modifier: "tag:",
    key: "tag_value",
    hint: "(can use multiple)",
    example: "tag:frontend1 tag:revision-abc",
  },
]

const SearchRefinementMenu = ({ handleChange, handleClose }) => {
  const menuNode = React.useRef()
  useOnClickOutside(menuNode, () => handleClose(false))

  const handleClick = (modifier) => {
    handleChange(modifier)
    handleClose(false)
  }

  return (
    <div className="c-dropdown absolute left-0 w-96 z-10" ref={menuNode}>
      <div className="c-dropdown__group">
        <h5 className="c-heading--caps font-semibold text-ms text-gray-700">
          Filters
        </h5>
      </div>
      {options.map((option) => (
        <button
          key={option.key}
          className="c-dropdown__item c-dropdown__item--lg ignore-old-css"
          onClick={() => handleClick(option.modifier)}
        >
          <i className="far fa-plus fa-fw c-dropdown__icon"></i>
          <div>
            <div className="pb-px">
              <span className="font-semibold">{option.modifier}</span>
              <span>{option.key} </span>
              {option.hint && (
                <span className="text-gray-700">{option.hint}</span>
              )}
            </div>
            <div className="pt-px text-gray-700 text-ms">
              <span>e.g. </span>
              <span className="c-inline-code text-xs">{option.example}</span>
            </div>
          </div>
        </button>
      ))}
    </div>
  )
}

SearchRefinementMenu.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default SearchRefinementMenu
