import gql from "graphql-tag"
import { useMutation } from "react-apollo"

const chartSettingsMutation = gql`
  mutation updateChartSettings(
    $chartShowHorizontalLines: Boolean
    $chartShowVerticalLines: Boolean
    $chartLineThickness: Float
    $chartColorPalette: String
  ) {
    updateViewer(
      chartShowHorizontalLines: $chartShowHorizontalLines
      chartShowVerticalLines: $chartShowVerticalLines
      chartLineThickness: $chartLineThickness
      chartColorPalette: $chartColorPalette
    ) {
      id
      chartShowHorizontalLines
      chartShowVerticalLines
      chartLineThickness
      chartColorPalette
      __typename
    }
  }
`

export function useChartSettingsMutation() {
  const [updateChartSettings] = useMutation(chartSettingsMutation)

  const persistHorizontalSupportLines = React.useCallback(
    (isEnabled) => {
      updateChartSettings({
        variables: {
          chartShowHorizontalLines: isEnabled,
        },
      })
    },
    [updateChartSettings],
  )

  const persistVerticalSupportLines = React.useCallback(
    (isEnabled) => {
      updateChartSettings({
        variables: {
          chartShowVerticalLines: isEnabled,
        },
      })
    },
    [updateChartSettings],
  )

  const persistLineThickness = React.useCallback(
    (lineThickness) => {
      updateChartSettings({
        variables: {
          chartLineThickness: lineThickness,
        },
      })
    },
    [updateChartSettings],
  )

  const persistColorPalette = React.useCallback(
    (colorPalette) => {
      updateChartSettings({
        variables: {
          chartColorPalette: colorPalette,
        },
      })
    },
    [updateChartSettings],
  )

  return {
    persistHorizontalSupportLines,
    persistVerticalSupportLines,
    persistLineThickness,
    persistColorPalette,
  }
}
