import CheckIns from "./RestApiClient/checkIns"
import Metrics from "./RestApiClient/metrics"
import Tracing from "./RestApiClient/tracing"

const ENV_IS_TEST = process.env.NODE_ENV === "test"

class RestApiClient {
  baseUrl
  token
  constructor(baseUrl, token) {
    this.baseUrl = baseUrl
    this.token = token
    this.metrics = new Metrics(this)
    this.tracing = new Tracing(this)
    this.checkIns = new CheckIns(this)
  }

  async customFetch(uri, options = {}) {
    if (!ENV_IS_TEST) {
      try {
        appsignal.addBreadcrumb({
          category: "REST",
          action: `REST query: ${uri}`,
        })
      } catch (e) {
        console.error("Failed to parse request body", e)
      }
    }

    const { signal, ...restOptions } = options

    return fetch(`${this.baseUrl}${uri}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      signal,
      ...restOptions,
    })
  }

  // Throw on non-2xx status codes
  async checkStatus(response) {
    if (!response.ok) {
      const error = new Error(`${response.status} ${response.statusText}`)
      error.response = response
      try {
        error.body = await response.json()
      } catch (e) {
        console.error("Failed to parse error response body", e)
      }
      throw error
    }
    return response
  }

  // 204 is No-content
  parseJson(response) {
    return response.status === 204 ? {} : response.json()
  }

  async get(url, options = {}) {
    return this.customFetch(url, { ...options, method: "GET" })
      .then(this.checkStatus)
      .then(this.parseJson)
  }

  async requestWithBody(method, url, data, options) {
    const body = JSON.stringify(data)
    return this.customFetch(url, { ...options, method, body })
      .then(this.checkStatus)
      .then(this.parseJson)
  }

  // delete is a reserved word
  async destroy(url, options = {}) {
    return this.customFetch(url, { ...options, method: "DELETE" })
      .then(this.checkStatus)
      .then(this.parseJson)
  }

  async post(url, data, options) {
    return this.requestWithBody("POST", url, data, options)
  }
  async put(url, data, options) {
    return this.requestWithBody("PUT", url, data, options)
  }
  async patch(url, data, options) {
    return this.requestWithBody("PATCH", url, data, options)
  }
}

export default RestApiClient
