import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import CustomMetricsDashboardDefinitionQuery from "../../../graphql/custom_metrics_dashboard_definition_query"
import ReorderVisualsMutation from "../../../graphql/reorder_visuals_mutation"
import Row from "./Row"

const ReorderForm = ({ appId, dashboardId, handleClose }) => {
  const [visuals, setVisuals] = useState([])
  const [reorderMutation] = useMutation(ReorderVisualsMutation)
  const { data, loading } = useQuery(CustomMetricsDashboardDefinitionQuery, {
    variables: {
      appId,
      id: dashboardId,
    },
  })

  const moveVisual = (visual, from, to) => {
    const newVisuals = visuals.slice()
    const item = newVisuals.splice(from, 1)[0]
    newVisuals.splice(to, 0, item)

    setVisuals(newVisuals)
  }

  const handleSave = async () => {
    const visualIds = visuals.map((visual) => visual.id)
    await reorderMutation({
      variables: {
        appId,
        id: dashboardId,
        visualIds: visualIds,
      },
    })
    handleClose()
  }

  useEffect(() => {
    if (data) {
      setVisuals(data.app.dashboard.visuals)
    }
  }, [data, setVisuals])

  if (loading) {
    return <LoadingAnimation message="Loading dashboard data" />
  } else {
    return (
      <div className="c-box text-gray-800">
        <div className="c-box__header">
          <h3>Graph order</h3>
        </div>
        <div className="px-5">
          {visuals.map((visual, index) => (
            <Row
              visual={visual}
              key={visual.id}
              total={visuals.length}
              current={index}
              moveVisual={moveVisual}
            />
          ))}
        </div>
        <div className="px-5 py-4 flex justify-end border-t border-gray-200">
          <a
            className="c-button c-button--sm c-button--white mr-3 cursor-pointer"
            onClick={() => handleClose()}
          >
            Cancel
          </a>
          <a className="c-button c-button--sm cursor-pointer" onClick={handleSave}>
            Save changes
          </a>
        </div>
      </div>
    )
  }
}

ReorderForm.propTypes = {
  appId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default ReorderForm
