import React from "react"

import MetricFormField from "../../shared/metric_form/Field"
import MetricFormTags from "../../shared/metric_form/Tags"

const VisualBuilderTimeseriesMetricForm = (props) => {
  return (
    <>
      <MetricFormField {...props} />
      <MetricFormTags {...props} />
    </>
  )
}

export default VisualBuilderTimeseriesMetricForm
