import UrlHelper from "../shared/url_helper"
import SideNavigation from "./side_navigation"
import Tooltip from "./Tooltip"

/// Groups dashboard by title, if the title contains a `/`
/// input: [{ title: "foo" }, { title: "sub/nav" }, { title: "sub/second" }]
/// output: [
///   { title: "foo", subnav: false, dashboards: [{ title: "foo"}] },
///   { title: "sub", subnav: true, dashboards: [{ title: "nav" }, { title: "second" }] }
/// ]
export const groupDashboards = (dashboards) => {
  const groupedDashboards = []

  // We only want to loop once, so we have to do some lookups here
  for (const dashboard of dashboards.sort((a, b) => a.title.localeCompare(b.title))) {
    const { title, ...rest } = dashboard
    const [groupTitle, ...restTitle] = title.split("/")
    const last = groupedDashboards[groupedDashboards.length - 1]

    // If we have a split and the "group" title matches the current group title
    // add the item to the array
    if (restTitle.length > 0 && last?.title == groupTitle) {
      last.dashboards.push({ title: restTitle.join("/"), ...rest })

      // If we don't have a matching last element, it's a new group
      // insert it as a new group
    } else if (restTitle.length > 0) {
      groupedDashboards.push({
        title: groupTitle,
        subnav: true,
        dashboards: [{ title: restTitle.join("/"), ...rest }],
      })
      // No `/` in the title, just insert is a a group with 1 child
    } else {
      groupedDashboards.push({
        title: dashboard.title,
        subnav: false,
        dashboards: [dashboard],
      })
    }
  }

  return groupedDashboards
}

export const renderDashboardMenuItems = (appInfo, hasUsedFeature) => {
  if (!appInfo || !appInfo?.dashboards) return null

  let dashboardLinks = []

  const renderDashboard = (dashboard) => {
    let url = UrlHelper.metricsShowPath({ id: dashboard.id })
    let label = null

    // Check if user has seen this new automated dashboard before, if not link to blank slate
    if (
      dashboard.key &&
      dashboard.source == "AUTO" &&
      !hasUsedFeature(`dashboard.${dashboard.key}`)
    ) {
      label = (
        <Tooltip content="Automated dashboard created" placement="bottom">
          <i className="fa fa-sparkles" />
        </Tooltip>
      )
      url = UrlHelper.magicDashboardBlankSlatePath({ id: dashboard.id, key: dashboard.key })
    }

    return (
      <SideNavigation
        key={dashboard.id}
        title={dashboard.title}
        href={url}
        type="subitem"
        label={label}
      />
    )
  }

  const groupedDashboards = groupDashboards(appInfo.dashboards.slice(0))

  dashboardLinks = groupedDashboards.map((dashboardGroup) => {
    if (dashboardGroup.subnav) {
      return (
        <SideNavigation key={dashboardGroup.title} title={dashboardGroup.title} type="submenu">
          {dashboardGroup.dashboards.map((d) => renderDashboard(d))}
        </SideNavigation>
      )
    } else {
      return renderDashboard(dashboardGroup.dashboards[0])
    }
  })

  return dashboardLinks
}
