import { useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React from "react"

import CustomMetricsDashboardsDefinitionQuery from "../../../graphql/custom_metrics_dashboards_definition_query"

const DashboardSelector = ({ appId, dashboardId, onSelect }) => {
  const { data, loading } = useQuery(CustomMetricsDashboardsDefinitionQuery, {
    variables: { appId }
  })

  const dashboardOptions = []
  if (loading) {
    dashboardOptions.push(
      <option key="loading_item" value="" hidden>Loading...</option>
    )
  } else {
    dashboardOptions.push(
      <option
        key="dashboard_option_placeholder"
        value=""
        hidden
      >
        Select dashboard
      </option>
    )
    const dashboards = data.app.dashboards
    if (dashboards.length > 0) {
      dashboards.forEach(dashboard => {
        dashboardOptions.push(
          <option
            key={`dashboard_option_${dashboard.id}`}
            value={dashboard.id}
          >
            {dashboard.title}
          </option>
        )
      })
    } else {
      dashboardOptions.push(
        <option
          key="dashboard_option_no_items"
          value=""
          disabled
        >
          No dashboards for app
        </option>
      )
    }
  }

  return (
    <>
      <label htmlFor="dashboard_select">Dashboard</label>
      <div className="c-form__element">
        <div className="c-select">
          <select
            id="dashboard_select"
            key="dashboard_selector"
            defaultValue={dashboardId || ""}
            onChange={event => onSelect(event.target.value)}
            className="c-select__input"
          >
            {dashboardOptions}
          </select>
        </div>
      </div>
    </>
  )
}

DashboardSelector.propTypes = {
  appId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string,
  onSelect: PropTypes.func.isRequired
}

export default DashboardSelector
