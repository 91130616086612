import PropTypes from "prop-types"
import React, { useState } from "react"

import DateTimeFilter from "../filters/DateTimeFilter"
import GraphQLErrors from "../shared/graphql_errors"
import Preview from "./skeleton/Preview"
import Sidebar from "./skeleton/Sidebar"

const VisualBuilderSkeleton = ({
  appId,
  visual,
  visualPreview,
  submitLabel,
  error,
  metricForm,
  optionsForm,
  preview,
  closeOverlay,
  onChange,
  onSubmit
}) => {
  const [previewTimeframe, setPreviewTimeframe] = useState("R1H")
  const handlePreviewTimeframeChange = ({ timeframe }) => {
    setPreviewTimeframe(timeframe)
  }
  const [lineLabelFocus, setLineLabelFocus] = useState(false)

  return (
    <section className="flex bg-gray-100 rounded h-full max-h-256">
      <div className="w-96 flex-shrink-0 overflow-y-auto border-r border-gray-200 bg-white">
        <Sidebar
          appId={appId}
          visual={visual}
          metricForm={metricForm}
          optionsForm={optionsForm}
          closeOverlay={closeOverlay}
          onChange={onChange}
          onLineLabelFocus={() => setLineLabelFocus(true)}
          onLineLabelBlur={() => setLineLabelFocus(false)}
        />
      </div>
      <div className="relative flex-grow flex flex-col h-full">
        <div className="h-full overflow-y-auto p-8 pb-16">
          <Preview
            appId={appId}
            visual={visual}
            visualPreview={visualPreview}
            timeframe={previewTimeframe}
            showLineLegend={lineLabelFocus}
            preview={preview}
          />

          <div className="flex justify-end">
            <DateTimeFilter 
              handleChange={handlePreviewTimeframeChange}
              hideCustomRange={true}
              initialValue={{ timeframe: previewTimeframe }} 
            />
          </div>
        </div>
        <div className="border-t border-gray-200">
          <div className="py-4 px-8 text-right bg-gray-100">
            {error && <GraphQLErrors error={error} />}
            <button
              className="c-button c-button--sm"
              onClick={onSubmit}
            >{submitLabel}</button>
          </div>
        </div>
      </div>
    </section>
  )
}

VisualBuilderSkeleton.propTypes = {
  appId: PropTypes.string.isRequired,
  visual: PropTypes.object.isRequired,
  visualPreview: PropTypes.object.isRequired,
  submitLabel: PropTypes.string.isRequired,
  error: PropTypes.object,
  metricForm: PropTypes.func.isRequired,
  optionsForm: PropTypes.func,
  preview: PropTypes.func.isRequired,
  closeOverlay: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

export default VisualBuilderSkeleton
