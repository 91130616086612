import PropTypes from "prop-types"
import React from "react"

import { Column,Table } from "../Table/"
import AlertsTableRow from "./AlertsTableRow"

export const AlertsTable = ({ params, alerts, loading, error, title, compact, ...rest }) => {
  /* eslint-disable react/prop-types */
  const rowCell = ({ rowData }) => (
    <AlertsTableRow appId={params.appId} alert={rowData} compact={compact} />
  )

  return (
    <Table
      data={alerts}
      loading={loading}
      error={error}
      title={title}
      initialSortKey="createdAt"
      rowRenderer={rowCell}
      estimatedRowHeight={compact === true ? 100 : 150}
      headerClassName="justify-between"
      {...rest}
    >
      <Column title="Trigger condition" dataKey="id" flexGrow={1} />
      <Column title="Details" dataKey="state" align="right" />
    </Table>
  )
}

AlertsTable.propTypes = {
  params: PropTypes.object.isRequired,
  compact: PropTypes.bool,
  alerts: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
}

export default AlertsTable
