import dayjs from "dayjs"

import TimeAgoInWords from "#Components/shared/time_ago_in_words"
import TimeSinceInWords from "#Components/shared/time_since_in_words"
import Tooltip from "#Components/shared/Tooltip"
import FormattedTime from "#Root/formatters/FormattedTime"

const AlertState = ({
  alert: {
    state,
    createdAt,
    resolvedAt,
    openedAt,
    trigger: { warmupDuration, cooldownDuration },
  },
  compact,
  peakComponent,
  meanComponent,
}) => {
  switch (state) {
    case "WARMUP": {
      let warmupDiff = dayjs().diff(dayjs(createdAt), "minutes")
      if (warmupDiff > warmupDuration) {
        warmupDiff = warmupDuration
      }

      return (
        <div className="flex justify-end text-right">
          <div>
            <p className="pb-px">
              <i className="mr-2 fa fa-arrow-right text-ms text-orange transform -rotate-45" />
              <span className="text-orange font-semibold">Warming up </span>
              <span className="text-orange">
                · {warmupDiff} out of {warmupDuration} min
              </span>
            </p>
            {compact && (
              <p className="pt-px text-gray-700">
                Peak: {peakComponent} – Avg: {meanComponent}
              </p>
            )}
            {!compact && (
              <p className="pt-px text-gray-700">
                Started <FormattedTime value={createdAt} format="short" />
              </p>
            )}
          </div>
        </div>
      )
    }
    case "COOLDOWN": {
      let cooldownDiff = dayjs().diff(dayjs(resolvedAt), "minutes")
      if (cooldownDiff > cooldownDuration) {
        cooldownDiff = cooldownDuration
      }
      return (
        <div className="flex justify-end text-right">
          <div>
            <p className="pb-px">
              <i className="mr-2 fa fa-arrow-right text-ms text-blue transform rotate-45" />

              <span className="text-blue font-semibold">Cooling down </span>
              <span className="text-blue">
                · {cooldownDiff} out of {cooldownDuration} min
              </span>
            </p>
            {compact && (
              <p className="pt-px text-gray-700">
                Peak: {peakComponent} – Avg: {meanComponent}
              </p>
            )}
            {!compact && (
              <p className="pt-px text-gray-700">
                Started <FormattedTime value={createdAt} format="short" />
              </p>
            )}
          </div>
        </div>
      )
    }
    case "OPEN":
      return (
        <div className="flex justify-end text-right">
          <div>
            <p className="pb-px">
              <i className="mr-2 fa fa-arrow-up text-ms text-red" />

              <span className="text-red font-semibold">Ongoing </span>
              <span className="text-red">
                · <TimeSinceInWords time={openedAt} />
              </span>
            </p>
            {compact && (
              <p className="pt-px text-gray-700">
                Peak: {peakComponent} – Avg: {meanComponent}
              </p>
            )}
            {!compact && (
              <p className="pt-px text-gray-700">
                Started <FormattedTime value={createdAt} format="short" />
              </p>
            )}
          </div>
        </div>
      )
    case "UNTRACKED":
      return (
        <div className="flex justify-end text-right">
          <div>
            <Tooltip
              content={
                <>
                  The option &quot;Treat missing datapoint as 0&quot; is active for this alert and
                  we haven&apos;t received metrics for this alert for the past 48 hours. <br />{" "}
                  After this time we stop tracking this alert.
                </>
              }
              placement="top"
            >
              <p className="pb-px">
                <i className="mr-2 fa fa-exclamation text-ms text-gray" />
                <span className="text-gray font-semibold">Untracked </span>
                <span className="text-gray">
                  · <TimeSinceInWords time={resolvedAt} />
                </span>
              </p>
            </Tooltip>
            {compact && (
              <p className="pt-px text-gray-700">
                Peak: {peakComponent} – Avg: {meanComponent}
              </p>
            )}
            {!compact && (
              <p className="pt-px text-gray-700">
                Started <FormattedTime value={createdAt} format="short" />
              </p>
            )}
          </div>
        </div>
      )
    default:
      return (
        <div className="flex justify-end text-right">
          <div>
            <p className="pb-px">
              <i className="mr-2 fa fa-arrow-to-right text-ms text-purple" />

              <span className="text-purple font-semibold">Ended </span>
              <span className="text-purple">
                · <TimeAgoInWords time={resolvedAt} />
              </span>
            </p>
            {compact && (
              <p className="pt-px text-gray-700">
                Peak: {peakComponent} – Avg: {meanComponent}
              </p>
            )}
            {!compact && (
              <p className="pt-px text-gray-700">
                <FormattedTime value={createdAt} format="short" /> –{" "}
                <FormattedTime value={resolvedAt} format="short" />
              </p>
            )}
          </div>
        </div>
      )
  }
}

AlertState.propTypes = {
  alert: PropTypes.object,
  compact: PropTypes.bool,
  peakComponent: PropTypes.object,
  meanComponent: PropTypes.object,
}

export default AlertState
