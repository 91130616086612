const wildcardMatchers = ["wildcardStartsWith", "wildcardEqual"]
function matcherFunction(value, matcher) {
  const wildcard = wildcardMatchers.includes(matcher)
  if (wildcard) {
    const query = value
    const regexSuffix = matcher == "wildcardEqual" ? "$" : ""
    // Take out any other special regex characters so that it does not use
    // those as matchers too.
    const sanitizedQuery = query.replace(/([.+?^=!${}()|[\]/\\])/g, "\\$&").replace("*", ".*")
    const wildcardRegex = new RegExp(`^${sanitizedQuery}${regexSuffix}`)
    return (_value, option) => wildcardRegex.test(option)
  } else if (matcher === "equal") {
    return (value, option) => value === option
  } else if (matcher === "startsWith") {
    return (value, option) => option.startsWith(value)
  }
}

export function filterArrayWith(givenValue, options, matcher) {
  const value = (givenValue || "").toLowerCase()
  const matcherFn = matcherFunction(value, matcher)

  return options.filter((option) => {
    const normalizedOption = option.toLowerCase()
    return matcherFn(value, normalizedOption)
  })
}

export const sortArray = (arr, field, direction, defaultValue) => {
  const nestedValueFor = (value, keys) => {
    if (keys.length > 0) {
      const key = keys.shift()

      const nextValue = value[key]

      // The path is not present in the object
      // We were looking for something like `a.b.c`, but we only have `a.z.c`
      // So we return the default value if any
      if (nextValue === undefined && keys.length > 0) {
        return defaultValue
      }

      return nestedValueFor(value[key], keys)
    } else {
      return value
    }
  }

  const valueFor = (value, key) => {
    const keys = key.split(".")
    return nestedValueFor(value, keys)
  }

  const newArray = arr.slice()

  newArray.sort((x, y) => {
    let a = valueFor(x, field)
    let b = valueFor(y, field)

    if (Number.isNaN(a) || a === undefined) {
      a = defaultValue
    }
    if (Number.isNaN(b) || b === undefined) {
      b = defaultValue
    }

    if (a === undefined) {
      throw `Sort field '${field}' not present`
    } else if (typeof a === "string" && "".localeCompare) {
      return b.localeCompare(a)
    } else {
      return b - a
    }
  })

  if (direction === "asc") {
    newArray.reverse()
  }

  return newArray
}
