import FormattedNumber from "#Root/formatters/FormattedNumber"

import NumberHelpers from "../../base/number_helpers"

export const getDurationParts = (val) => {
  const minute = 60 * 1000
  const hour = 60 * minute
  let time, suffix

  const zeroPrefixed = (val) => (val < 10 ? `0${val}` : val)

  // format everything below a minute as their unit (µs/ms/sec)
  if (val < minute) {
    const [, duration, helperSuffix] = NumberHelpers.numberToDurationParts(val)
    time = <FormattedNumber value={duration} />
    suffix = helperSuffix

    // format everything under an hour as mm:ss
  } else if (val < hour) {
    const { minutes, seconds } = NumberHelpers.millsecondsToTimeParts(val)
    time = `${zeroPrefixed(minutes)}:${zeroPrefixed(seconds)}`
    suffix = "min"

    // Render everything under 99 hours as (hh:mm)
  } else if (val < 99 * hour) {
    const { minutes, hours } = NumberHelpers.millsecondsToTimeParts(val)
    time = `${zeroPrefixed(hours)}:${zeroPrefixed(minutes)}`
    suffix = "h"

    // format everything over 99 hours as whole hours
  } else {
    time = Math.round(val / hour)
    suffix = "h"
  }

  return {
    time,
    suffix,
  }
}

const Duration = ({ val, className }) => {
  const { time, suffix } = getDurationParts(val)

  return (
    <span className={className || ""} title={`${val} ms`}>
      {time} <span className="text-gray-600">{suffix}</span>
    </span>
  )
}

Duration.propTypes = {
  val: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default Duration
