$(document).on("change", ".member_options", function() {
  let question
  const form = $(this).parent()
  const method = form.find("[name=_method]")
  switch ($(this).val()) {
    case "make_owner":
      question = "Promote this member to owner?"
      method.val("post")
      form.attr("action", form.data("add-owner-path"))
      break
    case "make_member":
      question = "Demote this owner to member?"
      method.val("delete")
      form.attr("action", form.data("remove-owner-path"))
      break
    case "remove_member":
      question = "Remove this member from organization?"
      method.val("delete")
      form.attr("action", form.data("remove-member-path"))
      break
  }

  if (question) {
    const answer = confirm(question)
    if (answer) {
      form.submit()
    }
  }
})
