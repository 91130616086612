import { flexRender } from "@tanstack/react-table"

import { useTableContext } from "#Root/contexts/TableContext"
import cn from "#Root/utils/cn"

const Rows = ({ narrow = false, isHighlighted, onClick }) => {
  const { table, error, dataSize } = useTableContext()

  if (error) return null
  if (dataSize === 0) return null

  return (
    <tbody>
      {table.getRowModel().rows.map((row) => (
        <tr
          key={row.id}
          onClick={() => {
            if (typeof onClick === "function") {
              onClick(row.original)
            }
          }}
          className={cn({
            "bg-blue-100": typeof isHighlighted === "function" && isHighlighted(row.original),
            "cursor-pointer": typeof onClick === "function",
          })}
        >
          {row.getVisibleCells().map((cell) => {
            return (
              <td
                key={cell.id}
                className={cn("c-table__td", {
                  "c-table__td--narrow": narrow,
                  "text-center": cell.column.id === "selection",
                })}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            )
          })}
        </tr>
      ))}
    </tbody>
  )
}

Rows.propTypes = {
  narrow: PropTypes.bool,
  isHighlighted: PropTypes.func,
  onClick: PropTypes.func,
}

export default Rows
