import PropTypes from "prop-types"

import useFormattedNumber from "#Root/hooks/formatters/useFormattedNumber"

const HumanNumber = ({ number, precision }) => {
  const { formatNumberToHuman, formatNumberWithPrecision } = useFormattedNumber()

  const formattedNumber = formatNumberWithPrecision(number, precision)
  const label = formatNumberToHuman(number, precision)

  return <span title={formattedNumber}>{label}</span>
}

HumanNumber.propTypes = {
  number: PropTypes.number,
  precision: PropTypes.number,
}

HumanNumber.defaultProps = {
  precision: 2,
}

export default HumanNumber
