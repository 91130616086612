export function usePageTitle(title, siteName) {
  const documentDefined = typeof document !== "undefined"
  const originalTitle = React.useRef(documentDefined ? document.title : null)

  React.useEffect(() => {
    if (!documentDefined) return

    const newTitle = siteName ? `${title} - ${siteName} - AppSignal` : `${title} - AppSignal`
    document.title = newTitle

    return () => {
      document.title = originalTitle.current
    }
  }, [title, siteName])
}
