import gql from "graphql-tag"

import StatusPageUpdateFragment from "./_statusPageUpdateFragment"

export default gql`
  mutation deleteStatusPageUpdateMutation(
    $organizationSlug: String!,
    $statusPageId: String!
    $id: String!,
  ) {
    deleteStatusPageUpdate(
      organizationSlug: $organizationSlug,
      statusPageId: $statusPageId,
      id: $id
    ) {
      ...StatusPageUpdate
    }
  }
  ${StatusPageUpdateFragment}
`
