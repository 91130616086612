import Tooltip from "./Tooltip"

const TooltipWithIcon = ({ close, ...props }) => (
  <Tooltip {...props}>
    <i className={`${close ? "ml-1" : "ml-2"} text-gray-600 text-ms align-baseline cursor-pointer fa fa-info-circle`}></i>
  </Tooltip>
)

TooltipWithIcon.propTypes = {
  close: PropTypes.bool,
}

TooltipWithIcon.defaultProps = {
  close: false,
}

export default TooltipWithIcon
