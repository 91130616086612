import BarChart from "#Components/shared/bar_chart"
import Duration from "#Components/shared/duration"

export const DurationCell = ({
  cellData,
  column: { dataKey },
  container: {
    props: { maxValues },
  },
}) => (
  <div className="c-virtual-table__row-cell-content">
    <Duration val={cellData} />
    <BarChart max={maxValues[dataKey]} val={cellData} />
  </div>
)

DurationCell.propTypes = {
  cellData: PropTypes.number.isRequired,
  container: PropTypes.shape({
    props: PropTypes.shape({
      maxValues: PropTypes.object.isRequired,
    }),
  }),
  column: PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
  }),
}

export default DurationCell
