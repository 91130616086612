import cn from "#Root/utils/cn"

const RadioGroup = ({ name, children, value, onChange, className = "" }) => {
  return (
    <div className={className}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          name,
          selected: value === child.props.value,
          setSelected: () => onChange(child.props.value),
        })
      })}
    </div>
  )
}

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

const Radio = ({
  id,
  label,
  className,
  labelClassName,
  disabled = false,
  value,
  ariaLabel,
  // Will be injected by RadioGroup
  name,
  selected,
  setSelected,
  children,
}) => {
  return (
    <div className={cn("c-radio ignore-old-css", className)}>
      <input
        id={id}
        className="c-radio__input"
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        checked={selected}
        onChange={setSelected}
        aria-label={ariaLabel}
      />
      <label className={cn("c-radio__label ignore-old-css", labelClassName)} htmlFor={id}>
        {label}
      </label>
      {children}
    </div>
  )
}

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  children: PropTypes.node,
  ariaLabel: PropTypes.string,
}

const Description = ({ children }) => {
  return <div className="c-radio__description ignore-old-css">{children}</div>
}

Description.propTypes = {
  children: PropTypes.node.isRequired,
}

const ErrorMessage = ({ children }) => {
  return <div className="c-radio__error ignore-old-css">{children}</div>
}

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
}

RadioGroup.Description = Description
RadioGroup.ErrorMessage = ErrorMessage
RadioGroup.Radio = Radio

export default RadioGroup
