import PropTpes from "prop-types"
import React, { useState } from "react"

import CopyToClipBoardButton from "#Root/ui/CopyToClipBoardButton/CopyToClipBoardButton"

export function ClipGraphMenu({ visual }) {
  const [title, setTitle] = useState(visual.title)
  const [renderLegend, setRenderLegend] = useState(true)
  const url = `${visual.pngUrl}?title=${encodeURIComponent(title)}&renderLegend=${renderLegend}`

  return (
    <>
      <div className="px-5 py-4 border-b border-gray-200">
        <h2 className="font-semibold">Export graph</h2>
      </div>
      <div className="px-5 py-4">
        <div className="c-form">
          <label className="c-form__label ignore-old-css" htmlFor="title">Graph title</label>
          <div className="c-form__element">
            <label className="c-textfield ignore-old-css">
              <input
                type="text"
                className="c-textfield__input"
                id="title"
                placeholder="Graph title"
                name="title"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
            </label>
          </div>
        </div>
        <div className="c-form mb-0">
          <label className="c-form__label ignore-old-css" htmlFor="legend">Legend</label>
          <div className="c-form__element">
            <div className="c-checkbox ignore-old-css">
              <input
                className="c-checkbox__input"
                type="checkbox"
                id="legend"
                defaultChecked={renderLegend}
                onClick={() => setRenderLegend(!renderLegend)}
              />
              <label
                className="c-checkbox__label cursor-pointer ignore-old-css"
                htmlFor="legend"
              >
                Show legend
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 py-3 border-t border-gray-200 space-x-3">
        <a
          href={`${url}?download`}
          className="c-button c-button--xs "
          target="_blank"
          rel="noopener noreferrer"
          role="button"
        >
          Download image
        </a>
        <CopyToClipBoardButton color="white" copiedText="copied!" content={url}>
          Copy URL
        </CopyToClipBoardButton>
      </div>
    </>
  )
}

ClipGraphMenu.propTypes = {
  visual: PropTpes.object.isRequired
}

export default ClipGraphMenu
