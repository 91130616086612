import TriggersQuery from "../../graphql/triggers_query"

export function getDataFrom(store, id) {
  return store.readQuery({
    query: TriggersQuery,
    variables: {
      appId: id,
    },
  })
}

export function writeDataTo(store, id, data) {
  store.writeQuery({
    query: TriggersQuery,
    data,
    variables: {
      appId: id,
    },
  })
}
