const NoMatchIsZero = ({ value, onChange }) => {
  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="noMatchIsZero">
        Treat missing datapoint as 0
      </label>
      <div className="c-form__element">
        <div className="c-checkbox mb-2">
          <input
            className="c-checkbox__input"
            id="noMatchIsZero"
            name="noMatchIsZero"
            type="checkbox"
            checked={value}
            onChange={() => onChange(!value)}
          />
          <label
            className="c-checkbox__label ignore-old-css cursor-pointer"
            htmlFor="noMatchIsZero"
          >
            When checked, we will treat missing datapoints as a value of 0.
          </label>
        </div>
        <p className="c-form__description">
          Missing datapoints will only be treated as a value of 0, if we have seen the data at least
          once. (e.g. we have received a value of 1 before the data is missing).
        </p>
      </div>
    </div>
  )
}

NoMatchIsZero.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default NoMatchIsZero
