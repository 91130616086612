import PropTypes from "prop-types"
import React, { useEffect, useRef,useState } from "react"

const ExceptionMessage = ({ message }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isTruncated, setIsTruncated] = useState(false)
  const codeRef = useRef(null)

  useEffect(() => {
    if (codeRef.current.scrollHeight > codeRef.current.clientHeight) {
      setIsTruncated(true)
    }
  }, [isTruncated])

  return (
    <>
      <pre
        ref={codeRef}
        className={`text-ms text-gray-800 leading-relaxed whitespace-pre-wrap ${ isExpanded ? "" : "overflow-y-hidden max-h-32"}`}
      >
        {message}
      </pre>
      { isTruncated &&
        <span className="font-mono text-ms text-gray-700 underline cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          { isExpanded ? "Show less" : "Show more" }
        </span>
      }
    </>
  )
}

ExceptionMessage.propTypes = {
  message: PropTypes.string.isRequired
}

export default ExceptionMessage
