import PropTypes from "prop-types"
import React from "react"

import FormattedTime from "../../formatters/FormattedTime/FormattedTime"
import Duration from "../shared/duration"
import Link from "../shared/link"
import ExceptionMessage from "./exception_message"

const SampleDetail = ({ sample, url }) => {
  const tags = sample.overview.map((item) => {
    if (!item.value) return

    return (
      <tr key={item.key}>
        <td className="pb-1 text-gray-700">{item.key}</td>
        <td className="pb-1">{item.value}</td>
      </tr>
    )
  })

  return (
    <table className="block ml-16 pl-2 pr-5 py-4">
      <colgroup>
        <col className="w-40" />
        <col className="" />
      </colgroup>
      <tbody>
        <tr>
          <td className="pb-4" colSpan="2">
            <Link href={url} className="text-blue">
              <FormattedTime format="long" value={sample.time} />
            </Link>
          </td>
        </tr>
        {sample.__typename === "PerformanceSample" && (
          <tr>
            <td className="pb-4 text-gray-700">duration</td>
            <td className="pb-4">
              <Duration val={sample.duration} />
            </td>
          </tr>
        )}
        {sample.__typename === "ExceptionSample" && (
          <tr>
            <td className="pb-4 align-top text-gray-700">Error message</td>
            <td className="pb-4">
              <ExceptionMessage message={sample.exception.message} />
            </td>
          </tr>
        )}
        {tags}
      </tbody>
    </table>
  )
}

SampleDetail.propTypes = {
  sample: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
}

export default SampleDetail
