import dayjs from "dayjs"

import { TIMEFRAME_MINUTELY_SECONDS } from "#Root/constants/timeframe"

// Return the resolution for range
export function resolution(timeOrString) {
  const start = dayjs(timeOrString)
  return start > dayjs().subtract(TIMEFRAME_MINUTELY_SECONDS, "seconds") ? "min" : "hour"
}

export function resolutionEnum(timeOrString) {
  return resolution(timeOrString) == "min" ? "MINUTELY" : "HOURLY"
}

// Return time range that's "current" (2 minutes ago)
export function currentRange() {
  return timeRange(dayjs().subtract(2, "minutes"))
}

// Return a range for given time, (($time, $time +1))
export function timeRange(timeOrString) {
  const start = rounded(dayjs(timeOrString))
  const end = next(start)

  return { start, end }
}

// Return next range for given time
// if time is 00:10, the start is 00:11 and the end 00:12
export function nextRange(timeOrString) {
  const start = next(dayjs(timeOrString))
  const end = next(start)

  return { start, end }
}

// Return previous range for given time
// if time is 00:10, the start is 00:09 and the end 00:10
export function previousRange(timeOrString) {
  const end = rounded(dayjs(timeOrString))
  const start = previous(end)

  return { start, end }
}

// Round time to given resolution
export function rounded(start) {
  return start > dayjs().subtract(TIMEFRAME_MINUTELY_SECONDS, "seconds")
    ? start.startOf("minute")
    : start.startOf("hour")
}

// Advance time by unit, unit depends on if we're in minutely or hourly territory
export function next(start) {
  return start > dayjs().subtract(TIMEFRAME_MINUTELY_SECONDS, "seconds")
    ? start.add(1, "minutes").startOf("minute")
    : start.add(1, "hours").startOf("hour")
}

// Un(?)advance time by unit, unit depends on if we"re in minutely or hourly territory
export function previous(start) {
  return start > dayjs().subtract(TIMEFRAME_MINUTELY_SECONDS, "seconds")
    ? start.subtract(1, "minutes").startOf("minute")
    : start.subtract(1, "hours").startOf("hour")
}
