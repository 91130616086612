export const TableContext = React.createContext()

export const useTableContext = () => {
  const context = React.useContext(TableContext)

  if (!context) {
    throw new Error("<Table.*> can only be used as a child of <Table>")
  }

  return context
}
