import PropTypes from "prop-types"
import React from "react"

import PopOver from "../PopOver"
import TextfieldWithAutoComplete from "../TextfieldWithAutoComplete"

export class TagsFormInputPair extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    tags: PropTypes.array.isRequired,
    tag: PropTypes.object.isRequired,
    metricMetadata: PropTypes.object,
  }

  state = {
    valueModified: false,
    valueFocus: false,
  }

  handleTagKeyChange(option) {
    const { tag } = this.props
    if (option === "") {
      this.handleDelete()
    } else {
      const newTag = { ...tag, key: option }
      const { valueModified } = this.state

      if (tag.value === "" && valueModified === false) {
        newTag.value = "*"
      }

      this.props.onChange(newTag)
    }
  }

  handleTagValueChange(e) {
    const tagValue = e.target.value
    const { tag } = this.props
    this.setState({ valueModified: true })
    this.props.onChange({ ...tag, value: tagValue })
  }

  handleDelete() {
    this.props.onChange(null)
  }

  handleValueFocus() {
    this.setState({ valueFocus: true })
  }

  handleValueBlur() {
    this.setState({ valueFocus: false })
  }

  autoCompleteOptions() {
    const { metricMetadata } = this.props

    const metricTags = metricMetadata ? metricMetadata.tags : []
    return Array.from(metricTags).sort()
  }

  isDuplicateTag() {
    const { tag, tags: allTags } = this.props
    const tags = allTags.filter((t) => t && t.key === tag.key)
    return tags.length > 1
  }

  isKnownTag() {
    const knownTags = this.autoCompleteOptions()
    const { tag } = this.props
    return tag.key === "" || knownTags.indexOf(tag.key) !== -1
  }

  renderError() {
    if (this.isDuplicateTag()) {
      return (
        <p className="c-form__error ml-2">
          Tag name already configured for this metric. Can&#39;t configure tag multiple times.
        </p>
      )
    }
  }

  renderWarning() {
    if (!this.isKnownTag()) {
      return (
        <p className="text-orange ml-2">
          Tag name not found in the last hour. It might still exist.
        </p>
      )
    }
  }

  render() {
    const {
      tag: { key, value },
    } = this.props
    const { valueFocus } = this.state
    return (
      <div className="mb-3">
        <TextfieldWithAutoComplete
          name="tag-input-pair-name"
          value={key || ""}
          placeholder="Tag name"
          containerClasses="c-textfield--connector-l-down mb-2"
          popOverClasses="ml-2"
          popOverHeader="Tags in last hour"
          options={this.autoCompleteOptions()}
          onSelect={this.handleTagKeyChange.bind(this)}
          hasError={this.isDuplicateTag()}
          hasWarning={!this.isKnownTag()}
        />

        <div className="relative">
          <label className="c-textfield c-textfield--connector-l-up ignore-old-css">
            <input
              type="text"
              id="metric_tag_value"
              name="tag_value"
              value={value}
              placeholder="Tag value"
              className="c-textfield__input ignore-old-css"
              autoComplete="none"
              onFocus={this.handleValueFocus.bind(this)}
              onBlur={this.handleValueBlur.bind(this)}
              onChange={this.handleTagValueChange.bind(this)}
            />
          </label>
          {valueFocus && (
            <PopOver
              className="mt-2 ml-2"
              style={{ maxHeight: "12rem" }}
              onClose={this.handleValueBlur.bind(this)}
              withEscapeListener={false}
            >
              <div className="p-2">Supports wildcards in values (*).</div>
            </PopOver>
          )}
        </div>
        {this.renderError()}
        {this.renderWarning()}
      </div>
    )
  }
}

export default TagsFormInputPair
