import Link from "#Components/shared/link"
import TriggerFormButton from "#Components/triggers/form_button"
import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import { labelWithHostnameFromTags } from "#Root/utils/host_metrics"
import { findTagValue } from "#Root/utils/metric_keys"

const TriggerHostCPUUsage = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const { comparisonOperator, value } = trigger.thresholdCondition
  const tags = alertTags || trigger.tags
  const state = findTagValue(tags, "state")
  const label = labelWithHostnameFromTags("Host CPU usage", tags)

  return (
    <>
      <span className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </span>
      <p className="pt-px text-gray-700">
        {state} CPU
        <FormattedComparisonOperator value={comparisonOperator} />
        {value} %
      </p>
    </>
  )
}
TriggerHostCPUUsage.propTypes = {
  trigger: PropTypes.object.isRequired,
  tags: PropTypes.array,
  link: PropTypes.string,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostCPUUsage
