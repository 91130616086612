import FormattedNumber from "#Root/formatters/FormattedNumber"

const FormattedExceptionRate = ({ value }) => (
  <span>
    <FormattedNumber value={value} />
    <i>%</i>
  </span>
)
FormattedExceptionRate.propTypes = {
  value: PropTypes.number.isRequired,
}

export default FormattedExceptionRate
