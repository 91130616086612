import PropTypes from "prop-types"
import React from "react"
import TextareaAutosize from "react-textarea-autosize"

export const TextArea = React.forwardRef(({ autosize, ...props }, ref) => {
  const renderTextArea = () => {
    if (autosize) {
      return (
        <TextareaAutosize
          className="c-textarea__input resize-y"
          {...props}
          ref={ref}
        />
      )
    } else {
      return <textarea className="c-textarea__input resize-y" {...props} ref={ref} />
    }
  }

  return (
    <div className="c-form__element">
      <div className="c-textarea">
        { renderTextArea() }
      </div>
    </div>
  )
})

TextArea.displayName = "TextArea"

TextArea.defaultProps = {
  autosize: true,
}

TextArea.propTypes = {
  autosize: PropTypes.bool.isRequired,
}

export default TextArea
