import PropTypes from "prop-types"
import React from "react"

import TriggerFormDescription from "#Components/triggers/form/fields/Description"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown"
import { TRIGGER_TYPES } from "#Root/constants/Triggers"

import { findTagValue } from "../../../../utils/metric_keys"
import withNotifiers, { notifiersPropType } from "../../../../wrappers/notifiers"
import withWarmupAndCooldown, {
  warmupAndCooldownPropType,
} from "../../../../wrappers/warmup_and_cooldown"
import TriggerGraphPreview from "../../GraphPreview"
import ShareLink from "../ShareLink"
import TriggerFormHostname from "./Hostname"

const METRIC_NAMES = [
  {
    key: "disk reading rate",
    value: "disk_io_read",
  },
  {
    key: "disk writing rate",
    value: "disk_io_written",
  },
]

const formName = "HostDiskIO"
export class TriggerFormHostDiskIO extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    blankSlate: PropTypes.bool,
    trigger: PropTypes.object,
    appId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    notifiers: notifiersPropType.isRequired,
    warmupAndCooldown: warmupAndCooldownPropType.isRequired,
    error: PropTypes.object,
  }
  static form = formName
  static params = {
    hostname: "tag",
    metricName: "field",
    comparisonOperator: "field",
    conditionValue: "field",
  }

  constructor(props) {
    super(props)
    const { trigger, params } = props
    // Use data from existing trigger
    if (trigger && trigger.id) {
      this.state = {
        hostname: findTagValue(trigger.tags, "hostname"),
        conditionValue: trigger.thresholdCondition.value,
        metricName: trigger.metricName,
        description: trigger.description,
      }
      return
    } else {
      this.state = {
        hostname: params.hostname || "*",
        metricName: params.metricName || "disk_io_read",
        conditionValue: params.conditionValue || 10,
        description: params.description || "",
      }
      return
    }
  }

  triggerObject() {
    const { metricName, hostname } = this.state
    return {
      metricName,
      field: "GAUGE",
      tags: [
        { key: "host_metric", value: "" },
        { key: "hostname", value: hostname },
        { key: "disk", value: "*" },
      ],
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { conditionValue, description } = this.state
    this.props.onChange({
      ...this.triggerObject(),
      comparisonOperator: "GREATER_THAN",
      conditionValue: parseFloat(conditionValue),
      description,
    })
  }

  render() {
    let cancelLink
    let saveButtonLabel = "Save Trigger"
    const { appId, error, onClose, notifiers, warmupAndCooldown } = this.props

    if (this.props.blankSlate) {
      saveButtonLabel = "Save your first Trigger"
    } else {
      cancelLink = (
        <button id="cancelLink" onClick={onClose} className="c-button c-button--sm c-button--white">
          Cancel
        </button>
      )
    }
    const { hostname } = this.state

    return (
      <>
        <div className="relative h-full overflow-y-auto">
          <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
            <h2 className="c-heading text-base mb-0 text-gray-800">Host Disk I/O Trigger</h2>
          </div>
          {error}
          <form onSubmit={this.handleFormSubmit} acceptCharset="UTF-8" className="px-5">
            <p className="py-5">
              Receive alerts when there&apos;s too much or too little disk activity on your host.
            </p>
            <hr />

            <div className="c-form">
              <label className="c-form__label" htmlFor="metricName">
                When
              </label>
              <div className="c-form__element">
                <div className="c-select">
                  <select
                    onChange={(e) => this.setState({ metricName: e.target.value })}
                    id="metricName"
                    name="metricName"
                    value={this.state.metricName}
                    className="c-select__input required"
                  >
                    {METRIC_NAMES.map(({ key, value }) => (
                      <option key={"metricName-" + value} value={value}>
                        {key}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="c-form">
              <label className="c-form__label" htmlFor="conditionValue">
                Is greater than
              </label>
              <div className="c-form__element">
                <div className="c-textfield">
                  <input
                    className="c-textfield__input"
                    name="conditionValue"
                    type="number"
                    value={this.state.conditionValue}
                    min="0"
                    aria-label="Threshold value"
                    onChange={(e) => this.setState({ conditionValue: e.target.value })}
                  />
                  <span className="c-textfield__suffix">MB/min</span>
                </div>
              </div>
            </div>
            <hr />

            <TriggerFormHostname
              appId={appId}
              hostname={hostname}
              onChange={(newHostname) => this.setState({ hostname: newHostname })}
            />
            <hr />

            <TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
            <hr />

            <TriggerFormDescription
              description={this.state.description}
              onChange={(newDescription) => this.setState({ description: newDescription })}
            />
            <hr />

            <TriggerFormNotifiers notifiers={notifiers} />
            <hr />

            <ShareLink trigger={this.state} form={formName} warmupAndCooldown={warmupAndCooldown} />

            <div className="my-5">
              <button className="c-button c-button--sm mr-4">{saveButtonLabel}</button>
              {cancelLink}
            </div>
          </form>
        </div>
        <div className="w-256 p-5 bg-gray-100">
          <TriggerGraphPreview
            title="Host Disk I/O graph preview"
            lineLabel={TRIGGER_TYPES["HostDiskIO"].label}
            valueFormat={TRIGGER_TYPES["HostDiskIO"].formatter}
            appId={appId}
            trigger={this.triggerObject()}
          />
        </div>
      </>
    )
  }
}

export default withWarmupAndCooldown(withNotifiers(TriggerFormHostDiskIO))
