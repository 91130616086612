import PropTypes from "prop-types"
import React from "react"

import { TIMEFRAMES } from "../../../base/timeframes"

const TimeFrame = ({ timeframe, active, onClick }) => {
  return (
    <a
      className={`c-button-group__item c-button c-button--sm c-button--white cursor-pointer ${active ? "" : "c-button--white-inactive"
        }`}
      onClick={() => onClick({ timeframe: timeframe.key })}
    >
      {timeframe.label}
    </a>
  )
}

TimeFrame.propTypes = {
  timeframe: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

const TimeFrameSelector = ({ onChange, currentValue, timeframes = TIMEFRAMES }) => {
  return (
    <>
      {Object.values(timeframes).map((timeframe) => (
        <TimeFrame
          timeframe={timeframe}
          key={timeframe.key}
          onClick={onChange}
          active={currentValue.timeframe === timeframe.key}
        />
      ))}
    </>
  )
}
TimeFrameSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentValue: PropTypes.shape({
    timeframe: PropTypes.string,
  }),
  timeframes: PropTypes.object,
}

export default TimeFrameSelector
