import PropTypes from "prop-types"
import React from "react"

import UrlHelper from "../shared/url_helper"
import SideNavigation from "./side_navigation"
import TrialNotification from "./TrialNotification"

export const OrganizationNavigation = ({ params }) => {
  return (
    <div className="flex flex-col h-full px-3">
      <ul className="c-side-nav my-4 flex-grow">
        <SideNavigation
          id="nav-accounts"
          title="Applications"
          color="blue"
          icon="fa-browser"
          type="link"
          fullMatch
          href={UrlHelper.showOrganizationPath({
            organizationSlug: params.organizationSlug,
          })}
        />

        <SideNavigation
          id="nav-statuspages"
          title="Uptime pages"
          color="yellow"
          icon="fa-traffic-light"
          type="link"
          href={UrlHelper.statusPagesPath({ organizationSlug: params.organizationSlug })}
        />
      </ul>

      <ul className="c-side-nav sticky bottom-0 -mx-3 p-3 space-y-1 bg-white border-t">
        <li className="c-side-nav__section">
          {params?.organizationSlug && (
            <TrialNotification organizationSlug={params.organizationSlug} />
          )}
        </li>
        <li className="c-side-nav__section">
          <a
            className="c-side-nav__item"
            href={UrlHelper.editOrganizationPath({
              organizationSlug: params.organizationSlug,
            })}
          >
            <i className="c-side-nav__item-icon fa fa-cog fa-fw min-w-[17px]" />
            <span className="c-side-nav__item-title responsive-hidden">
              Organization settings
            </span>
          </a>
        </li>
      </ul>
    </div>
  )
}

OrganizationNavigation.propTypes = {
  params: PropTypes.shape({
    organizationSlug: PropTypes.string.isRequired,
  }),
}

export default OrganizationNavigation
