import PropTypes from "prop-types"
import React from "react"

import TooltipWithIcon from "../../../shared/TooltipWithIcon"

const VisualFormLineLabel = ({
  visual: { lineLabel },
  onChange,
  onLineLabelFocus,
  onLineLabelBlur
}) => {
  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="visual-line-label">
        Label of value in legend
        <TooltipWithIcon
          content={
            <>
              <p className="my-2">Define the format of the label of every line that is shown in the graph legend on hover.</p>
              <ul className="list-disc list-inside">
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">%name%</span>: the metric name.
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">%field%</span>: the field type of the metric.
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">%tag%</span>: every available tag key. The <span className="c-inline-code bg-purple py-px text-white">tag</span> name is dynamic (e.g. <span className="c-inline-code bg-purple py-px text-white">%namespace%</span>) and can differ per metric.
                  </span>
                </li>
              </ul>
            </>
          }
        />
      </label>
      <div className="c-form__element">
        <div className="c-textfield">
          <input
            type="text"
            id="visual-line-label"
            name="visual-line-label"
            className="c-textfield__input"
            placeholder="Label format"
            value={lineLabel}
            onChange={e => onChange({ lineLabel: e.target.value })}
            onFocus={onLineLabelFocus}
            onBlur={onLineLabelBlur}
          />
        </div>
      </div>
    </div>
  )
}

VisualFormLineLabel.propTypes = {
  visual: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onLineLabelFocus: PropTypes.func.isRequired,
  onLineLabelBlur: PropTypes.func.isRequired
}

export default VisualFormLineLabel
