import PropTypes from "prop-types"
import React from "react"

import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import SearchErrorRow from "./error_row"
import NoResults from "./NoResults"
import SearchPerformanceRow from "./performance_row"

const SearchIndexTable = ({ loading, results, params }) => {
  let rows

  if (loading) {
    rows = <LoadingAnimation message="Searching" />
  } else if (results.length === 0) {
    rows = <NoResults />
  } else {
    rows = results.map((result) => {
      const key = "search-" + result.id
      if (result.exception != null) {
        return <SearchErrorRow params={params} sample={result} key={key} />
      } else {
        return <SearchPerformanceRow params={params} sample={result} key={key} />
      }
    })
  }

  return rows
}

SearchIndexTable.propTypes = {
  params: PropTypes.object.isRequired,
  results: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default SearchIndexTable
