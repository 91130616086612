import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export default gql`
  mutation reorderVisuals($id: String!, $appId: String!, $visualIds: [String]!) {
    reorderVisuals(id: $id, appId: $appId, visualIds: $visualIds) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`
