import { findCommonPrefix } from "./findCommonPrefix"

export const generateCommonPrefixRecord = (timeseries) => {
  const labelsByTag = timeseries.keys.reduce((acc, key) => {
    const tags = key.tags ?? []
    tags.forEach(({ key: tagKey, value }) => {
      if (!acc[tagKey]) {
        acc[tagKey] = []
      }
      acc[tagKey].push(value)
    })
    return acc
  }, {})

  const commonPrefixes = {}

  for (const key in labelsByTag) {
    commonPrefixes[key] = findCommonPrefix(labelsByTag[key])
  }

  return commonPrefixes
}
