import gql from "graphql-tag"

export default gql`
  mutation createSavedVisualMutation(
    $appId: String!,
    $resolution: String!,
    $valueFormat: String,
    $valueInput: String,
    $title: String!,
    $series: [SerieInput]!
  ) {
    createSavedVisual(
      appId: $appId,
      resolution: $resolution,
      valueFormat: $valueFormat,
      valueInput: $valueInput,
      title: $title,
      series: $series
    ) {
        id
        pngUrl
        htmlUrl
        title
      }
  }
`
