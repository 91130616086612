import PropTypes from "prop-types"
import React from "react"

import { filteredErrors,hashCode } from "../../utils/react_utils"
const VALIDATION_ERROR_ID = "validation-errors"

const ValidationErrors = ({ title, error, hideTitle, className }) => {
  let content = <pre>{error.message}</pre>
  const errors = filteredErrors(error, "ValidationError")

  if (errors.length > 0) {
    content = (
      <ul>
        {errors.map((error) => (
          <li key={hashCode(error.extensions.field + error.message)}>
            {error.extensions.field && <strong>{error.extensions.field}:</strong>} {error.message}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div id={VALIDATION_ERROR_ID} className={`c-message c-message--red mb-4 ${className}`}>
      {!hideTitle && (
        <h2 className="c-heading">{title || "One or more errors prevented saving"}</h2>
      )}
      {content}
    </div>
  )
}

export const scrollValidationErrorsIntoView = () => {
  const element = document.getElementById(VALIDATION_ERROR_ID)
  if (element && typeof element.scrollIntoView === "function") {
    element.scrollIntoView({ behavior: "smooth" })
  }
}

ValidationErrors.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object,
  className: PropTypes.string,
  hideTitle: PropTypes.bool,
}

export default ValidationErrors
