import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export const mockData = {
  createDashboard: {
    __typename: "Dashboard",
    id: "bla",
    title: "",
    description: "",
    visuals: []
  }
}

export default gql `
  mutation createDashboardMutation(
    $appId: String!,
    $title: String!,
    $description: String
  ) {
    createDashboard(
      appId: $appId,
      title: $title,
      description: $description
    ) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`
