import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export const mockData = {
  importDashboard: {
    __typename: "Dashboard",
    id: "bla",
    title: "",
    description: "",
    visuals: [],
    graphs: []
  }
}

export default gql `
  mutation importDashboardMutation(
    $appId: String!,
    $json: String!
  ) {
    importDashboard(
      appId: $appId,
      json: $json
    ) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`
