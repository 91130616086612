import gql from "graphql-tag"

import AssigneeFragment from "./_assignee_fragment"
import ExceptionIntegrationsFragment from "./_exception_integrations_fragment"
import ExceptionSampleFragment from "./_exception_sample_fragment"

export default gql`
  fragment ExceptionIncident on ExceptionIncident {
    id
    number
    lastOccurredAt
    actionNames
    digests
    exceptionName
    notificationFrequency
    notificationThreshold
    state
    namespace
    firstBacktraceLine
    errorGroupingStrategy
    description
    severity
    assignees {
      ...AssigneeFragment
    }
    sample(id: $sampleId, timestamp: $timestamp, timerange: $timerange) {
      ... ExceptionSample
    }
    ... ExceptionIntegrationsFragment
  }
  ${ExceptionSampleFragment}
  ${ExceptionIntegrationsFragment}
  ${AssigneeFragment}
`
