import { cn } from "#Root/utils/cn"

const LoadingAnimation = ({ message, className = "" }) => {
  return (
    <div
      className={cn("h-full flex flex-col space-y-3 justify-center items-center", { className })}
    >
      <img
        src="/assets/illustrations/loading-logo-animation.svg"
        alt="Loading"
        className="animate-pulse w-16"
      />
      {message && <span className="text-gray-600">{message}</span>}
    </div>
  )
}

LoadingAnimation.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
}

export default LoadingAnimation
