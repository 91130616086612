import React from "react"

import { useRouter } from "#Root/hooks"

const withParamNavigator = function withParamNavigator(WrappedComponent) {
  const ParamNavigator = (props) => {
    const { currentPathWithParams } = useRouter()

    return <WrappedComponent currentPathWithParams={currentPathWithParams} {...props} />
  }

  ParamNavigator.displayName = `ParamNavigator(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`
  return ParamNavigator
}

export default withParamNavigator
