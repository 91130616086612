import { RouterContext } from "../../contexts/RouterContext"

const Button = ({
  disabled,
  color = "green",
  size = "sm",
  onClick,
  children = "Button Label",
  type = "button",
  loading,
  loadingText = "Saving...",
  native,
  href,
  className,
  iconLeft,
  iconRight,
  iconClassName,
  overrideClassName,
  ...rest
}) => {
  const router = React.useContext(RouterContext)
  const extraClasses = [className]

  const sizeMap = {
    xs: "c-button--xs",
    sm: "c-button--sm",
    md: "c-button--md",
    lg: "c-button--lg",
  }

  const colorMap = {
    white: "c-button--white",
    green: "c-button--green",
    red: "c-button--red",
    gray: "c-button--gray",
  }

  const disabledColorMap = {
    white: "c-button--white-disabled",
    green: "c-button--green-disabled",
    red: "c-button--red-disabled",
    gray: "c-button--gray-disabled",
  }

  if (disabled || loading) extraClasses.push("c-button--disabled")
  if ((disabled || loading) && color && disabledColorMap[color])
    extraClasses.push(disabledColorMap[color])
  if (color && colorMap[color]) extraClasses.push(colorMap[color])
  if (size && sizeMap[size]) extraClasses.push(sizeMap[size])

  const handleLinkClick = (event) => {
    // If link is disabled, stop anything from happening
    if (disabled === true || loading === true) {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    if (onClick) {
      if (onClick(event) === false) {
        event.preventDefault()
        event.stopPropagation()
        return
      }
    }

    // If the `native` prop is set to true,
    // or if someone presses cmd/alt or the middle mouse button
    // leave it up to the browser to handle the url
    // (e.g. don't use the router, usually opens in  new window)
    if (
      native !== true &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.shiftKey &&
      event.button !== 1
    ) {
      event.preventDefault()
      event.stopPropagation()

      router.navigate(href, { trigger: true })
    }
  }

  if (type === "link") {
    return (
      <a
        onClick={handleLinkClick}
        className={
          overrideClassName ||
          `ignore-old-css cursor-pointer c-button ${extraClasses.filter((c) => c).join(" ")}`
        }
        href={href}
        {...rest}
      >
        <span className="flex gap-x-2 items-center">
          <Icon icon={iconLeft} buttonSize={size} className={iconClassName} />
          {loading && loadingText}
          {!loading && children}
          <Icon icon={iconRight} buttonSize={size} className={iconClassName} />
        </span>
      </a>
    )
  } else {
    return (
      <button
        onClick={onClick}
        className={
          overrideClassName || `ignore-old-css c-button ${extraClasses.filter((c) => c).join(" ")}`
        }
        type={type}
        disabled={disabled || loading}
        {...rest}
      >
        <span className="flex gap-x-2 items-center">
          <Icon icon={iconLeft} buttonSize={size} className={iconClassName} />
          {loading && loadingText}
          {!loading && children}
          <Icon icon={iconRight} buttonSize={size} className={iconClassName} />
        </span>
      </button>
    )
  }
}

Button.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  href: PropTypes.string,
  native: PropTypes.bool,
  className: PropTypes.string,
  overrideClassName: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit", "reset", "link"]),
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconClassName: PropTypes.string,
}

const Icon = ({ icon, buttonSize, className }) => {
  if (!icon) return null
  const iconSizes = {
    xs: "text-xs",
    sm: "text-xs",
    md: "text-sm",
    lg: "text-md",
  }
  return <i className={`${icon} ${iconSizes[buttonSize]} ${className}`}></i>
}

Icon.propTypes = {
  buttonSize: PropTypes.string.isRequired,
  icon: PropTypes.string,
  className: PropTypes.string,
}

export default Button
