import { durationFields } from "#Root/utils/metric_keys"

export const DEFAULT_DURATION_OPTION = "MEAN"

const TriggerFormDurationField = ({ onChange, value }) => (
  <div className="c-select w-full">
    <select
      onChange={(e) => onChange({ field: e.target.value })}
      name="field"
      value={value}
      className="c-select__input required"
      aria-label="Duration Field"
    >
      {Object.entries(durationFields).map(([value, label]) => (
        <option key={"field-" + value} value={value}>
          {label}
        </option>
      ))}
    </select>
  </div>
)

TriggerFormDurationField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default TriggerFormDurationField
