import gql from "graphql-tag"

export default gql`
  fragment Trigger on Trigger {
    id
    name
    metricName
    field
    dashboardId
    kind
    previousTrigger {
      id
    }
    thresholdCondition {
      value
      comparisonOperator
    }
    notifiers {
      id
      name
      icon
    }
    tags {
      key
      value
    }
    user {
      id
      name
    }
    warmupDuration
    cooldownDuration
    description
    user {
      id
      name
    }
    noMatchIsZero
    format
    formatInput
  }
`
