import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import PropTypes from "prop-types"
import React from "react"
dayjs.extend(relativeTime)

const TimeSinceInWords = ({ time, excludePrefix = false }) => {
  const dayJsTime = dayjs(time)
  const timestamp = dayJsTime.format()
  const formattedTime = dayJsTime.fromNow(true)
  return (
    <time dateTime={timestamp} title={timestamp}>
      {excludePrefix ? "" : "since "}
      {formattedTime}
    </time>
  )
}

TimeSinceInWords.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  excludePrefix: PropTypes.bool,
}

export default TimeSinceInWords
