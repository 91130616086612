import { curveMonotoneX, line } from "d3-shape"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import cn from "#Root/utils/cn"

import { HOVER_LOW_OPACITY } from "../../constants"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

export const Line = ({ className, data, id, ...rest }) => {
  const { settings } = useChartSettingsContext()
  const { xScale, yScale, colors, domain } = useChartGraphContext()
  const { hoveredLine } = useChartHoverStateContext()
  const color = colors({ id, ...rest })

  xScale.domain(domain.x)
  yScale.domain(domain.y)

  const d3Line = line()
    .y((d) => yScale(d.y))
    .x((d) => xScale(d.x))
    .curve(curveMonotoneX)

  const path = d3Line(data)
  const style = {
    strokeWidth: settings.lineThickness,
    stroke: color,
    color: color,
    fill: "none",
    opacity: hoveredLine && hoveredLine != id ? HOVER_LOW_OPACITY : 1,
  }

  return <path d={path} className={cn("line", className)} style={style} />
}

Line.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default Line
