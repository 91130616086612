import PropTypes from "prop-types"
import React from "react"

import FormattedFileSize from "#Root/formatters/FormattedFileSize"
import FormattedPercent from "#Root/formatters/FormattedPercent"

import { findTagValue } from "../../utils/metric_keys"
import withParamNavigator from "../../wrappers/param_navigator"
import Duration from "../shared/duration"
import HumanNumber from "../shared/human_number"
import Link from "../shared/link"
import TriggerAdvanced from "../shared/triggers/advanced"
import TriggerDescription from "../shared/triggers/description"
import TriggerExceptionCount from "../shared/triggers/exception_count"
import TriggerExceptionRate from "../shared/triggers/exception_rate"
import TriggerHostCPUUsage from "../shared/triggers/host/cpu_usage"
import TriggerHostDiskIO from "../shared/triggers/host/disk_io"
import TriggerHostDiskUsage from "../shared/triggers/host/disk_usage"
import TriggerHostLoadAverage from "../shared/triggers/host/load_average"
import TriggerHostMemoryUsage from "../shared/triggers/host/memory_usage"
import TriggerHostNetworkTraffic from "../shared/triggers/host/network_traffic"
import TriggerHostSwapUsage from "../shared/triggers/host/swap_usage"
import TriggerPerformance from "../shared/triggers/performance"
import TriggerQueueTime from "../shared/triggers/queue_time"
import TriggerThroughput from "../shared/triggers/throughput"
import UrlHelper from "../shared/url_helper"
import AlertState from "./AlertState"
import ArchiveAlertButton from "./ArchiveAlertButton"

export class AlertsTableRow extends React.Component {
  static propTypes = {
    appId: PropTypes.string.isRequired,
    alert: PropTypes.object.isRequired,
    compact: PropTypes.bool,
    currentPathWithParams: PropTypes.func,
  }

  static defaultProps = {
    compact: false,
  }

  render() {
    const { alert, currentPathWithParams, compact, appId } = this.props
    const { id, trigger, tags, peakValue, mean } = alert

    let titleComponent
    let peakComponent = <HumanNumber number={peakValue} />
    let meanComponent = <HumanNumber number={mean} />

    const path = UrlHelper.anomalyIncidentAlertPagePath({ id })

    switch (trigger.kind) {
      case "ExceptionRate":
        titleComponent = <TriggerExceptionRate trigger={trigger} tags={tags} link={path} />
        peakComponent = <FormattedPercent value={peakValue} />
        meanComponent = <FormattedPercent value={mean} />
        break
      case "ExceptionCount":
        titleComponent = <TriggerExceptionCount trigger={trigger} tags={tags} link={path} />
        peakComponent = <HumanNumber number={peakValue} />
        meanComponent = <HumanNumber number={mean} />
        break
      case "Performance":
        titleComponent = <TriggerPerformance trigger={trigger} tags={tags} link={path} />
        peakComponent = <Duration val={peakValue} />
        meanComponent = <Duration val={mean} />
        break
      case "QueueTime":
        titleComponent = <TriggerQueueTime trigger={trigger} tags={tags} link={path} />
        peakComponent = <Duration val={peakValue} />
        meanComponent = <Duration val={mean} />
        break
      case "Throughput":
        titleComponent = <TriggerThroughput trigger={trigger} tags={tags} link={path} />
        break
      case "HostDiskUsage":
        titleComponent = <TriggerHostDiskUsage trigger={trigger} tags={tags} link={path} />
        peakComponent = <FormattedPercent value={Math.round(peakValue)} />
        meanComponent = <FormattedPercent value={Math.round(mean)} />
        break
      case "HostSwapUsage":
        titleComponent = <TriggerHostSwapUsage trigger={trigger} tags={tags} link={path} />
        if (findTagValue(trigger.tags, "state") === "usage") {
          peakComponent = <FormattedPercent value={Math.round(peakValue)} />
          meanComponent = <FormattedPercent value={Math.round(mean)} />
        } else {
          peakComponent = <FormattedFileSize value={peakValue} />
          meanComponent = <FormattedFileSize value={mean} />
        }
        break
      case "HostLoadAverage":
        titleComponent = <TriggerHostLoadAverage trigger={trigger} tags={tags} link={path} />
        break
      case "HostDiskIO":
        titleComponent = <TriggerHostDiskIO trigger={trigger} tags={tags} link={path} />
        peakComponent = <FormattedFileSize value={peakValue} />
        meanComponent = <FormattedFileSize value={mean} />
        break
      case "HostMemoryUsage":
        titleComponent = <TriggerHostMemoryUsage trigger={trigger} tags={tags} link={path} />
        if (findTagValue(trigger.tags, "state") === "usage") {
          peakComponent = <FormattedPercent value={Math.round(peakValue)} />
          meanComponent = <FormattedPercent value={Math.round(mean)} />
        } else {
          peakComponent = <FormattedFileSize value={peakValue} />
          meanComponent = <FormattedFileSize value={mean} />
        }
        break
      case "HostNetworkTraffic":
        titleComponent = <TriggerHostNetworkTraffic trigger={trigger} tags={tags} link={path} />
        peakComponent = <FormattedFileSize value={peakValue} />
        meanComponent = <FormattedFileSize value={mean} />
        break
      case "HostCPUUsage":
        titleComponent = <TriggerHostCPUUsage trigger={trigger} tags={tags} link={path} />
        peakComponent = <FormattedPercent value={Math.round(peakValue)} />
        meanComponent = <FormattedPercent value={Math.round(mean)} />
        break
      case "Advanced":
        titleComponent = <TriggerAdvanced trigger={trigger} tags={tags} link={path} />
        break
    }

    const triggerEditPath = currentPathWithParams({
      overlay: "triggerForm",
      triggerId: trigger.id,
      form: trigger.kind,
    })

    return (
      <div className="px-5 py-4 w-full h-full flex flex-col justify-between">
        <div className="flex mt-0 w-full" id={`tr-${id}`}>
          <div className="mr-auto truncate overflow-hidden">{titleComponent}</div>
          <div className="shrink-0" style={{ width: "210px" }}>
            <AlertState
              alert={alert}
              compact={compact}
              peakComponent={peakComponent}
              meanComponent={meanComponent}
            />
          </div>
          {!compact && (
            <div className="w-36 text-right">
              <div className="pb-px font-semibold">Peak: {peakComponent}</div>
              <div className="pt-px text-gray-700">Avg: {meanComponent}</div>
            </div>
          )}
          {!compact && (
            <div className="w-52 text-right">
              <Link href={triggerEditPath} className="c-button c-button--xs c-button--white mb-2">
                Edit trigger
              </Link>
              <ArchiveAlertButton appId={appId} id={alert.id} />
            </div>
          )}
        </div>
        {!compact && trigger.description && (
          <div className="w-full mt-4" id={`tr-desc-${id}`}>
            <TriggerDescription description={trigger.description} />
          </div>
        )}
      </div>
    )
  }
}

export default withParamNavigator(AlertsTableRow)
