import PropTypes from "prop-types"
import React from "react"

export class LoadingSpinner extends React.Component {
  static propTypes = {
    message: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = { message: "Loading data", className: null }

  render() {
    return (
      <p className={this.props.className ? `${this.props.className} loader` : "loader"}>
        <img src="/assets/puff.svg" /> {this.props.message}
      </p>
    )
  }
}

export default LoadingSpinner
