import dayjs from "dayjs"

import DateRangePicker from "#Root/ui/DateRangePicker"

const DateFilter = ({ onDateChange, params }) => {
  return (
    <div className="c-button-group">
      <a
        className={`
      c-button-group__item c-button c-button--sm c-button--white
      cursor-pointer
      ${params.timeframe == null || params.timeframe == "all" ? "" : "c-button--white-inactive"}
    `}
        onClick={() =>
          onDateChange({
            timeframe: "all",
            from: null,
            to: null,
          })
        }
      >
        All time
      </a>
      <DateRangePicker
        onChange={onDateChange}
        value={{
          timeframe: params.timeframe,
          from: params.from ? dayjs(params.from) : null,
          to: params.to ? dayjs(params.to) : null,
        }}
      />
    </div>
  )
}

DateFilter.propTypes = {
  onDateChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
}

export default DateFilter
