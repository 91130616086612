import PropTypes from "prop-types"
import React from "react"
import { graphql } from "react-apollo"

import AppInfoQuery from "../graphql/app_info_query"

const withAppInfo = function withAppInfo(WrappedComponent) {
  class WithAppInfo extends React.Component {
    constructor(props) {
      super(props)
    }

    render() {
      let appInfo
      const { appInfoData, ...props } = this.props
      if (appInfoData && !appInfoData.loading) {
        const hasHerokuHostMetrics = includesHerokuHostMetrics(appInfoData.app)
        appInfo = {
          hasHerokuHostMetrics,
          ...appInfoData.app,
          viewer: appInfoData.viewer,
          refetch: appInfoData.refetch,
        }
      }
      return <WrappedComponent appInfo={appInfo} {...props} />
    }
  }

  WithAppInfo.displayName = `WithAppInfo(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`
  WithAppInfo.propTypes = {
    appInfoData: PropTypes.shape({
      loading: PropTypes.bool,
      app: PropTypes.object,
      viewer: PropTypes.object,
      refetch: PropTypes.func,
    }),
  }

  return graphql(AppInfoQuery, {
    name: "appInfoData",
    skip: (args) => shouldSkip(args),
    options: ({ params, appId }) => ({
      variables: {
        appId: appId || params.appId,
      },
    }),
  })(WithAppInfo)
}

export function shouldSkip(args) {
  const { params, appId } = args
  return (!params || !params.appId) && !appId
}

// Extracting the methods here so we can test them
export function includesHerokuHostMetrics(appInfo) {
  return (
    appInfo && (appInfo.platforms.includes("logplexv2") || appInfo.platforms.includes("logplex"))
  )
}

export default withAppInfo
