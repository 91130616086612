import Link from "#Components/shared/link"
import NamespaceLabel from "#Components/shared/namespace_label"
import UrlHelper from "#Components/shared/url_helper"
import { Column,Table } from "#Components/Table"
import { DurationCell, ExceptionRateCell,NumberCell } from "#Components/TableCell"
import { usePaginatedMetricsListQuery } from "#Root/hooks/usePaginatedQuery"
import { aggregateMetricListToObjects } from "#Root/utils/metric_list_aggregator"
import { maxField } from "#Root/utils/table"

const paramsToQueryVariables = (params, namespaces) => {
  const query = []
  for (const namespace of namespaces) {
    query.push({
      name: "transaction_duration",
      tags: [
        { key: "hostname", value: "*" },
        { key: "namespace", value: namespace },
      ],
      fields: [
        { field: "COUNT", aggregate: "SUM" },
        { field: "P90", aggregate: "AVG" },
        { field: "MEAN", aggregate: "AVG" },
      ],
    })

    query.push({
      name: "transaction_exception_rate",
      tags: [
        { key: "hostname", value: "*" },
        { key: "namespace", value: namespace },
      ],
      fields: [{ field: "GAUGE", aggregate: "AVG" }],
    })
  }
  return {
    appId: params.appId,
    start: params.td_start,
    end: params.td_end,
    query: query,
  }
}

const TimeDetectiveHostsPage = ({ params, namespaces }) => {
  const { pagination, loading, error, data } = usePaginatedMetricsListQuery(
    paramsToQueryVariables(params, namespaces)
  )
  const map = {
    transaction_duration: { count: "throughput", p90: "p90", mean: "mean" },
    transaction_exception_rate: { gauge: "exRate" },
  }
  const hosts = aggregateMetricListToObjects(data?.app?.metrics?.list?.rows || [], map)
  const maxValues = {
    mean: maxField(hosts, "mean"),
    p90: maxField(hosts, "p90"),
    throughput: maxField(hosts, "throughput"),
    exRate: maxField(hosts, "exRate"),
  }

  /* eslint-disable react/prop-types */
  const NameCell = ({ rowData }) => (
    <div className="c-virtual-table__row-cell-content max-w-fit">
      <Link
        className="text-blue truncate"
        href={UrlHelper.hostUsageInspectPath(
          { hostnames: `${rowData.namespace}:${rowData.hostname}` },
          ["namespaces"]
        )}
        title={rowData.hostname}
      />
      <NamespaceLabel className="max-w-fit mt-1" namespace={rowData.namespace} />
    </div>
  )

  return (
    <Table
      data={hosts}
      title="Host usage in this timeframe"
      initialSortKey="throughput"
      loading={loading}
      error={error}
      maxValues={maxValues}
      pagination={pagination}
      ignoreContainerHeight
    >
      <Column dataKey="hostname" flexgrow={1} title="Name" cellRenderer={NameCell} sortable />
      <Column dataKey="mean" width="20%" title="Mean" cellRenderer={DurationCell} sortable />
      <Column dataKey="p90" width="20%" title="Percentile" cellRenderer={DurationCell} sortable />
      <Column
        dataKey="throughput"
        width="20%"
        title="Throughput"
        cellRenderer={NumberCell}
        sortable
      />
      <Column
        dataKey="exRate"
        width="20%"
        title="Error rate"
        cellRenderer={ExceptionRateCell}
        sortable
      />
    </Table>
  )
}

TimeDetectiveHostsPage.propTypes = {
  params: PropTypes.object.isRequired,
  namespaces: PropTypes.array.isRequired,
}

export default TimeDetectiveHostsPage
