import gql from "graphql-tag"

export default gql `
  query MetricsListQuery(
    $appId:     String!,
    $start:     DateTime,
    $end:       DateTime,
    $timeframe: TimeframeEnum,
    $query:     [MetricAggregation!]!
    $limit:     Int,
    $offset:    Int
  ){
    app(id: $appId) {
      id
      metrics {
        list(start: $start, end: $end, query: $query, timeframe: $timeframe, limit: $limit, offset: $offset) {
          start
          end
          rows {
            name
            tags {
              key
              value
            }
            fields {
              key
              value
            }
          }
        }
      }
    }
  }
`
