export const COMPARISON_OPERATORS = [
  {
    key: "More than",
    value: "GREATER_THAN",
  },
  {
    key: "Less than",
    value: "LESS_THAN",
  },
  {
    key: "Equal to",
    value: "EQUAL",
  },
  {
    key: "Not equal to",
    value: "NOT_EQUAL",
  },
  {
    key: "More than or equal to",
    value: "GREATER_THAN_OR_EQUAL",
  },
  {
    key: "Less than or equal to",
    value: "LESS_THAN_OR_EQUAL",
  },
]

const TriggerFormComparisonOperators = ({ onChange, value }) => (
  <div className="c-select">
    <select
      onChange={(e) => onChange({ comparisonOperator: e.target.value })}
      id="comparisonOperator"
      name="comparisonOperator"
      value={value}
      aria-label="Comparison Operator"
      className="c-select__input required"
    >
      {COMPARISON_OPERATORS.map(({ key, value }) => (
        <option key={"metricName-" + value} value={value}>
          {key}
        </option>
      ))}
    </select>
  </div>
)
TriggerFormComparisonOperators.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export default TriggerFormComparisonOperators
