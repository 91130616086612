import gql from "graphql-tag"

export default gql `
  fragment Alert on Alert {
    id
    state
    message
    metricDigest
    createdAt
    openedAt
    resolvedAt
    closedAt
    timeframeStartAt
    timeframeEndAt
    lastValue
    peakValue
    mean
    tags {
      key
      value
    }
  }
`
