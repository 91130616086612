import gql from "graphql-tag"

import TriggerFragment from "./_trigger_fragment"
import ViewerFragment from "./_viewer_fragment"

export const TriggersQuery = gql`
  query TriggersQuery($appId: String!) {
    app(id: $appId) {
      id
      triggers {
        ...Trigger
      }
    }
    viewer {
      ...Viewer
    }
  }
  ${TriggerFragment}
  ${ViewerFragment}
`

export default TriggersQuery
