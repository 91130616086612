const Header = ({ title, children }) => {
  return (
    <div className="c-box__header sticky z-30 top-0 bg-white h-14 flex justify-between items-center left-0">
      <h2>{title}</h2>
      {children}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array, PropTypes.object]),
}

export default Header
