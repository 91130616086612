const ErrorState = ({ height }) => {
  return (
    <div className="flex items-center justify-center flex-col" style={{ height }}>
      Uh oh! There was an error :(
    </div>
  )
}

ErrorState.propTypes = {
  height: PropTypes.number,
}

export default ErrorState
