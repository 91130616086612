import PropTypes from "prop-types"
import React from "react"

import TriggerFormComparisonOperators from "#Components/triggers/form/fields/ComparisonOperators"
import TriggerFormDescription from "#Components/triggers/form/fields/Description"
import TriggerFormNamespace, { findNamespace } from "#Components/triggers/form/fields/Namespace"
import NoMatchIsZero from "#Components/triggers/form/fields/NoMatchIsZero"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown"
import { NULL_AS_ZERO_COMPARISON_OPERATORS, TRIGGER_TYPES } from "#Root/constants/Triggers"

import withNotifiers, { notifiersPropType } from "../../../wrappers/notifiers"
import withWarmupAndCooldown, {
  warmupAndCooldownPropType,
} from "../../../wrappers/warmup_and_cooldown"
import TriggerGraphPreview from "../GraphPreview"
import ShareLink from "./ShareLink"

const formName = "Throughput"
export class TriggerFormThroughput extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    blankSlate: PropTypes.bool,
    trigger: PropTypes.object,
    appId: PropTypes.string.isRequired,
    namespaces: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    notifiers: notifiersPropType.isRequired,
    warmupAndCooldown: warmupAndCooldownPropType.isRequired,
    error: PropTypes.object,
  }
  static form = formName
  static params = {
    namespace: "tag",
    comparisonOperator: "field",
    conditionValue: "field",
  }

  constructor(props) {
    super(props)
    // If a trigger is given as a param, use the namespace/conditionValue
    if (props.trigger && props.trigger.id) {
      this.state = {
        namespace: props.trigger.tags[0].value,
        conditionValue: props.trigger.thresholdCondition.value,
        comparisonOperator: props.trigger.thresholdCondition.comparisonOperator,
        description: props.trigger.description,
        noMatchIsZero: props.trigger.noMatchIsZero,
      }

      return
    } else {
      const { params } = this.props
      this.state = {
        namespace: findNamespace(params.namespace, props.namespaces),
        comparisonOperator: params.comparisonOperator || "LESS_THAN",
        conditionValue: params.conditionValue || 100,
        description: params.description || "",
        noMatchIsZero: false,
      }

      return
    }
  }

  triggerObject() {
    return {
      metricName: "transaction_duration",
      tags: [{ key: "namespace", value: this.state.namespace }],
      field: "COUNT",
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    this.props.onChange({
      ...this.triggerObject(),
      comparisonOperator: this.state.comparisonOperator,
      conditionValue: parseFloat(this.state.conditionValue),
      noMatchIsZero: NULL_AS_ZERO_COMPARISON_OPERATORS.includes(this.state.comparisonOperator)
        ? this.state.noMatchIsZero
        : false,
      description: this.state.description,
    })
  }

  render() {
    let cancelLink
    let saveButtonLabel = "Save Trigger"
    const { appId, error, params, namespaces, onClose, notifiers, warmupAndCooldown } = this.props

    if (this.props.blankSlate) {
      saveButtonLabel = "Save your first Trigger"
    } else {
      cancelLink = (
        <button id="cancelLink" onClick={onClose} className="c-button c-button--sm c-button--white">
          Cancel
        </button>
      )
    }

    return (
      <>
        <div className="relative h-full overflow-y-auto">
          <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
            <h2 className="c-heading text-base mb-0 text-gray-800">Throughput Trigger</h2>
          </div>
          {error}
          <form onSubmit={this.handleFormSubmit} acceptCharset="UTF-8" className="px-5">
            <p className="py-5">Receive alerts when the throughput of a namespace changes.</p>
            <hr />

            <TriggerFormNamespace
              namespace={this.state.namespace}
              namespaceParam={params.namespace}
              namespaces={namespaces}
              onChange={(namespace) => this.setState({ namespace })}
            />
            <hr />

            <div className="c-form">
              <label className="c-form__label">Alert me when the throughput is</label>
              <div className="c-form__element flex">
                <div className="w-1/2 mr-2">
                  <TriggerFormComparisonOperators
                    onChange={(e) => this.setState({ comparisonOperator: e.comparisonOperator })}
                    value={this.state.comparisonOperator}
                  />
                </div>
                <div className="w-1/2 ml-2">
                  <div className="c-textfield">
                    <input
                      className="c-textfield__input"
                      name="conditionValue"
                      type="number"
                      value={this.state.conditionValue}
                      min="1"
                      aria-label="Threshold value"
                      onChange={(e) => this.setState({ conditionValue: e.target.value })}
                    />
                    <span className="c-textfield__suffix">req/min</span>
                  </div>
                </div>
              </div>
            </div>

            {NULL_AS_ZERO_COMPARISON_OPERATORS.includes(this.state.comparisonOperator) && (
              <NoMatchIsZero
                value={this.state.noMatchIsZero}
                onChange={(value) => this.setState({ noMatchIsZero: value })}
              />
            )}

            <TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
            <hr />

            <TriggerFormDescription
              description={this.state.description}
              onChange={(newDescription) => this.setState({ description: newDescription })}
            />
            <hr />

            <TriggerFormNotifiers notifiers={notifiers} />
            <hr />

            <ShareLink trigger={this.state} form={formName} warmupAndCooldown={warmupAndCooldown} />

            <div className="my-5">
              <button className="c-button c-button--sm mr-4">{saveButtonLabel}</button>
              {cancelLink}
            </div>
          </form>
        </div>
        <div className="w-256 p-5 bg-gray-100">
          <TriggerGraphPreview
            title="Throughput graph preview"
            lineLabel={TRIGGER_TYPES["Throughput"].label}
            valueFormat={TRIGGER_TYPES["Throughput"].formatter}
            appId={appId}
            trigger={this.triggerObject()}
          />
        </div>
      </>
    )
  }
}

export default withWarmupAndCooldown(withNotifiers(TriggerFormThroughput))
