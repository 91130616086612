export const DEFAULT_CHART_HEIGHT = 250
export const X_AXIS_HEIGHT = 40
export const Y_AXIS_WIDTH = 60
export const MARKERS_HEIGHT = 50
export const MIN_HOVER_PANEL_WIDTH = 256
export const HOVER_LOW_OPACITY = 0.2
export const BAR_OPACITY = 0.4
export const AREA_OPACITY = 0.3
export const BAR_EFFECTIVE_WIDTH = 0.8

export const HOVER_PANEL_Z_INDEX = 40
export const LINE = "line"
export const AREA = "area"
export const AREA_RELATIVE = "area-relative"
export const BAR = "bar"
export const SPARKLINE = "sparkline"
export const STACKED_RENDERS = [AREA, BAR, SPARKLINE, AREA_RELATIVE]
export const RELATIVE_RENDERS = [AREA_RELATIVE]

export const DATA_FIELDS = {
  P90: "p90",
  P95: "p95",
  MEAN: "mean",
  GAUGE: "gauge",
  COUNT: "count",
  COUNTER: "counter",
}
export const DISPLAY_FIELDS = {
  P90: "90th",
  P95: "95th",
  MEAN: "mean",
  GAUGE: "gauge",
  COUNT: "count",
  COUNTER: "counter",
}
