import gql from "graphql-tag"

import VisualFragment from "./_visual_fragment"

export default gql `
  fragment Dashboard on Dashboard {
    id
    title
    description
    visuals {
      ...Visual
    }
  }
  ${VisualFragment}
`
