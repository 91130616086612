import { flexRender } from "@tanstack/react-table"
import { useVirtualizer } from "@tanstack/react-virtual"

import { useTableContext } from "#Root/contexts/TableContext"
import cn from "#Root/utils/cn"

const VirtualizedRows = ({ narrow = false }) => {
  const { tableContainerRef, table, error, dataSize } = useTableContext()

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    overscan: 10,
    getScrollElement: () => tableContainerRef?.current,
    estimateSize: () => 45,
  })

  const items = rowVirtualizer.getVirtualItems()
  const paddingTop = items.length > 0 ? items?.[0]?.start || 0 : 0
  const paddingBottom =
    items.length > 0 ? rowVirtualizer.getTotalSize() - (items?.[items.length - 1]?.end || 0) : 0

  if (error) return null
  if (dataSize === 0) return null
  return (
    <tbody>
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {items.map((virtualRow) => {
        const row = rows[virtualRow.index]

        return (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => {
              return (
                <td
                  key={cell.id}
                  className={cn("c-table__td", {
                    "c-table__td--narrow": narrow,
                    "text-center": cell.column.id === "selection",
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
          </tr>
        )
      })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </tbody>
  )
}

VirtualizedRows.propTypes = {
  narrow: PropTypes.bool,
}

export default VirtualizedRows
