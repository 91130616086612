/* eslint-disable react/prop-types */
import PropTypes from "prop-types"
import React, { useCallback, useRef } from "react"
import BaseTable, { AutoResizer,Column } from "react-base-table"
import ResizeObserver from "resize-observer-polyfill"

import HoverCard from "#Root/ui/HoverCard"
import LoadingSpinner from "#Root/ui/LoadingSpinner"

import useTableSort from "../../hooks/useTableSort"
import CheckBox from "../shared/check_box"

const resizeObserver = window.ResizeObserver || ResizeObserver

const Table = ({
  title,
  data,
  loading,
  initialSortKey,
  pagination,
  error,
  initialSortOrder,
  mapFunction,
  emptyRenderer,
  loadingRenderer,
  errorRenderer,
  children,
  selectable,
  selectedLimit,
  selectedRows,
  onSelectedChange,
  selectedAll,
  onSelectedAll,
  useDefaultSort,
  ignoreContainerHeight,
  externalRef,
  ...props
}) => {
  const childrenArray =
    children?.length > 1 ? Array.from(children || []).filter((n) => n) : [children]
  const columns = childrenArray.filter((child) => child?.props?.__TYPE == "Column")
  const footer = childrenArray.filter((child) => child?.props?.__TYPE == "Footer")[0]

  const { currentSortColumn, currentSortDirection, sortColumn, sortArray } = useTableSort({
    initialSortColumn: initialSortKey,
    initialSortDirection: initialSortOrder || "desc",
  })

  const tableNode = useRef()

  const tableRef = useCallback(
    (node) => {
      if (node !== null && !tableNode.current) {
        tableNode.current = node
        if (externalRef) externalRef.current = node

        const removeScrollbarPadding = () => {
          const headerGroup = document.querySelectorAll("[role=rowgroup]")
          const tables = document.querySelectorAll(".c-virtual-table__table")

          for (const headerNode of headerGroup) {
            headerNode.style.width = "100%"
          }

          for (const table of tables) {
            const headerGroup = table.querySelector(".header-columns")
            const body = table.querySelector(".c-virtual-table__body")

            const scrollbarWidth = getScrollbarWidth(body)
            headerGroup.style.paddingRight = `${Math.max(scrollbarWidth - 1, 0)}px`
          }
        }

        const updateAutosizerHeight = () => {
          const table = tableNode.current.tableNode
          const autosizer = tableNode.current.tableNode?.parentElement

          if (autosizer) {
            autosizer.style.height = table.style.height
            autosizer.style.marginBottom = "30px"
          }
        }

        const fixTableLayout = () => {
          removeScrollbarPadding()
          updateAutosizerHeight()
        }

        const observer = new resizeObserver(fixTableLayout)
        observer.observe(tableNode.current.tableNode)
        fixTableLayout()
      }
    },
    [externalRef]
  )

  const renderColumns = (children) => {
    const columns = children?.map((child) => {
      const { dataKey, title, width, ...rest } = child?.props
      return (
        <Column
          key={dataKey}
          dataKey={dataKey}
          title={title}
          width={width || 200}
          flexShrink={1}
          {...rest}
        />
      )
    })

    return columns
  }

  const onColumnSort = (newSort) => {
    newSort.key !== currentSortColumn ? (newSort.order = "desc") : null
    sortColumn(newSort.key, newSort.order)
  }

  const getScrollbarWidth = (node) => node.offsetWidth - node.clientWidth

  const HeaderRenderer = ({ cells }) => (
    <div className="flex flex-col w-full">
      {React.isValidElement(title) ? (
        title
      ) : (
        <div className="c-virtual-table__title">
          <h2 className="py-px">{title}</h2>
        </div>
      )}
      <div className="flex flex-row items-center border-x header-columns">
        {cells.map((cell) => cell)}
      </div>
    </div>
  )

  const TableHeaderCell = (data) => {
    const { selectedAll, onSelectedAll } = data.container.props
    const { columnIndex } = data
    const { title, sortable, tooltip } = data.column
    const iconDirection = currentSortDirection === "asc" ? "up" : "down"
    const iconClassName =
      data.column.key === currentSortColumn
        ? `far fa-angle-${iconDirection} ml-2`
        : "far fa-angle-down ml-2 opacity-0 group-hover:opacity-50"

    const shouldDisplaySortIndicator = sortable && !loading && !error
    const cursorClassName = shouldDisplaySortIndicator ? "cursor-pointer" : ""

    const shouldAddCheckbox =
      columnIndex === 0 && selectable && !selectedLimit && !loading && !error
    const shouldAddPadding = !selectable ? "" : ""

    if (shouldAddCheckbox) {
      return (
        <div className="c-table__row-cell-content w-full border-0 flex flex-col max-w-fit items-center">
          <CheckBox checked={selectedAll} onChange={() => onSelectedAll(!selectedAll)} />
        </div>
      )
    }

    if (tooltip) {
      return (
        <div className="group contents">
          <HoverCard openDelay={0} closeDelay={100}>
            <HoverCard.Trigger asChild>
              <span
                className={`c-virtual-table__header-cell-content ${cursorClassName} ${shouldAddPadding} no-underline`}
              >
                {title}
              </span>
            </HoverCard.Trigger>
            <HoverCard.Content side="top" asTooltip>
              {tooltip}
            </HoverCard.Content>
          </HoverCard>
          {shouldDisplaySortIndicator && <i className={`${iconClassName}`} />}
        </div>
      )
    }

    return (
      <div className="group contents">
        <span
          className={`c-virtual-table__header-cell-content ${cursorClassName} ${shouldAddPadding}`}
        >
          {title}
        </span>
        {shouldDisplaySortIndicator && <i className={`${iconClassName}`} />}
      </div>
    )
  }

  const CheckBoxCell = ({ container, rowData }) => {
    const { selectedRows, onSelectedChange, selectedLimit } = container.props
    const { id } = rowData

    return (
      <div className="w-full border-0 flex flex-col max-w-fit items-center">
        <CheckBox
          checked={selectedRows?.includes(id)}
          onChange={() => onSelectedChange(id)}
          disabled={
            selectedLimit
              ? selectedRows?.length >= selectedLimit && !selectedRows?.includes(id)
              : false
          }
        />
      </div>
    )
  }

  const EmptyState = () => (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <i className="text-2xl mb-4 text-gray-600 fa fa-search" />
      <h2 className="c-heading--base">No data found</h2>
      <p className="text-gray-700 text-center">We haven&apos;t found any data for this timeframe</p>
    </div>
  )

  const ErrorState = () => (
    <div className="flex flex-col w-full h-full justify-center items-center">
      <i className="text-2xl mb-4 text-gray-600 fa fa-exclamation-triangle" />
      <h2 className="c-heading--base">Something is wrong</h2>
      <p className="text-gray-700 text-center">
        We were unable to load this table. Please try again or contact our support if the problem
        persists.
      </p>
    </div>
  )

  const LoadingState = () => (
    <div className="flex items center justify-center h-48">
      <LoadingSpinner />
    </div>
  )

  const PaginatedLoadingState = () => (
    <div className="flex items center justify-center h-48">
      <LoadingSpinner text={`Loaded ${pagination.currentPage} of ${pagination.totalPages}`} />
    </div>
  )

  const rendererMap = {
    loading: loadingRenderer || (pagination?.paginated ? PaginatedLoadingState : LoadingState),
    error: errorRenderer || ErrorState,
    empty: emptyRenderer || EmptyState,
  }

  const Table = (width, height, type) => {
    if (type === "data") {
      const mappedData = mapFunction ? (data || []).map((data) => mapFunction(data)) : data || []
      const sortedData = useDefaultSort === false ? mappedData : sortArray(mappedData)

      return (
        <BaseTable
          ref={tableRef}
          classPrefix="c-virtual-table"
          maxHeight={height - 1}
          width={width - 1}
          data={sortedData}
          sortBy={{ order: currentSortDirection, key: currentSortColumn }}
          onColumnSort={onColumnSort}
          rowHeight={66}
          headerHeight={93}
          components={{ TableHeaderCell, SortIndicator: () => null }}
          footerHeight={footer ? 50 : 0}
          footerRenderer={footer}
          selectedLimit={selectedLimit}
          selectedRows={selectedRows}
          onSelectedChange={onSelectedChange}
          onSelectedAll={onSelectedAll}
          selectedAll={selectedAll}
          headerRenderer={HeaderRenderer}
          {...props}
        >
          {selectable && (
            <Column
              className="justify-start"
              title=""
              dataKey="id"
              key="id"
              width={50}
              flexShrink={0}
              cellRenderer={CheckBoxCell}
            />
          )}
          {renderColumns(columns)}
        </BaseTable>
      )
    } else {
      const renderer = rendererMap[type]

      return (
        <BaseTable
          ref={tableRef}
          classPrefix="c-virtual-table"
          width={width - 2}
          height={300}
          emptyRenderer={renderer}
          rowHeight={66}
          headerHeight={93}
          components={{ TableHeaderCell, SortIndicator: () => null }}
          headerRenderer={HeaderRenderer}
          {...props}
        >
          {renderColumns(columns)}
        </BaseTable>
      )
    }
  }

  const containerWrapper = (children) =>
    ignoreContainerHeight ? children : <div className="h-full overflow-hidden">{children}</div>

  return containerWrapper(
    <AutoResizer>
      {({ width, height }) => {
        if (error) {
          return Table(width, height, "error")
        } else if (loading) {
          return Table(width, height, "loading")
        } else if (data?.length > 0 && !loading) {
          return Table(width, height, "data")
        } else {
          return Table(width, height, "empty")
        }
      }}
    </AutoResizer>
  )
}

Table.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  initialSortKey: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    paginated: PropTypes.bool,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  error: PropTypes.object,
  mapFunction: PropTypes.func,
  initialSortOrder: PropTypes.string,
  children: PropTypes.node,
  emptyRenderer: PropTypes.node,
  loadingRenderer: PropTypes.node,
  errorRenderer: PropTypes.node,
  selectable: PropTypes.bool,
  selectedRows: PropTypes.array,
  onSelectedChange: PropTypes.func,
  selectedLimit: PropTypes.number,
  selectedAll: PropTypes.bool,
  onSelectedAll: PropTypes.func,
  ignoreContainerHeight: PropTypes.bool,
}

export default Table
