import { area, curveMonotoneX, line } from "d3-shape"

import { AREA_OPACITY, HOVER_LOW_OPACITY } from "#Root/ui/ASChart/constants"
import { useChartGraphContext } from "#Root/ui/ASChart/contexts/GraphContext"
import { useChartHoverStateContext } from "#Root/ui/ASChart/contexts/HoverStateContext"
import cn from "#Root/utils/cn"

export const Sparkline = ({ className, data, id, ...rest }) => {
  const { xScale, yScale, colors, domain } = useChartGraphContext()
  const { hoveredLine } = useChartHoverStateContext()
  const color = colors({ id, ...rest })

  const lineStyle = {
    stroke: color,
    color: color,
    fill: "none",
    opacity: hoveredLine && hoveredLine != id ? HOVER_LOW_OPACITY : 1,
  }

  xScale.domain(domain.x)
  yScale.domain(domain.y)

  const d3Line = line()
    .y((d) => yScale(d.y1))
    .x((d) => xScale(d.x))
    .curve(curveMonotoneX)

  const linePath = d3Line(data)

  const areaStyle = {
    stroke: color,
    color: color,
    fill: color,
    opacity: hoveredLine && hoveredLine == id ? 1 : AREA_OPACITY,
  }

  const d3Area = area()
    .y1((d) => yScale(d.y1))
    .y0((d) => yScale(d.y0))
    .x((d) => xScale(d.x))
    .curve(curveMonotoneX)
  const areaPath = d3Area(data)

  return (
    <>
      <path d={linePath} className={cn("line", className)} style={lineStyle} />
      <path d={areaPath} className={cn("line", className)} style={areaStyle} />
    </>
  )
}

Sparkline.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default Sparkline
