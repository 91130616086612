import gql from "graphql-tag"

export default gql`
  query statusPagesQuery($organizationSlug: String!) {
    organization(slug: $organizationSlug) {
      id
      slug
      name
      statusPages {
        id
        title
        description
        hasSsl
        forceSsl
        hostname
        state
        updates {
          id
          title
          description
          state
          createdAt
        }
      }
    }
  }
`
