import gql from "graphql-tag"

import StatusPageFragment from "./_statusPageFragment"

export default gql`
  mutation createStatusPageMutation(
    $organizationSlug: String!,
    $statusPage: CreateStatusPageInput!
  ) {
    createStatusPage(
      organizationSlug: $organizationSlug,
      statusPage: $statusPage
    ) {
      ...StatusPage
    }
  }
  ${StatusPageFragment}
`
