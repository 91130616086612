import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

const Animation = ({ filename, disableHover, autoPlay, poster, className }) => {
  const videoRef = useRef()

  const playVideo = () => {
    videoRef.current.play()
  }

  const stopVideo = () => {
    videoRef.current.pause()
  }

  useEffect(() => {
    if (videoRef.current && !disableHover) {
      videoRef.current.addEventListener("mouseover", playVideo)
      videoRef.current.addEventListener("mouseout", stopVideo)
    }
  }, [videoRef, disableHover])

  return (
    <video
      loop
      preload="true"
      src={filename}
      ref={videoRef}
      autoPlay={autoPlay}
      poster={poster}
      className={className}
      data-testid="video"
    />
  )
}

Animation.propTypes = {
  filename: PropTypes.string.isRequired,
  disableHover: PropTypes.bool,
  autoPlay: PropTypes.bool,
  poster: PropTypes.string,
  className: PropTypes.string,
}

export default Animation
