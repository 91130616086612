import PropTypes from "prop-types"
import React, { useContext } from "react"

import NumberHelpers from "../../base/number_helpers"
import FileSizeCalculator from "../../components/shared/file_size_calculator"
import { FormatterContext } from "../../contexts/FormatterContext"

const FormattedFileSize = ({ value, format }) => {
  const formatter = useContext(FormatterContext)
  const separator = formatter.number === "DC" ? "," : "."
  const delimiter = formatter.number === "DC" ? "." : ","

  // The size in the agent is in megabytes, convert it back
  // to bytes for the formatter we're using.
  const formattedSize = (() => {
    switch (format) {
      case "bit":
        return value / 8
      case "byte":
        return value
      case "kilobit":
        return (value / 8) * 1024
      case "kilobyte":
        return value * 1024
      default:
        return FileSizeCalculator.mebibytesToBytes(value)
    }
  })()

  const formattedNumber = NumberHelpers.number_to_human_size(formattedSize, {
    separator,
    delimiter,
    strip_empty_fractional_parts: true,
    strip_insignificant_zeros: true,
    precision: 2,
    significant: false,
  })

  return <span>{formattedNumber}</span>
}

FormattedFileSize.propTypes = {
  value: PropTypes.number.isRequired,
  format: PropTypes.string,
}

FormattedFileSize.defaultProps = {
  format: "megabyte",
}

export default FormattedFileSize
