import { useQuery } from "react-apollo"

import Link from "#Components/shared/link"
import UrlHelper from "#Components/shared/url_helper"
import { useRouter } from "#Root/hooks"
import Dropdown from "#Root/ui/Dropdown"
import LoadingSpinner from "#Root/ui/LoadingSpinner/LoadingSpinner"

import EnvironmentLabel from "./environment_label"

export const ORGANIZATION_QUERY = gql`
  query OrganizationQuery($slug: String!) {
    organization(slug: $slug) {
      id
      name
      slug
      apps {
        id
        name
        environment
        viewerPinned
      }
    }
  }
`

const AppSwitcher = ({ params }) => {
  const { loading, data } = useQuery(ORGANIZATION_QUERY, {
    variables: {
      slug: params.organizationSlug,
    },
    skip: !params.organizationSlug,
  })

  const router = useRouter()
  const [filter, setFilter] = React.useState("")

  const filteredApps =
    data?.organization.apps
      .filter((app) => {
        return app.name.toLowerCase().includes(filter.toLowerCase())
      })
      .sort((a, b) => a.name.localeCompare(b.name)) ?? []

  const pinnedApps = filteredApps.filter((app) => app.viewerPinned) ?? []

  const renderButton = () => {
    let label
    let environment

    if (loading) {
      label = <LoadingSpinner spinnerClassName="[&+span]:text-white text-white" />
    } else if (data) {
      const selectedApp = data.organization.apps.find((app) => app.id === params.appId)

      if (selectedApp) {
        environment = (
          <EnvironmentLabel env={selectedApp.environment} className="text-white font-normal" />
        )
        label = `${selectedApp.name}`
      } else {
        label = data.organization.name
      }
    }

    return (
      <Dropdown.Button addClassName="border-none font-semibold bg-gray-900 hover:bg-gray-900 dark [&>i]:text-gray-300">
        <span className="flex">
          <span className="text-white truncate max-w-40 inline-block">{label}</span>

          {environment && <span className="ml-2">{environment}</span>}
        </span>
      </Dropdown.Button>
    )
  }

  const renderOptions = () => {
    if (loading) {
      return (
        <div className="px-1.5">
          <Dropdown.Item key="loading" value="" selected={true}>
            Loading...
          </Dropdown.Item>
        </div>
      )
    }

    return (
      <>
        <Dropdown.Search filter={filter} setFilter={setFilter} placeholder="Search application" />
        <div className="overflow-y-auto max-h-96 px-1.5">
          {pinnedApps.length > 0 && (
            <div>
              <span className="font-semibold px-1.5 mb-1 inline-block mt-2">Pinned apps</span>
              {pinnedApps.map((app) => (
                <Dropdown.Item
                  key={app.id}
                  value={app.id}
                  selected={app.id === params.appId}
                  customIcon
                >
                  <a
                    href={UrlHelper.dashboardShowPath({
                      organizationSlug: data.organization.slug,
                      appId: app.id,
                    })}
                    className="no-underline flex space-x-2"
                  >
                    <span className="truncate max-w-48">{app.name}</span>
                    <EnvironmentLabel env={app.environment} />
                  </a>
                </Dropdown.Item>
              ))}
              <Dropdown.Divider />
            </div>
          )}
          <>
            <Dropdown.Item
              value={data?.organization.name}
              selected={
                router.currentPathWithParams().includes(params.organizationSlug) && !params.appId
              }
              customIcon
            >
              <Link href={`/${data?.organization.slug}`} className="no-underline flex">
                <span className="truncate max-w-60">{data?.organization.name}</span>
              </Link>
            </Dropdown.Item>
            {filteredApps.map((app) => (
              <Dropdown.Item
                key={app.id}
                value={app.id}
                selected={app.id === params.appId}
                customIcon
              >
                <a
                  href={UrlHelper.dashboardShowPath({
                    organizationSlug: data?.organization.slug,
                    appId: app.id,
                  })}
                  className="no-underline flex space-x-2"
                >
                  <span className="truncate max-w-48">{app.name}</span>
                  <EnvironmentLabel env={app.environment} />
                </a>
              </Dropdown.Item>
            ))}
          </>
        </div>
      </>
    )
  }

  return (
    <Dropdown>
      {renderButton()}
      <Dropdown.Items align="left" className="max-h-none px-0 pt-0 w-80 text-gray-800">
        {renderOptions()}
      </Dropdown.Items>
    </Dropdown>
  )
}

AppSwitcher.propTypes = {
  params: PropTypes.shape({
    organizationSlug: PropTypes.string,
    appId: PropTypes.string,
  }),
}

export default AppSwitcher
