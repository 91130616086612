import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export default gql`
  mutation updateVisualTimeseriesMutation(
    $appId: String!,
    $visualId: String!,
    $visual: VisualTimeseriesInput!
  ) {
    updateVisualTimeseries(
      appId: $appId,
      visualId: $visualId,
      visual: $visual
    ) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`
