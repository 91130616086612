import LoadingSpinner from "#Components/shared/loading_spinner"
import TextfieldWithAutoComplete from "#Components/shared/TextfieldWithAutoComplete"
import { useRestClientContext } from "#Root/contexts/RestClientContext"
import { filterArrayWith } from "#Root/utils/Array"
import { useQuery } from "#Root/utils/react-query-client"

const MetricName = ({ triggerData, onChange, appId }) => {
  const restApiClient = useRestClientContext()
  const { data, isLoading: loading } = useQuery({
    queryKey: ["metricNames", appId],
    queryFn: () => restApiClient.metrics.metricNames(appId),
  })

  const names = data ?? []
  const autoCompleteMessage = loading ? <LoadingSpinner message="Loading metrics..." /> : null
  const metricName = triggerData.metricName || ""
  const withWildcard = metricName.includes("*")
  const matchedOptions = withWildcard ? filterArrayWith(metricName, names, "wildcardEqual") : names
  const unknownMetric = !loading && metricName !== "" && matchedOptions.length === 0

  return (
    <>
      <div className="c-form">
        <label className="c-form__label" htmlFor="metricName">
          Metric name
        </label>
        <div className="c-form__element">
          <TextfieldWithAutoComplete
            id="metricName"
            name="metricName"
            autoComplete="off"
            message={autoCompleteMessage}
            value={triggerData.metricName}
            placeholder="Metric"
            options={names.sort()}
            popOverHeader="Metrics in last hour"
            hasWarning={unknownMetric}
            wildcardSupport={true}
            onSelect={(name) => onChange({ ...triggerData, metricName: name })}
          />
        </div>
        {withWildcard && (
          <p className="mt-2 mb-0 c-p--sm">
            <em>
              Wildcard detected in metric name. Matches {matchedOptions.length} metrics from the
              last hour.
            </em>
          </p>
        )}
        {unknownMetric && (
          <p className="mt-2 mb-0 c-p--sm text-orange-500">
            Selected metric was not found in the last hour on page load. It might exist outside this
            timeframe and still render a graph.
          </p>
        )}
      </div>
      <hr />
    </>
  )
}

MetricName.propTypes = {
  triggerData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  timeframe: PropTypes.string.isRequired,
}

export default MetricName
