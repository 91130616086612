import { measurementFields } from "#Root/utils/metric_keys"

import TriggerFormFieldDropdown from "./field/Dropdown"
import TriggerFormFieldRadiobuttons from "./field/Radiobuttons"

const TriggerFormField = ({ trigger, metricMetadata, onChange }) => {
  const { field } = trigger
  const measurementFieldKeys = Object.keys(measurementFields)
  const isMeasurementField = measurementFieldKeys.includes(field)

  return (
    <>
      {!metricMetadata && (
        <TriggerFormFieldDropdown
          trigger={trigger}
          isMeasurementField={isMeasurementField}
          onChange={onChange}
        />
      )}
      {isMeasurementField && (
        <TriggerFormFieldRadiobuttons onChange={onChange} value={trigger.field} />
      )}
      {(!metricMetadata || isMeasurementField) && <hr />}
    </>
  )
}

TriggerFormField.propTypes = {
  trigger: PropTypes.object.isRequired,
  metricMetadata: PropTypes.object,
  onChange: PropTypes.func.isRequired,
}

export default TriggerFormField
