import Duration from "#Root/components/shared/duration"
import HumanNumber from "#Root/components/shared/human_number"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"
import FormattedPercent from "#Root/formatters/FormattedPercent"

const RESOLUTION_MAP = {
  HOURLY: "/h",
  MINUTELY: "/min",
}

const FormattedLabel = ({ value, valueFormat, resolution, valuePrecision, valueInput }) => {
  switch (valueFormat) {
    case "number":
      return <HumanNumber number={value} precision={valuePrecision} />
    case "duration":
      return <Duration val={value} />
    case "size":
      return <FormattedFileSize value={value} format={valueInput} />
    case "throughput":
      return (
        <span>
          <HumanNumber number={value} />
          {RESOLUTION_MAP[resolution]}
        </span>
      )
    case "percent":
      return <FormattedPercent value={value} precision={valuePrecision} />
    default:
      return value
  }
}

FormattedLabel.propTypes = {
  value: PropTypes.number.isRequired,
  valueFormat: PropTypes.string,
  valueInput: PropTypes.string,
  valuePrecision: PropTypes.number,
  resolution: PropTypes.oneOf(["HOURLY", "MINUTELY"]),
}

export default FormattedLabel
