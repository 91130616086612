import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export default gql`
  query CustomMetricsDashboardsDefinitionQuery(
    $appId: String!
  ) {
    app(id: $appId) {
      id
      dashboards {
        ...Dashboard
      }
    }
  }
  ${DashboardFragment}
`
