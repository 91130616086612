document.querySelectorAll("[data-role~='toggle_notifier']").forEach(function (element) {
  $(element).on("ajax:success", function (event) {
    const [data] = event.detail
    element.querySelector("input").checked = data.subscribed

    const toggle = element.querySelector(".c-toggle")

    if (toggle) {
      if (data.subscribed) {
        toggle.classList.add("c-toggle--on")
      } else {
        toggle.classList.remove("c-toggle--on")
      }
    }
  })
})
