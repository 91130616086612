import DashboardFormOverlay from "../DashboardFormOverlay"
import HideGettingStartedOverlay from "../HideGettingStartedOverlay/HideGettingStartedOverlay"
import MarkerBoxOverlay from "../MarkerBoxOverlay"
import TimeDetectiveOverlay from "../TimeDetectiveOverlay"

const GlobalOverlays = ({ params }) => {
  if (params.overlay === "dashboardForm") {
    return <DashboardFormOverlay open={true} params={params} />
  }

  if (params.overlay === "customMarker") {
    return <MarkerBoxOverlay open={true} params={params} />
  }

  if (params.overlay === "hideGettingStartedOverlay") {
    return <HideGettingStartedOverlay />
  }

  if (params.overlay === "snapshot" || params.overlay == "timeDetective") {
    return <TimeDetectiveOverlay params={params} />
  }

  return null
}

GlobalOverlays.propTypes = {
  params: PropTypes.object.isRequired,
}

export default GlobalOverlays
