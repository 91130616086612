$(document).ready(function () {
  const appSwitcherElement = $("#app-switcher")
  const appSwitcherToggle = $("#app-switcher-button")
  const profileDropdownElement = $("#profile-dropdown")
  const profileDropdownToggle = $("#profile-dropdown-toggle")
  const helpDropdownElement = $("#help-dropdown")
  const helpDropdownToggle = $("#help-dropdown-toggle")
  const app = $(".l-app")

  const menus = [
    {
      element: appSwitcherElement,
      toggle: appSwitcherToggle,
      contentElement: appSwitcherElement.find(".l-inner"),
    },
    {
      element: profileDropdownElement,
      toggle: profileDropdownToggle,
      contentElement: appSwitcherElement.find(".l-inner"),
    },
    {
      element: helpDropdownElement,
      toggle: helpDropdownToggle,
      contentElement: appSwitcherElement.find(".l-inner"),
    },
  ]

  const isHidden = (element) => element.hasClass("hidden")

  const showElement = (element) => {
    element.removeClass("hidden")
    hideOtherElements(element)
  }

  const hideElement = (element) => {
    element.addClass("hidden")
  }

  const hideOtherElements = (element) => {
    menus.forEach((menu) => {
      if (menu.element != element) hideElement(menu.element)
    })
  }

  menus.forEach((menu) => {
    const { element, toggle } = menu

    toggle.on("click", () => {
      isHidden(element) ? showElement(element) : hideElement(element)
    })

    app.on("click", () => {
      if (!isHidden(element)) hideElement(element)
    })
  })
})
