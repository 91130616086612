import { colorPaletteMap, useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import { useRouter } from "#Root/hooks"

import { useChartDataContext } from "../contexts/DataContext"
import { applyColorPalette } from "../utils/util"
import ClipGraphButton from "./ClipGraphButton"

const ExportButton = ({ title, valueInput, valueFormat = "number", getColorPalette }) => {
  const { visibleSeries, timeseries, loading, error, renderer } = useChartDataContext()
  const { settings } = useChartSettingsContext()

  const { getParams } = useRouter()

  if (loading || error) {
    return null
  }

  if (visibleSeries.length === 0) {
    return null
  }

  let colors
  // Prioritize the function the user passed in
  if (typeof getColorPalette === "function") {
    colors = getColorPalette({ series: visibleSeries })
  } else {
    const colorPalette = colorPaletteMap[settings.colorPalette]
    colors = applyColorPalette(visibleSeries, colorPalette)
  }

  return (
    <ClipGraphButton
      getGraphData={() => ({
        appId: getParams().appId,
        title: title,
        valueFormat,
        valueInput,
        resolution: timeseries.resolution,
        series: visibleSeries.map((serie) => ({
          name: serie.label,
          data: serie.data,
          color: colors(serie),
          renderer,
        })),
      })}
    />
  )
}

ExportButton.propTypes = {
  title: PropTypes.string.isRequired,
  renderer: PropTypes.string,
  valueFormat: PropTypes.string,
  valueInput: PropTypes.string,
  getColorPalette: PropTypes.func,
  label: PropTypes.string,
}

export default ExportButton
