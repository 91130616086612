import { useState } from "react"

const Checkbox = ({
  id = "checkbox",
  name = "checkbox",
  label,
  selected,
  setSelected,
  className,
  labelClassName,
  ariaLabel,
}) => {
  const [controlledSelected, setControlledSelected] = useState(false)

  const toggle = () => {
    if (isControlled()) {
      setSelected(!selected)
    } else {
      setControlledSelected(!controlledSelected)
    }
  }

  const isControlled = () => selected !== undefined
  const isSelected = isControlled() ? selected : controlledSelected

  return (
    <div className={`c-checkbox ignore-old-css ${className || ""}`}>
      <input
        className="c-checkbox__input ignore-old-css"
        type="checkbox"
        id={id}
        name={name}
        checked={isSelected}
        onChange={toggle}
        aria-label={ariaLabel}
      />
      {label && (
        <label
          className={`c-checkbox__label ignore-old-css select-none cursor-pointer ${
            labelClassName || ""
          }`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
    </div>
  )
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  selected: PropTypes.bool,
  setSelected: PropTypes.func,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  ariaLabel: PropTypes.string,
}

export default Checkbox
