import PropTypes from "prop-types"
import React from "react"

import DataFormatSelector from "../../../shared/visuals/DataFormatSelector"
import VisualFormDrawNullAsZero from "./DrawNullAsZero"
import VisualFormLineLabel from "./LineLabel"
import MinYAxisCheckbox from "./MinYAxisCheckbox"

export const VisualOptions = ({
  visual,
  optionsForm: OptionsForm,
  onLineLabelFocus,
  onLineLabelBlur,
  onChange,
}) => {
  const options = OptionsForm ? <OptionsForm visual={visual} onChange={onChange} /> : null

  return (
    <div className="p-6">
      {options}

      <VisualFormLineLabel
        visual={visual}
        onChange={onChange}
        onLineLabelFocus={onLineLabelFocus}
        onLineLabelBlur={onLineLabelBlur}
      />

      <DataFormatSelector visual={visual} onChange={onChange} />

      <VisualFormDrawNullAsZero visual={visual} onChange={onChange} />

      <MinYAxisCheckbox visual={visual} onChange={onChange} />
    </div>
  )
}

VisualOptions.propTypes = {
  visual: PropTypes.object.isRequired,
  optionsForm: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onLineLabelFocus: PropTypes.func.isRequired,
  onLineLabelBlur: PropTypes.func.isRequired,
}

export default VisualOptions
