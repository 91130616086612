import Link from "#Components/shared/link"
import withParamNavigator from "#Root/wrappers/param_navigator"

const TriggerFormButton = ({
  triggerId,
  title,
  children,
  className,
  form,
  currentPathWithParams,
}) => {
  const defaultTitle = "Add trigger"
  return (
    <Link
      href={currentPathWithParams({
        overlay: "triggerForm",
        triggerId: triggerId,
        form: form,
      })}
      className={className || "c-button c-button--xs"}
      title={title || defaultTitle}
    >
      {children || title || defaultTitle}
    </Link>
  )
}

TriggerFormButton.propTypes = {
  triggerId: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  form: PropTypes.string,
  currentPathWithParams: PropTypes.func.isRequired,
}

export default withParamNavigator(TriggerFormButton)
