import RadioGroup from "../RadioGroup/RadioGroup"
import Section from "./Section"

const LinePreview = ({ thickness }) => (
  <div className="w-full bg-azure-500 rounded-xs" style={{ height: thickness }} />
)

LinePreview.propTypes = {
  thickness: PropTypes.number,
}

const ChartLineThickness = ({ lineThickness, setLineThickness }) => {
  return (
    <Section>
      <Section.Title>Line thickness</Section.Title>
      <Section.Content>
        <RadioGroup
          name="line-stroke-width"
          value={String(lineThickness)}
          onChange={(value) => setLineThickness(Number(value))}
          className="flex justify-between space-x-3"
        >
          <RadioGroup.Radio
            id="width-1"
            value="1"
            label={<LinePreview thickness={1} />}
            className="px-1 w-full my-0"
            ariaLabel="thickness-1"
          />
          <RadioGroup.Radio
            id="width-1.5"
            value="1.5"
            label={<LinePreview thickness={2} />}
            className="px-1 w-full my-0"
            ariaLabel="thickness-1.5"
          />
          <RadioGroup.Radio
            id="width-2"
            value="2"
            label={<LinePreview thickness={3} />}
            className="px-1 w-full my-0"
            ariaLabel="thickness-2"
          />
        </RadioGroup>
      </Section.Content>
    </Section>
  )
}

ChartLineThickness.propTypes = {
  lineThickness: PropTypes.number,
  setLineThickness: PropTypes.func,
}

export default ChartLineThickness
