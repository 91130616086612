import PropTypes from "prop-types"
import React from "react"

import SearchField from "./SearchField"
import SearchRefinement from "./SearchRefinement"

const SearchFieldWithRefinement = ({ initialValue, onSubmit }) => {
  const searchFieldRef = React.createRef()
  const [searchValue, setSearchValue] = React.useState(initialValue)

  const handleRefinement = (refinement) => {
    setSearchValue(`${searchValue} ${refinement}`.trim())
    searchFieldRef.current.focus()
  }

  const handleSubmit = () => {
    setSearchValue(searchValue)
    onSubmit(searchValue)
  }

  return (
    <div className="flex">
      <SearchRefinement handleRefinement={handleRefinement} />
      <SearchField
        value={searchValue}
        handleSubmit={handleSubmit}
        handleChange={setSearchValue}
        ref={searchFieldRef}
      />
    </div>
  )
}

SearchFieldWithRefinement.propTypes = {
  initialValue: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

export default SearchFieldWithRefinement
