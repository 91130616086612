import { ApolloClient } from "apollo-client"
import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import { HttpLink } from "apollo-link-http"

import { apolloCache } from "../utils/apollo-cache"

const customFetch = (uri, options) => {
  if (process.env.NODE_ENV !== "test") {
    const body = JSON.parse(options.body)

    appsignal.addBreadcrumb({
      category: "graphql",
      action: `GraphQL query: ${body.operationName}`,
    })
  }

  return fetch(uri, options)
}

const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          try {
            appsignal.sendError(new Error(errorMessage))
          } catch (error) {
            console.warn(errorMessage)
          }
        })
      if (networkError) {
        const errorMessage = `[GraphQL Network error]: ${networkError}`
        console.warn(errorMessage)
        try {
          appsignal.sendError(new Error(errorMessage))
        } catch (error) {
          console.warn(errorMessage)
        }
      }
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: { "client-name": "AppSignal.com" },
    }),
  ]),
  cache: apolloCache,
  fetch: customFetch,
})

const apolloTestClient = new ApolloClient({
  link: ApolloLink.from([
    onError(() => {}),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: { "client-name": "AppSignal.com" },
    }),
  ]),
  cache: apolloCache,
  fetch: customFetch,
})

apolloTestClient.defaultOptions = {
  watchQuery: {
    fetchPolicy: "network-only",
  },
  query: {
    fetchPolicy: "network-only",
  },
  mutate: {
    errorPolicy: "all",
  },
}

export { apolloClient, apolloTestClient }
