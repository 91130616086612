import React from "react"
import { ApolloProvider } from "react-apollo"
import ReactDOM from "react-dom"

import App from "../components/app"
import { apolloClient } from "../utils/apollo-client"
import Router from "../utils/router"

$(document).ready(function () {
  const router = new Router()

  // Check if there"s an app div, if so initialize the app in that div
  if ($("#app").length > 0) {
    const body = $("body")

    ReactDOM.render(
      <ApolloProvider client={apolloClient}>
        <App
          appsignal={appsignal}
          router={router}
          token={body.data("user-token")}
          site_name={body.data("site-name")}
          formatters={{
            date: body.data("date-format"),
            number: body.data("number-format"),
            timeZone: body.data("time-zone"),
          }}
          platforms={body.data("platforms")}
          enabledLabFeatures={body.data("enabled-lab-features")}
          chartSettings={{
            horizontalSupportLines: body.data("chart-show-horizontal-lines"),
            verticalSupportLines: body.data("chart-show-vertical-lines"),
            colorPalette: body.data("chart-color-palette"),
            lineThickness: body.data("chart-line-thickness"),
          }}
          restClientSettings={{
            endpoint: body.data("rest-endpoint"),
            token: body.data("rest-token"),
          }}
        />
      </ApolloProvider>,
      document.getElementById("app"),
    )
  }
  router.start()
})
