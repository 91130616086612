export function getChildrenMetadata(children) {
  let reactChildren = []
  if (children) {
    reactChildren = Array.isArray(children) ? children : [children]
    // filter out null, or undefined that we get from conditional rendering (e.g. {condition && <Component />})
    reactChildren = reactChildren.filter(Boolean)
  }
  const childrenTypes = reactChildren.map((c) => c.type.displayName)

  const hasCanvas = childrenTypes.includes("Canvas")
  const hasYAxis = childrenTypes.includes("YAxis")
  const hasXAxis = childrenTypes.includes("XAxis")
  const hasMarkers = childrenTypes.includes("Markers")
  const hasHighlightLine = childrenTypes.includes("HighlightLine")

  const hasLockFeature =
    hasHighlightLine &&
    reactChildren.find((c) => c.type.displayName === "HighlightLine").props.enableLock

  const bodyChildren = reactChildren.filter((c) => c.type.displayName !== "Markers")
  const footerChildren = reactChildren.filter((c) => c.type.displayName === "Markers")

  return {
    // helpers
    hasCanvas,
    hasYAxis,
    hasXAxis,
    hasMarkers,
    hasHighlightLine,
    hasLockFeature,
    // components
    bodyChildren,
    footerChildren,
  }
}
