document.querySelectorAll("[data-saving-text]").forEach(function(element) {
  element.addEventListener("click", function(e) {
    const target = e.target
    target.value = target.dataset.savingText
  })
})

document.querySelectorAll("[data-loading-text]").forEach(function(element) {
  element.addEventListener("click", function(e) {
    const target = e.target
    target.innerHTML = target.dataset.loadingText
  })
})

document.querySelectorAll("[data-loaded-text]").forEach(function(element) {
  $(element).on("ajax:complete", function(e) {
    const target = e.target

    target.innerHTML = target.dataset.loadedText

    if (target.dataset.originalText) {
      setTimeout(function() {
        target.innerHTML = target.dataset.originalText
      }, 4000)
    }
  })
})
