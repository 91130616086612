import { useDatePickerContext } from "#Root/contexts/DatePickerContext"
import FormattedTime from "#Root/formatters/FormattedTime"
import useRouter from "#Root/hooks/useRouter"
import Button from "#Root/ui/Button"

export const DatePickerButton = React.forwardRef(({ onClick }, _ref) => {
  const { range } = useDatePickerContext()
  const { getParams } = useRouter()

  let label = "Custom range"
  if (getParams().timeframe === "custom" && range.from) {
    label = (
      <>
        <FormattedTime format="short" value={range.from} />
        {range.to && " - "}
        {range.to && <FormattedTime format="short" value={range.to} />}
      </>
    )
  }

  const classNames = [
    "ignore-old-css c-button c-button--sm c-button--white cursor-pointer select-none space-x-3 c-button-group__item first:rounded-l-none",
  ]
  if (getParams().timeframe !== "custom") classNames.push("c-button--white-inactive")

  return (
    <Button onClick={onClick} className={classNames.filter((c) => c).join(" ")}>
      {label}
    </Button>
  )
})

DatePickerButton.propTypes = {
  onClick: PropTypes.func,
}
DatePickerButton.displayName = "DatePickerButton"

export default DatePickerButton
