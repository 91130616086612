import dayjs from "dayjs"

import Link from "#Root/components/shared/link"
import { useRouter } from "#Root/hooks"

import { HoverContext } from "../../contexts/HoverContext"

const TimeDetectiveButton = ({ tab }) => {
  const { currentPathWithParams } = useRouter()
  const { date, resolution } = React.useContext(HoverContext)

  const time = dayjs(date).startOf("minute").utc()
  const timeframe = resolution === "MINUTELY" ? "minute" : "hour"
  const params = {
    overlay: "snapshot",
    td_start: time.toISOString(),
    td_end: time.add(1, timeframe).toISOString(),
  }

  if (tab) {
    params.td_tab = tab
  }

  return (
    <Link
      href={currentPathWithParams(params)}
      className="block c-button c-button--gray c-button--xs"
    >
      <i className="fa fa-fw fa-user-secret mr-1" /> Open Time Detective
    </Link>
  )
}

TimeDetectiveButton.propTypes = {
  tab: PropTypes.string,
}

export default TimeDetectiveButton
