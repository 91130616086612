import { ChartDataProvider } from "../contexts/DataContext"

const Container = ({ children, timeseries, loading, error, renderer, label, minYAxis }) => {
  return (
    <ChartDataProvider
      timeseries={timeseries}
      loading={loading}
      error={error}
      renderer={renderer}
      label={label}
      minYAxis={minYAxis}
    >
      {children}
    </ChartDataProvider>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  timeseries: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.object,
  height: PropTypes.number,
  renderer: PropTypes.oneOf(["line", "bar", "area", "area-relative"]),
  label: PropTypes.string,
  minYAxis: PropTypes.number,
}

export default Container
