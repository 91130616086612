import gql from "graphql-tag"

export const mockData = {
  app: {
    __typename: "App",
    id: "abc123",
    hasHerokuHostMetrics: false,
    metrics: {
      __typename: "Metrics",
      keys: [],
    },
  },
}

export default gql`
  query MetricKeysQuery(
    $appId: String!
    $start: DateTime
    $end: DateTime
    $timeframe: TimeframeEnum
    $name: String
    $tags: [TagInput!]
  ) {
    app(id: $appId) {
      id
      metrics {
        keys(start: $start, end: $end, name: $name, tags: $tags, timeframe: $timeframe) {
          digest
          type
          name
          tags {
            key
            value
          }
        }
      }
    }
  }
`
