import gql from "graphql-tag"

import ExceptionIncidentFragment from "./_exception_incident_fragment"
import LogbookFragment from "./_logbook_fragment"
import PerformanceIncidentFragment from "./_performance_incident_fragment"

export const IncidentQueryWithSample = gql`query IncidentQuery(
    $appId: String!,
    $incidentNumber: Int!,
    $sampleId: String,
    $timestamp: String,
    $timerange: [DateTime]
  ) {
    app(id: $appId) {
      id
      organization {
        id
        ttl
      }
      incident(incidentNumber: $incidentNumber) {
        ... on ExceptionIncident {
          ... ExceptionIncident
        }
        ... on PerformanceIncident {
          ... PerformanceIncident
        }
      }
    }
  }
  ${ExceptionIncidentFragment}
  ${PerformanceIncidentFragment}
`

export const IncidentQueryWithLogbook = gql`query IncidentQuery(
    $appId: String!,
    $incidentNumber: Int!,
    $sampleId: String,
    $timestamp: String,
    $timerange: [DateTime]
  ) {
    app(id: $appId) {
      id
      incident(incidentNumber: $incidentNumber) {
        ... on ExceptionIncident {
          ... ExceptionIncident
          logbook {
            ... Logbook
          }
        }
        ... on PerformanceIncident {
          ... PerformanceIncident
          logbook {
            ... Logbook
          }
        }
      }
    }
  }
  ${ExceptionIncidentFragment}
  ${PerformanceIncidentFragment}
  ${LogbookFragment}
`
