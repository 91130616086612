import PropTypes from "prop-types"
import React from "react"

import { setTimestampsFromParams } from "../utils/datetime"

function withDateRange(WrappedComponent) {
  class DateRange extends React.Component {
    static propTypes = {
      setParams: PropTypes.func.isRequired
    }

    render() {
      const newProps = {
        onDateChange: (params) => this.props.setParams(setTimestampsFromParams(params))
      }

      return <WrappedComponent {...this.props} {...newProps} />
    }
  }

  DateRange.displayName = `DateRange(${WrappedComponent.displayName || WrappedComponent.name || "Component"})`
  return DateRange
}

export default withDateRange
