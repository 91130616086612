import { TIMEFRAMES as ORIGINAL_TIMEFRAMES } from "#Root/base/timeframes"

export const POSSIBLE_COLUMNS = ["timestamp", "source", "severity", "hostname", "group"]
export const SEVERITIES = [
  { name: "Unknown", value: "UNKNOWN", color: "#BFC6D6" },
  { name: "Trace", value: "TRACE", color: "#1E9393" },
  { name: "Debug", value: "DEBUG", color: "#E65F8C" },
  { name: "Info", value: "INFO", color: "#336ABC" },
  { name: "Notice", value: "NOTICE", color: "#8537AD" },
  { name: "Warn", value: "WARN", color: "#F0B254" },
  { name: "Error", value: "ERROR", color: "#EC9C24" },
  { name: "Critical", value: "CRITICAL", color: "#E05729" },
  { name: "Alert", value: "ALERT", color: "#C8223D" },
  { name: "Fatal", value: "FATAL", color: "#9C1B30" },
]

export const COLOR_BY_SEVERITY = SEVERITIES.reduce((acc, severity) => {
  acc[severity.value] = severity.color
  return acc
}, {})

export const PLATFORMS = [
  { key: "syslog", value: "Syslog" },
  { key: "http", value: "HTTP" },
  { key: "http_json", value: "HTTP - JSON", format: "PLAINTEXT", hideFormat: true },
  { key: "http_syslog", value: "HTTP - Syslog" },
  { key: "heroku", value: "Heroku Log Drain" },
  { key: "scalingo", value: "Scalingo" },
  { key: "gigalixir", value: "Gigalixir", format: "PLAINTEXT", hideFormat: true },
  { key: "clever_cloud", value: "Clever Cloud" },
  { key: "aws_kinesis", value: "AWS Kinesis / Cloudwatch" },
  { key: "vector", value: "Vector", format: "PLAINTEXT", hideFormat: true, hideKey: true },
  {
    key: "vercel",
    value: "Vercel",
    format: "PLAINTEXT",
    requireAuth: true,
    authProvider: "vercel",
    hideColumns: ["hostname", "group", "source"],
    hideFormat: true,
  },
  {
    key: "netlify",
    value: "Netlify",
    format: "PLAINTEXT",
    hideColumns: ["hostname", "group", "source"],
    hideFormat: true,
  },
  {
    key: "render",
    value: "Render",
  },
]

export const FORMATS = [
  { key: "PLAINTEXT", value: "plaintext" },
  { key: "LOGFMT", value: "logfmt" },
  { key: "JSON", value: "json" },
]

/*
  Re-add these when graph supports custom timeframes:

  R5M: { key: "R5M", value: 5, unit: "minute", label: "5M", pollInterval: 60000 },
  R30M: { key: "R30M", value: 30, unit: "minute", label: "30M", pollInterval: 60000 },
*/
export const TIMEFRAMES = Object.fromEntries(
  Object.entries(ORIGINAL_TIMEFRAMES).filter(([_key, val]) => val.key !== "R30D"),
)
