import gql from "graphql-tag"

export default gql`
  fragment PhabricatorIssue on PhabricatorIssue {
    id
    title
    description
    url
    remoteId
    priority
  }
`
