import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import IncidentNumber from "#Components/IncidentNew/IncidentNumber"
import Link from "#Components/shared/link"
import NamespaceLabel from "#Components/shared/namespace_label"
import UrlHelper from "#Components/shared/url_helper"
import { Column,Table } from "#Components/Table"
import { NumberCell } from "#Components/TableCell"
import { maxField } from "#Root/utils/table"

const QUERY = gql`
  query TimeDetectiveErrorPageQuery(
    $appId: String!
    $start: DateTime!
    $end: DateTime!
    $namespaces: [String]!
  ) {
    app(id: $appId) {
      id
      timeDetectiveErrorDataPoints(start: $start, end: $end, namespaces: $namespaces) {
        throughput
        namespace
        actionName
        exceptionName
        incident {
          ... on ExceptionIncident {
            id
            number
            exceptionName
            namespace
            actionNames
            firstBacktraceLine
            errorGroupingStrategy
          }
        }
      }
    }
  }
`

const TimeDetectiveErrorPage = ({ params, namespaces }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
      namespaces: namespaces,
    },
  })

  const points = (data?.app?.timeDetectiveErrorDataPoints || [])
    .filter((m) => m.incident && m.incident.__typename == "ExceptionIncident")
    .map(({ incident, ...metric }) => {
      const { id, namespace } = incident
      const actionName =
        (incident.actionNames || []).length > 0
          ? incident.actionNames[0]
          : "Happened outside an action"
      const href = UrlHelper.exceptionIncidentSamplesPagePath({
        number: incident.number,
        time: params.td_start,
      })

      return { ...metric, incident, actionName, namespace, href, id }
    })
  const maxValues = {
    throughput: maxField(points, "throughput"),
  }

  /* eslint-disable react/prop-types */
  const NameCell = ({ rowData: { incident, namespace, actionName, href } }) => (
    <div className="c-virtual-table__row-cell-content">
      <div className="flex">
        <Link className="text-blue truncate" href={href} title={incident.exceptionName} />
        <IncidentNumber incident={incident} />
      </div>
      {incident.errorGroupingStrategy == "TYPE_AND_RELEVANT_BACKTRACE_LINE" &&
        incident.firstBacktraceLine && (
        <div className="truncate">
          <code title={incident.firstBacktraceLine}>{incident.firstBacktraceLine}</code>
        </div>
      )}
      <div className="flex">
        <span className="truncate" title={actionName}>
          {actionName}
        </span>
        <NamespaceLabel className={`pt-0.5 ${actionName ? "ml-2" : ""}`} namespace={namespace} />
      </div>
    </div>
  )

  return (
    <Table
      data={points}
      title="Error incidents in this timeframe"
      initialSortKey="throughput"
      loading={loading}
      error={error}
      maxValues={maxValues}
      ignoreContainerHeight
    >
      <Column
        dataKey="actionName"
        flexGrow={1}
        minWidth={300}
        title="Name"
        cellRenderer={NameCell}
        sortable
      />
      <Column
        dataKey="throughput"
        title="Count"
        cellRenderer={NumberCell}
        sortable
        width={180}
        flexShrink={0}
      />
    </Table>
  )
}

TimeDetectiveErrorPage.propTypes = {
  params: PropTypes.object.isRequired,
  title: PropTypes.string,
  namespaces: PropTypes.array.isRequired,
}

export default TimeDetectiveErrorPage
