const setLocalStorageItem = (key, value) => {
  const stringifiedValue = JSON.stringify(value)
  window.localStorage.setItem(key, stringifiedValue)
}

const getLocalStorageItem = (key) => {
  return window.localStorage.getItem(key)
}

export function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = React.useState(() => {
    if (!key) {
      return initialValue
    }

    try {
      const item = getLocalStorageItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.error("Error retrieving data from localStorage:", error)
      return initialValue
    }
  })

  const setValue = React.useCallback(
    (value) => {
      if (!key) {
        return
      }

      try {
        const valueToStore = typeof value === "function" ? value(storedValue) : value
        setStoredValue(valueToStore)
        setLocalStorageItem(key, valueToStore)
      } catch (error) {
        console.error("Error storing data to localStorage:", error)
      }
    },
    [key, storedValue],
  )

  // Save on initial run
  React.useEffect(() => {
    if (!key) {
      return
    }
    if (getLocalStorageItem(key) === null && typeof initialValue !== "undefined") {
      setLocalStorageItem(key, initialValue)
    }
  }, [key, initialValue])

  return [storedValue, setValue]
}
