import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import IncidentNumber from "#Components/IncidentNew/IncidentNumber"
import Link from "#Components/shared/link"
import NamespaceLabel from "#Components/shared/namespace_label"
import UrlHelper from "#Components/shared/url_helper"
import { Column, Table } from "#Components/Table"
import { DurationCell, NumberCell } from "#Components/TableCell"
import { maxField } from "#Root/utils/table"

const QUERY = gql`
  query TimeDetectivePerformancePageQuery(
    $appId: String!
    $start: DateTime!
    $end: DateTime!
    $namespaces: [String]!
  ) {
    app(id: $appId) {
      id
      timeDetectivePerformanceDataPoints(start: $start, end: $end, namespaces: $namespaces) {
        namespace
        actionName
        mean
        p90
        throughput
        incident {
          ... on PerformanceIncident {
            id
            number
          }
        }
      }
    }
  }
`

const TimeDetectivePerformancePage = ({ params, namespaces }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
      namespaces: namespaces,
    },
  })

  const points = (data?.app?.timeDetectivePerformanceDataPoints || []).map(
    ({ incident, ...metric }) => {
      let href, id
      if (incident) {
        href = UrlHelper.performanceIncidentSamplesPagePath({
          number: incident.number,
          time: params.td_start,
        })
        id = incident.id
      } else {
        href = UrlHelper.actionShowPath({
          action_name: metric.actionName,
          namespace: metric.namespace,
        })
        id = `${metric.actionName}${metric.namespace}`
      }

      return { incident, href, id, ...metric }
    },
  )
  const maxValues = {
    throughput: maxField(points, "throughput"),
    mean: maxField(points, "mean"),
    p90: maxField(points, "p90"),
  }

  /* eslint-disable react/prop-types */
  const NameCell = ({ rowData: { incident, namespace, actionName, href } }) => (
    <div className="c-virtual-table__row-cell-content max-w-fit">
      <span>
        <Link className="c-link" href={href} title={actionName} />{" "}
        {incident && <IncidentNumber incident={incident} />}
      </span>
      <NamespaceLabel className="max-w-fit mt-1" namespace={namespace} />
    </div>
  )

  return (
    <Table
      data={points}
      title="Performance incidents in this timeframe"
      initialSortKey="throughput"
      loading={loading}
      error={error}
      maxValues={maxValues}
      ignoreContainerHeight
    >
      <Column
        dataKey="actionName"
        flexGrow={1}
        minWidth={300}
        title="Name"
        cellRenderer={NameCell}
        sortable
      />
      <Column
        dataKey="mean"
        title="Mean"
        cellRenderer={DurationCell}
        sortable
        width={180}
        flexShrink={0}
      />
      <Column
        dataKey="p90"
        title="90th Percentile"
        cellRenderer={DurationCell}
        sortable
        width={180}
        flexShrink={0}
      />
      <Column
        dataKey="throughput"
        title="Throughput"
        cellRenderer={NumberCell}
        sortable
        width={180}
        flexShrink={0}
      />
    </Table>
  )
}

TimeDetectivePerformancePage.propTypes = {
  params: PropTypes.object.isRequired,
  namespaces: PropTypes.array.isRequired,
}

export default TimeDetectivePerformancePage
