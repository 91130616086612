import Dropdown from "#Root/ui/Dropdown"

const namespaceButtonText = (data, loading, namespace) => {
  if (loading && !data?.organization) return "Loading..."
  if (namespace) return namespace

  return "All namespaces"
}

const sortedNamespaceOptions = (apps, appId) => {
  if (appId) {
    const app = apps.find((app) => app.id === appId)
    return app ? app.namespaces.sort((a, b) => a.localeCompare(b)) : []
  }

  return [...new Set(apps.flatMap((app) => app.namespaces))].sort((a, b) => a.localeCompare(b))
}

const NamespaceFilter = ({ setParams, data, loading, params }) => {
  return (
    <Dropdown select="single" closeOnSelect={false}>
      <Dropdown.Button>{namespaceButtonText(data, loading, params.namespace)}</Dropdown.Button>
      <Dropdown.Items align="left">
        <Dropdown.Item
          onSelect={() => setParams({ namespace: null })}
          selected={params.namespace == null || params.namespace == ""}
        >
          All namespaces
        </Dropdown.Item>
        {sortedNamespaceOptions(data?.organization?.apps || [], params.searchAppId).map((namespace) => (
          <Dropdown.Item
            key={namespace}
            onSelect={() => setParams({ namespace: namespace })}
            selected={params.namespace == namespace}
          >
            {namespace}
          </Dropdown.Item>
        ))}
      </Dropdown.Items>
    </Dropdown>
  )
}

NamespaceFilter.propTypes = {
  setParams: PropTypes.func.isRequired,
  data: PropTypes.object,
  loading: PropTypes.bool,
  params: PropTypes.object,
}

export default NamespaceFilter
