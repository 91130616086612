import gql from "graphql-tag"

import UptimeMonitorFragment from "./_uptimeMonitorFragment"

export default gql`
  mutation updateUptimeMonitorMutation(
    $appId: String!,
    $id: String!,
    $uptimeMonitor: UptimeMonitorInput!
  ) {
    updateUptimeMonitor(
      appId: $appId,
      id: $id
      uptimeMonitor: $uptimeMonitor
    ) {
      ...UptimeMonitor
    }
  }
  ${UptimeMonitorFragment}
`
