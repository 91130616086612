import React from "react"

const BlankSlate = () => (
  <div className="c-box mb-0 flex px-5 py-4">
    <div className="flex-shrink-0 w-10 mr-4">
      <img src="/assets/illustrations/search.svg" />
    </div>
    <div className="">
      <h2 className="mb-2">
        <span className="font-semibold">Sample search</span>
      </h2>
      <ul className="c-ul text-gray-700 ml-2 space-y-1 mb-3">
        <li>Search for any incident name, action name or tag values.</li>
        <li>Queries are case-sensitive.</li>
        <li>Wildcards are supported.</li>
        <li>
          Use the&nbsp;
          <span className="c-pill c-pill--sans text-sm">Filter</span> button to
          narrow down the results and search on other sample fields.
        </li>
      </ul>
      <p className="text-gray-700 mb-2">Example:</p>
      <pre className="c-code mb-3 bg-gray-100 text-gray-700 whitespace-normal leading-relaxed">
        action:User::BlogpostController#edit error:StandardError
        message:&quot;could not connect&quot; tag:frontend*
      </pre>
    </div>
  </div>
)

export default BlankSlate
