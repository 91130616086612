import TooltipWithIcon from "#Components/shared/TooltipWithIcon"
export const WARMUP_SOFT_LIMIT = 60

const TriggerFormWarmupAndCooldown = ({
  warmupAndCooldown: { onWarmupChange, onCooldownChange, warmupDuration, cooldownDuration },
}) => (
  <>
    <div className="c-form block">
      <label htmlFor="warmupDuration" className="c-form__label">
        Alert warm-up
        <TooltipWithIcon content={<p>How long AppSignal waits before opening an alert</p>} />
      </label>
      <div className="c-form__element">
        <div className="c-textfield">
          <input
            id="warmupDuration"
            className="c-textfield__input"
            name="warmupDuration"
            step="1"
            type="number"
            min="0"
            value={warmupDuration}
            onChange={(e) => onWarmupChange(e.target.value)}
          />
          <span className="c-textfield__suffix">minutes</span>
        </div>
      </div>
      {warmupDuration > WARMUP_SOFT_LIMIT && (
        <div className="c-message c-message--yellow mt-4">
          <p>
            Using anomaly detection to detect if processes are running is not recommended. Read more
            about how and why on our{" "}
            <a
              href="https://docs.appsignal.com/anomaly-detection#warm-up-and-cooldown"
              target="_blank"
              rel="noopener noreferrer"
            >
              warm-up and cooldown documentation
            </a>
            .
          </p>
        </div>
      )}
    </div>

    <div className="c-form">
      <label htmlFor="cooldownDuration" data-html="true" className="c-form__label">
        Alert cooldown
        <TooltipWithIcon content={<p>How long AppSignal waits before closing an alert</p>} />
      </label>
      <div className="c-form__element">
        <div className="c-textfield">
          <input
            id="cooldownDuration"
            className="c-textfield__input"
            name="cooldownDuration"
            step="1"
            type="number"
            min="0"
            value={cooldownDuration}
            onChange={(e) => onCooldownChange(e.target.value)}
          />
          <span className="c-textfield__suffix">minutes</span>
        </div>
      </div>
      <p className="c-form__description">
        See our docs for more information about&nbsp;
        <a
          href="https://docs.appsignal.com/anomaly-detection#warm-up-and-cooldown"
          target="_blank"
          rel="noopener noreferrer"
        >
          warm-up and cooldown
        </a>
        .
      </p>
    </div>
  </>
)
TriggerFormWarmupAndCooldown.propTypes = {
  warmupAndCooldown: PropTypes.shape({
    onWarmupChange: PropTypes.func.isRequired,
    onCooldownChange: PropTypes.func.isRequired,
    warmupDuration: PropTypes.string.isRequired,
    cooldownDuration: PropTypes.string.isRequired,
  }).isRequired,
}

export default TriggerFormWarmupAndCooldown
