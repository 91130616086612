import PropTypes from "prop-types"
import React from "react"

const TagsFormCombinations = ({ metricMetadata, onChange }) => {
  function handleClick(combination) {
    const tags = combination.map(tag => ({ key: tag, value: "*" }))
    onChange({ tags })
  }

  // Metric is unknown, we can't render links
  if (!metricMetadata) { return <></> }

  const { tagCombinations } = metricMetadata
  const tags = tagCombinations.map((combination, index) => {
    return (
      <li className="pl-2" key={`tagCombination-${index}`}>
        <span className="-ml-1">{combination.join(", ")} – </span>
        <a
          className="cursor-pointer text-blue-500 underline"
          onClick={handleClick.bind(this, combination)}
        >Apply tags</a>
      </li>
    )
  })

  return (
    <>
      Must be one of these tag combinations:

      <ul className="c-ul mb-4">
        {tags}
        <li className="pl-2">
          <span className="-ml-1">No tags – </span>
          <a
            className="cursor-pointer text-blue-500 underline"
            onClick={handleClick.bind(this, [])}
          >Reset tags</a>
        </li>
      </ul>
    </>
  )
}

TagsFormCombinations.propTypes = {
  metricMetadata: PropTypes.object,
  onChange: PropTypes.func.isRequired
}

export default TagsFormCombinations
