import gql from "graphql-tag"

export default gql `
  query appMetricKeysQuery(
    $appId: String!,
    $name: String!,
    $start: DateTime,
    $tags: [TagInput!]
  ) {
    app(id: $appId) {
      id
      metrics {
        keys(name: $name, tags: $tags, start: $start) {
          tags {
            key
            value
          }
        }
      }
    }
  }
`
