import gql from "graphql-tag"

import AsanaTaskFragment from "./_asana_task_fragment"
import GitHubIssueFragment from "./_github_issue_fragment"
import GitLabIssueFragment from "./_gitlab_issue_fragment"
import JiraIssueFragment from "./_jira_issue_fragment"
import LinearIssueFragment from "./_linear_issue_fragment"
import PhabricatorIssueFragment from "./_phabricator_issue_fragment"
import PivotalTrackerStoryFragment from "./_pivotal_tracker_story_fragment"
import ShortcutStoryFragment from "./_shortcut_story_fragment"
import TrelloCardFragment from "./_trello_card_fragment"

export default gql`
  fragment ExceptionIntegrationsFragment on ExceptionIncident {
    integrations {
      ... on Asana {
        name
        tasks {
          ...AsanaTask
        }
      }
      ... on GitHub {
        name
        issues {
          ...GitHubIssue
        }
      }
      ... on GitLab {
        name
        issues {
          ...GitLabIssue
        }
      }
      ... on Jira {
        name
        issues {
          ...JiraIssue
        }
      }
      ... on Phabricator {
        name
        priorities {
          key
          value
        }
        issues {
          ...PhabricatorIssue
        }
      }
      ... on Shortcut {
        name
        needsMigration
        stories {
          ...ShortcutStory
        }
      }
      ... on PivotalTracker {
        name
        stories {
          ...PivotalTrackerStory
        }
      }
      ... on Trello {
        name
        lists {
          key
          value
        }
        cards {
          ...TrelloCard
        }
      }
      ... on Linear {
        name
        issues {
          ...LinearIssue
        }
      }
    }
  }
  ${AsanaTaskFragment}
  ${GitHubIssueFragment}
  ${GitLabIssueFragment}
  ${JiraIssueFragment}
  ${PhabricatorIssueFragment}
  ${PivotalTrackerStoryFragment}
  ${TrelloCardFragment}
  ${ShortcutStoryFragment}
  ${LinearIssueFragment}
`
