import { Controller } from "@hotwired/stimulus"

/* HTML needed
  In this case both the controller, values and actions
  are defined on the same element, it looks like this:
  <a
    href=""
    data-controller="user-preference",
    data-action="click->user-preference#toggle",
    data-user-preference-field-value="hide_getting_started",
    data-user-preference-current-value="false"
  >Click me!</a>
*/
export default class extends Controller {
  static targets = ["toggle", "style"]

  static values = {
    field: String,
    current: Boolean
  }

  connect() {
    this.boundToggleSwitchState = this.toggleSwitchState.bind(this)
    document.addEventListener("hide_getting_started", this.boundToggleSwitchState)
  }

  disconnect() {
    document.addEventListener("hide_getting_started", this.boundToggleSwitchState)
  }

  toggleSwitchState(event) {
    const classList = this.toggleTarget.classList

    if (this.currentValue != event.detail) {
      this.currentValue = event.detail
    }

    if (this.currentValue) {
      classList.remove("c-toggle--on")
      classList.add("c-toggle--off")
    } else {
      classList.remove("c-toggle--off")
      classList.add("c-toggle--on")
    }
  }

  toggle(event) {
    // Stop the menu from closing
    event.preventDefault()
    event.stopPropagation()

    const formData = new FormData()
    formData.append(`user[${this.fieldValue}]`, !this.currentValue)

    fetch(this.element.getAttribute("href"), {
      body: formData,
      method: "PATCH",
      credentials: "include",
      dataType: "script",
      headers: { "X-CSRF-Token": document.getElementsByName("csrf-token")[0]?.content },
    })
      .then((response) => response.json())
      .then((json) => {
        this.currentValue = json[this.fieldValue]
        const event = new CustomEvent(this.fieldValue, { detail: this.currentValue })
        document.dispatchEvent(event)
      })
  }
}
