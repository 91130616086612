const accordion__heads = document.querySelectorAll("#security.c-table [data-role~='accordion-head']")
accordion__heads.forEach(function(accordion__head) {
  accordion__head.addEventListener("click", function(event) {
    // Ignore click when target is button
    if (event.target.classList.contains("c-button")) {
      return
    }

    const accordion = event.target.closest("[data-role~='accordion']")
    const accordion_bodies = accordion.querySelectorAll("[data-role~='accordion-body']")

    accordion_bodies.forEach((accordion_body) => {
      accordion_body.classList.toggle("hidden")
    })
    accordion.querySelector(".fa.fa-caret-right").classList.toggle("rotate-90")
  })
})
