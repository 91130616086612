import gql from "graphql-tag"

import StatusPageFragment from "./_statusPageFragment"

export default gql`
  mutation updateStatusPageMutation(
    $organizationSlug: String!,
    $id: String!,
    $statusPage: UpdateStatusPageInput!
  ) {
    updateStatusPage(
      organizationSlug: $organizationSlug,
      id: $id,
      statusPage: $statusPage
    ) {
      ...StatusPage
    }
  }
  ${StatusPageFragment}
`
