import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { RouterContext } from "../../contexts/RouterContext"
import Ident from "../../utils/Ident"
import withParamNavigator from "../../wrappers/param_navigator"
import OverlayLayout from "../shared/overlay"
import Form from "./Form"

const states = {
  new: {
    metric: "StatusPageUpdateNew",
    title: "Create new status page update",
  },
  edit: {
    metric: "StatusPageUpdateEdit",
    title: "Edit status page update"
  }
}

export const StatusPageUpdateOverlay = ({ organizationSlug, statusPageId, statusPageUpdateId, currentPathWithParams }) => {
  const router = useContext(RouterContext)
  const stateKey = statusPageUpdateId ? "edit" : "new"
  const state = states[stateKey]
  useEffect(() => {
    Ident.trackAction(state.metric)
  })

  const handleClose = () => {
    router.navigate(currentPathWithParams({ overlay: null, statusPageId: null, statusPageUpdateId: null, organizationSlug: null }), { trigger: true })
  }

  return (
    <OverlayLayout
      onClose={handleClose}
      title={state.title}
      innerClassName="c-overlay__inner--md overflow-x-auto"
    >
      <Form
        organizationSlug={organizationSlug}
        statusPageId={statusPageId}
        statusPageUpdateId={statusPageUpdateId}
        closeOverlay={handleClose}
      />
    </OverlayLayout>
  )
}

StatusPageUpdateOverlay.propTypes = {
  currentPathWithParams: PropTypes.func.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  statusPageId: PropTypes.string,
  statusPageUpdateId: PropTypes.string
}

export default withParamNavigator(StatusPageUpdateOverlay)
