import { RED_TRIAL_DAYS } from "#Root/constants/trial"

const ProgressRing = ({ daysLeftInTrial }) => {
  let icon = <i className="far fa-spinner-third" />

  if (daysLeftInTrial < RED_TRIAL_DAYS) {
    icon = <i className="fa fa-exclamation-triangle" />
  }

  return icon
}

ProgressRing.propTypes = {
  daysLeftInTrial: PropTypes.number
}

export default ProgressRing
