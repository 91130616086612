// exposing an object with the features, so that we can rely on autocomplete,
// and to avoid using strings that could be misspelled or changed in the future.
export const platformFeatures = {
  HOST_MONITORING: "host_monitoring",
  PERFORMANCE_ISSUE_LIST: "performance_issue_list",
  PERFORMANCE_ACTIONS: "performance_actions",
  PERFORMANCE_SLOW_EVENTS: "performance_slow_events",
  PERFORMANCE_SLOW_API_REQUESTS: "performance_slow_api_requests",
  PERFORMANCE_SLOW_QUERIES: "performance_slow_queries",
}

const hideForPlatforms = {
  logplex: [],
  logplexv1: [],
  logplexv2: [],
  heroku: [],
  dokku: [],
  vercel: [],
  opentelemetry: [
    platformFeatures.HOST_MONITORING,
    platformFeatures.PERFORMANCE_ISSUE_LIST,
    platformFeatures.PERFORMANCE_ACTIONS,
    platformFeatures.PERFORMANCE_SLOW_EVENTS,
    platformFeatures.PERFORMANCE_SLOW_API_REQUESTS,
    platformFeatures.PERFORMANCE_SLOW_QUERIES,
  ],
  samples: [],
  frontend: [],
}

export const PlatformContext = React.createContext({ platforms: [] })

const usePlatformFeature = (featureList = hideForPlatforms) => {
  const platforms = React.useContext(PlatformContext)

  const hasPlatform = (platform) => platforms.includes(platform)

  const isFeatureVisible = (feature) => {
    if (platforms.length === 0) return true

    const featuresToBeHidden = []

    platforms.forEach((platform) => {
      if (Object.keys(featureList).includes(platform)) {
        featuresToBeHidden.push(...featureList[platform])
      }
    })

    const filteredFeatureList = featuresToBeHidden.filter(
      (potentialFeature) => potentialFeature === feature,
    )
    return filteredFeatureList.length !== platforms.length
  }

  return { isFeatureVisible, hasPlatform }
}

export default usePlatformFeature
