import { isDOMNode } from "./util"

// We have different sibling elements that don't need to close the hover-panel when the mouse enters them
export const captureMouseLeave = ({ event, onExit, lockRef, hoverRef, canvasRef }) => {
  const relatedTarget = event.relatedTarget

  if (!relatedTarget || !isDOMNode(relatedTarget)) {
    onExit({})
    return
  }

  // We're moving to the lock icon, so don't exit
  if (lockRef.current && lockRef.current.contains(relatedTarget)) {
    return
  }

  // We're moving to the hover panel, so don't exit
  if (hoverRef.current && hoverRef.current.contains(relatedTarget)) {
    return
  }

  // We're moving to the chart, so don't exit (usually after hovering the highlight line)
  if (canvasRef.current && canvasRef.current.contains(relatedTarget)) {
    return
  }

  onExit({})
}
