import gql from "graphql-tag"

export default gql`
  fragment TimelineEvent on TimelineEvent {
    action
    duration
    childDuration
    group
    name
    payload {
      name
      body
    }
    time
    end
    digest
    count
    level
    allocationCount
    childAllocationCount
  }
`
