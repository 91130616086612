import Button from "#Root/ui/Button"

const CopyToClipBoardButton = ({ content, children, copiedText = "copied!", ...buttonProps }) => {
  const [copyText, setCopyText] = React.useState(children)
  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(content)
      setCopyText(copiedText)
      setTimeout(() => setCopyText(children), 2000)
    } catch (err) {
      setCopyText("Failed :(")
    }
  }

  return (
    <Button
      onClick={() => copyToClipBoard()}
      {...buttonProps}
    >{copyText}</Button>
  )
}

CopyToClipBoardButton.propTypes = {
  content: PropTypes.string.isRequired,
  copiedText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node, PropTypes.string]).isRequired,
}

export default CopyToClipBoardButton
