import { area, curveMonotoneX } from "d3-shape"

import { useChartSettingsContext } from "#Root/contexts/ChartSettingsContext"
import cn from "#Root/utils/cn"

import { AREA_OPACITY } from "../../constants"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

export const Area = ({ className, data, id, ...rest }) => {
  const { xScale, yScale, colors, domain } = useChartGraphContext()
  const { hoveredLine } = useChartHoverStateContext()
  const color = colors({ id, ...rest })
  const { settings } = useChartSettingsContext()

  xScale.domain(domain.x)
  yScale.domain(domain.y)

  const d3Area = area()
    .y1((d) => yScale(d.y1))
    .y0((d) => yScale(d.y0))
    .x((d) => xScale(d.x))
    .curve(curveMonotoneX)
  const path = d3Area(data)

  const style = {
    stroke: color,
    color: color,
    fill: color,
    strokeWidth: settings.lineThickness,
    fillOpacity: hoveredLine && hoveredLine == id ? 1 : AREA_OPACITY,
  }

  return <path d={path} className={cn("area", className)} style={style} />
}

Area.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default Area
