import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import Tooltip from "../shared/Tooltip"

function TruncatedText({ text, textClassName, tooltip }) {
  const [isTruncated, setIsTruncated] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef.current.scrollWidth > textRef.current.clientWidth) {
      setIsTruncated(true)
    } else {
      setIsTruncated(false)
    }
  }, [text])

  if (isTruncated) {
    return (
      <Tooltip
        content={<div className="py-2">{ tooltip == null ? text : tooltip }</div>}
        placement={"bottom"}
      >
        <div ref={textRef} className={`truncate cursor-pointer ${textClassName}`}>{ text }</div>
      </Tooltip>
    )
  } else {
    return (
      <div ref={textRef} className={`truncate ${textClassName}`}>{ text }</div>
    )
  }
}

TruncatedText.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  textClassName: PropTypes.string,
  tooltip: PropTypes.object,
}

export default TruncatedText
