import NumberHelpers from "../base/number_helpers"
import UrlHelper from "../components/shared/url_helper"
import { replaceEntities } from "./string"

export const integrationsMap = {
  Asana: "tasks",
  GitHub: "issues",
  GitLab: "issues",
  PivotalTracker: "stories",
  Trello: "cards",
  Jira: "issues",
  Phabricator: "issues",
  Shortcut: "stories",
  Linear: "issues"
}

export function activeIntegration(integration) {
  const issues = integration[integrationsMap[integration.name]]
  return issues[0]
}

export function formattedBacktraceLine(lineObject) {
  const { column, line, method, original, path } = lineObject
  if (!path && !line) {
    return original
  }

  const parts = [path]
  if (line) { parts.push(`:${line}`) }
  if (column) { parts.push(`:${column}`) }
  if (method) { parts.push(` ${method}`) }
  return parts.join("")
}

export function IntegrationDescription(incident, sample, numberFormat) {
  const url = IntegrationUrl(incident, sample)
  if (incident.__typename === "ExceptionIncident") {
    return `\
**Message**

\`\`\`
  ${sample.exception.message}
\`\`\`

**Backtrace (last 10 lines)**

\`\`\`
${sample.exception.backtrace.slice(0, 10).map(b => formattedBacktraceLine(b)).join("\n")}
\`\`\`

View on AppSignal: ${url}\
    `
  } else {
    return `\
**Duration**

\`\`\`
  ${formattedDuration(sample.duration, numberFormat)}
\`\`\`

View on AppSignal: ${url}\
    `
  }
}

export function JiraIntegrationDescription(incident, sample, numberFormat) {
  const url = IntegrationUrl(incident, sample)
  if (incident.__typename === "ExceptionIncident") {
    return `\
h2. Message

{noformat}
  ${sample.exception.message}
{noformat}

h2. Backtrace (last 10 lines)

{noformat}
${sample.exception.backtrace.slice(0, 10).map(b => formattedBacktraceLine(b)).join("\n")}
{noformat}

View on AppSignal: ${url}\
`
  } else {
    return `\
h2. Duration

{noformat}
  ${formattedDuration(sample.duration, numberFormat)}
{noformat}

View on AppSignal: ${url}\
`
  }
}

export function AsanaIntegrationDescription(incident, sample, numberFormat) {
  const url = IntegrationUrl(incident, sample)
  if (incident.__typename === "ExceptionIncident") {
    return `\
<strong>Message</strong>
<code>
  ${replaceEntities(sample.exception.message)}
</code>

<strong>Backtrace (last 10 lines)</strong>
<code>
${replaceEntities(sample.exception.backtrace.slice(0, 10).map(b => formattedBacktraceLine(b)).join("\n"))}
</code>

View on AppSignal: <a href="${url}"/>\
`
  } else {
    return `\
<strong>Duration</strong>

<code>${formattedDuration(sample.duration, numberFormat)}</code>

View on AppSignal: <a href="${url}"/>\
`
  }
}

export function IntegrationTitle(incident) {
  let title

  if (incident.__typename === "ExceptionIncident") {
    title = `${incident.exceptionName}`
    if (incident.actionNames[0]) {
      title += ` happened in ${incident.actionNames[0]}`
    }
  } else {
    title = `Slow performance in ${incident.actionNames[0]}`
  }
  return title
}

function IntegrationUrl(incident, sample) {
  if (incident.__typename == "ExceptionIncident") {
    return `${UrlHelper.exceptionIncidentPageUrl({ number: incident.number })}`
  } else {
    return `${UrlHelper.performanceIncidentPageUrl({ number: incident.number })}?timestamp=${sample.time}`
  }
}

export function formattedDuration(value, numberFormat) {
  const [prefix, duration, suffix] = NumberHelpers.numberToDurationParts(value)
  const options = {
    separator: (numberFormat === "DC") ? "," : ".",
    delimiter: (numberFormat === "DC") ? "." : ",",
    strip_empty_fractional_parts: true,
    strip_insignificant_zeros: true
  }
  const formattedNumber = NumberHelpers.number_with_precision(duration, options)

  return `${prefix}${formattedNumber} ${suffix}`
}
