import { forwardRef } from "react"

const Box = forwardRef(function Box({ className, children, ...props }, ref) {
  return (
    <div ref={ref} className={`c-box ${className || ""}`} {...props}>
      {children}
    </div>
  )
})

Box.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

const Header = ({ children, flex }) => {
  return <div className={`c-box__header${flex ? " flex items-center" : ""}`}>{children}</div>
}

Header.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.bool,
}

const Content = ({ noSpacing = false, className = "", children }) => {
  return <div className={`${className} ${noSpacing ? null : "px-5 py-4"}`}>{children}</div>
}

Content.propTypes = {
  noSpacing: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
}

const Footer = ({ children }) => {
  return <footer className="c-box__footer">{children}</footer>
}

Footer.propTypes = {
  children: PropTypes.node,
}

Box.Header = Header
Box.Content = Content
Box.Footer = Footer

export default Box
