import MetricFormName from "../shared/metric_form/Name"
import VisualBuilderSkeletonSidebarMetricFormWithMetadata from "./SidebarMetricFormWithMetadata"

const VisualBuilderSkeletonSidebarMetricForm = ({ appId, onChange, metric, metricForm }) => {
  const handleChange = (changes) => {
    const metricConfig = {
      ...metric,
      ...changes,
    }
    onChange(metricConfig)
  }
  return (
    <>
      <MetricFormName onChange={handleChange} metric={metric} />
      {metric.name && (
        <VisualBuilderSkeletonSidebarMetricFormWithMetadata
          appId={appId}
          onChange={handleChange}
          metric={metric}
          metricForm={metricForm}
        />
      )}
    </>
  )
}

VisualBuilderSkeletonSidebarMetricForm.propTypes = {
  appId: PropTypes.string.isRequired,
  metric: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  metricForm: PropTypes.func.isRequired,
}
export default VisualBuilderSkeletonSidebarMetricForm
