import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"

import { labelWithHostnameFromTags } from "../../../../utils/host_metrics"
import { findTagValue } from "../../../../utils/metric_keys"
import Link from "../../../shared/link"
import TriggerFormButton from "../../../triggers/form_button"

const TriggerHostDiskUsage = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const { comparisonOperator, value } = trigger.thresholdCondition
  const tags = alertTags || trigger.tags
  const mountpoint = findTagValue(tags, "mountpoint")
  const label = labelWithHostnameFromTags(`Host disk usage for "${mountpoint}"`, tags)

  return (
    <>
      <span className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </span>
      <p className="pt-px text-gray-700">
        Disk usage
        <FormattedComparisonOperator value={comparisonOperator} />
        {value} %
      </p>
    </>
  )
}
TriggerHostDiskUsage.propTypes = {
  trigger: PropTypes.object.isRequired,
  tags: PropTypes.array,
  link: PropTypes.string,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostDiskUsage
