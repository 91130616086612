import gql from "graphql-tag"

export default gql `
query MetricTimeseriesQuery(
  $appId:     String!,
  $start:     DateTime,
  $end:       DateTime,
  $timeframe: TimeframeEnum,
  $resolution: ResolutionEnum,
  $timezone:   String,
  $query:     [MetricTimeseries]
) {
  app(id: $appId) {
    id
    metrics {
      timeseries(start: $start, end: $end, timeframe: $timeframe, resolution: $resolution, timezone: $timezone, query: $query) {
        start
        end
        resolution
        keys {
          digest
          name
          fields
          tags {
            key
            value
          }
        }
        points {
          timestamp
          values {
            key
            value
          }
        }
      }
    }
  }
}
`
