if (document.querySelector("#geckoboard-form")) {
  const generateGeckoboardUrl = function() {
    const form    = document.querySelector("#geckoboard-form")
    const baseUrl = form.dataset.base
    const params  = {}

    // Loop through the selects and get the values
    form.querySelectorAll("select").forEach(function(el, _index) {
      const nameAttr = el.getAttribute("name")
      params[nameAttr] = el.value
    })

    // Generate url from base and params from select
    const URLparams = new URLSearchParams(Object.entries(params))
    const url = `${baseUrl}?${URLparams}`
    form.querySelector("#geckoboard-url").value = url
  }

  document.querySelector("#geckoboard-form").addEventListener("change", generateGeckoboardUrl)

  generateGeckoboardUrl()
}
