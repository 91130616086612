import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"
import FormattedPercent from "#Root/formatters/FormattedPercent"

import { labelWithHostnameFromTags } from "../../../../utils/host_metrics"
import { findTagValue } from "../../../../utils/metric_keys"
import Link from "../../../shared/link"
import { STATES } from "../../../triggers/form/host/memory_usage"
import TriggerFormButton from "../../../triggers/form_button"

const TriggerHostMemoryUsage = ({ trigger, tags: alertTags, link, appId, triggerId }) => {
  const tags = alertTags || trigger.tags
  const { comparisonOperator, value } = trigger.thresholdCondition
  const label = labelWithHostnameFromTags("Host memory usage", tags)
  const state = findTagValue(tags, "state")
  let formattedValue
  if (state === "usage") {
    formattedValue = <FormattedPercent value={value} />
  } else {
    formattedValue = <FormattedFileSize value={value} />
  }

  return (
    <>
      <span className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={label} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={label}
            className="text-blue"
          />
        )}
      </span>
      <p className="pt-px text-gray-700">
        {STATES[state]}
        <FormattedComparisonOperator value={comparisonOperator} />
        {formattedValue}
      </p>
    </>
  )
}
TriggerHostMemoryUsage.propTypes = {
  trigger: PropTypes.object.isRequired,
  link: PropTypes.string,
  tags: PropTypes.array,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerHostMemoryUsage
