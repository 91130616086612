export const DatePickerContext = React.createContext()

export const useDatePickerContext = () => {
  const context = React.useContext(DatePickerContext)

  if (!context) {
    throw new Error("<DatePicker.*> can only be used as a child of <DatePicker>")
  }

  return context
}
