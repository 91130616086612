import gql from "graphql-tag"

import UptimeMonitorFragment from "./_uptimeMonitorFragment"

export const uptimeMonitorFormQuery = gql`
  query uptimeMonitorFormQuery($appId: String!, $id: String) {
    app(id: $appId) {
      id
      uptimeMonitor(id: $id) {
        ...UptimeMonitor
      }
      notifiers {
        id
        name
        icon
      }
      uptimeMonitorRegions {
        id
        name
      }
    }
  }
  ${UptimeMonitorFragment}
`

export default uptimeMonitorFormQuery
