import Duration from "#Components/shared/duration"
import HumanNumber from "#Components/shared/human_number"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"
import FormattedPercent from "#Root/formatters/FormattedPercent"
import FormattedThroughput from "#Root/formatters/FormattedThroughput"
import cn from "#Root/utils/cn"

import { useChartDataContext } from "../../contexts/DataContext"
import { useChartFormattingContext } from "../../contexts/FormattingContext"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

const Row = ({ serie }) => {
  const { valueFormat, valueInput, valuePrecision } = useChartFormattingContext()
  const { colors } = useChartGraphContext()
  const { toggleHoveredLine } = useChartHoverStateContext()
  const { disabledLines, toggleSelectedLine, timeseries } = useChartDataContext()

  const { resolution } = timeseries

  const disabled = disabledLines.includes(serie.id)
  const color = colors(serie)
  const value = disabled ? 0 : serie.value

  const content = () => {
    switch (valueFormat) {
      case "number":
        return <HumanNumber number={value} precision={valuePrecision} />
      case "duration":
        return <Duration val={value} />
      case "size":
        return <FormattedFileSize value={value} format={valueInput} />
      case "throughput":
        return <FormattedThroughput value={value} resolution={resolution} />
      case "percent":
        return <FormattedPercent value={value} precision={valuePrecision} />
    }
  }

  return (
    <div
      className="flex justify-between px-3 py-1 cursor-pointer text-gray-600"
      onClick={(e) => {
        toggleSelectedLine(serie.id, e.metaKey || e.altKey)
      }}
      onMouseEnter={() => {
        toggleHoveredLine(serie.id)
      }}
      onMouseLeave={() => {
        toggleHoveredLine(null)
      }}
    >
      <div className="flex mr-2 items-center">
        <div
          className={cn("h-3 w-3 rounded-full flex-shrink-0 mr-2 border-2 bg-white", {
            "border-gray-400": disabled,
          })}
          style={disabled ? {} : { borderColor: color }}
        ></div>
        <div className="truncate">{serie.label}</div>
      </div>
      <div className="ml-2 whitespace-nowrap tabular-nums">{content()}</div>
    </div>
  )
}

Row.propTypes = {
  serie: PropTypes.object.isRequired,
}
export default Row
