import gql from "graphql-tag"

export default gql`
  mutation DuplicateVisualMutation(
    $appId: String!,
    $visualId: String!,
    $newAppId: String!,
    $newDashboardId: String!
  ) {
    duplicateVisual(
      appId: $appId,
      visualId: $visualId,
      newAppId: $newAppId,
      newDashboardId: $newDashboardId
    ) {
      ... on VisualTimeseries {
        id
      }
    }
  }
`
