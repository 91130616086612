import { useDatePickerContext } from "#Root/contexts/DatePickerContext"

import Button from "../Button/Button"
export const DatePickerContainer = ({ handleApply, closeCalendar, children }) => {
  return (
    <div className="c-dropdown p-0 max-h-full" key="calendar-container">
      <div className="relative p-3">{children}</div>
      <div className="flex space-x-3 px-3 pb-3">
        <div className="w-1/2">{<TimeSelectInput name="from" />}</div>
        <div className="w-1/2">{<TimeSelectInput name="to" defaultValue="23:59:59" />}</div>
      </div>
      <div className="flex items-center p-3 border-t border-gray-200 space-x-3">
        <Button onClick={handleApply}>Apply</Button>
        <Button onClick={closeCalendar} color="white">
          Cancel
        </Button>
      </div>
    </div>
  )
}

DatePickerContainer.propTypes = {
  handleApply: PropTypes.func.isRequired,
  closeCalendar: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const TimeSelectInput = ({ name }) => {
  const { timestamp, setTimestamp } = useDatePickerContext()

  const handleTimestampChange = (e) => {
    setTimestamp({ ...timestamp, [name]: e.target.value })
  }

  return (
    <>
      <p className="not-italic font-normal text-sm text-gray-500">{name}</p>
      <input
        key="time-input"
        type="time"
        step="1"
        min="00:00:00"
        max="23:59:59"
        defaultValue={timestamp[name]}
        onBlur={handleTimestampChange}
        className="w-full box-border h-8 bg-gray-100 border-x border-y p-2 rounded"
      />
    </>
  )
}

TimeSelectInput.propTypes = {
  name: PropTypes.string.isRequired,
}

export default DatePickerContainer
