const TriggerFormDescription = ({ description, onChange }) => (
  <div className="c-form">
    <label className="c-form__label" htmlFor="trigger-description">
      Description
    </label>
    <div className="c-form__element">
      <div className="c-textfield">
        <textarea
          className="c-textfield__input"
          name="Description"
          id="trigger-description"
          value={description}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </div>
    <p className="c-form__description">
      This text is shown with alerts generated by this trigger. You can use it to tell the viewer
      what to do when this trigger generates an alert.
    </p>
  </div>
)

TriggerFormDescription.propTypes = {
  description: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default TriggerFormDescription
