import PropTypes from "prop-types"
import React from "react"

const VisualBuilderSkeletonPreview = ({ preview: Preview, ...props }) => {
  return <Preview {...props} />
}

VisualBuilderSkeletonPreview.propTypes = {
  preview: PropTypes.func.isRequired
}

export default VisualBuilderSkeletonPreview
