import Link from "#Root/components/shared/link"
import UrlHelper from "#Root/components/shared/url_helper"

export const IncidentNumber = ({ incident, className }) => {
  let href
  switch (incident.__typename) {
    case "ExceptionIncident":
      href = UrlHelper.exceptionIncidentPagePath({ number: incident.number })
      break
    case "PerformanceIncident":
      href = UrlHelper.performanceIncidentPagePath({ number: incident.number })
      break
    case "LogIncident":
      href = UrlHelper.logsIncidentPagePath({ number: incident.number })
      break
    case "AnomalyIncident":
      href = UrlHelper.anomalyIncidentPagePath({ number: incident.number })
      break
  }

  return (
    <Link
      href={href}
      title={`#${incident.number}`}
      className={"text-gray-600 no-underline " + className || "pl-2"}
    />
  )
}

IncidentNumber.propTypes = {
  incident: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default IncidentNumber
