export function aggregateMetricList(metrics) {
  const cache = {}

  for (const metric of metrics) {
    const cacheKey = metric.tags.map(t => t.value).sort().join("_")
    const cacheValue = cache[cacheKey] || { id: cacheKey, tags: metric.tags, fields: [] }

    for (const field of metric.fields) {
      const keyKey = `${metric.name}_${field.key}`
      cacheValue.fields.push({ key: keyKey, value: field.value })
    }
    cache[cacheKey] = cacheValue
  }
  return Object.values(cache)
}

export function aggregateMetricListToObjects(metrics, map) {
  const cache = {}

  for (const metric of metrics) {
    // Only return metrics in the map
    if (!map[metric.name]) { continue }

    const cacheKey = metric.tags.map(t => t.value).sort().join("_")
    const cacheValue = cache[cacheKey] || defaultMetricObject(cacheKey, metric.tags, map)

    for (const field of metric.fields) {
      // Only return fields in the map
      if (!map[metric.name][field.key]) { continue }
      cacheValue[map[metric.name][field.key]] = field.value
    }
    cache[cacheKey] = cacheValue
  }
  return Object.values(cache)
}

function defaultMetricObject(key, tags, map) {
  const object = { id: key }

  for (const tag of tags) {
    object[tag.key] = tag.value
  }

  for (const metric of Object.values(map)) {
    for (const field of Object.values(metric)) {
      object[field] = 0
    }
  }
  return object
}
