import { useHotkeys } from "react-hotkeys-hook"

import { TIME_DETECTIVE_SHORTCUT_KEY } from "#Root/constants/KeyboardShortcuts"
import { useRouter } from "#Root/hooks"
import { currentRange } from "#Root/utils/TimeDetective"

export const GlobalKeyboardShortcuts = () => {
  const { navigateWithParams } = useRouter()

  useHotkeys(
    TIME_DETECTIVE_SHORTCUT_KEY,
    () => {
      const { start, end } = currentRange()

      navigateWithParams({
        overlay: "timeDetective",
        td_start: start.toISOString(),
        td_end: end.toISOString(),
      })
    },
    { scopes: ["global"], enableOnFormTags: true, enabled: true, enableOnContentEditable: true }
  )

  return <></>
}

export default GlobalKeyboardShortcuts
