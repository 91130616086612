export const dashboardsPropType = PropTypes.array

const TriggerFormDashboard = ({ dashboardId, dashboards, onChange }) => (
  <div className="c-form">
    <label className="c-form__label" htmlFor="dashboardId">
      Dashboard
    </label>
    <div className="c-form__element">
      <div className="c-select">
        <select
          onChange={(e) => onChange(e.target.value)}
          id="dashboardId"
          name="dashboardId"
          value={dashboardId || ""}
          className="c-select__input required"
        >
          <option key="" value=""></option>
          {dashboards.map(({ id, title }) => (
            <option key={id} value={id}>
              {title}
            </option>
          ))}
        </select>
      </div>
    </div>
    <p className="c-form__description">
      Select a dashboard to link. When opening an alert from a notification it will open the linked
      dashboard for the time it occurred.
    </p>
  </div>
)

TriggerFormDashboard.propTypes = {
  dashboardId: PropTypes.string,
  dashboards: PropTypes.array,
  onChange: PropTypes.func.isRequired,
}

export default TriggerFormDashboard
