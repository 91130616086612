import gql from "graphql-tag"

import StatusPageUpdateFragment from "./_statusPageUpdateFragment"

export default gql`
  mutation createStatusPageUpdateMutation(
    $organizationSlug: String!,
    $statusPageId: String!,
    $statusPageUpdate: StatusPageUpdateInput!
  ) {
    createStatusPageUpdate(
      organizationSlug: $organizationSlug,
      statusPageId: $statusPageId,
      statusPageUpdate: $statusPageUpdate
    ) {
      ...StatusPageUpdate
    }
  }
  ${StatusPageUpdateFragment}
`
