import { useQuery } from "@apollo/react-hooks"

import Timeframes from "#Root/base/timeframes"
import MetricTimeseriesQuery from "#Root/graphql/metrics_timeseries_query"
import { useRouter } from "#Root/hooks"

const identity = (x) => x

const GraphQL = (props) => {
  const { getParams } = useRouter()
  const params = getParams()
  // TODO: this is wrong. This might get unrelated stuff like `to=page_redirect`
  // Let's explicitly pass the values we need everywhere
  const {
    pollInterval: givenPollInterval,
    queries,
    resolution,
    onSuccess = identity,
    from,
    to,
    appId = params.appId,
    timeframe,
  } = props

  const pollInterval = isNaN(givenPollInterval)
    ? Timeframes.pollIntervalFromTimeframe(timeframe)
    : givenPollInterval

  const { data, loading, error } = useQuery(MetricTimeseriesQuery, {
    variables: {
      appId: appId,
      start: from,
      end: to || undefined, // ditch nulls TODO: fix this
      timeframe: Timeframes.backwardsCompatibleTimeframeKey(timeframe),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      resolution: resolution,
      query: queries.map((query) => ({
        name: query.name,
        tags: query.tags,
        fields: query.fields,
      })),
    },
    pollInterval,
    fetchPolicy: "network-only",
  })

  // The following line throws if there's more than one child
  const container = React.Children.only(props.children)
  const timeseries = data ? onSuccess(data?.app?.metrics?.timeseries) : {}

  return React.cloneElement(container, {
    ...container.props,
    timeseries,
    loading,
    error,
  })
}

GraphQL.propTypes = {
  queries: PropTypes.array.isRequired,
  resolution: PropTypes.string,
  pollInterval: PropTypes.number,
  children: PropTypes.node.isRequired,
  onSuccess: PropTypes.func,
  //
  appId: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  timeframe: PropTypes.string,
}

export default GraphQL
