import { useMutation, useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useState } from "react"

import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import VisualQuery from "../../graphql/visual_query"
import Ident from "../../utils/Ident"
import { cleanObject } from "../../utils/object"
import ErrorBox from "../shared/error_box"
import Skeleton from "./Skeleton"
import Timeseries from "./visuals/timeseries/Timeseries"

const states = {
  new: {
    submitLabel: "Create graph",
  },
  edit: {
    submitLabel: "Update graph",
  },
}

// Remove __typename and ids because they are not part of the input type
// for GraphQL
export const cleanVisual = (visual) => {
  delete visual.__typename
  delete visual.id
  for (const metric of visual.metrics) {
    delete metric.__typename
    const fields = metric.fields || []
    if (fields.length > 0) {
      for (const field of fields) {
        delete field.__typename
      }
    }
    const tags = metric.tags || []
    if (tags.length > 0) {
      for (const tag of tags) {
        delete tag.__typename
      }
    }
  }
  return visual
}

// Remove any incomplete config to be used by the previews, so that it doesn't
// make any invalid queries to the GraphQL API.
export const normalizeVisual = (rawObject) => {
  const object = cleanObject(rawObject, [], [null, undefined, ""])
  const metrics = []
  object.metrics.forEach((metric) => {
    if (metric.name && (metric.fields || []).length > 0) {
      // Remove any metrics that are incomplete so that the graph and
      // code preview don't render with invalid configs
      metrics.push(normalizeMetric(metric))
    }
  })
  object.metrics = metrics
  return object
}

const normalizeMetric = (metric) => {
  const tags = metric.tags
  if (tags) {
    const newTags = []
    tags.forEach((tag) => {
      if (tag && tag.key) {
        // Remove any metric tags that are incomplete so that the graph and
        // code preview don't render with invalid configs
        newTags.push(tag)
      }
    })
    if (newTags.length > 0) {
      // Don't set any tags key is there are no tags
      metric.tags = newTags
    } else {
      delete metric.tags
    }
  }
  return metric
}

export const lineLabelFor = (metrics) => {
  const mappedTags = new Set()
  const mappedFields = new Set()
  metrics.forEach((metric) => {
    const fields = metric.fields || []
    fields.forEach((field) => mappedFields.add(field.field))
    const tags = metric.tags || []
    tags.forEach((tag) => tag && mappedTags.add(`%${tag.key}%`))
  })
  const label = ["%name%"]
  if (mappedFields.size > 1) {
    label.push(" %field%")
  }
  if (mappedTags.size > 0) {
    label.push(` ${[...mappedTags].sort().join("-")}`)
  }
  return label.join("")
}

const VisualBuilderEditor = ({ appId, dashboardId, visualId, closeOverlay }) => {
  const VisualConfig = Timeseries
  const [visual, setVisual] = useState(VisualConfig.newMetric)
  const [lineLabelModified, setLineLabelModified] = useState(false)
  const [mutationError, setMutationError] = useState(null)
  const onSuccess = (identMetric) => {
    setMutationError(null)
    closeOverlay()
    Ident.trackAction(identMetric)
  }
  const onError = (message, error) => {
    // eslint-disable-next-line no-console
    console.error(message, error)
    setMutationError(error)
  }
  const [createVisual] = useMutation(VisualConfig.CreateMutation, {
    onCompleted() {
      onSuccess("GraphBuilderOverlayCreateGraph")
    },
    onError(error) {
      onError("Error creating graph", error)
    },
  })
  const [updateVisual] = useMutation(VisualConfig.UpdateMutation, {
    onCompleted() {
      onSuccess("GraphBuilderOverlayUpdateGraph")
    },
    onError(error) {
      onError("Error updating graph", error)
    },
  })
  const stateKey = visualId ? "edit" : "new"
  const state = states[stateKey]
  const handleChange = (changes) => {
    const hasLineLabelBeenModified = !!changes.lineLabel
    if (!lineLabelModified && changes.metrics) {
      changes.lineLabel = lineLabelFor(changes.metrics)
    }
    if (hasLineLabelBeenModified) {
      setLineLabelModified(true)
    }
    setVisual({ ...visual, ...changes })
  }
  const handleSubmit = () => {
    const payload = { appId, visual: cleanVisual(visual) }
    if (stateKey === "edit") {
      updateVisual({ variables: { ...payload, visualId } })
    } else {
      createVisual({ variables: { ...payload, dashboardId } })
    }
  }

  const { loading, error } = useQuery(VisualQuery, {
    skip: stateKey !== "edit",
    variables: { appId, dashboardId, visualId },
    onCompleted: (data) => {
      if (!data) {
        return
      } // Skipped request

      setVisual(cleanVisual(data.app.dashboard.visual))
    },
  })
  if (loading) {
    return <LoadingAnimation message="Loading data" />
  }
  if (error) {
    return <ErrorBox error={error} />
  }

  return (
    <Skeleton
      appId={appId}
      metricForm={VisualConfig.MetricForm}
      optionsForm={VisualConfig.OptionsForm}
      preview={VisualConfig.Preview}
      visual={visual}
      visualPreview={normalizeVisual(visual)}
      submitLabel={state.submitLabel}
      error={mutationError}
      closeOverlay={closeOverlay}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  )
}

VisualBuilderEditor.propTypes = {
  appId: PropTypes.string.isRequired,
  dashboardId: PropTypes.string.isRequired,
  visualId: PropTypes.string,
  closeOverlay: PropTypes.func.isRequired,
}

export default VisualBuilderEditor
