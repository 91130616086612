import PropTypes from "prop-types"
import React from "react"

import withParamNavigator from "../../../wrappers/param_navigator"
import Link from "../../shared/link"

export const TriggerFormSidebar = ({ currentForm, currentPathWithParams }) => {
  return (
    <ul>
      <h3 className="c-heading--caps mb-3 text-ms text-gray-700 font-semibold">Actions</h3>
      <li>
        <ul className="c-side-nav -mx-3 space-y-0">
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "ExceptionRate" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "ExceptionRate" })}>
              <span className="c-side-nav__item-title">Error rate</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "ExceptionCount" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "ExceptionCount" })}>
              <span className="c-side-nav__item-title">Error count</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "Throughput" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "Throughput" })}>
              <span className="c-side-nav__item-title">Throughput</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "Performance" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "Performance" })}>
              <span className="c-side-nav__item-title">Slow actions</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "QueueTime" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "QueueTime" })}>
              <span className="c-side-nav__item-title">Queue time</span>
            </Link>
          </li>
        </ul>
      </li>

      <h3 className="c-heading--caps mt-6 mb-3 text-ms text-gray-700 font-semibold">Host metrics</h3>
      <li>
        <ul className="c-side-nav -mx-3 space-y-0">
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostCPUUsage" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostCPUUsage" })}>
              <span className="c-side-nav__item-title">CPU usage</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostDiskIO" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostDiskIO" })}>
              <span className="c-side-nav__item-title">Disk I/O</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostDiskUsage" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostDiskUsage" })}>
              <span className="c-side-nav__item-title">Disk usage</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostLoadAverage" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostLoadAverage" })}>
              <span className="c-side-nav__item-title">Load average</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostMemoryUsage" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostMemoryUsage" })}>
              <span className="c-side-nav__item-title">Memory usage</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostSwapUsage" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostSwapUsage" })}>
              <span className="c-side-nav__item-title">Swap usage</span>
            </Link>
          </li>
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "HostNetworkTraffic" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "HostNetworkTraffic" })}>
              <span className="c-side-nav__item-title">Network traffic</span>
            </Link>
          </li>
        </ul>
      </li>

      <h3 className="c-heading--caps mt-6 mb-3 text-ms text-gray-700 font-semibold">Other</h3>
      <li>
        <ul className="c-side-nav -mx-3 space-y-0">
          <li className={`c-side-nav__section c-side-nav__section--blue ${currentForm == "Advanced" ? "c-side-nav__section--active" : ""}`}>
            <Link className="c-side-nav__item" href={currentPathWithParams({ form: "Advanced" })}>
              <span className="c-side-nav__item-title">Custom metrics</span>
            </Link>
          </li>
        </ul>
      </li>
    </ul>
  )
}
TriggerFormSidebar.propTypes = {
  currentForm: PropTypes.string.isRequired,
  currentPathWithParams: PropTypes.func.isRequired
}

export default withParamNavigator(TriggerFormSidebar)
