import PropTypes from "prop-types"
import React from "react"

import SidebarMetricForm from "./SidebarMetricForm"
const VisualBuilderSkeletonSidebarMetricPanel = ({
  metricForm,
  onChange,
  metric,
  appId,
  closeMetricsPanel
}) => {
  const handleDelete = () => {
    if (window.confirm("Are you sure you want to delete this metric? You can not undo this.")) {
      onChange(null)
    }
  }

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div>
          <div className="sticky top-0 flex items-center bg-white border-b z-10">
            <a
              title="Back to overview"
              className="cursor-pointer ml-2 p-4"
              onClick={closeMetricsPanel}
            ><i className="fa fa-arrow-left" /></a>
            <h1 className="c-heading py-4 mb-0">Edit metric</h1>
          </div>
          <div className="m-6">
            <SidebarMetricForm appId={appId} onChange={onChange} metric={metric} metricForm={metricForm}/>
          </div>
        </div>
        <div>
          <a
            className="c-button c-button--sm c-button--white text-center block cursor-pointer m-6 mt-0"
            onClick={closeMetricsPanel}
          ><i className="fa fa-fw fa-arrow-left mr-2" />Back to overview</a>
          <hr className="w-full mx-0 bg-none border-b" />
          <a
            className="c-button c-button--sm c-button--red text-center block cursor-pointer m-6"
            onClick={handleDelete}
          >Delete metric</a>
        </div>
      </div>
    </>
  )
}

VisualBuilderSkeletonSidebarMetricPanel.propTypes = {
  appId: PropTypes.string.isRequired,
  metric: PropTypes.object.isRequired,
  metricForm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  closeMetricsPanel: PropTypes.func.isRequired
}

export default VisualBuilderSkeletonSidebarMetricPanel
