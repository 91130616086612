import { useMutation } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useContext,useRef, useState } from "react"

import { RouterContext } from "../../contexts/RouterContext"
import AppInfoQuery from "../../graphql/app_info_query"
import ImportDashboardMutation from "../../graphql/import_dashboard_mutation"
import withParamNavigator from "../../wrappers/param_navigator"
import Overlay from "../shared/overlay"
import UrlHelper from "../shared/url_helper"
import ValidationErrors from "../shared/validation_errors"

export const DashboardImportOverlay = ({ currentPathWithParams, appId }) => {
  const router = useContext(RouterContext)
  const [mutation] = useMutation(ImportDashboardMutation)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const jsonRef = useRef()

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (isLoading) { return }
    setIsLoading(true)

    await mutation({
      awaitRefetchQueries: true,
      variables: {
        appId,
        json: jsonRef.current.value,
      },
      refetchQueries: [{
        query: AppInfoQuery,
        variables: { appId }
      }],
    }).then(({ data: { importDashboard: { id } }}) => {
      router.navigate(UrlHelper.metricsShowPath({ id }), { trigger: true })
    }).catch(error => {
      setIsLoading(false)
      setError(error)
    })
  }

  // handleClose from Overlay doesn't emit an event
  const handleClose = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    router.navigate(currentPathWithParams({ overlay: "dashboardForm" }), { trigger: true })
  }

  return (
    <Overlay
      classNames="medium"
      onClose={handleClose}
      title="Import dashboard from JSON"
    >
      <form className="c-box text-gray-800" onSubmit={handleSubmit}>
        <div className="c-box__header">
          <h2>Dashboard JSON</h2>
        </div>
        {error && <ValidationErrors error={error} title="Dashboard could not be imported, make sure the JSON is valid." />}
        <div className="p-5">
          <div className="c-textarea">
            <textarea
              ref={jsonRef}
              name="json"
              className="c-textarea__input"
              placeholder="Paste the exported dashboard JSON here."
              rows="16"
            >
            </textarea>
          </div>
        </div>
        <div className="border-t border-gray-200 p-4 text-right">
          <a className="c-button c-button--sm c-button--white mr-4 cursor-pointer" onClick={handleClose}>Cancel</a>
          <input
            type="submit"
            value="Create dashboard"
            className={`ignore-old-css c-button c-button--sm cursor-pointer ${isLoading ? "c-button--disabled" : ""}`}
            disabled={isLoading}
          />
        </div>
      </form>
    </Overlay>
  )
}

DashboardImportOverlay.propTypes = {
  currentPathWithParams: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired
}

export default withParamNavigator(DashboardImportOverlay)
