import Link from "#Root/components/shared/link"
import FormattedTime from "#Root/formatters/FormattedTime"
import { useRouter } from "#Root/hooks"

import { MARKERS_HEIGHT } from "../../constants"
import { useChartGraphContext } from "../../contexts/GraphContext"

const CustomMarker = ({ marker, spacingLeft }) => {
  const { xScale, domain } = useChartGraphContext()
  const { currentPathWithParams } = useRouter()

  xScale.domain(domain.x)

  const markerEditPath = currentPathWithParams({
    overlay: "customMarker",
    markerId: marker.id,
    startDate: xScale.domain()[0].toISOString(),
    endDate: xScale.domain()[1].toISOString(),
  })

  return (
    <div
      className="marker-custom h-full absolute top-[50%] -translate-x-2/4 -translate-y-2/4 flex items-center justify-center group"
      style={{ left: `${spacingLeft}px` }}
      data-marker-type="custom"
    >
      <Link href={markerEditPath} className="no-underline">
        {marker.icon}
      </Link>
      <div
        className="absolute bottom-0 c-box w-56 p-4 hidden group-hover:block"
        style={{ marginBottom: `${MARKERS_HEIGHT / 2 + 15}px` }}
      >
        <table className="c-table">
          <tbody>
            <tr>
              <td>
                Time: <FormattedTime value={marker.createdAt} format="short" />
              </td>
            </tr>
            <tr>
              <td>{marker.message ? marker.message : "No message"}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

CustomMarker.propTypes = {
  marker: PropTypes.object.isRequired,
  spacingLeft: PropTypes.number.isRequired,
}

export default CustomMarker
