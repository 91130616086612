import React, { createContext, useContext, useState } from "react"

const DraggingContext = createContext()

export const DraggingProvider = ({ children }) => {
  const [dragging, setDragging] = useState(false)
  const [dragCoords, setDragCoords] = useState({ start: null, end: null })

  const hasSelectedArea =
    // prettier-ignore
    dragCoords.start && dragCoords.end
      ? Boolean(
        Math.abs(dragCoords.start.x - dragCoords.end.x) * Math.abs(dragCoords.start.y - dragCoords.end.y),
      )
      : false

  return (
    <DraggingContext.Provider
      value={{ dragging, setDragging, dragCoords, setDragCoords, hasSelectedArea }}
    >
      {children}
    </DraggingContext.Provider>
  )
}

export const useDraggingContext = () => useContext(DraggingContext)

DraggingProvider.propTypes = {
  children: PropTypes.node,
}
