import React from "react"
import Linkify from "react-linkify"

// Small function to generate react keys from (long) strings
// from: https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
export function hashCode(stringOrObject) {
  let string = stringOrObject
  if (typeof stringOrObject === "object") {
    string = JSON.stringify(stringOrObject)
  }
  let hash = 0
  if (string.length == 0) return hash
  for (let i = 0; i < string.length; i++) {
    hash = (hash << 5) - hash + string.charCodeAt(i)
    hash = hash & hash
  }
  return hash
}

export function filteredErrors(error, filter) {
  if (error.networkError && error.networkError.result && error.networkError.result.errors) {
    return error.networkError.result.errors.filter(
      (error) => error.extensions && error.extensions.__typename == filter,
    )
  }
  return []
}

export function linkifyComponentDecorator(href, text, key) {
  return (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )
}

export function formattedUserContent(content, className = "c-p mb-0") {
  if (content === undefined || content === null) {
    return ""
  }
  return content.split("\n").map((item, key) => {
    return (
      <p key={key} className={className}>
        <Linkify componentDecorator={linkifyComponentDecorator}>{item}</Linkify>
      </p>
    )
  })
}
