import cn from "#Root/utils/cn"

import { BAR_EFFECTIVE_WIDTH, BAR_OPACITY } from "../../constants"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { useChartHoverStateContext } from "../../contexts/HoverStateContext"

export const Bar = ({ className, id, data, ...rest }) => {
  const { canvasWidth, yScale, xScale, colors, onClick, domain } = useChartGraphContext()
  const { invertDate, hoveredLine } = useChartHoverStateContext()

  xScale.domain(domain.x)
  yScale.domain(domain.y)

  const [xDomainStart, xDomainEnd] = domain.x
  const visibleDataPoints = data.filter((d) => d.x >= xDomainStart && d.x <= xDomainEnd).length

  const pointWidth = canvasWidth / visibleDataPoints
  // Making the bars slightly narrower than the actual width
  const barWidth = (pointWidth * BAR_EFFECTIVE_WIDTH).toFixed(2)
  // Making the rest the spacing. No fixed width, but relative to the point width
  const barSpacing = pointWidth * (1 - BAR_EFFECTIVE_WIDTH).toFixed(2)
  const color = colors({ id, ...rest })

  const bars = data.map((point) => {
    let opacity = BAR_OPACITY

    if (point.x === invertDate && hoveredLine && hoveredLine == id) {
      opacity = 1
    } else if (point.x === invertDate && onClick) {
      opacity = 1
    }

    const x = xScale(point.x)
    const y1 = yScale(point.y1)
    const y0 = yScale(point.y0)
    // show at least 1px for 0 values, or outliers that would be less than 1px
    const height = y0 - y1 <= 1 ? 1 : y0 - y1

    return (
      <rect
        className={cn("bar", className)}
        height={height}
        width={barWidth}
        fill={color}
        opacity={opacity}
        style={{ cursor: onClick ? "pointer" : "default" }}
        x={x + barSpacing / 2}
        y={y1}
        key={`bar-${point.x}`}
      />
    )
  })

  return <g>{bars}</g>
}

Bar.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
}

export default Bar
