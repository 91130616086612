import FormattedComparisonOperator from "#Root/formatters/FormattedComparisonOperator"
import FormattedFileSize from "#Root/formatters/FormattedFileSize"
import FormattedPercent from "#Root/formatters/FormattedPercent"

import Duration from "../../shared/duration"
import HumanNumber from "../../shared/human_number"
import Link from "../../shared/link"
import TriggerFormButton from "../../triggers/form_button"

const TriggerAdvanced = ({ trigger, tags, link, appId, triggerId }) => {
  const { comparisonOperator, value } = trigger.thresholdCondition
  const formattedTags = (tags || trigger.tags).map((t) => `${t.key}:${t.value}`).join(", ")
  const formatValue = function (value, format, formatInput) {
    const valuePrecision = 2
    switch (format) {
      case "duration": {
        return <Duration val={value} />
      }
      case "size": {
        return <FormattedFileSize value={value} format={formatInput} />
      }
      case "throughput": {
        return (
          <>
            <HumanNumber number={value} precision={valuePrecision} />
            &nbsp;
            <abbr title="requests per minute">req/min</abbr>
          </>
        )
      }
      case "percent": {
        return <FormattedPercent value={value} precision={valuePrecision} />
      }
      default: {
        return <HumanNumber number={value} precision={valuePrecision} />
      }
    }
  }

  return (
    <>
      <p className="pb-px truncate">
        {link ? (
          <Link className="text-blue" href={link} title={trigger.name} />
        ) : (
          <TriggerFormButton
            appId={appId}
            triggerId={triggerId}
            title={trigger.name}
            className="text-blue"
          />
        )}
      </p>
      <p className="pt-px text-gray-700 truncate">{formattedTags}</p>
      <div className="text-gray-700">
        Value
        <FormattedComparisonOperator value={comparisonOperator} />
        {formatValue(value, trigger.format, trigger.formatInput)}
      </div>
    </>
  )
}

TriggerAdvanced.propTypes = {
  trigger: PropTypes.object.isRequired,
  tags: PropTypes.array,
  link: PropTypes.string,
  appId: PropTypes.string,
  triggerId: PropTypes.string,
}

export default TriggerAdvanced
