import gql from "graphql-tag"

export default gql`
  query Search(
    $organizationSlug: String!
    $query: String
    $namespace: String
    $appId: String
    $sampleType: SampleTypeEnum
    $timerange: [DateTime]
  ) {
    organization(slug: $organizationSlug) {
      id
      apps {
        id
        name
        environment
        namespaces
      }
      search(
        query: $query
        namespace: $namespace
        appId: $appId
        sampleType: $sampleType
        timerange: $timerange
      ) {
        ... on ExceptionSample {
          id
          time
          action
          namespace
          overview {
            key
            value
          }
          exception {
            name
            message
          }
          incident {
            ... on ExceptionIncident {
              number
            }
          }
          app {
            name
            environment
            id
          }
        }
        ... on PerformanceSample {
          id
          appId
          time
          action
          namespace
          duration
          overview {
            key
            value
          }
          incident {
            ... on PerformanceIncident {
              number
            }
          }
          app {
            name
            environment
            id
          }
        }
      }
    }
  }
`
