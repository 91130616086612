import FormattedTime from "#Root/formatters/FormattedTime"

import { MARKERS_HEIGHT } from "../../constants"

const DeployMarker = ({ marker, spacingLeft }) => {
  const onClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    if (marker.gitCompareUrl) {
      window.open(marker.gitCompareUrl, "_blank")
    }
  }
  return (
    <div
      className="h-full absolute top-[50%] -translate-x-2/4 -translate-y-2/4 flex items-center justify-center group"
      style={{ left: `${spacingLeft}px` }}
      onClick={onClick}
      data-marker-type="deploy"
    >
      <span className="cursor-pointer h-3 w-3 relative">
        <span className="absolute block w-1.5 h-1.5 top-[3px] left-[3px] bg-blue/30 group-hover:bg-blue rounded-full"></span>
        <span className="absolute block w-3 h-3 top-0 left-0 bg-blue/10 group-hover:bg-blue/40 rounded-full"></span>
      </span>
      <div
        className="absolute bottom-0 c-box w-56 p-4 hidden group-hover:block"
        style={{ marginBottom: `${MARKERS_HEIGHT / 2 + 15}px` }}
      >
        <table className="c-table">
          <tbody>
            <tr>
              <td className="w-1/3">Deploy:</td>
              <td>
                <FormattedTime value={marker.createdAt} format="short" />
              </td>
            </tr>
            <tr>
              <td>Revision:</td>
              <td>{marker.shortRevision}</td>
            </tr>
            {marker.user && (
              <tr>
                <td>User:</td>
                <td>{marker.user}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

DeployMarker.propTypes = {
  marker: PropTypes.object.isRequired,
  spacingLeft: PropTypes.number.isRequired,
}

export default DeployMarker
