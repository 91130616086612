import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory"

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: [
        {
          kind: "UNION",
          name: "Marker",
          possibleTypes: [
            { name: "DeployMarker" },
            { name: "NotificationMarker" },
            { name: "CustomMarker" },
          ],
        },
        {
          kind: "Interface",
          name: "Incident",
          possibleTypes: [
            { name: "PerformanceIncident" },
            { name: "ExceptionIncident" }
          ],
        },
        {
          kind: "UNION",
          name: "Visual",
          possibleTypes: [
            { name: "VisualTimeseries" }
          ],
        },
      ],
    },
  }
})

const apolloCache = new InMemoryCache({ fragmentMatcher })

export { apolloCache, fragmentMatcher }
