export function tagsToString(tags) {
  if (tags.length == 0) {
    return ""
  }
  return tags.map(t => `${t.key}:${t.value}`).join(", ")
}

export function tagsToArray(tags) {
  if (!tags){
    return []
  }
  const tagArray = []
  for (const splitTag of tags.split(", ")) {
    const [key, ...values] = splitTag.split(":")
    tagArray.push({ key: key || "", value: values.join(":") || "" })
  }
  return tagArray
}
