const DEFAULT_MIN_PREFIX_LENGTH = 8

export const findCommonPrefix = (words, minPrefixLength = DEFAULT_MIN_PREFIX_LENGTH) => {

  if (words.length <= 1) {
    return ""
  }

  let index = 0

  // while all words have the same character at the currentIndex, move on
  while (words[0][index] && words.every((w) => w[index] === words[0][index])) {
    index++
  }
  const prefix = words[0].substr(0, index)

  // if the prefix is less than X characters, it's not worth it
  if (prefix.length < minPrefixLength) {
    return ""
  }

  // let's check that the prefix isn't the whole word
  return prefix.length === words[0].length ? "" : prefix
}
