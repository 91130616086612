import useFormattedNumber from "#Root/hooks/formatters/useFormattedNumber"

const FormattedNumber = ({ value, precision }) => {
  const { formatNumberWithPrecision } = useFormattedNumber()
  const formattedNumber = formatNumberWithPrecision(value, precision)

  return <span>{formattedNumber}</span>
}

FormattedNumber.propTypes = {
  value: PropTypes.number.isRequired,
  precision: PropTypes.number,
}

export default FormattedNumber
