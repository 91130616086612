import gql from "graphql-tag"

export default gql `
  fragment StatusPageUpdate on StatusPageUpdate {
    id
    title
    state
    description
  }
`
