import gql from "graphql-tag"

import StatusPageFragment from "./_statusPageFragment"

export default gql`
  mutation deleteStatusPageMutation(
    $organizationSlug: String!,
    $id: String!,
  ) {
    deleteStatusPage(
      organizationSlug: $organizationSlug,
      id: $id
    ) {
      ...StatusPage
    }
  }
  ${StatusPageFragment}
`
