import PropTypes from "prop-types"
import React from "react"

import EnvironmentLabel from "../shared/environment_label"
import Link from "../shared/link"
import UrlHelper from "../shared/url_helper"
import SampleDetail from "./sample_detail"

const SearchErrorRow = ({ sample }) => {
  const { incident } = sample
  const url = UrlHelper.exceptionIncidentSamplePagePath({
    number: incident.number,
    sampleId: sample.id,
    appId: sample.app.id
  })
  return (
    <>
      <article className="c-box">
        <header className="px-5 py-4 flex items-center justify-between border-b">
          <div className="flex items-center">
            <div className="c-icon-box c-icon-box--sm c-icon-box--orange mr-5">
              <i className="fa fa-bug text-sm"></i>
            </div>
            <div>
              <h3>
                <ul className="flex space-x-3 mb-1">
                  <li>
                    <span className="font-semibold">{sample.app.name}</span>
                    <EnvironmentLabel env={sample.app.environment} className="ml-1" />
                  </li>
                  <li>
                    <i className="far fa-angle-right text-gray-700 text-ms"></i>
                  </li>
                  <li>
                    <span className="font-semibold">{sample.exception.name}</span>
                    <span className="text-gray-700 ml-1">#{incident.number}</span>
                  </li>
                </ul>
                <div className="flex items-center">
                  <span className="text-gray-700">{sample.action} in </span>
                  <span className="c-pill ml-1">{sample.namespace}</span>
                </div>
              </h3>
            </div>
          </div>
          <div>
            <Link className="c-button c-button--sm c-button--white" href={url}>
              Go to sample
            </Link>
          </div>
        </header>
        <SampleDetail sample={sample} url={url} />
      </article>
    </>
  )
}

SearchErrorRow.propTypes = {
  params: PropTypes.object.isRequired,
  sample: PropTypes.object.isRequired
}

export default SearchErrorRow
