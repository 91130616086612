import PropTypes from "prop-types"
import React from "react"

import TooltipWithIcon from "../../../shared/TooltipWithIcon"

const VisualFormDisplay = ({ visual, onChange }) => {
  const { display: selectedDisplay } = visual

  function renderDisplayRadioButton(display, label) {
    return (
      <div className="c-radio">
        <input
          type="radio"
          id={`visual-display-${display}`}
          name="visual-display"
          className="c-radio__input"
          value={display}
          checked={selectedDisplay === display}
          onChange={(e) => onChange({ display: e.target.value })}
        />
        <label className="ignore-old-css" htmlFor={`visual-display-${display}`}>
          {label}
        </label>
      </div>
    )
  }

  return (
    <div className="c-form mb-5">
      <label className="c-form__label mb-1">
        Graph display
        <TooltipWithIcon
          trigger="mouseenter click"
          content={
            <>
              <p className="my-2">
                Some data is better understood if the graph is rendered in a different way:
              </p>
              <ul className="list-disc list-inside">
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    Use <span className="c-inline-code bg-purple py-px text-white">Line graph</span>{" "}
                    and <span className="c-inline-code bg-purple py-px text-white">area graph</span>{" "}
                    to visualize the changes of data over time.
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    Use{" "}
                    <span className="c-inline-code bg-purple py-px text-white">
                      Relative area graph
                    </span>{" "}
                    to show the relation between data over time.
                  </span>
                </li>
              </ul>
              <hr className="ignore-old-css -mx-3 border-gray-700 my-2" />
              <a
                className="my-2 block text-underline text-blue-200"
                href="https://docs.appsignal.com/metrics/custom-metrics/dashboards.html#graph-types"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read the documentation
              </a>
            </>
          }
        />
      </label>
      <div className="c-form__element">
        {renderDisplayRadioButton("LINE", "Line graph")}
        {renderDisplayRadioButton("AREA", "Area graph")}
        {renderDisplayRadioButton("AREA_RELATIVE", "Relative area graph")}
      </div>
    </div>
  )
}

VisualFormDisplay.propTypes = {
  visual: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default VisualFormDisplay
