export const COMPARISON_OPERATORS = {
  LESS_THAN: "<",
  GREATER_THAN: ">",
  LESS_THAN_OR_EQUAL: "=<",
  GREATER_THAN_OR_EQUAL: ">=",
  EQUAL: "=",
  NOT_EQUAL: "!=",
}

const useFormattedComparisonOperator = () => {
  const formatComparisonOperator = (value) => {
    return { formattedValue: COMPARISON_OPERATORS[value] }
  }

  return { formatComparisonOperator }
}
export default useFormattedComparisonOperator
