import PropTypes from "prop-types"
import React from "react"

import Tooltip from "../../shared/Tooltip"

const Row = ({ visual, total, current, moveVisual }) => {
  const { title, description } = visual

  const disabledClasses = "text-gray-200 cursor-not-allowed"
  const enabledClasses = "text-gray-600 hover:text-gray-800 cursor-pointer"

  const upArrowDisabled = current === 0
  const downArrowDisabled = current === (total - 1)

  return (
    <div className="my-4 px-3 py-2 flex w-full justify-between border border-gray-200 rounded hover:bg-blue-100 hover:border-blue-200">
      <Tooltip
        content={
          <div className="my-2">
            <div className="font-semibold mb-1">{ title }</div>
            <p>{ description }</p>
          </div>
        }
        placement={"bottom"}
      >
        <div className="truncate">
          <span className="font-semibold">{ title }</span>
          <span className="text-gray-600 ml-2">{ description }</span>
        </div>
      </Tooltip>
      <div className="flex justify-end w-24 ml-4">
        <a
          title="Move higher in order"
          className={`mx-2 ${upArrowDisabled ? disabledClasses : enabledClasses}`}
          onClick={() => { if (!upArrowDisabled) moveVisual(visual, current, current - 1)}}
        >
          <i className="fa fa-fw fa-arrow-up"/>
        </a>
        <a
          title="Move lower in order"
          className={`mx-2 ${downArrowDisabled ? disabledClasses : enabledClasses}`}
          onClick={() => { if (!downArrowDisabled) moveVisual(visual, current, current + 1)}}
        >
          <i className="fa fa-fw fa-arrow-down"/>
        </a>
      </div>
    </div>
  )
}

Row.propTypes = {
  visual: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  moveVisual: PropTypes.func.isRequired
}

export default Row
