import Pagebar from "../../components/shared/Pagebar"

const Page = ({ children }) => {
  return <section className="px-8 h-full flex flex-col">{children}</section>
}

Page.propTypes = {
  children: PropTypes.node,
}

const Bar = ({ ...props }) => {
  return <Pagebar {...props} />
}

const TwoColumn = ({ children }) => <div className="flex h-full w-full">{children}</div>

TwoColumn.propTypes = {
  children: PropTypes.node,
}

const LeftColumn = ({ children }) => <div className="w-80 flex-auto">{children}</div>

LeftColumn.propTypes = {
  children: PropTypes.node,
}

const RightColumn = ({ children }) => (
  <div className="w-80 pl-6 sticky top-4 self-start">{children}</div>
)

RightColumn.propTypes = {
  children: PropTypes.node,
}

Page.Bar = Bar
Page.TwoColumn = TwoColumn
Page.LeftColumn = LeftColumn
Page.RightColumn = RightColumn
export default Page
