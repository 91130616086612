import gql from "graphql-tag"

import TriggerFragment from "./_trigger_fragment"

export default gql`
  query triggerFormQuery(
    $appId: String!,
    $triggerId: String
  ) {
    app(id: $appId) {
      id
      namespaces
      notifiers {
        id
        name
        icon
      }
      dashboards {
        id
        title
      }
      trigger(id: $triggerId) {
        ...Trigger
      }
    }
  }
  ${TriggerFragment}
`
