import PropTypes from "prop-types"
import React from "react"

import { measurementFields } from "../../../../../utils/metric_keys"
import TooltipWithIcon from "../../../../shared/TooltipWithIcon"
import { areFieldsConfigured } from "../../Utils"

const MetricFormFieldCheckboxes = ({ onChange, metric }) => {
  const { fields } = metric
  const handleMetricFieldChange = ({ target }) => {
    const selectedField = target.value
    const { fields: immutableFields } = metric
    const fields = immutableFields.slice(0)
    if (target.checked) {
      // Add field to array
      fields.push({ field: selectedField })
      onChange({ fields })
    } else {
      // Remove field from array
      const fieldIndex = fields.findIndex(field => field.field === selectedField)
      if (fieldIndex !== -1) {
        fields.splice(fieldIndex, 1)
      }
      onChange({ fields })
    }
  }

  const checkboxes = Object.entries(measurementFields).map(field => {
    const [key, label] = field
    return (
      <div
        key={`metric_field_checkbox_${key}`}
        className="c-checkbox p-0"
      >
        <input
          id={`metric_field_checkbox_${key}`}
          className="c-checkbox__input"
          type="checkbox"
          value={key}
          checked={fields.findIndex(field => field.field === key) !== -1}
          onChange={handleMetricFieldChange}
        />
        <label
          htmlFor={`metric_field_checkbox_${key}`}
          className="c-checkbox__label font-normal mb-0 ignore-old-css"
        >
          {label}
        </label>
      </div>
    )
  })

  return (
    <div className="c-form" data-testid="MetricFormFieldCheckboxes">
      <label className="c-form__label mb-1">
        Measurement
        <TooltipWithIcon
          content={
            <>
              <p className="my-2">Select which type of measurements to show:</p>
              <ul className="list-disc list-inside">
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">Count</span>: Counts how many times the helper was called.
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">Mean</span>: The average metric value for the point in time.
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">90th percentile</span>: The 90th percentile of the metric value for the point in time.
                  </span>
                </li>
                <li className="pl-2 my-1">
                  <span className="-ml-1">
                    <span className="c-inline-code bg-purple py-px text-white">95th percentile</span>: The 95th percentile of the metric value for the point in time.
                  </span>
                </li>
              </ul>
              <hr className="ignore-old-css -mx-3 border-gray-700 my-2"/>
              <p className="my-2"><a className="text-underline text-blue-200" href="https://docs.appsignal.com/metrics/custom.html#measurement" target="_blank" rel="noopener noreferrer">Read the documentation</a></p>
            </>
          }
        />
      </label>
      <div className="c-form__element">
        {checkboxes}
      </div>
      {!areFieldsConfigured(fields) && <p className="c-form__error">
        No measurement fields configured. Please select one or more fields.
      </p>}
    </div>
  )
}

MetricFormFieldCheckboxes.propTypes = {
  onChange: PropTypes.func.isRequired,
  metric: PropTypes.object.isRequired
}

export default MetricFormFieldCheckboxes
