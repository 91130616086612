import useFormattedComparisonOperator from "#Root/hooks/formatters/useFormattedComparisonOperator"
export const FormattedComparisonOperator = ({ value }) => {
  const { formatComparisonOperator } = useFormattedComparisonOperator()
  return <span>&nbsp;{formatComparisonOperator(value).formattedValue}&nbsp;</span>
}

FormattedComparisonOperator.propTypes = {
  value: PropTypes.string.isRequired,
}

export default FormattedComparisonOperator
