import gql from "graphql-tag"

import ViewerFragment from "./_viewer_fragment"

export const mockData = {
  app: {
    __typename: "Site",
    id: "abc123",
    name: "",
    environment: "",
    namespaces: "",
    platforms: "",
    dashboards: [],
    logViews: [],
  },
  viewer: {
    __typename: "Viewer",
    id: "user123",
    usedFeatures: [],
  },
}

export default gql`
  query AppInfoQuery($appId: String!) {
    app(id: $appId) {
      id
      name
      environment
      namespaces
      platforms
      dashboards {
        id
        title
        source
        key
      }
      logViews {
        id
        name
        sourceIds
        severities
        query
        columns
        lineHeight
      }
    }
    viewer {
      ...Viewer
    }
  }
  ${ViewerFragment}
`
