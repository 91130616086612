// We're missing the import of font-awesome, but its styles are being imported in `application.sass`
import "~/javascripts/base/timeframes"
import "~/javascripts/appsignal"
import "~/javascripts/web-vitals"
import "~/javascripts/intercom"
import "~/javascripts/base/run"
import "~/javascripts/modules/billing"
import "~/javascripts/modules/content_autofocus"
import "~/javascripts/modules/diagnose_reports"
import "~/javascripts/modules/email_settings"
import "~/javascripts/modules/flash"
import "~/javascripts/modules/geckoboard"
import "~/javascripts/modules/header"
import "~/javascripts/modules/members"
import "~/javascripts/modules/notifiers"
import "~/javascripts/modules/save_loading_indicator"
import "~/javascripts/modules/security"

import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

import UrlHelper from "~/javascripts/components/shared/url_helper"

window.loadIntercom()
UrlHelper.init()

const application = Application.start()
window.Stimulus = application

const controllers = import.meta.glob("../javascripts/**/*_controller.js", {
  eager: true,
})

registerControllers(application, controllers)
