import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"

import { usePageTitle } from "#Root/hooks/usePageTitle"

import Overlay from "../../components/shared/overlay"
import { RouterContext } from "../../contexts/RouterContext"
import Ident from "../../utils/Ident"
import withParamNavigator from "../../wrappers/param_navigator"
import Editor from "./Editor"

const states = {
  new: {
    title: "Add graph to dashboard",
    metric: "GraphBuilderOverlayNew",
  },
  edit: {
    title: "Edit graph",
    metric: "GraphBuilderOverlayEdit",
  },
}

const VisualBuilderOverlay = ({ appId, id, visualId, currentPathWithParams }) => {
  const router = useContext(RouterContext)

  const stateKey = visualId ? "edit" : "new"
  const state = states[stateKey]
  usePageTitle(state.title)

  useEffect(() => {
    Ident.trackAction(state.metric)
  })

  const handleClose = () => {
    if (
      window.confirm(
        "All configuration for this graph will be lost. Are you sure you want to exit?",
      )
    ) {
      closeOverlay()
    }
  }

  const handleCloseWithoutConfirmation = () => {
    closeOverlay()
  }

  const closeOverlay = () => {
    router.navigate(closePath(), { trigger: true })
  }

  const closePath = () => {
    return currentPathWithParams({ overlay: null, visualId: null })
  }

  return (
    <Overlay classNames="full" onClose={handleClose} title={state.title}>
      <Editor
        appId={appId}
        dashboardId={id}
        visualId={visualId}
        closeOverlay={handleCloseWithoutConfirmation}
      />
    </Overlay>
  )
}

VisualBuilderOverlay.propTypes = {
  appId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired, // Dashboard ID
  visualId: PropTypes.string,
  currentPathWithParams: PropTypes.func.isRequired,
}

export default withParamNavigator(VisualBuilderOverlay)
