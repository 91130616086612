import PropTypes from "prop-types"
import React from "react"

import cn from "#Root/utils/cn"

export const TextField = React.forwardRef(({ icon, suffix, isInvalid, ...props }, ref) => {
  return (
    <div className="c-form__element">
      <label
        className={cn("c-textfield ignore-old-css", {
          "c-textfield--error": isInvalid,
        })}
      >
        {icon && <i className={`c-textfield__icon-left ${icon}`} role="img" />}
        <input type="text" className="c-textfield__input" ref={ref} {...props} />{" "}
        {suffix && <span className="c-textfield__suffix">{suffix}</span>}
      </label>
    </div>
  )
})

TextField.displayName = "TextField"

TextField.propTypes = {
  icon: PropTypes.string,
  suffix: PropTypes.string,
  isInvalid: PropTypes.bool,
}

export default TextField
