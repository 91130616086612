import gql from "graphql-tag"

import UptimeMonitorFragment from "./_uptimeMonitorFragment"

export const uptimeMonitorsQuery = gql`
  query uptimeMonitorsQuery($appId: String!) {
    app(id: $appId) {
      id
      uptimeMonitors {
        ...UptimeMonitor
      }
    }
  }
  ${UptimeMonitorFragment}
`

export default uptimeMonitorsQuery
