import gql from "graphql-tag"

export default gql`
  fragment PivotalTrackerStory on PivotalTrackerStory {
    id
    title
    description
    url
    remoteId
  }
`
