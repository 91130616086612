import Button from "#Root/ui/Button"
import Overlay from "#Root/ui/Overlay"

const ConfirmDialogContext = React.createContext()

const ConfirmDialog = ({ open, confirmButtonText, closeCallback, confirmCallback, children }) => {
  return (
    <ConfirmDialogContext.Provider
      value={{ handleClose: closeCallback, handleConfirm: confirmCallback, confirmButtonText }}
    >
      <Overlay open={open} onClose={closeCallback} size="sm">
        {children}
      </Overlay>
    </ConfirmDialogContext.Provider>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  closeCallback: PropTypes.func.isRequired,
  confirmCallback: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}

const Title = ({ children }) => {
  const { handleClose } = React.useContext(ConfirmDialogContext)

  return <Overlay.Title title={children} handleClose={handleClose} />
}

Title.propTypes = {
  children: PropTypes.node.isRequired,
}

const Content = ({ children }) => {
  const { handleClose, handleConfirm, confirmButtonText } = React.useContext(ConfirmDialogContext)
  return (
    <Overlay.Content>
      <div className="min-w-[400px]">{children}</div>
      <Overlay.Actions>
        <div className="flex gap-2">
          <Button color="red" onClick={handleConfirm}>
            {confirmButtonText}
          </Button>
          <Button color="white" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </Overlay.Actions>
    </Overlay.Content>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

ConfirmDialog.Title = Title
ConfirmDialog.Content = Content

export default ConfirmDialog
