import SegmentedControl from "./segmented_control"

const KindFilter = ({ setParams, params }) => {
  return (
    <SegmentedControl
      options={[
        { name: "All types", value: "" },
        { name: "Errors", value: "EXCEPTION" },
        { name: "Performance", value: "PERFORMANCE" },
      ]}
      onChange={(v) => setParams({ sampleType: v })}
      selected={params.sampleType}
    />

  )
}

KindFilter.propTypes = {
  setParams: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
}

export default KindFilter
