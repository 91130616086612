import { flexRender } from "@tanstack/react-table"

import { useTableContext } from "#Root/contexts/TableContext"

const Headers = () => {
  const { table } = useTableContext()

  return (
    <thead className="sticky top-14 z-20">
      <tr>
        {table.getFlatHeaders().map((header) => {
          const { minSize, maxSize, size } = header.column.columnDef
          const style = minSize || maxSize || size ? { width: header.getSize() } : {}
          return (
            <th key={header.id} colSpan={header.colSpan} className="c-table__th h-px" style={style}>
              {header.isPlaceholder ? null : (
                <div
                  {...{
                    className: `${header.column.getCanSort() ? "cursor-pointer select-none" : ""} ${
                      header.id === "selection" ? "text-center" : ""
                    }`,
                    onClick: header.column.getToggleSortingHandler(),
                  }}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: <i className="far fa-angle-up ml-2"></i>,
                    desc: <i className="far fa-angle-down ml-2"></i>,
                  }[header.column.getIsSorted()] ?? null}
                </div>
              )}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default Headers
