const BYTES_TO_MEBIBYTES = 1048576

export const FileSizeCalculator = {
  bytesToMebibytes: (bytes) => (
    bytes / BYTES_TO_MEBIBYTES
  ),

  mebibytesToBytes: (mebibytes) => (
    mebibytes * BYTES_TO_MEBIBYTES
  )
}

export default FileSizeCalculator
