import { useState } from "react"
import Linkify from "react-linkify"

import Overlay from "../../../ui/Overlay"

export const TriggerDescription = ({ description }) => {
  const [isOpen, setIsOpen] = useState(false)
  const lines = description.split("\n")

  return (
    <>
      <Overlay open={isOpen} size="md" onClose={(_) => setIsOpen(false)}>
        <Overlay.Title title="Description" handleClose={(_) => setIsOpen(false)} />
        <Overlay.Content>
          <div className="break-all">
            <Linkify>
              {lines.map((l, i) => (
                <span key={i}>
                  {l} <br />
                </span>
              ))}
            </Linkify>
          </div>
        </Overlay.Content>
      </Overlay>
      <a
        className="text-gray-700 px-3 py-2 block bg-gray-100 rounded cursor-pointer no-underline"
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsOpen(true)
        }}
      >
        <p className="truncate">
          <Linkify>{lines[0]}</Linkify>
        </p>
      </a>
    </>
  )
}

TriggerDescription.propTypes = {
  description: PropTypes.string,
}

export default TriggerDescription
