import PropTypes from "prop-types"
import React from "react"

import Ident from "../../utils/Ident"
import DashboardExportOverlay from "../dashboard/ExportOverlay"
import DashboardImportOverlay from "../dashboard/ImportOverlay"
import ReorderDashboardOverlay from "../dashboard/ReorderOverlay"
import IncidentIntegration from "../incident_integration_overlay/Overlay"
import SearchOverlay from "../SearchOverlay/Overlay"
import StatusPageOverlay from "../StatusPageOverlay/Overlay"
import StatusPageUpdateOverlay from "../StatusPageUpdateOverlay/Overlay"
import TriggerArchive from "../triggers/archive"
import NewTrigger from "../triggers/new"
import UptimeMonitorOverlay from "../UptimeMonitorOverlay/Overlay"
import VisualBuilderOverlay from "../visual_builder/Overlay"
import VisualDuplicateOverlay from "./VisualDuplicateOverlay/Overlay"
export class OptionalLightbox extends React.Component {
  static propTypes = {
    params: PropTypes.object,
  }
  static registeredLightboxes = {
    customMarker: "CustomMarkerOverlay",
    triggerForm: "TriggerNewOverlay",
    triggerArchive: "TriggerArchiveOverlay",
    incidentIntegration: "IncidentIntegrationOverlay",
    exportDashboardOverlay: "ExportDashboardOverlay",
    importDashboardOverlay: "ImportDashboardOverlay",
    visualDuplicate: "VisualDuplicateOverlay",
    uptimeMonitorForm: "UptimeMonitorOverlay",
    search: "search",
    statusPageForm: "StatusPageOverlay",
  }

  componentDidMount() {
    this.trackLightbox()
  }

  componentDidUpdate() {
    this.trackLightbox()
  }

  trackLightbox() {
    const overlayKey = this.props.params.overlay
    const lightboxPageViewName = OptionalLightbox.registeredLightboxes[overlayKey]
    if (!lightboxPageViewName) {
      return
    }

    Ident.trackAction(lightboxPageViewName)
  }

  render() {
    const { params } = this.props
    let lightbox = null

    switch (params.overlay) {
      case "search":
        lightbox = <SearchOverlay {...params} key="search" />
        break
      case "triggerForm":
        lightbox = <NewTrigger {...params} key="NewTrigger" />
        break
      case "triggerArchive":
        lightbox = <TriggerArchive {...params} key="TriggerArchive" />
        break
      case "incidentIntegration":
        lightbox = <IncidentIntegration {...params} key="IncidentIntegration" />
        break
      case "visualBuilder":
        lightbox = <VisualBuilderOverlay {...params} key="VisualBuilder" />
        break
      case "dashboardExportOverlay":
        lightbox = <DashboardExportOverlay {...params} key="DashboardExportOverlay" />
        break
      case "dashboardImportOverlay":
        lightbox = <DashboardImportOverlay {...params} key="DashboardImportOverlay" />
        break
      case "reorderDashboard":
        lightbox = <ReorderDashboardOverlay {...params} key="ReorderDashboardOverlay" />
        break
      case "visualDuplicate":
        lightbox = <VisualDuplicateOverlay {...params} key="VisualDuplicateOverlay" />
        break
      case "uptimeMonitorForm":
        lightbox = <UptimeMonitorOverlay {...params} key="UptimeMonitorOverlay" />
        break
      case "statusPageForm":
        lightbox = <StatusPageOverlay {...params} key="StatusPageOverlay" />
        break
      case "statusPageUpdateForm":
        lightbox = <StatusPageUpdateOverlay {...params} key="StatusPageUpdateOverlay" />
        break
    }

    return <>{lightbox}</>
  }
}

export default OptionalLightbox
