import PropTypes from "prop-types"
import React from "react"

import TagsFormInputPair from "./InputPair"

const TagFormInputPairs = ({ onChange, tags, metricMetadata }) => {
  function handleTagChange(tagIndex, tag) {
    const newTags = (tags || []).slice()
    if (tag) {
      if (newTags[tagIndex]) {
        // Override existing tag entry
        newTags[tagIndex] = tag
      } else {
        // Add new tag to array
        newTags.push(tag)
      }
    } else {
      // Replace tag value with `null` as to unset/delete the config
      // It still exists so that we can render unique forms with the index as
      // the React key.
      newTags[tagIndex] = null
    }
    onChange({ tags: newTags })
  }

  const tagForms = []
  tags.forEach((tag, tagIndex) => {
    if (!tag) { return }

    tagForms.push(
      <div key={`metricTagForm${tagIndex}-${tag.key}`}>
        <TagsFormInputPair
          tags={tags}
          tag={tag}
          metricMetadata={metricMetadata}
          onChange={handleTagChange.bind(this, tagIndex)}
        />
      </div>
    )
  })

  const newTagIndex = tags.length
  tagForms.push(
    <div
      key={`MetricFormTagInputPair${newTagIndex}`}
    >
      <TagsFormInputPair
        tags={tags}
        tag={{ key: "", value: "" }}
        metricMetadata={metricMetadata}
        onChange={handleTagChange.bind(this, newTagIndex)}
      />
    </div>
  )
  return tagForms
}

TagFormInputPairs.propTypes = {
  tags: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default TagFormInputPairs
