import { useMutation } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React from "react"

import ArchiveAlertMutation from "../../graphql/ArchiveAlertMutation"

const ArchiveAlertButton = ({ appId, id }) => {
  const [archiveAlert, { loading }] = useMutation(ArchiveAlertMutation, {
    variables: { appId, id },
    awaitRefetchQueries: true,
    refetchQueries: ["AlertsQuery"]
  })

  return (
    <a disabled={loading} className="ml-2 c-button c-button--xs c-button--red cursor-pointer mb-2" onClick={archiveAlert}>
      { loading ? "Archiving..." : "Archive"}
    </a>
  )
}

ArchiveAlertButton.propTypes = {
  appId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default ArchiveAlertButton
