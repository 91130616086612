import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import AlertsTable from "#Components/AlertsTable/AlertsTable"

const QUERY = gql`
  query TimeDetectiveAlertsPageQuery($appId: String!, $start: DateTime!, $end: DateTime!) {
    app(id: $appId) {
      id
      alerts(start: $start, end: $end) {
        id
        state
        metricDigest
        createdAt
        openedAt
        resolvedAt
        closedAt
        timeframeStartAt
        timeframeEndAt
        lastValue
        peakValue
        mean
        tags {
          key
          value
        }
        trigger {
          id
          metricName
          name
          field
          kind
          description
          tags {
            key
            value
          }
          thresholdCondition {
            value
            comparisonOperator
          }
        }
      }
    }
  }
`

const TimeDetectiveAlertsPage = ({ params }) => {
  const { loading, error, data } = useQuery(QUERY, {
    variables: {
      appId: params.appId,
      start: params.td_start,
      end: params.td_end,
    },
  })

  return (
    <AlertsTable
      alerts={data?.app?.alerts || []}
      loading={loading}
      error={error}
      title="Alerts for this timeframe"
      params={params}
      compact={true}
      ignoreContainerHeight
    />
  )
}

TimeDetectiveAlertsPage.propTypes = {
  params: PropTypes.object.isRequired,
}

export default TimeDetectiveAlertsPage
