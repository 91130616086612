import UrlHelper from "../shared/url_helper"
import SideNavigation from "./side_navigation"

export const groupLogViews = (logViews) => {
  const groupedLogViews = []

  // We only want to loop once, so we have to do some lookups here
  for (const logView of logViews.sort((a, b) => a.name.localeCompare(b.name))) {
    const { name, ...rest } = logView
    const [groupName, ...restName] = name.split("/").map((item) => item.trim())
    const last = groupedLogViews[groupedLogViews.length - 1]

    // If we have a split and the "group" title matches the current group title
    // add the item to the array
    if (restName.length > 0 && last?.name == groupName) {
      last.logViews.push({ name: restName.join("/"), ...rest })

      // If we don't have a matching last element, it's a new group
      // insert it as a new group
    } else if (restName.length > 0) {
      groupedLogViews.push({
        name: groupName,
        subnav: true,
        logViews: [{ name: restName.join("/"), ...rest }],
      })
      // No `/` in the title, just insert is a a group with 1 child
    } else {
      groupedLogViews.push({
        name: logView.name,
        subnav: false,
        logViews: [logView],
      })
    }
  }

  return groupedLogViews
}

export const renderLogViewMenuItems = (appInfo) => {
  if (!appInfo || !appInfo?.logViews) return null

  let logViewLinks = []

  const renderLogView = (view) => {
    const url = UrlHelper.logsViewPagePath({
      viewId: view.id,
      query: view.query,
      sourceIds: view.sourceIds,
      severities: view.severities,
      columns: view.columns,
      clamp: view.lineHeight,
      viewName: view.name,
    })
    const label = null

    return (
      <SideNavigation key={view.id} title={view.name} href={url} type="subitem" label={label} />
    )
  }

  const groupedLogViews = groupLogViews(appInfo.logViews.slice(0))

  logViewLinks = groupedLogViews.map((logViewGroup) => {
    if (logViewGroup.subnav) {
      return (
        <SideNavigation key={logViewGroup.name} title={logViewGroup.name} type="submenu">
          {logViewGroup.logViews.map((d) => renderLogView(d))}
        </SideNavigation>
      )
    } else {
      return renderLogView(logViewGroup.logViews[0])
    }
  })

  return logViewLinks
}
