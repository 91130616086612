import PropTypes from "prop-types"
import React from "react"

import SearchRefinementMenu from "./SearchRefinementMenu"

const SearchRefinement = ({ handleRefinement }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className="relative">
      <button
        className="c-button c-button--white c-button--sm space-x-2 shadow-none rounded-r-none border-r-0 focus:outline-none ignore-old-css"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>Filters</span>
        <i className="far fa-angle-down c-button__icon text-gray-700"></i>
      </button>
      {isOpen && (
        <SearchRefinementMenu
          handleChange={handleRefinement}
          handleClose={setIsOpen}
        />
      )}
    </div>
  )
}

SearchRefinement.propTypes = {
  handleRefinement: PropTypes.func.isRequired,
}

export default SearchRefinement
