import compose from "lodash.flowright"
import PropTypes from "prop-types"
import React from "react"
import { graphql } from "react-apollo"

import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import { IncidentQueryWithLogbook } from "../../graphql/incident_query"
import withParamNavigator from "../../wrappers/param_navigator"
import ErrorBox from "../shared/error_box"
import Overlay from "../shared/overlay"
import AsanaForm from "./forms/AsanaForm"
import GitHubForm from "./forms/GitHubForm"
import GitLabForm from "./forms/GitLabForm"
import JiraForm from "./forms/JiraForm"
import LinearForm from "./forms/LinearForm"
import PhabricatorForm from "./forms/PhabricatorForm"
import PivotalTrackerForm from "./forms/PivotalTrackerForm"
import ShortcutForm from "./forms/ShortcutForm"
import TrelloForm from "./forms/TrelloForm"

const integrations = {
  Asana: {
    title: "Asana task",
    form: AsanaForm,
  },
  GitHub: {
    title: "GitHub issue",
    form: GitHubForm,
  },
  GitLab: {
    title: "GitLab issue",
    form: GitLabForm,
  },
  PivotalTracker: {
    title: "Pivotal Tracker story",
    form: PivotalTrackerForm,
  },
  Trello: {
    title: "Trello card",
    form: TrelloForm,
  },
  Jira: {
    title: "Jira issue",
    form: JiraForm,
  },
  Phabricator: {
    title: "Phabricator issue",
    form: PhabricatorForm,
  },
  Shortcut: {
    title: "Shortcut story",
    form: ShortcutForm,
  },
  Linear: {
    title: "Linear issue",
    form: LinearForm,
  },
}

export class IncidentIntegrationOverlay extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }
  static propTypes = {
    appId: PropTypes.string.isRequired,
    number: PropTypes.string.isRequired,
    integration: PropTypes.string.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      app: PropTypes.object,
    }).isRequired,
    currentPathWithParams: PropTypes.func.isRequired,
  }

  handleClose = (event) => {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }
    this.context.router.navigate(this.closePath(), { trigger: true })
  }

  closePath = () => {
    return this.props.currentPathWithParams({
      overlay: null,
      integration: null,
    })
  }

  findIntegrationByName(integrations, name) {
    return integrations.find((i) => i.name === name)
  }

  content() {
    const {
      integration,
      data: { loading, error, app },
    } = this.props

    if (loading && !app) {
      return {
        title: "Loading integration",
        content: <LoadingAnimation message="Loading integration..." />,
      }
    } else if (error) {
      return {
        title: "Error loading integration",
        content: <ErrorBox error={error} />,
      }
    }

    const {
      appId,
      data: {
        app: { incident },
      },
    } = this.props
    const integrationData = this.findIntegrationByName(incident.integrations, integration)
    const defaultProps = {
      incident,
      appId,
      integration: integrationData,
      onSubmit: this.handleClose,
    }
    const integrationConfig = integrations[integration]
    if (integrationData?.needsMigration === true) {
      return {
        title: "This integration requires an update",
        content: (
          <ErrorBox>
            <p>This integration has been updated and the configuration needs to be changed.</p>
            <p className="mt-2">
              Stories/issues created before the update continue to work, new stories require a
              config update first.
            </p>
            <p className="mt-2">
              You can find the config under &quot;App settings&quot; &gt; &quot;Integrations&quot;
              &gt; &quot;{integration}&quot;.
            </p>
          </ErrorBox>
        ),
      }
    } else if (integrationConfig) {
      const { title, form: Form } = integrationConfig
      return {
        title: `Create ${title}`,
        content: (
          <section className="mod-overlay-form">
            <Form {...defaultProps} />
          </section>
        ),
      }
    } else {
      return {
        title: "Unknown integration",
        content: <ErrorBox error={{ message: "Unknown integration" }} />,
      }
    }
  }

  render() {
    const { title, content } = this.content()
    return (
      <Overlay title={title} onClose={this.handleClose} classNames="large">
        {content}
      </Overlay>
    )
  }
}

export default compose(
  graphql(IncidentQueryWithLogbook, {
    options: ({ appId, number }) => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        appId,
        incidentNumber: parseInt(number),
      },
    }),
  }),
  withParamNavigator
)(IncidentIntegrationOverlay)
