import PropTypes from "prop-types"
import React from "react"

const DatePickerInputButton = React.forwardRef(
  (
    {
      active,
      label,
      onClick,
      segmentedControl = true,
      customIcon = <i className="far fa-angle-down c-button__icon" />,
    },
    _ref
  ) => {
    const buttonClass = () => {
      let basicClasses =
        "c-button c-button--sm c-button--white cursor-pointer select-none space-x-3"

      if (segmentedControl) {
        basicClasses += " c-button-group__item first:rounded-l-none"
        if (!active) basicClasses += " c-button--white-inactive"
      }

      return basicClasses
    }

    return (
      <a className={buttonClass()} onClick={onClick}>
        <span>{label}</span>
        {customIcon}
      </a>
    )
  }
)
DatePickerInputButton.displayName = "DatePickerInputButton"
DatePickerInputButton.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onClick: PropTypes.func,
  customIcon: PropTypes.node,
  segmentedControl: PropTypes.bool,
}

export default DatePickerInputButton
