import PropTypes from "prop-types"
import React, { useContext } from "react"

import { RouterContext } from "../../contexts/RouterContext"
import withParamNavigator from "../../wrappers/param_navigator"
import SearchIndex from "../search/index"
import OverlayLayout from "../shared/overlay"

const Overlay = ({ currentPathWithParams, ...params }) => {
  const router = useContext(RouterContext)
  const handleClose = () => {
    router.navigate(currentPathWithParams({ overlay: null }), {
      trigger: true,
    })
  }

  return (
    <OverlayLayout
      onClose={handleClose}
      title="Search for saved samples"
      innerClassName="c-overlay__inner--2xl overflow-x-auto"
    >
      <div className="c-box text-gray-800 bg-gray-100">
        <SearchIndex params={params} />
      </div>
    </OverlayLayout>
  )
}

Overlay.propTypes = {
  currentPathWithParams: PropTypes.func.isRequired,
}

export default withParamNavigator(Overlay)
