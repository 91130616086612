export function useMeasure() {
  const ref = React.useRef(null)
  const [rect, setRect] = React.useState({
    width: 0,
    height: 0,
  })

  React.useLayoutEffect(() => {
    if (!ref.current) return

    const observer = new ResizeObserver(([entry]) => {
      if (entry && entry.contentRect) {
        setRect({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        })
      }
    })

    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, [])

  return [ref, rect]
}

export default useMeasure
