import cn from "#Root/utils/cn"

import {
  SORT_ASC,
  SORT_DESC,
  SORT_NAME,
  SORT_VALUE,
  useChartHoverStateContext,
} from "../../contexts/HoverStateContext"

const SortingMenu = () => {
  const { sortBy, sortDirection, setHoverSort } = useChartHoverStateContext()

  const setSorting = (newSortBy) => {
    // same sort by, just toggle direction
    if (newSortBy === sortBy) {
      setHoverSort({
        sortBy: newSortBy,
        sortDirection: sortDirection === SORT_ASC ? SORT_DESC : SORT_ASC,
      })
      return
    }

    if (newSortBy === SORT_NAME) {
      // Prefer ascending order for name (A-Z)
      setHoverSort({ sortBy: newSortBy, sortDirection: SORT_ASC })
      return
    } else {
      // Prefer descending order for value (high to low)
      setHoverSort({ sortBy: newSortBy, sortDirection: SORT_DESC })
      return
    }
  }

  return (
    <div className="grid grid-cols-2 gap-2 text-[20px] -my-1">
      <button onClick={() => setSorting(SORT_NAME)}>
        <i
          className={cn(
            "far",
            sortBy === SORT_NAME && sortDirection === SORT_ASC
              ? "fa-sort-alpha-down text-gray-800"
              : sortBy === SORT_NAME && sortDirection === SORT_DESC
                ? "fa-sort-alpha-down-alt text-gray-800"
                : "fa-sort-alpha-down text-gray-400",
          )}
        />
      </button>
      <button onClick={() => setSorting(SORT_VALUE)}>
        <i
          className={cn(
            "far",
            sortBy === SORT_VALUE && sortDirection === SORT_ASC
              ? "fa-sort-numeric-down text-gray-800"
              : sortBy === SORT_VALUE && sortDirection === SORT_DESC
                ? "fa-sort-numeric-down-alt text-gray-800"
                : "fa-sort-numeric-down-alt text-gray-400",
          )}
        />
      </button>
    </div>
  )
}

export default SortingMenu
