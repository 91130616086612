import CreateVisualTimeseriesMutation from "../../../../graphql/create_visual_timeseries_mutation"
import UpdateVisualTimeseriesMutation from "../../../../graphql/update_visual_timeseries_mutation"
import MetricForm from "./MetricForm"
import OptionsForm from "./OptionsForm"
import Preview from "./Preview"

const timeseries = {
  newMetric: {
    display: "LINE",
    title: "New graph",
    description: null,
    lineLabel: "%name%",
    format: "number",
    formatInput: null,
    drawNullAsZero: true,
    metrics: []
  },
  MetricForm,
  OptionsForm,
  Preview,
  CreateMutation: CreateVisualTimeseriesMutation,
  UpdateMutation: UpdateVisualTimeseriesMutation
}

export default timeseries
