import HumanNumber from "#Root/components/shared/human_number"

function FormattedThroughput({ value, resolution }) {
  let req_hr, req_min

  if (resolution === "MINUTELY") {
    req_min = Math.round(value)
    req_hr = Math.round(value * 60)
  } else if (resolution === "DAILY") {
    req_min = Math.round(value / (60 * 24))
    req_hr = Math.round(value / 24)
  } else {
    req_min = Math.round(value / 60)
    req_hr = Math.round(value)
  }

  return (
    <div className="space-x-1">
      <span className="double-value">
        <HumanNumber number={req_min} /> / min
      </span>
      <span className="double-value">
        <HumanNumber number={req_hr} /> / h
      </span>
    </div>
  )
}

FormattedThroughput.propTypes = {
  value: PropTypes.number.isRequired,
  resolution: PropTypes.string.isRequired,
}

export default FormattedThroughput
