document.querySelectorAll("#addNotifierButton").forEach(function(element) {
  element.addEventListener("click", function(e) {
    e.preventDefault()
    e.stopPropagation()
    document.querySelector("#addNotifier").style.display = "block"

    function hideAddNotifier() {
      document.querySelector("#addNotifier").style.display = "none"
      document.querySelector("html").removeEventListener("click", hideAddNotifier)
    }

    document.querySelector("html").addEventListener("click", hideAddNotifier)
  })
})
