import PropTypes from "prop-types"
import React from "react"
import { useHotkeys } from "react-hotkeys-hook"

import { SEARCH_PANEL_SHORTCUT_KEY } from "#Root/constants/KeyboardShortcuts"
import useRouter from "#Root/hooks/useRouter"
import KeyboardShortcut from "#Root/ui/KeyboardShortcut/KeyboardShortcut"

import withParamNavigator from "../../wrappers/param_navigator"
import Link from "./link"

export const SearchInput = ({ currentPathWithParams }) => {
  const { navigateWithParams } = useRouter()

  const handleKeyDown = (e) => {
    e.preventDefault()
    navigateWithParams({ overlay: "search" })
  }

  useHotkeys(SEARCH_PANEL_SHORTCUT_KEY, handleKeyDown, {
    enableOnFormTags: true,
    enabled: true,
    enableOnContentEditable: true,
  })

  return (
    <Link
      href={currentPathWithParams({ overlay: "search" })}
      className="flex items-center no-underline my-2 py-1 px-3 space-x-3 bg-gray-100 border border-gray-200 rounded"
      id="search-link"
    >
      <i className="text-gray-600 far fa-search text-ms"></i>
      <span className="text-gray-700 inline-block truncate">Search for saved samples</span>
      <KeyboardShortcut shortcut={SEARCH_PANEL_SHORTCUT_KEY} />
    </Link>
  )
}

SearchInput.propTypes = {
  params: PropTypes.object.isRequired,
  currentPathWithParams: PropTypes.func,
}

export default withParamNavigator(SearchInput)
