import { useQuery } from "@apollo/react-hooks"
import PropTypes from "prop-types"
import React, { useState } from "react"

import MetricKeysQuery from "../../../../graphql/metric_keys_query"
import { filterArrayWith } from "../../../../utils/Array"
import { hostnameFromTags } from "../../../../utils/host_metrics"
import TextfieldWithAutoComplete from "../../../shared/TextfieldWithAutoComplete"

const TriggerFormHostname = ({ appId, hostname: givenHostname, onChange }) => {
  const hostname = givenHostname.trim()
  const [hostnames, setHostnames] = useState([])
  const { loading, error } = useQuery(MetricKeysQuery, {
    variables: {
      appId,
      timeframe: "R1H",
      name: "load_average",
      tags: [
        {
          key: "host_metric",
          value: "",
        },
        {
          key: "hostname",
          value: "*"
        }
      ]
    },
    onCompleted: (data) => {
      const hostnames = []
      for (const key of data.app.metrics.keys) {
        const hostname = hostnameFromTags(key.tags)
        if (hostname) {
          hostnames.push(hostname)
        }
      }
      setHostnames(hostnames.sort())
    }
  })

  const withWildcard = hostname.includes("*")
  const message = autoCompleteMessage(loading, error)
  const unconfiguredHost = !hostname
  const matchedOptions = withWildcard ? filterArrayWith(hostname, hostnames, "wildcardEqual") : hostnames
  const unknownHost = !loading && !unconfiguredHost && matchedOptions.length === 0
  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="trigger_hostname">Hostname matches</label>
      <div className="c-form__element">
        <TextfieldWithAutoComplete
          id="trigger_hostname"
          name="trigger_hostname"
          message={message}
          value={hostname}
          placeholder="Metric"
          options={hostnames}
          popOverHeader="Hosts from the last hour"
          hasWarning={unknownHost}
          hasError={unconfiguredHost}
          wildcardSupport={true}
          onSelect={hostname => onChange(hostname)}
        />
      </div>
      {unconfiguredHost && <p className="mt-2 mb-0 c-form__error">
        No hostname configured. Please select a hostname.
      </p>}
      {unknownHost && <p className="mt-2 mb-0 c-p--sm text-orange-500">
        Selected host was not found in the last hour on page load. It might
        exist outside this timeframe and still trigger an alert.
      </p>}
      <p className="c-form__description">
        Select a host using their hostname.
        Match multiple hosts by using a wildcard in the name (<code>*</code>).
        The default value of <code>*</code> matches all hostnames.
      </p>
    </div>
  )
}

TriggerFormHostname.propTypes = {
  appId: PropTypes.string.isRequired,
  hostname: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

function autoCompleteMessage(loading, error) {
  if (loading) {
    return "Loading hosts..."
  }
  if (error) {
    return "An error occurred while loading the hosts."
  }
}

export default TriggerFormHostname
