const EmptyState = ({ height }) => {
  return (
    <div className="flex items-center justify-center flex-col" style={{ height }}>
      No data found for this time period
    </div>
  )
}

EmptyState.propTypes = {
  height: PropTypes.number,
}

export default EmptyState
