import gql from "graphql-tag"

import AlertFragment from "./_alert_fragment"
import StatusPageFragment from "./_statusPageFragment"

export default gql `
  fragment UptimeMonitor on UptimeMonitor {
    id
    name
    url
    description
    notifierIds
    warmupDuration
    checkBodyContent
    regions
    notifiers {
      id
      name
      icon
    }
    headers {
      key
      value
    }
    alerts {
      ...Alert
    }
    statusPages {
      ...StatusPage
    }
  }
  ${AlertFragment}
  ${StatusPageFragment}
`
