function updateVatNumberVisiblity() {
  const billingCountryElement = $(".js-billing-country")
  const billingVatNumberElement = $(".js-billing-vat-number")
  const euCountries = billingCountryElement.data("euCountries")

  const selectedCountry = billingCountryElement.val()
  if (euCountries.includes(selectedCountry)) {
    billingVatNumberElement.show()
  } else {
    billingVatNumberElement.hide()
  }
}

$(function() {
  const billingCountryElement = $(".js-billing-country")
  if (billingCountryElement.length > 0) {
    updateVatNumberVisiblity()

    billingCountryElement.chosen().on("change", function() {
      updateVatNumberVisiblity()
    })
  }
})
