import PropTypes from "prop-types"
import React from "react"

const Regions = ({ available, selected, onChange }) => (
  <div className="c-form">
    <label className="c-form__label ignore-old-css" htmlFor="field">Regions to check from</label>
    <div className="c-form__element">
      {available.map(region => {
        const checkboxId = `n-${region.id}`
        return (
          <div className="c-checkbox" key={region.id}>
            <input
              className="c-checkbox__input"
              id={checkboxId}
              type="checkbox"
              checked={selected.has(region.id)}
              onChange={() => onChange(region.id)}
            />
            <label
              className="c-checkbox__label ignore-old-css cursor-pointer"
              htmlFor={checkboxId}
            >
              {region.name}
            </label>
          </div>
        )
      })}
    </div>
    <p className="c-form__description">At least one region is required.</p>
  </div>
)

Regions.propTypes = {
  available: PropTypes.array.isRequired,
  selected: PropTypes.instanceOf(Set).isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Regions
