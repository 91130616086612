import gql from "graphql-tag"

export default gql `
  query AccountsQuery {
    viewer {
      id
      organizations {
        id
        name
        slug
        throughputLast30Days
        heroku
        apps {
          id
          name
          environment
          namespaces
          platforms
          viewerPinned
        }
      }
    }
  }
`
