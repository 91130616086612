export const PageBar = ({ color, icon, className, children }) => (
  <div className={`c-pagebar mt-0 mb-6 -mx-8 py-4 px-8 border-b border-gray-200 ${className}`}>
    <Icon color={color} icon={icon} />
    {children}
  </div>
)

PageBar.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

const Icon = ({ color, icon }) => {
  const colorMapping = {
    blue: "c-icon-box--blue",
    green: "c-icon-box--green",
    orange: "c-icon-box--orange",
    pink: "c-icon-box--pink",
    purple: "c-icon-box--purple",
    red: "c-icon-box--red",
    teal: "c-icon-box--teal",
    yellow: "c-icon-box--yellow",
    azure: "c-icon-box--azure",
  }

  const iconBoxColor = colorMapping[color] || ""

  return (
    <div className="c-pagebar__icon">
      <div className={`c-icon-box ${iconBoxColor}`}>
        <i className={`fa fa-${icon}`} />
      </div>
    </div>
  )
}

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
}

const Title = ({ title, subtitle }) => (
  <div className="c-pagebar__header">
    <h1 className="c-pagebar__title">{title}</h1>
    {subtitle && <h2 className="c-pagebar__subtitle">{subtitle}</h2>}
  </div>
)

Title.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
}

const Actions = ({ children }) => <div className="c-pagebar__actions">{children}</div>

Actions.propTypes = {
  children: PropTypes.node.isRequired,
}

PageBar.Icon = Icon
PageBar.Title = Title
PageBar.Actions = Actions

export default PageBar
