import gql from "graphql-tag"

import DashboardFragment from "./_dashboard_fragment"

export const mockData = {
  updateDashboard: {
    __typename: "Dashboard",
    id: "dashboard1",
    title: "",
    description: "",
    visuals: []
  }
}

export default gql `
  mutation updateDashboardMutation(
    $id: String!,
    $appId: String!,
    $title: String!,
    $description: String
  ) {
    updateDashboard(
      id: $id,
      appId: $appId,
      title: $title,
      description: $description
    ) {
      ...Dashboard
    }
  }
  ${DashboardFragment}
`
