import BoxEmpty from "#Components/shared/boxes/empty"
import FormattedTime from "#Root/formatters/FormattedTime"

const OutsideTTL = ({ ttlTime }) => (
  <BoxEmpty header="Outside your account's retention" className="c-table__td border-b-0 py-1">
    <p className="text-gray-700">
      The time you requested a snapshot for is outside the retention of your account.
    </p>
    <p className="text-gray-700">
      Please select a time after <FormattedTime value={ttlTime} format="long" />.
    </p>
  </BoxEmpty>
)

OutsideTTL.propTypes = {
  ttlTime: PropTypes.object.isRequired,
}

export default OutsideTTL
