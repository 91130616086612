import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import PropTypes from "prop-types"
import React, { useEffect,useState } from "react"

dayjs.extend(relativeTime)

const TimeAgoInWords = ({ time, className }) => {
  const timestamp = React.useMemo(() => dayjs(time).format(), [time])
  const [formattedTime, setFormattedTime] = useState(dayjs(time).fromNow(true), [time])

  useEffect(() => {
    const interval = setInterval(() => setFormattedTime(dayjs(time).fromNow(true), [time]), 60000)
    return () => {
      clearInterval(interval)
    }
  }, [time])

  return (
    <time dateTime={timestamp} title={timestamp} className={className}>
      {formattedTime} ago
    </time>
  )
}

TimeAgoInWords.propTypes = {
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
}

export default TimeAgoInWords
