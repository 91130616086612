class NumberHelpers {
  static number_to_currency(float, opts) {
    let result
    if (opts == null) {
      opts = {}
    }
    const _precision = opts.precision != null ? opts.precision : 2
    const _unit = opts.unit != null ? opts.unit : "$"
    const _separator = opts.separator != null ? opts.separator : "."
    const _delimiter = opts.delimiter != null ? opts.delimiter : ","
    const _unit_pos = opts.unit_position != null ? opts.unit_position : "start"

    let sign = ""

    // Non-number values return zero precision
    if (isNaN(float)) {
      result = float
    } else {
      float = parseFloat(float) // Arg might be a string, integer
      if (float < 0) {
        sign = "-"
      }
      const fixedWidth = Math.abs(float).toFixed(_precision)
      const delimited = NumberHelpers.number_with_delimiter(fixedWidth, { delimiter: _delimiter })
      result = delimited.split(".").join(_separator)
    }

    if (_unit_pos === "end") {
      return `${sign}${result}${_unit}`
    } else {
      return `${sign}${_unit}${result}`
    }
  }

  static number_with_delimiter(float, opts) {
    if (opts == null) {
      opts = {}
    }
    let _separator = opts.separator != null ? opts.separator : "."
    const _delimiter = opts.delimiter != null ? opts.delimiter : ","

    const number = float.toString().split(".")
    let integer = number[0]
    const decimal = number[1] != null ? number[1] : ""

    // Remove separator if no decimal
    if (!decimal) {
      _separator = ""
    }

    const rgx = /(\d+)(\d{3})/
    if (_delimiter) {
      while (rgx.test(integer)) {
        integer = integer.replace(rgx, `$1${_delimiter}$2`)
      }
    }

    return `${integer}${_separator}${decimal}`
  }

  static number_with_precision(float, opts) {
    let dlen, rnd, rounded
    if (opts == null) {
      opts = {}
    }
    const _precision = opts.precision != null ? opts.precision : 3
    const _delimiter = opts.delimiter != null ? opts.delimiter : ","
    let _separator = opts.separator != null ? opts.separator : "."
    const _significant = opts.significant != null ? opts.significant : false
    const _strip_insignificant_zeros =
      opts.strip_insignificant_zeros != null ? opts.strip_insignificant_zeros : false
    const _skip_empty_fractionals = opts.strip_empty_fractional_parts

    // Break number into inspectable pieces
    let number = float.toString().split(".")
    let integer = number[0]
    let decimal = number[1] != null ? number[1] : ""

    // Significant Digits need rounding to match number of sig digits
    if (_significant) {
      rnd = _precision - integer.length
    } else {
      rnd = _precision
    }

    // Make sure rounding is not to a negative place
    if (rnd < 1) {
      rnd = 0
    }

    // Round
    const multiple = Math.pow(10, rnd)
    if (multiple > 1) {
      rounded = Math.round(float * multiple) / multiple
    } else {
      rounded = float
    }

    // Break number into inspectable pieces
    number = rounded.toString().split(".")
    integer = number[0]
    decimal = number[1] != null ? number[1] : ""

    // Pad to _precision
    decimal = parseFloat(`0.${decimal}`).toFixed(_precision)
    decimal = decimal.toString().split(".")
    decimal = decimal[1] != null ? decimal[1] : ""

    // Reconstitute the number with correct decimal
    number = `${integer}.${decimal}` * 1
    let num_array = number.toString().split("")
    const num_lngth = num_array.length

    // Count Non-zero Digits
    let i = 0
    let sigs = 0
    while (i < num_lngth) {
      if (num_array[i] !== "." && num_array[i] !== "0") {
        sigs++
      }
      i++
    }

    // Significant Digits
    if (_significant && sigs >= _precision && _precision > 0) {
      let significant = number.toPrecision(_precision) * 1
      significant = significant.toString().split(".")
      integer = significant[0]
      decimal = significant[1] != null ? significant[1] : ""
    }

    // Delimiter Integer
    integer = NumberHelpers.number_with_delimiter(integer, { delimiter: _delimiter })

    // Strip Insignificant Digits
    if (_strip_insignificant_zeros) {
      dlen = decimal.length
      let newlen = dlen

      while (newlen > 0 && decimal[newlen - 1] === "0") {
        newlen = newlen - 1
      }

      if (newlen === 0) {
        decimal = ""
      } else if (newlen !== dlen) {
        decimal = decimal.slice(0, newlen)
      }
    }

    if (_skip_empty_fractionals) {
      i = 0
      let zcount = 0
      num_array = decimal.split("")
      dlen = decimal.length
      while (i < dlen) {
        if (num_array[i] === "0") {
          zcount++
        }
        i++
      }
      if (zcount === dlen) {
        decimal = ""
      }
    }

    // Remove separator if no decimal
    if (!decimal) {
      _separator = ""
    }

    return `${integer}${_separator}${decimal}`
  }

  static number_to_human(float, opts) {
    let denom, label
    if (opts == null) {
      opts = {}
    }
    const _precision = opts.precision != null ? opts.precision : 3
    const _separator = opts.separator != null ? opts.separator : "."
    const _significant = opts.significant != null ? opts.significant : true
    const _delimiter = opts.delimiter != null ? opts.delimiter : ","
    const _strip_insignificant_zeros =
      opts.strip_insignificant_zeros != null ? opts.strip_insignificant_zeros : false
    const _space_label = opts.space_label === false ? "" : " "
    const _labels = {
      thousand:
        __guard__(opts.labels, (x) => x.thousand) != null
          ? __guard__(opts.labels, (x) => x.thousand)
          : "Thousand",
      million:
        __guard__(opts.labels, (x1) => x1.million) != null
          ? __guard__(opts.labels, (x1) => x1.million)
          : "Million",
      billion:
        __guard__(opts.labels, (x2) => x2.billion) != null
          ? __guard__(opts.labels, (x2) => x2.billion)
          : "Billion",
      trillion:
        __guard__(opts.labels, (x3) => x3.trillion) != null
          ? __guard__(opts.labels, (x3) => x3.trillion)
          : "Trillion",
      quadrillion:
        __guard__(opts.labels, (x4) => x4.quadrillion) != null
          ? __guard__(opts.labels, (x4) => x4.quadrillion)
          : "Quadrillion",
    }

    // Remove the sign of the number for easier comparision
    const abs_float = Math.abs(float)

    // Less than Thousand does not need text or a insignifiant digits
    if (abs_float < Math.pow(10, 3)) {
      denom = 1
      label = false
    } else if (abs_float >= Math.pow(10, 3) && abs_float < Math.pow(10, 6)) {
      denom = Math.pow(10, 3)
      label = _labels.thousand
    } else if (abs_float >= Math.pow(10, 6) && abs_float < Math.pow(10, 9)) {
      denom = Math.pow(10, 6)
      label = _labels.million
    } else if (abs_float >= Math.pow(10, 9) && abs_float < Math.pow(10, 12)) {
      denom = Math.pow(10, 9)
      label = _labels.billion
    } else if (abs_float >= Math.pow(10, 12) && abs_float < Math.pow(10, 15)) {
      denom = Math.pow(10, 12)
      label = _labels.trillion
    } else if (abs_float >= Math.pow(10, 15)) {
      denom = Math.pow(10, 15)
      label = _labels.quadrillion
    }

    // Process the number into a presentable format
    const number = float / denom
    const precise = NumberHelpers.number_with_precision(number, {
      precision: _precision,
      significant: _significant,
      delimiter: label === "Quadrillion" ? "" : _delimiter,
      separator: _separator,
      strip_insignificant_zeros: !label ? true : _strip_insignificant_zeros,
    })

    //No label needed for less than thousand
    if (label) {
      return `${precise}${_space_label}${label}`
    } else {
      return precise
    }
  }

  static number_to_human_size(float, opts) {
    let denom, label
    if (opts == null) {
      opts = {}
    }
    const _precision = opts.precision != null ? opts.precision : 3
    const _separator = opts.separator != null ? opts.separator : "."
    const _significant = opts.significant != null ? opts.significant : true
    const _delimiter = opts.delimiter != null ? opts.delimiter : ","
    const _strip_insignificant_zeros =
      opts.strip_insignificant_zeros != null ? opts.strip_insignificant_zeros : false

    // Power of 10 to Bytes Converter
    if (float > 1000) {
      float = float / 1.024
    }
    if (float > 1000000) {
      float = float / 1.024
    }
    if (float > 1000000000) {
      float = float / 1.024
    }
    if (float > 1000000000000) {
      float = float / 1.024
    }

    // Remove the sign of the number for easier comparision
    const abs_float = Math.abs(float)

    // Less than Thousand does not need text or a insignifiant digits
    if (abs_float < Math.pow(10, 3)) {
      denom = 1
      label = "Bytes"
    } else if (abs_float >= Math.pow(10, 3) && abs_float < Math.pow(10, 6)) {
      denom = Math.pow(10, 3)
      label = "KB"
    } else if (abs_float >= Math.pow(10, 6) && abs_float < Math.pow(10, 9)) {
      denom = Math.pow(10, 6)
      label = "MB"
    } else if (abs_float >= Math.pow(10, 9) && abs_float < Math.pow(10, 12)) {
      denom = Math.pow(10, 9)
      label = "GB"
    } else {
      denom = Math.pow(10, 12)
      label = "TB"
    }

    // Process the number into a presentable format
    const number = float / denom

    const precise = NumberHelpers.number_with_precision(number, {
      precision: _precision,
      significant: _significant,
      delimiter: _delimiter,
      separator: _separator,
      strip_insignificant_zeros: label === "Bytes" ? true : _strip_insignificant_zeros,
    })

    return `${precise} ${label}`
  }

  static number_to_phone(number, opts) {
    let first
    if (opts == null) {
      opts = {}
    }
    const _area_code = opts.area_code != null ? opts.area_code : false
    const _delimiter = opts.delimiter != null ? opts.delimiter : "-"
    let _country_code = opts.country_code != null ? opts.country_code : false
    let _extension = opts.extension != null ? opts.extension : false

    // Not a numerical value
    if (isNaN(number)) {
      return number
    }

    const str = number.toString()
    const lng = str.length

    // Last Four Digits
    const last = str.substr(lng - 4, lng)

    if (lng < 8) {
      first = str.substr(0, 3)
    } else {
      first = str.substr(0, 3)
      const second = str.substr(3, 3)

      // Area Code
      if (_area_code) {
        first = `(${first}) ${second}`
      } else {
        first = `${first}${_delimiter}${second}`
      }
    }

    // Extension Code
    _extension = _extension ? ` x ${opts.extension}` : ""

    // Country Code
    _country_code = _country_code ? `+${_country_code}${_delimiter}` : ""

    return `${_country_code}${first}${_delimiter}${last}${_extension}`
  }

  static number_to_percentage(float, opts) {
    if (opts == null) {
      opts = {}
    }
    const _precision = opts.precision != null ? opts.precision : 3
    const _separator = opts.separator != null ? opts.separator : "."
    const _significant = opts.significant != null ? opts.significant : false
    const _delimiter = opts.delimiter != null ? opts.delimiter : ""
    const _strip_insignificant_zeros =
      opts.strip_insignificant_zeros != null ? opts.strip_insignificant_zeros : false

    if (!isNaN(float)) {
      float = NumberHelpers.number_with_precision(float, {
        precision: _precision,
        significant: _significant,
        delimiter: _delimiter,
        separator: _separator,
        strip_insignificant_zeros: _strip_insignificant_zeros,
      })
    }

    return `${float}%`
  }

  static numberToDuration(value, options) {
    const [prefix, duration, suffix] = NumberHelpers.numberToDurationParts(value)
    const formattedDuration = NumberHelpers.number_with_precision(duration, {
      strip_empty_fractional_parts: true,
      strip_insignificant_zeros: true,
      ...options,
    })
    return `${prefix}${formattedDuration} ${suffix}`
  }

  static numberToDurationParts(value) {
    if (value === 0) {
      return ["", 0, "ms"]
    } else if (value < 1) {
      return ["", Math.round(value * 1000), "µs"]
    } else if (value < 1000) {
      return ["", Math.round(value), "ms"]
    } else if (value < 60000) {
      return ["", Math.round((value / 1000) * 100) / 100, "sec"]
    } else {
      return ["~", Math.round(value / 60000), "min"]
    }
  }

  static millsecondsToTimeParts(milliseconds) {
    const hour = 60 * 60 * 1000
    const minute = 60 * 1000

    const hours = Math.floor(milliseconds / hour)
    const minutes = Math.floor((milliseconds - hours * hour) / minute)
    const seconds = Math.floor((milliseconds - hours * hour - minutes * minute) / 1000)

    return { hours, minutes, seconds }
  }
}

function __guard__(value, transform) {
  return typeof value !== "undefined" && value !== null ? transform(value) : undefined
}

export default NumberHelpers
