import NumberHelpers from "#Root/base/number_helpers"
import { FormatterContext } from "#Root/contexts/FormatterContext"

const UNSET_VALUES = [null, undefined]
const useFormattedNumber = ({ removeSpaces } = {}) => {
  const formatter = React.useContext(FormatterContext)

  const getOptions = (number, precision) => {
    const numberFormat = formatter.number
    const separator = numberFormat === "DC" ? "," : "."
    const delimiter = numberFormat === "DC" ? "." : ","

    const options = {
      separator,
      delimiter,
      strip_empty_fractional_parts: true,
      strip_insignificant_zeros: true,
      precision: precision,
      significant: false,
      labels: {
        thousand: "K",
        million: "M",
        billion: "B",
        trillion: "T",
        quadrillion: "Q",
      },
    }

    return options
  }

  const formatNumberWithPrecision = (number, precision) => {
    const options = getOptions(number, precision)
    if (UNSET_VALUES.includes(number)) number = 0
    const formattedNumber = NumberHelpers.number_with_precision(number, options)

    return removeSpaces ? formattedNumber.replace(/\s/g, "") : formattedNumber
  }
  const formatNumberToHuman = (number, precision) => {
    const options = getOptions(number, precision)
    if (UNSET_VALUES.includes(number)) number = 0
    const formattedNumber = NumberHelpers.number_to_human(number, options)

    return removeSpaces ? formattedNumber.replace(/\s/g, "") : formattedNumber
  }

  return { formatNumberWithPrecision, formatNumberToHuman }
}

useFormattedNumber.propTypes = {
  number: PropTypes.number.isRequired,
  precision: PropTypes.number,
}

export default useFormattedNumber
