import { useQuery } from "@apollo/react-hooks"
import isEqual from "lodash.isequal"

import metricKeysQuery from "../graphql/metric_keys_query"

export function mapKeysToMetadata(keys) {
  let type = null
  const tagSet = new Set()
  const tagCombinations = []

  for (const key of keys) {
    type = key.type
    const keyTags = new Set()
    key.tags.forEach(({ key: tagKey }) => {
      tagSet.add(tagKey)
      keyTags.add(tagKey)
    })
    const tags = [...keyTags].sort()
    if (tags.length > 0 && !tagCombinations.some((element) => isEqual(element, tags))) {
      tagCombinations.push(tags)
    }
  }

  return { type, tagCombinations, tags: Array.from(tagSet) }
}

export const useMetricKeyMetadata = (options, onMatch) => {
  let metadata = null
  const { loading, error, data } = useQuery(metricKeysQuery, {
    ...options,
    onCompleted: (data) => {
      if (!data) {
        return
      }

      const key = data.app.metrics.keys[0]
      if (key) {
        onMatch(key)
      }
    },
  })

  if (
    data &&
    data.app &&
    data.app.metrics &&
    data.app.metrics.keys &&
    data.app.metrics.keys.length > 0
  ) {
    metadata = mapKeysToMetadata(data.app.metrics.keys)
  }

  return { loading, error, metadata }
}

export default useMetricKeyMetadata
