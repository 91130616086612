import PropTypes from "prop-types"
import React from "react"

export class SegmentedControl extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.string,
  }

  static defaultProps = {
    legacy: true,
  }

  handleClick(new_status, event) {
    event.preventDefault()
    event.stopPropagation()

    this.props.onChange(new_status)
  }

  render() {
    const { options, selected } = this.props
    const selectedIndex = options.findIndex((option) => option.value === selected)

    const buttons = options.map((option, index) => {
      const isSelected = index === selectedIndex

      return (
        <a
          key={index}
          className={`c-button-group__item c-button c-button--white c-button--sm ${isSelected ? "" : "c-button--white-inactive"
            } cursor-pointer`}
          onClick={this.handleClick.bind(this, option.value)}
        >
          {option.name}
        </a>
      )
    })

    return <div className="c-button-group">{buttons}</div>
  }
}

export default SegmentedControl
