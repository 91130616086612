import { bisector } from "d3"

export const DEFAULT_GRAPH_SETTINGS = {
  yAxisMin: null,
  showHorizontalGridLines: false,
  height: 0,
  width: 0,
  canvasWidth: 0,
  canvasHeight: 0,
  xScale: null,
  yScale: null,
  renderer: "line",
  hasYAxis: false,
}

export const ChartGraphContext = React.createContext(DEFAULT_GRAPH_SETTINGS)

export const ChartGraphProvider = ({
  children,
  originalYDomain,
  originalXDomain,
  yScale,
  xScale,
  timeseries,
  ...props
}) => {
  const originalDomain = React.useRef()
  const [domain, setDomain] = React.useState({ x: originalXDomain, y: originalYDomain })
  const [isScaleOverridden, setIsScaleOverridden] = React.useState(false)

  React.useEffect(() => {
    // Let's see what's the saved domain is. That's the original domain (x/y limits) that the graph is using
    const currentDomain = originalDomain.current

    // If there's nothing saved, initialize it
    if (!currentDomain) {
      originalDomain.current = { x: originalDomain, y: originalYDomain }
      return
    }

    // This can only happen because timeseries changed, either by polling or by disabling lines
    // Could also happen because we forced changed the yAxis
    const hasChangedXDomain =
      new Date(currentDomain.x[0]).getTime() !== new Date(originalXDomain[0]).getTime() ||
      new Date(currentDomain.x[1]).getTime() !== new Date(originalXDomain[1]).getTime()

    const hasChangedYDomain =
      currentDomain.y[0] !== originalYDomain[0] || currentDomain.y[1] !== originalYDomain[1]

    const hasChangedInitialDomains = hasChangedXDomain || hasChangedYDomain

    // Save the new limits if changed
    if (hasChangedInitialDomains) {
      originalDomain.current = { x: originalXDomain, y: originalYDomain }

      // Override if we're not zoomed
      if (!isScaleOverridden) {
        xScale.domain(originalXDomain)
        yScale.domain(originalYDomain)
        setDomain({ x: originalXDomain, y: originalYDomain })
      }
    }
  }, [originalXDomain, originalYDomain, isScaleOverridden, xScale, yScale])

  // Re-apply the full domain to the scales
  const restoreScales = () => {
    xScale.domain(originalXDomain)
    yScale.domain(originalYDomain)

    setDomain({ x: originalXDomain, y: originalYDomain })
    setIsScaleOverridden(false)
  }

  const findClosestDataPoint = (value, data) => {
    const bisect = bisector((d) => d.x).left
    const index = bisect(data, value)
    const closestPoint = data[Math.max(0, Math.min(index, data.length - 1))]
    return closestPoint ? closestPoint.x : value
  }

  // Get the coordinates of the zoomed area and set the new domain
  // We don't want the consumer to do any d3 specific operations, just let us know the new x,y values
  const setScaleCoordinates = ({ x, y }) => {
    // Get the initialvalues based on the user selection
    let xMin = xScale.domain(domain.x).invert(x[0])
    let xMax = xScale.domain(domain.x).invert(x[1])

    // Get the closest data points to the selected xMin and xMax
    xMin = findClosestDataPoint(xMin, timeseries.series[0].data)
    xMax = findClosestDataPoint(xMax, timeseries.series[0].data)

    // Ensure xMin and xMax are within the original domain
    xMin = Math.max(xMin, domain.x[0])
    xMax = Math.min(xMax, domain.x[1])

    // Get the initial yMin and yMax based on the user selection
    let yMin = yScale.domain(domain.y).invert(y[1])
    let yMax = yScale.domain(domain.y).invert(y[0])

    // Ensure yMin and yMax are within the original domain
    yMin = Math.max(yMin, domain.y[0])
    yMax = Math.min(yMax, domain.y[1])

    // Set the new domains for the scales
    yScale.domain([yMin, yMax])
    xScale.domain([xMin, xMax])

    // Update the domain state and indicate that the scale has been overridden
    setDomain({ x: [xMin, xMax], y: [yMin, yMax] })
    setIsScaleOverridden(true)
  }

  return (
    <ChartGraphContext.Provider
      value={{
        ...DEFAULT_GRAPH_SETTINGS,
        ...props,
        // Due to the nature of d3 scales, where it's just function and we just mutate/update it, we also need a reference to trigger a re-render, and it's the updated domain
        domain,
        yScale,
        xScale,
        setScaleCoordinates,
        restoreScales,
        isScaleOverridden,
      }}
    >
      {children}
    </ChartGraphContext.Provider>
  )
}

ChartGraphProvider.propTypes = {
  children: PropTypes.node.isRequired,
  yAxisMin: PropTypes.number,
  showHorizontalGridLines: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  canvasWidth: PropTypes.number,
  canvasHeight: PropTypes.number,
  xScale: PropTypes.func,
  yScale: PropTypes.func,
  renderer: PropTypes.string,
  hasYAxis: PropTypes.bool,
  colors: PropTypes.func,
  onClick: PropTypes.func,
  resolution: PropTypes.string,
  originalXDomain: PropTypes.array,
  originalYDomain: PropTypes.array,
  timeseries: PropTypes.object,
  zoomEnabled: PropTypes.bool,
}

export const useChartGraphContext = () => React.useContext(ChartGraphContext)
