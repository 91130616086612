import gql from "graphql-tag"

export default gql`
  fragment TrelloCard on TrelloCard {
    id
    title
    description
    url
    remoteId
    listId
  }
`
