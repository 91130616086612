import gql from "graphql-tag"

import ExceptionIntegrationsFragment from "./_exception_integrations_fragment"
import PerformanceIntegrationsFragment from "./_performance_integrations_fragment"

export default gql`
  mutation createPivotalTrackerStoryMutation($appId: String!, $incidentNumber: Int!, $title: String!, $description: String!) {
    createPivotalTrackerStory(appId: $appId, incidentNumber: $incidentNumber, title: $title, description: $description) {
      ... on PerformanceIncident {
        id
        ...PerformanceIntegrationsFragment
      }
      ... on ExceptionIncident {
        id
        ...ExceptionIntegrationsFragment
      }
    }
  }
  ${PerformanceIntegrationsFragment}
  ${ExceptionIntegrationsFragment}
`
