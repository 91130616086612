import dayjs from "dayjs"
import PropTypes from "prop-types"
import React from "react"
import { graphql } from "react-apollo"

import TriggerFormDescription from "#Components/triggers/form/fields/Description"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
import TriggerFormWarmupAndCooldown from "#Components/triggers/form/fields/WarmupAndCooldown"
import { TRIGGER_TYPES } from "#Root/constants/Triggers"
import LoadingAnimation from "#Root/ui/LoadingAnimation/LoadingAnimation"

import appMetricKeysQuery from "../../../../graphql/app_metric_keys_query"
import { findTagValue } from "../../../../utils/metric_keys"
import withNotifiers, { notifiersPropType } from "../../../../wrappers/notifiers"
import withWarmupAndCooldown, {
  warmupAndCooldownPropType,
} from "../../../../wrappers/warmup_and_cooldown"
import ErrorBox from "../../../shared/error_box"
import TriggerGraphPreview from "../../GraphPreview"
import ShareLink from "../ShareLink"
import TriggerFormHostname from "./Hostname"

const formName = "HostDiskUsage"
export class TriggerFormHostDiskUsage extends React.Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    data: PropTypes.shape({
      loading: PropTypes.bool,
      error: PropTypes.object,
      app: PropTypes.object,
    }).isRequired,
    blankSlate: PropTypes.bool,
    trigger: PropTypes.object,
    appId: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    notifiers: notifiersPropType.isRequired,
    warmupAndCooldown: warmupAndCooldownPropType.isRequired,
    error: PropTypes.object,
  }
  static form = formName
  static params = {
    hostname: "tag",
    mountpoint: "tag",
    comparisonOperator: "field",
    conditionValue: "field",
  }

  constructor(props, context) {
    super(props, context)
    const { trigger, params } = props
    // Use data from existing trigger
    if (trigger && trigger.id) {
      const { tags } = trigger
      this.state = {
        hostname: findTagValue(tags, "hostname"),
        mountpoint: findTagValue(tags, "mountpoint"),
        conditionValue: trigger.thresholdCondition.value,
        description: trigger.description,
      }
      return
    } else {
      this.state = {
        hostname: params.hostname || "*",
        mountpoint: this.findMountpoint(params.mountpoint, [""]),
        conditionValue: params.conditionValue || 90,
        description: params.description || "",
      }
      return
    }
  }

  findMountpoint(mountpointParam, mountpoints) {
    if (mountpoints.includes(mountpointParam)) {
      return mountpointParam
    } else {
      return mountpoints[0]
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      params,
      data: { loading, error },
    } = nextProps
    if (loading || error) {
      return
    }
    if (!this.props.trigger && !this.state.mountpoint) {
      const mountpoints = this.mountpointsFrom(nextProps.data)
      this.setState({
        mountpoint: this.findMountpoint(params.mountpoint, mountpoints),
      })
    }
  }

  triggerObject() {
    const { hostname, mountpoint } = this.state
    return {
      metricName: "disk_usage",
      field: "GAUGE",
      tags: [
        { key: "host_metric", value: "" },
        { key: "hostname", value: hostname },
        { key: "mountpoint", value: mountpoint },
      ],
    }
  }

  handleFormSubmit = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const { conditionValue, description } = this.state
    this.props.onChange({
      ...this.triggerObject(),
      comparisonOperator: "GREATER_THAN",
      conditionValue: parseFloat(conditionValue),
      description,
    })
  }

  mountpointsFrom = (data) => {
    const mountpoints = new Set()
    const {
      app: {
        metrics: { keys },
      },
    } = data
    for (const metricKey of keys) {
      for (const tag of metricKey.tags) {
        if (tag.key == "mountpoint") {
          mountpoints.add(tag.value)
        }
      }
    }
    return Array.from(mountpoints.values()).sort()
  }

  isKnownMountpointParam(mountpointParam, mountpoints) {
    if ((mountpointParam || "").trim() === "") {
      return true
    }

    return mountpoints.includes(mountpointParam)
  }

  render() {
    let cancelLink
    let saveButtonLabel = "Save Trigger"
    const {
      appId,
      error,
      params,
      data,
      data: { loading, app },
      onClose,
      notifiers,
      warmupAndCooldown,
    } = this.props
    const { error: fetchError } = data

    if (loading && !app) {
      return <LoadingAnimation message="Loading mountpoints" />
    } else if (fetchError) {
      return <ErrorBox error={fetchError} />
    }

    const mountpoints = this.mountpointsFrom(data)
    if (mountpoints.length == 0) {
      const error = {
        message:
          "No mountpoints found for this app. Cannot create a disk usage trigger without host metrics.",
      }
      const help = {
        label: "Learn more about host metrics.",
        url: "https://docs.appsignal.com/metrics/host-metrics/index.html",
      }
      return <ErrorBox error={error} help={help} />
    }

    if (this.props.blankSlate) {
      saveButtonLabel = "Save your first Trigger"
    } else {
      cancelLink = (
        <button id="cancelLink" onClick={onClose} className="c-button c-button--sm c-button--white">
          Cancel
        </button>
      )
    }
    const { hostname } = this.state
    const knownMountpointFromParam = this.isKnownMountpointParam(params.mountpoint, mountpoints)

    return (
      <>
        <div className="relative h-full overflow-y-auto">
          <div className="sticky top-0 bg-white z-10 px-5 py-4 border-b border-gray-200">
            <h2 className="c-heading text-base mb-0 text-gray-800">Host Disk usage Trigger</h2>
          </div>
          {error}
          <form onSubmit={this.handleFormSubmit} acceptCharset="UTF-8" className="px-5">
            <p className="py-5">
              Receive alerts when a volume on your host&apos;s disk is using more space than you
              specify for this trigger.
            </p>
            <hr />

            <div className="c-form">
              <label className="c-form__label" htmlFor="mountpoint">
                Mountpoint
              </label>
              <div className="c-form__element">
                <div className="c-select">
                  <select
                    onChange={(e) => this.setState({ mountpoint: e.target.value })}
                    id="mountpoint"
                    name="mountpoint"
                    value={this.state.mountpoint}
                    className="c-select__input required"
                  >
                    {mountpoints.map((mountpoint) => (
                      <option key={"mountpoint-" + mountpoint} value={mountpoint}>
                        {mountpoint}
                      </option>
                    ))}
                  </select>
                </div>
                {!knownMountpointFromParam && (
                  <p className="c-form__error">
                    Mounpoint <code>{params.mountpoint}</code> does not exist for this app. Please
                    select another mountpoint.
                  </p>
                )}
              </div>
            </div>

            <div className="c-form">
              <label className="c-form__label" htmlFor="conditionValue">
                Is above
              </label>
              <div className="c-form__element">
                <div className="c-textfield">
                  <input
                    className="c-textfield__input"
                    id="conditionValue"
                    name="conditionValue"
                    type="number"
                    value={this.state.conditionValue}
                    min="1"
                    max="100"
                    aria-label="Threshold value"
                    onChange={(e) => this.setState({ conditionValue: e.target.value })}
                  />
                  <span className="c-textfield__suffix">%</span>
                </div>
              </div>
            </div>
            <hr />

            <TriggerFormHostname
              appId={appId}
              hostname={hostname}
              onChange={(newHostname) => this.setState({ hostname: newHostname })}
            />
            <hr />

            <TriggerFormWarmupAndCooldown warmupAndCooldown={warmupAndCooldown} />
            <hr />

            <TriggerFormDescription
              description={this.state.description}
              onChange={(newDescription) => this.setState({ description: newDescription })}
            />
            <hr />

            <TriggerFormNotifiers notifiers={notifiers} />
            <hr />

            <ShareLink trigger={this.state} form={formName} warmupAndCooldown={warmupAndCooldown} />

            <div className="my-5">
              <button className="c-button c-button--sm mr-4">{saveButtonLabel}</button>
              {cancelLink}
            </div>
          </form>
        </div>
        <div className="w-256 p-5 bg-gray-100">
          <TriggerGraphPreview
            title="Host Disk usage graph preview"
            lineLabel={TRIGGER_TYPES["HostDiskUsage"].label}
            valueFormat={TRIGGER_TYPES["HostDiskUsage"].formatter}
            appId={appId}
            trigger={this.triggerObject()}
          />
        </div>
      </>
    )
  }
}

export const withMetrics = graphql(appMetricKeysQuery, {
  options: ({ appId }) => ({
    notifyOnNetworkStatusChange: false,
    variables: {
      appId: appId,
      name: "disk_usage",
      start: dayjs().subtract(1, "hour").toISOString(),
      tags: [
        {
          key: "host_metric",
          value: "",
        },
        {
          key: "hostname",
          value: "*",
        },
        {
          key: "mountpoint",
          value: "*",
        },
      ],
    },
  }),
})

export default withWarmupAndCooldown(withMetrics(withNotifiers(TriggerFormHostDiskUsage)))
