import { Portal } from "react-portal"

import FormattedTime from "#Root/formatters/FormattedTime"

import { MIN_HOVER_PANEL_WIDTH } from "../../constants"
import { useChartDataContext } from "../../contexts/DataContext"
import { useDraggingContext } from "../../contexts/DraggingContext"
import { useChartGraphContext } from "../../contexts/GraphContext"
import { HoverContext } from "../../contexts/HoverContext"
import { SORT_ASC, SORT_NAME, useChartHoverStateContext } from "../../contexts/HoverStateContext"
import MarkerButton from "./MarkerButton"
import PreviewMode from "./PreviewMode"
import Row from "./Row"
import SortingMenu from "./SortingMenu"
import TimeDetectiveButton from "./TimeDetectiveButton"
import { useHoverPlacement } from "./useHoverPlacement"

const sortSeries = ({ series, sortBy, sortDirection, dataIndex }) => {
  return series
    .map((serie) => ({
      id: serie.id,
      value: serie.data[dataIndex].y,
      label: serie.label,
    }))
    .sort((a, b) => {
      if (sortBy === SORT_NAME) {
        return sortDirection === SORT_ASC
          ? a.label.localeCompare(b.label)
          : b.label.localeCompare(a.label)
      } else {
        return sortDirection === SORT_ASC ? a.value - b.value : b.value - a.value
      }
    })
}

const Hover = ({ children }) => {
  const { hoverRef, setHoverState, dataIndex, invertDate, sortBy, sortDirection } =
    useChartHoverStateContext()
  const { visibleSeries, timeseries, disabledSeries } = useChartDataContext()
  const { isVisible, ...placementState } = useHoverPlacement()
  const { width } = useChartGraphContext()
  const { hasSelectedArea } = useDraggingContext()

  if (!isVisible) {
    return null
  }

  if (hasSelectedArea) {
    return null
  }

  // Ensure we only render actual React elements
  const childrenArray = (Array.isArray(children) ? children : [children]).filter((child) =>
    React.isValidElement(child),
  )

  const allSeries = [...visibleSeries, ...disabledSeries]

  return (
    <Portal>
      <div
        data-testid="hover-panel"
        className="fixed z-[1000] bg-white rounded shadow text-ms"
        style={{ minWidth: MIN_HOVER_PANEL_WIDTH, ...placementState, maxWidth: width }}
        ref={hoverRef}
        onMouseLeave={() => setHoverState({})}
      >
        <HoverContext.Provider value={{ resolution: timeseries.resolution, date: invertDate }}>
          <header className="py-2 px-3 border-b border-gray-200 flex items-center justify-between">
            <FormattedTime
              format={timeseries.resolution == "DAILY" ? "dateLong" : "weekday"}
              value={invertDate}
            />
            <SortingMenu />
          </header>
          <section className="py-1 max-h-24 overflow-y-auto custom-scrollbar">
            <ul>
              {sortSeries({ series: allSeries, sortBy, sortDirection, dataIndex }).map((serie) => (
                <Row key={serie.id} serie={serie} />
              ))}
            </ul>
          </section>
          {childrenArray.length > 0 && (
            <footer className="px-3 py-2 border-t border-gray-200 space-y-2">
              {childrenArray}
            </footer>
          )}
        </HoverContext.Provider>
      </div>
    </Portal>
  )
}

Hover.propTypes = {
  children: PropTypes.node,
  sortBy: PropTypes.oneOf(["name", "value"]),
  sortDirection: PropTypes.oneOf(["asc", "desc"]),
}

Hover.TimeDetectiveButton = TimeDetectiveButton
Hover.MarkerButton = MarkerButton
Hover.PreviewMode = PreviewMode
Hover.displayName = "Hover"

export default Hover
