import PropTypes from "prop-types"
import React from "react"

import TooltipWithIcon from "../TooltipWithIcon"

const DataFormatSelector = ({ visual, onChange }) => {
  const handleValueFormatChange = (event) => {
    const value = event.target.value
    onChange(deconstructFormat(value))
  }

  const deconstructFormat = (value) => {
    if (value.startsWith("size-")) {
      const [format, formatInput] = value.split("-")
      return {
        format,
        formatInput
      }
    } else {
      return {
        format: value,
        formatInput: null
      }
    }
  }

  const formatFormatValue = ({ format, formatInput }) => {
    const input = formatInput ? `-${formatInput}` : ""
    return `${format}${input}`
  }

  const renderOption = (label, formatString) => {
    return <option value={formatString}>{label}</option>
  }

  return (
    <div className="c-form">
      <label className="c-form__label" htmlFor="visual-format">
        Data format
        <TooltipWithIcon
          content={
            <div>
              <p className="my-2">Select the value format that is used to display values in the graph and graph legends.</p>
              <hr className="ignore-old-css -mx-3 border-gray-700 my-2" />
              <p className="my-2"><a className="text-underline text-blue-200" href="https://docs.appsignal.com/metrics/custom.html#metric-values-value-formatting" target="_blank" rel="noopener noreferrer">Read the documentation</a></p>
            </div>
          }
        />
      </label>
      <div className="c-form__element">
        <div className="c-select">
          <select
            id="visual-format"
            name="graph-format"
            className="c-select__input"
            onChange={handleValueFormatChange}
            value={formatFormatValue(visual)}
          >
            {renderOption("Number", "number")}
            {renderOption("Percent", "percent")}
            {renderOption("Duration", "duration")}
            {renderOption("Throughput", "throughput")}
            <optgroup label="Size">
              {renderOption("Size in Bits", "size-bit")}
              {renderOption("Size in Byte", "size-byte")}
              {renderOption("Size in Kilobits", "size-kilobit")}
              {renderOption("Size in Kilobytes", "size-kilobyte")}
              {renderOption("Size in Megabytes", "size-megabyte")}
            </optgroup>
          </select>
        </div>
      </div>
    </div>
  )
}

DataFormatSelector.propTypes = {
  visual: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default DataFormatSelector
