import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)

export const TIMESTAMP_SURROUND = { value: 1, unit: "hour" }
export const TIMEFRAMES = {
  R1H: { key: "R1H", value: 1, unit: "hour", label: "1H", pollInterval: 60000 },
  R4H: { key: "R4H", value: 4, unit: "hour", label: "4H", pollInterval: 60000 },
  R8H: { key: "R8H", value: 8, unit: "hour", label: "8H", pollInterval: 60000 },
  R12H: { key: "R12H", value: 12, unit: "hour", label: "12H", pollInterval: 2 * 60000 },
  R24H: { key: "R24H", value: 24, unit: "hour", label: "24H", pollInterval: 2 * 60000 },
  R48H: { key: "R48H", value: 48, unit: "hour", label: "48H", pollInterval: 2 * 60000 },
  R7D: { key: "R7D", value: 7, unit: "days", label: "7D", pollInterval: 5 * 60000 },
  R30D: { key: "R30D", value: 30, unit: "days", label: "30D", pollInterval: 5 * 60000 },
}

export class Timeframes {
  static timeframeFromParam(timeframe, fallback = "R4H", timeframes = TIMEFRAMES) {
    return (
      timeframes[timeframe] ||
      Object.values(timeframes).find((range) => {
        return range.label == timeframe
      }) ||
      timeframes[fallback]
    )
  }

  static backwardsCompatibleTimeframeKey(timeframe) {
    if (timeframe == "custom" || timeframe == null) {
      return null
    }
    return this.timeframeFromParam(timeframe).key
  }

  static isValidTimeframe(timeframe) {
    return this.backwardsCompatibleTimeframeKey(timeframe) !== null
  }

  static fromToFromTimeframe(param, fallback = "R4H", timeframes = TIMEFRAMES) {
    const timeframe = this.timeframeFromParam(param, fallback, timeframes)
    return {
      from: dayjs()
        .subtract(timeframe.value, timeframe.unit)
        .utc()
        .second(0)
        .millisecond(0)
        .toISOString(),
      to: dayjs()
        .utc()
        .second(0)
        .millisecond(0)
        .toISOString(),
    }
  }

  static pollIntervalFromTimeframe(timeframe) {
    return this.timeframeFromParam(timeframe, null)?.pollInterval || 0
  }

  static timeframeFromTimestamp(timestamp) {
    for (const timeframe of TIMEFRAMES) {
      if (dayjs(timestamp).isAfter(dayjs().subtract(timeframe.value, timeframe.unit))) {
        return timeframe
      }
    }
  }

  static fromToFromTimestamp(timestamp) {
    const { value, unit } = TIMESTAMP_SURROUND

    return {
      from: dayjs(timestamp)
        .subtract(value, unit)
        .utc()
        .toISOString(),
      to: dayjs(timestamp)
        .add(value, unit)
        .utc()
        .toISOString(),
    }
  }
}

export default Timeframes
