import { ErrorBoundary } from "@appsignal/react"
import PropTypes from "prop-types"
import React, { Suspense } from "react"
import { HotkeysProvider } from "react-hotkeys-hook"
import { Portal } from "react-portal"

import { PlatformContext } from "#Hooks/usePlatformFeature"
import GlobalKeyboardShortcuts from "#Root/components/GlobalKeyboardShortcuts"
import { ChartSettingsProvider } from "#Root/contexts/ChartSettingsContext"
import { RestClientContext } from "#Root/contexts/RestClientContext"
import GlobalOverlays from "#Root/overlays/GlobalOverlays"
import ChartSettingsMenu from "#Root/ui/ChartSettingsMenu"
import { ReactQueryProvider } from "#Root/utils/react-query-client"
import RestApiClient from "#Root/utils/RestApiClient"

import { EnabledLabFeaturesContext } from "../contexts/EnabledLabFeaturesContext"
import { FormatterContext } from "../contexts/FormatterContext"
import MenuContextController, { MenuContext } from "../contexts/MenuContext"
import { RouterContext } from "../contexts/RouterContext"
import Ident from "../utils/Ident"
import AppSwitcher from "./shared/AppSwitcher"
import ComponentMap from "./shared/component_map"
import ErrorBox from "./shared/error_box"
import Navigation from "./shared/navigation"
import OptionalLightbox from "./shared/optional_lightbox"
import OrganizationNavigation from "./shared/OrganizationNavigation"
import Search from "./shared/Search"

// import "../../stylesheets/globals.css"
//

export class App extends React.Component {
  static propTypes = {
    formatters: PropTypes.object,
    enabledLabFeatures: PropTypes.array,
    platforms: PropTypes.array,
    router: PropTypes.object,
    appsignal: PropTypes.object,
    token: PropTypes.string,
    chartSettings: PropTypes.shape({
      horizontalSupportLines: PropTypes.bool,
      verticalSupportLines: PropTypes.bool,
      colorPalette: PropTypes.string,
    }),
    restClientSettings: PropTypes.shape({
      endpoint: PropTypes.string,
      token: PropTypes.string,
    }),
  }

  static childContextTypes = {
    router: PropTypes.object.isRequired,
    enabledLabFeatures: PropTypes.array.isRequired,
    platforms: PropTypes.array,
  }

  state = {
    action: "",
    namespace: "web",
    params: {},
  }

  trackPage() {
    Ident.trackAction(this.state.action)
  }

  componentDidMount() {
    this.trackPage()
  }

  componentDidUpdate() {
    this.trackPage()
  }

  getChildContext() {
    return {
      router: this.props.router,
      enabledLabFeatures: this.props.enabledLabFeatures,
      platforms: this.props.platforms,
    }
  }

  UNSAFE_componentWillMount() {
    return this.props.router.addCallback((action_with_params, params) => {
      const action = action_with_params.split("||")[0]
      const action_names = action_with_params.split("||")[1]
      const mapped_params = this.mappedParams(action_names, params)
      window._routerParams = mapped_params
      if (action === "native") {
        return (location.href = `/${params[0]}`)
      } else {
        return this.setState({ action, params: mapped_params })
      }
    })
  }

  mappedParams = (keys_string, values) => {
    const result = {}
    let key
    let keys = []

    if (keys_string) {
      keys = keys_string.split("|")
    }

    // Check if we have custom params (?foo=bar&baz=baa)
    // and if so, parse them
    if (keys.length < values.length) {
      result.rawParams = this.queryParamsFromString(values[values.length - 1])
      for (key in result.rawParams) {
        const val = result.rawParams[key]
        result[key] = val
      }
    }

    for (let index = 0; index < keys.length; index++) {
      key = keys[index]
      if (key.indexOf("=") > -1) {
        const keyWithValue = key.split("=")
        result[keyWithValue[0]] = keyWithValue[1]
      } else {
        result[key] = values[index]
      }
    }

    result.token = this.props.token
    return result
  }

  queryParamsFromString = (string) => {
    let match
    const urlParams = {}

    if (!string) {
      return urlParams
    }
    const search = /([^&=]+)=?([^&]*)/g
    const decode = (s) => decodeURIComponent(s.replace(/\+/g, " "))

    // eslint-disable-next-line no-cond-assign
    while ((match = search.exec(string))) {
      urlParams[decode(match[1])] = decode(match[2])
    }

    return urlParams
  }

  render() {
    let component
    const {
      appsignal,
      formatters,
      enabledLabFeatures,
      platforms,
      router,
      chartSettings,
      restClientSettings,
    } = this.props
    const { action, params } = this.state
    const Component = ComponentMap[action]

    const restApiClient = new RestApiClient(restClientSettings.endpoint, restClientSettings.token)

    if (Component) {
      component = <Component {...this.props} params={params} />
    } else {
      if (action) {
        console.error(`No component for action "${action}" registered.`)
      }
      component = <div></div>
    }

    return (
      <ErrorBoundary
        instance={appsignal}
        action={action}
        tags={{ appId: params?.appId }}
        override={(span, _) => {
          span.setParams(params)
          return span
        }}
        fallback={(error) => <ErrorBox error={error} />}
      >
        <RouterContext.Provider value={router}>
          <HotkeysProvider initiallyActiveScopes={["global"]}>
            <GlobalKeyboardShortcuts />
            <MenuContextController>
              <EnabledLabFeaturesContext.Provider value={enabledLabFeatures}>
                <PlatformContext.Provider value={platforms}>
                  <ReactQueryProvider>
                    <RestClientContext.Provider value={restApiClient}>
                      <ChartSettingsProvider value={chartSettings}>
                        <FormatterContext.Provider value={formatters}>
                          <GlobalOverlays params={params} />
                          <OptionalLightbox params={params} />
                          <Suspense fallback={null}>{component}</Suspense>
                          {this.renderMenu()}
                          {this.renderAppSwitcher()}
                          {this.renderSearch()}
                          {this.renderChartSettingsMenu()}
                        </FormatterContext.Provider>
                      </ChartSettingsProvider>
                    </RestClientContext.Provider>
                  </ReactQueryProvider>
                </PlatformContext.Provider>
              </EnabledLabFeaturesContext.Provider>
            </MenuContextController>
          </HotkeysProvider>
        </RouterContext.Provider>
      </ErrorBoundary>
    )
  }

  renderSearch = () => {
    const component = <Search params={this.state.params} />
    return this.renderPortal("react-search", component)
  }

  renderAppSwitcher = () => {
    const component = <AppSwitcher params={this.state.params} />
    return this.renderPortal("react-app-switcher", component)
  }

  renderOrgMenu = () => {
    const { params } = this.state

    const component = <OrganizationNavigation params={params} />
    return this.renderPortal("react-nav", component)
  }

  renderAppMenu = () => {
    const { params, action } = this.state

    const component = <Navigation params={params} activeComponent={action} />
    return this.renderPortal("react-nav", component)
  }

  renderMenu = () => {
    return (
      <MenuContext.Consumer>
        {(value) => {
          if (value.menu === "default") return this.renderAppMenu()
          if (value.menu === "organization") return this.renderOrgMenu()
        }}
      </MenuContext.Consumer>
    )
  }

  renderChartSettingsMenu = () => {
    const component = <ChartSettingsMenu params={this.state.params} />
    return this.renderPortal("chart-settings-menu-slot", component)
  }

  renderPortal = (div, component) => {
    const reactNavElement = document.getElementById(div)

    if (!reactNavElement) {
      return
    }

    // Ensure the native Menu is removed
    reactNavElement.innerHTML = null

    // Create a new element to attach the React menu on
    const newContainer = document.createElement("div")
    newContainer.className = "h-full"
    reactNavElement.appendChild(newContainer)

    return <Portal node={newContainer}>{component}</Portal>
  }
}

export default App
