import PropTypes from "prop-types"
import React from "react"

const MinYAxisCheckbox = ({ visual, onChange }) => {
  return (
    <div className="c-form">
      <label className="c-form__label mb-1">Y-Axis</label>
      <div className="c-form__element">
        <div className="c-checkbox items-start">
          <input
            type="checkbox"
            id="visual-y-index-starts-0"
            name="visual-y-index-starts-0"
            className="c-checkbox__input !mt-[6px]"
            value={true}
            checked={visual.minYAxis === 0}
            onChange={(_) => {
              onChange({ minYAxis: visual.minYAxis === 0 ? null : 0 })
            }}
          />
          <label
            className="c-checkbox__label ignore-old-css cursor-pointer select-none"
            htmlFor="visual-y-index-starts-0"
          >
            Start Y-axis at 0 (unless data contains negative values)
          </label>
        </div>
      </div>
    </div>
  )
}

MinYAxisCheckbox.propTypes = {
  visual: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default MinYAxisCheckbox
