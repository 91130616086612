import React from "react"

import Animation from "#Root/components/Animation/Animation"
import UrlHelper from "#Root/components/shared/url_helper"
import { useRouter } from "#Root/hooks/useRouter"
import Button from "#Root/ui/Button"
import Overlay from "#Root/ui/Overlay"

const HideGettingStartedPageOverlay = () => {
  const { router } = useRouter()

  const handleClose = () => {
    router.navigate(UrlHelper.dashboardShowPath(), { trigger: true })
  }

  return (
    <Overlay open={true} onClose={handleClose} size="md">
      <Overlay.Title title="Getting Started page is now hidden" handleClose={handleClose} />
      <Overlay.Content>
        <div className="flex gap-4">
          <Animation
            className="h-52 w-[330px] rounded shadow border-gray-200 border"
            filename="/assets/animations/gettingStartedOverlay.mp4"
            autoPlay
            disableHover
          />
          <div className="flex flex-col gap-3 max-w-xs">
            <h2 className="c-heading c-heading--lg mb-0">Want to see this page again?</h2>
            <p className="text-gray-700">
              You can enable this page again from the dropdown on the top right of the page.
              Toggling the page back on will show the page in each of your applications.
            </p>
            <Button className="w-fit" onClick={handleClose}>
              Go to your dashboard
            </Button>
          </div>
        </div>
      </Overlay.Content>
    </Overlay>
  )
}

export default HideGettingStartedPageOverlay
