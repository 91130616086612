import gql from "graphql-tag"

import StatusPageUpdateFragment from "./_statusPageUpdateFragment"

export const statusPageUpdateFormQuery = gql`
  query statusPageUpdateFormQuery($organizationSlug: String!, $statusPageId: String, $id: String) {
    organization(slug: $organizationSlug) {
      id
      statusPageUpdate(statusPageId: $statusPageId, id: $id) {
        ...StatusPageUpdate
      }
    }
  }
  ${StatusPageUpdateFragment}
`

export default statusPageUpdateFormQuery
