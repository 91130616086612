import PropTypes from "prop-types"
import React from "react"

export const notifiersPropType = PropTypes.shape({
  onChange: PropTypes.func.isRequired,
  available: PropTypes.array.isRequired,
  selected: PropTypes.object.isRequired,
})

/*
withNotifiers is a High order Component (HoC) that adds a `notifiers` propType to the wrapped component
with 3 props that are required to render a list of checkboxes for each notifiers,
everything else is abstracted away in this component.

This component hijacks the `onChange` prop and injects the `notifierIds` field with all selected notifiers.
To use this component follow these steps:

Step one, import the withNotifiers mixin, the PropType definition and the TriggerFormNotifiers input.

```
import withNotifiers, { notifiersPropType } from "../../../wrappers/notifiers"
import TriggerFormNotifiers from "#Components/triggers/form/fields/Notifiers"
```

Step two, require the right props:

```
propTypes: {
  notifiers: notifiersPropType.isRequired
}
```
Step three, render the notifiers component:

```
<section>
  <TriggerFormNotifiers notifiers={notifiers} />
</section>
```

Also don't forget to wrap the component

```
export default withNotifiers(<component>)
```
*/
export default function withNotifiers(WrappedComponent) {
  class WithNotifiers extends React.Component {
    constructor(props) {
      super(props)

      const selectedNotifiersSet = new Set()

      if (props.trigger && props.trigger.id) {
        for (const notifier of props.trigger.notifiers) {
          selectedNotifiersSet.add(notifier.id)
        }
      }
      this.state = {
        selectedNotifiersSet: selectedNotifiersSet,
      }
    }

    handleCheckBoxChange(notifierId) {
      const set = this.state.selectedNotifiersSet
      if (set.has(notifierId)) {
        set.delete(notifierId)
      } else {
        set.add(notifierId)
      }
      this.setState({ selectedNotifiersSet: set })
    }

    handleFormSubmit(params) {
      params["notifierIds"] = Array.from(this.state.selectedNotifiersSet)
      this.props.onChange(params)
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          notifiers={{
            available: this.props.availableNotifiers,
            onChange: this.handleCheckBoxChange.bind(this),
            selected: this.state.selectedNotifiersSet,
          }}
          onChange={this.handleFormSubmit.bind(this)}
        />
      )
    }
  }

  WithNotifiers.displayName = `WithNotifiers(${
    WrappedComponent.displayName || WrappedComponent.name || "Component"
  })`
  WithNotifiers.propTypes = {
    availableNotifiers: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    trigger: PropTypes.object,
  }

  return WithNotifiers
}
