import PropTypes from "prop-types"

/**
 * This column component is for prop typing only, since the Table will render it's own Columns
 * This is due to the library's implementation of columns
 * For more see https://github.com/Autodesk/react-base-table/issues/91
 * @param  {String} dataKey The key that associates the column with it's data
 * @param  {String} title The column title
 */

const Column = () => null

Column.propTypes = {
  dataKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  headerClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  style: PropTypes.object,
  dataGetter: PropTypes.func,
  align: PropTypes.oneOf(["left", "center", "right"]),
  flexGrow: PropTypes.number,
  flexShrink: PropTypes.number,
  maxWidth: PropTypes.number,
  minWidth: PropTypes.number,
  frozen: PropTypes.oneOf(["left", "right", true, false]),
  hidden: PropTypes.bool,
  resizable: PropTypes.bool,
  sortable: PropTypes.bool,
  cellRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  headerRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  tooltip: PropTypes.node,
  __TYPE: PropTypes.string,
}

Column.defaultProps = {
  __TYPE: "Column",
}

export default Column
