import React from "react"

const NoResults = () => (
  <div className="c-box mb-0 flex px-5 py-4">
    <div className="flex-shrink-0 w-10 mr-4">
      <img src="/assets/illustrations/search.svg" />
    </div>
    <div className="">
      <h2 className="font-semibold mb-2">No results found</h2>
      <ul className="c-ul text-gray-700 ml-2 space-y-1 mb-3">
        <li>Try adjusting the filters to broaden your search.</li>
        <li>Check if you&apos;re using error or action names. Partial search is only supported for error messages.</li>
      </ul>
      <p className="text-gray-700 mb-2">Example:</p>
      <pre className="c-code bg-gray-100 text-gray-700 whitespace-normal leading-relaxed">
        action:User::BlogpostController#edit error:StandardError message:&quot;could not connect&quot; tag:frontend1
      </pre>
    </div>
  </div>
)

export default NoResults
