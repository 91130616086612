import PropTypes from "prop-types"
import React from "react"

import { hashCode } from "../../utils/react_utils"

const GraphQLErrors = ({ title, error }) => {
  let content = <pre>{error.message}</pre>
  const errors = unwrapError(error)

  if (errors.length > 0) {
    const errorsList = []
    for (const error of errors) {
      if (!error.extensions || !error.extensions.problems) {
        continue
      }
      for (const problem of error.extensions.problems) {
        const formattedPath = formatPathAndValue(problem, error.extensions.value)
        errorsList.push(
          <li key={hashCode(problem.explanation)}>
            {problem.explanation}
            {formattedPath}
          </li>
        )
      }
    }
    if (errorsList.length > 0) {
      content = <ul>{errorsList}</ul>
    }
  }

  return (
    <div className="mod-validation-errors">
      <h2>{title || "One or more errors prevented saving"}</h2>
      {content}
    </div>
  )
}

function unwrapError(error) {
  return (error.networkError && error.networkError.result && error.networkError.result.errors) || []
}

// Returns a formatted path on which the error occurred
// Give a list of GraphQL paths on a problem and it will return a string with the fields chained with dots.
//
// Usage
//   formatPath({ path: [0, "metrics", 0, "title"] }, [{ metrics: [{ title: "my title" }] }])
//   // => " where metrics.title = \"my title\""
//   formatPath({ path: [0, "metrics", 0, "number"] }, [{ metrics: [{ number: 1 }] }])
//   // => " where metrics.number = 1"
//   formatPath({}, {})
//   // => ""
function formatPathAndValue(problem, value) {
  if (!problem || !problem.path || problem.path.length == 0) {
    return ""
  }

  let actualValue = value
  const realPaths = []
  problem.path.map((path) => path)
  for (const path of problem.path) {
    if (typeof path === "number") {
      realPaths.push(`[${path}]`)
    } else {
      realPaths.push(path)
    }
    actualValue = actualValue[path]
  }
  const formattedValue = typeof actualValue === "string" ? `"${actualValue}"` : actualValue
  return ` where: ${realPaths.join(".")} = ${formattedValue}`
}

GraphQLErrors.propTypes = {
  title: PropTypes.string,
  error: PropTypes.object,
}

export default GraphQLErrors
